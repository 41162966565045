import React from "react";
import NavUser from "./NavUser";
import NavLogo from "./NavLogo";
import NavRetailer from "./NavRetailer";
import NavNotification from "./NavNotification";
import { logout } from "../../../../api/LoginAPI";

class FeatureNavigationTopNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: {
        newPass: "",
        confNewPass: "",
        successMessage: "",
        errorMessage: "",
      },
    };
  }

  componentDidMount() {
    const { ShowChangePasswordPrompt } = this.props.fields;
    if (
      ShowChangePasswordPrompt &&
      ShowChangePasswordPrompt.value &&
      !sessionStorage.getItem("promptPassword")
    ) {
      sessionStorage.setItem("setPassword", true);
      if (window.location.pathname.indexOf("profile") !== 1) {
        window.location.pathname = "/profile";
      }
    }
  }

  editProfilePage = () => {
    window.location.pathname = "/profile";
  };

  logoutUser = async () => {
    const response = await logout();
    if (response) {
      if (
        response.status === 200 &&
        response.data &&
        response.data.StatusCode === 200
      ) {
        window.history.pushState(null, "Town Square", window.location.pathname);
        window.location.pathname = "home/login";
      }
    }
  };

  render() {
    return (
      <div className="top-nav-wrapper">
        <NavUser
          data={this.props.fields}
          optionSelected={(selected) => {
            if (selected === "Your profile") {
              this.editProfilePage();
            } else if (selected === "Logout") {
              this.logoutUser();
            }
          }}
        />
        <NavLogo data={this.props.fields} />
        <NavRetailer data={this.props.fields} />
        <NavNotification />
      </div>
    );
  }
}
export default FeatureNavigationTopNavigation;
