import React from "react";
// import TagManager from "react-gtm-module";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { getStoreInfo } from "../../../../api/StoreAPI";

class FeatureRetailerPortalHub extends React.Component {
  constructor(props) {
    super(props);
  }

  getData = async () => {
    let params = {
      pageNumber: 1,
      centre: "",
    };
    let centreList = [];
    const response = await getStoreInfo(params);
    if (response) {
      if (
        response.data &&
        response.data.StoreInfoList &&
        response.data.StoreInfoList.length
      ) {
        response.data.StoreInfoList.map((item) => {
          const { CentreName: centre = null } = item || {};
          if (centreList.includes(centre.value) === false) {
            centreList.push(centre.value);
          }
        });
      }
      // if (centreList && centreList.length) {
      //   centreList.map(centeName => {
      //     TagManager.dataLayer({
      //       dataLayer: {
      //         event: "loginCentreInfo",
      //         centreName: centeName ? centeName : ""
      //       },
      //       dataLayerName: "dataLayer"
      //     });
      //   });
      // }
    }
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { Heading, Links } = this.props.fields;
    return (
      <div className="hub-container">
        <div className="hub-heading">
          <Text field={Heading} />
        </div>
        <div className="hub-controls">
          <div className="row">
            {Links &&
              Links.length !== 0 &&
              Links.map((item, index) => {
                const { querystring, href, target, text } =
                  item.fields.Navigation.value || null;
                const title =
                  item &&
                  item.fields &&
                  item.fields.Title &&
                  item.fields.Title.value
                    ? item.fields.Title.value
                    : "";
                let icon = "";
                if (/event/.test(querystring)) {
                  icon = "events";
                } else if (/offer/.test(querystring)) {
                  icon = "offers";
                } else if (/storeinfo/.test(querystring)) {
                  icon = "stores";
                } else if (/article/.test(querystring)) {
                  icon = "articles";
                }

                return (
                  <div className="col" key={`${querystring}-${index}`}>
                    <a
                      className="hub-button"
                      href={`${href}?${querystring}`}
                      target={target}
                    >
                      <div className="hub-button-details">
                        <i className={`hub-icon icon icon-${icon}`} />
                        {title && <span className="hub-title">{title}</span>}
                        {text && <span className="hub-text">{text}</span>}
                      </div>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default FeatureRetailerPortalHub;
