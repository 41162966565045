import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DefaultUserLogo from "../../../../assets/icons/png/author.png";
import logoPlaceholder from "../../../../assets/img/logoPlaceholder.png";
import EditProfile from "../../../Modules/Profile/EditProfile";
import UpdatePassword from "../../../Modules/Profile/UpdatePassword";
import Popup from "../../../Base/Popup";
import Card from "../../../Base/Card";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "../../../../helper/ScrollHelper";
import PageHeader from "../../../Base/PageHeader";

class FeatureRetailerPortalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditProfileModal: false,
      showChangePasswordModal: false,
      willRefreshPage: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("setPassword") === "true") {
      this.setState({ showChangePasswordModal: true }, () => {
        sessionStorage.setItem("setPassword", false);
        sessionStorage.setItem("promptPassword", "done");
      });
    }
  }

  renderChangePasswordModal = () => {
    disableBodyScroll();
    return (
      <Popup>
        <UpdatePassword
          antiForgeryToken={this.props.fields.antiForgeryToken}
          show={(showChangePasswordModal) =>
            this.setState({ showChangePasswordModal }, () => enableBodyScroll())
          }
        />
      </Popup>
    );
  };

  renderEditProfileModal = () => {
    disableBodyScroll();
    const { willRefreshPage } = this.state;
    return (
      <Popup
        type="small"
        title="Edit profile"
        show={(showEditProfileModal) =>
          this.setState({ showEditProfileModal }, () => {
            enableBodyScroll();
            willRefreshPage && window.location.reload();
          })
        }
      >
        <EditProfile
          data={this.props.fields}
          antiForgeryToken={this.props.fields.antiForgeryToken}
          show={(showEditProfileModal) =>
            this.setState({ showEditProfileModal }, () => {
              enableBodyScroll();
              willRefreshPage && window.location.reload();
            })
          }
          refresh={(willRefreshPage) => this.setState({ willRefreshPage })}
        />
      </Popup>
    );
  };

  render() {
    const {
      ProfilePicture: thumbnail,
      FullName,
      ContactNumber,
      EmailAddress,
      Address,
      Permissions,
    } = this.props.fields;

    const renderDetails = (
      <React.Fragment>
        <div className="user-thumbnail">
          {thumbnail && thumbnail.value && thumbnail.value.src ? (
            <img
              alt="icon"
              src={logoPlaceholder}
              data-src={thumbnail.value.src}
              className="lazyload"
            />
          ) : (
            <img src={DefaultUserLogo} alt="retailer" />
          )}
        </div>
        <div className="user-details">
          <div>
            <Text field={FullName} />
          </div>
          <div>
            <Text field={ContactNumber} />
          </div>
          <div className="user-email">
            <Text field={EmailAddress} />
          </div>
          <div>
            <Text field={Address} />
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <div className="profile-wrapper">
        {this.state.showEditProfileModal ? this.renderEditProfileModal() : null}
        {this.state.showChangePasswordModal
          ? this.renderChangePasswordModal()
          : null}
        <PageHeader title={{ value: "Your profile" }} />
        <Card
          className="card-dt"
          title="Contact details"
          content={renderDetails}
          btnText="Edit"
          btnOnClick={() => this.setState({ showEditProfileModal: true })}
        />
        <Card
          className="card-pw"
          title="Password"
          textContent={{ value: "••••••••••••" }}
          btnText="Change password"
          btnOnClick={() => this.setState({ showChangePasswordModal: true })}
        />
        {Permissions && Permissions.value && (
          <Card
            className="card-ps"
            title="Permissions"
            textContent={Permissions}
            btnText="Can't edit"
            btnDisabled
          />
        )}
      </div>
    );
  }
}
export default FeatureRetailerPortalProfile;
