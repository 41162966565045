import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import { get } from "lodash";
import { XMasonry, XBlock } from "react-xmasonry";
import DefaultUserLogo from "../../../../assets/icons/png/author.png";
import logoPlaceholder from "../../../../assets/img/logoPlaceholder.png";
import {
  getDashboardCorrespondences,
  getDashboardStoreInfos,
  getDashboardEvents,
  getDashboardOffers,
  getDashboardArticles
} from "../../../../api/DashboardAPI";
import Stores from "../Stores";
import PromotionListing from "../PromotionListing";
import ArticleListing from "../ArticleListing";
import Notifications from "../Notifications";
import PageHeader from "../../../Base/PageHeader";
import { addClassLazyload } from "../../../../helper/AddClassLazyloadHelper";

// import getStoreInfoData from "./json/getStoreInfo.json";
// import getEventsData from "./json/getEvents.json";
// import getOffersData from "./json/getOffers.json";
// import getArticlesData from "./json/getArticles.json";
// import getCorresnpondences from "./json/getCorresnpondences.json";

class FeatureRetailerPortalDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      correspondenceList: [],
      storeInfoList: [],
      eventsList: [],
      offersList: [],
      articlesList: [],
      isDashboardReady: false,
      columnWidth: 0,
      showStoreInfoLoader: true,
      showEventsLoader: true,
      showOffersLoader: true,
      showArticlesLoader: true,
      hideAcknowledgeButton: false
    };
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("resize", this.handleResize);
    }
    if (document) {
      let containerWrapper = document.querySelector(".body-wrapper .container");
      if (containerWrapper) {
        this.setState({
          columnWidth: containerWrapper.clientWidth / 2,
          isDashboardReady: true
        })
      }
      this.getCorrespondenceData();
      this.getStoreInfoData();
      this.getEventsData();
      this.getOffersData();
      this.getArticlesData();
    }
  }

  handleResize = () => {
    let containerWrapper = document.querySelector(".body-wrapper .container");
    if (containerWrapper) {
      this.setState({
        columnWidth: containerWrapper.clientWidth / 2
      })
    }
  }

  getCorrespondenceData = async () => {
    try {
      const response = await getDashboardCorrespondences();
      if (response.status === 200 && response.data) {
        const correspondenceList = get(response.data, "CorrespondenceList", []);
        const hideAcknowledgeButton = get(response.data, "HideAcknowledgeButton.value", false);
        this.setState({
          correspondenceList,
          hideAcknowledgeButton,
        })
      }
    }
    catch (e) {
      console.error("Get Dashboard Correspondence Data Error: " + e.response.statusText);
      // let response = {
      //   data: getCorresnpondences
      // }
      // const correspondenceList = get(response.data, "CorrespondenceList", []);
      // const hideAcknowledgeButton = get(response.data, "HideAcknowledgeButton.value", false);
      // this.setState({
      //   correspondenceList,
      //   hideAcknowledgeButton,
      // })
    }
  };

  getStoreInfoData = async (isUpdate) => {
    if (!isUpdate) {
      this.setState({
        showStoreInfoLoader: true
      })
    }

    try {
      const response = await getDashboardStoreInfos();
      if (response.status === 200 && response.data) {
        const storeInfoList = get(response.data, "StoreInfoList", []);
        this.setState({
          storeInfoList,
          showStoreInfoLoader: false,
        },
          () => {
            addClassLazyload("dashboard-content");
          }
        )
      }
    }
    catch (e) {
      console.error("Get Dashboard Store Info Data Error: " + e.response.statusText);
      // let response = {
      //   data: getStoreInfoData
      // }
      // const storeInfoList = get(response.data, "StoreInfoList", []);
      // this.setState({
      //   storeInfoList,
      //   showStoreInfoLoader: false,
      // })
    }
  }

  getEventsData = async (isUpdate) => {
    if (!isUpdate) {
      this.setState({
        showEventsLoader: true
      })
    }

    try {
      const response = await getDashboardEvents();
      if (response.status === 200 && response.data) {
        const eventsList = get(response.data, "EventsList", []);
        this.setState({
          eventsList,
          showEventsLoader: false,
        },
          () => {
            addClassLazyload("dashboard-content");
          }
        )
      }
    }
    catch (e) {
      console.error("Get Dashboard Events Data Error: " + e.response.statusText);
      // let response = {
      //   data: getEventsData
      // }
      // const eventsList = get(response.data, "EventsList", []);
      // this.setState({
      //   eventsList,
      //   showEventsLoader: false,
      // })
    }
  }

  getOffersData = async (isUpdate) => {
    if (!isUpdate) {
      this.setState({
        showOffersLoader: true
      })
    }

    try {
      const response = await getDashboardOffers();
      if (response.status === 200 && response.data) {
        const offersList = get(response.data, "OffersList", []);
        this.setState({
          offersList,
          showOffersLoader: false,
        },
          () => {
            addClassLazyload("dashboard-content");
          }
        )
      }
    }
    catch (e) {
      console.error("Get Dashboard Offers Data Error: " + e.response.statusText);
      // let response = {
      //   data: getOffersData
      // }
      // const offersList = get(response.data, "OffersList", []);
      // this.setState({
      //   offersList,
      //   showOffersLoader: false,
      // })
    }
  }

  getArticlesData = async (isUpdate) => {
    if (!isUpdate) {
      this.setState({
        showArticlesLoader: true
      })
    }

    try {
      const response = await getDashboardArticles();
      if (response.status === 200 && response.data) {
        const articlesList = get(response.data, "ArticlesList", []);
        this.setState({
          articlesList,
          showArticlesLoader: false,
        },
          () => {
            addClassLazyload("dashboard-content");
          }
        )
      }
    }
    catch (e) {
      console.error("Get Dashboard Articles Data Error: " + e.response.statusText);
      // let response = {
      //   data: getArticlesData
      // }
      // const articlesList = get(response.data, "ArticlesList", []);
      // this.setState({
      //   articlesList,
      //   showArticlesLoader: false,
      // })
    }
  }

  dashboardPanelLayout = (component, headerName, showLink, link, showLoader, noResultText) => {
    let panelLayout = "";

    if (!showLoader) {
      panelLayout =
        <div className="dashboard-content__wrapper">
          <div className="dashboard-content__wrapper--fade">
            {component}
            {showLink ?
              <div className="dashboard-content__link">
                <Link
                  field={{
                    ...link.value,
                    href: `${link.value.href}${
                      link.value.querystring
                        ? "?" + link.value.querystring
                        : ""
                      }`,
                    text: link.value.text ? link.value.text : "View all"
                  }}
                />
              </div> :
              <div className="dashboard-content__no-result">
                <div className="dashboard-content__no-result--text">
                  {noResultText ? noResultText : "No Result"}
                </div>
              </div>
            }
          </div>
        </div>
    } else {
      panelLayout =
        <div className="dashboard-content__wrapper">
          <PageHeader
            title={{ value: headerName }}
          />
          <div className="loader-wrapper">
            <div className="loader">
            </div>
          </div>
        </div>
    }

    return panelLayout;
  }

  renderCorrespondence = (list, link) => {
    const {
      hideAcknowledgeButton
    } = this.state
    let correspondenceLayout = "";
    let showLink = true;
    let component =
      <Notifications
        isDashboard={true}
        notificationsList={list}
        hideAcknowledgeButton={hideAcknowledgeButton}
      />


    correspondenceLayout =
      this.dashboardPanelLayout(
        component,
        "Correspondence",
        showLink,
        link,
      )

    return correspondenceLayout;
  }

  renderStoreInfo = (list, link, showLoader) => {
    let storeInfoLayout = "";
    let showLink = false;
    let component =
      <Stores
        isDashboard={true}
        storeList={list}
        updateDashboard={(isUpdate) => {
          if (isUpdate) {
            this.getStoreInfoData(isUpdate)
          }
        }}
      />

    if (list && list.length > 0) {
      showLink = true;
    }

    storeInfoLayout =
      this.dashboardPanelLayout(
        component,
        "Store information",
        showLink,
        link,
        showLoader
      )

    return storeInfoLayout;
  }

  renderEvents = (list, link, hideCreateButton, showLoader) => {
    let eventsLayout = "";
    let showLink = false;
    let component =
      <PromotionListing
        dashboardType="event"
        isDashboard={true}
        promotionList={list}
        noStore={hideCreateButton && hideCreateButton.value ? hideCreateButton.value : false}
        updateDashboard={(isUpdate) => {
          if (isUpdate) {
            this.getEventsData(isUpdate)
          }
        }}
      />

    if (list && list.length > 0) {
      showLink = true;
    }

    eventsLayout =
      this.dashboardPanelLayout(
        component,
        "Events",
        showLink,
        link,
        showLoader,
        "Create your first event and promote your store!"
      )

    return eventsLayout;
  }

  renderOffers = (list, link, hideCreateButton, showLoader) => {
    let offersLayout = "";
    let showLink = false;
    let component =
      <PromotionListing
        dashboardType="offer"
        isDashboard={true}
        promotionList={list}
        noStore={hideCreateButton && hideCreateButton.value ? hideCreateButton.value : false}
        updateDashboard={(isUpdate) => {
          if (isUpdate) {
            this.getOffersData(isUpdate)
          }
        }}
      />

    if (list && list.length > 0) {
      showLink = true;
    }

    offersLayout =
      this.dashboardPanelLayout(
        component,
        "Offers",
        showLink,
        link,
        showLoader,
        "Create your first offer and promote your store!"
      )

    return offersLayout;
  }

  renderArticles = (list, link, hideCreateButton, showLoader) => {
    let articlesLayout = "";
    let showLink = false;
    let component =
      <ArticleListing
        isDashboard={true}
        articleList={list}
        noStore={hideCreateButton && hideCreateButton.value ? hideCreateButton.value : false}
        updateDashboard={(isUpdate) => {
          if (isUpdate) {
            this.getArticlesData(isUpdate)
          }
        }}
      />

    if (list && list.length > 0) {
      showLink = true;
    }

    articlesLayout =
      this.dashboardPanelLayout(
        component,
        "Articles",
        showLink,
        link,
        showLoader,
        "Create your first article and promote your store!"
      )

    return articlesLayout;
  }

  renderContent = () => {
    const {
      CorrespondenceListingLink,
      StoresListingLink,
      EventsListingLink,
      OffersListingLink,
      ArticlesListingLink,
      HideCreateButton
    } = this.props.fields;

    const {
      correspondenceList,
      storeInfoList,
      eventsList,
      offersList,
      articlesList,
      showStoreInfoLoader,
      showEventsLoader,
      showOffersLoader,
      showArticlesLoader,
      columnWidth,
    } = this.state

    return (
      <div className="dashboard-content">
        <XMasonry targetBlockWidth={columnWidth}>
          {correspondenceList &&
            correspondenceList.length > 0 &&
            < XBlock key="correspondenceList">
              {this.renderCorrespondence(correspondenceList, CorrespondenceListingLink)}
            </XBlock>
          }
          <XBlock key="storeInfoList">
            {this.renderStoreInfo(storeInfoList, StoresListingLink, showStoreInfoLoader)}
          </XBlock>
          <XBlock key="eventsList">
            {this.renderEvents(eventsList, EventsListingLink, HideCreateButton, showEventsLoader)}
          </XBlock>
          <XBlock key="offersList">
            {this.renderOffers(offersList, OffersListingLink, HideCreateButton, showOffersLoader)}
          </XBlock>
          <XBlock key="articlesList">
            {this.renderArticles(articlesList, ArticlesListingLink, HideCreateButton, showArticlesLoader)}
          </XBlock>
        </XMasonry>
      </div>
    )
  }

  render() {
    const {
      UserName,
      UserPhoto,
      UserRole,
    } = this.props.fields;

    const {
      isDashboardReady,
    } = this.state

    return (
      <div className="dashboard">
        <div className="dashboard-header">
          <div className="is-desktop">
            <div className="dashboard-header__image">
              {(UserPhoto && UserPhoto.value) ?
                <img
                  alt="dashboard-image-logo"
                  src={logoPlaceholder}
                  data-src={UserPhoto.value}
                  className="lazyload"
                /> :
                <img src={DefaultUserLogo} alt="dashboard-image-default" />
              }
            </div>
            <div className="dashboard-header__text">
              <div className="dashboard-header__name">
                <span>{"Welcome, "}</span>
                {UserName &&
                  UserName.value &&
                  <span>
                    <Text field={UserName} />{"!"}
                  </span>
                }
              </div>
              {UserRole &&
                UserRole.value &&
                <div className="dashboard-header__description">
                  <Text field={UserRole} />
                </div>
              }
            </div>
          </div>
          <div className="is-tablet">
            <PageHeader title={{ value: `Welcome, ${UserName && UserName.value ? UserName.value : ""}` }} />
          </div>
        </div>
        {isDashboardReady &&
          this.renderContent()
        }
      </div>
    )
  }
}

export default FeatureRetailerPortalDashboard;
