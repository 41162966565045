import React from "react";
import CharacterCount from "../CharacterCount";

const FormInputMultiple = props => {
  const {
    label = null,
    placeholder = null,
    required = null,
    maxLength = null,
    value = null,
    error = null,
    onInputChange = null,
    disabled = null,
  } = props;
  return (
    <div className={`form-group form__input-multiple 
    ${error ? "has-error" : ""}
    `}>
      {(label || required) &&
        <label className="form__label">
          {label}
          {required &&
            <span className="form__asterisk">*</span>
          }
        </label>
      }
      <div className="form__input-multiple--details">
        <textarea
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={e => onInputChange(e.target.value)}
          disabled={disabled}
        />
        <CharacterCount remaining={maxLength - value.length} />
      </div>
      <div className="form__validation-message">
        {error}
      </div>
    </div>
  );
};

export default FormInputMultiple;
