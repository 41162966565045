import React from "react";
import { Link } from "@sitecore-jss/sitecore-jss-react";

const NavFooter = (props) => {
  const { FooterLinks: links = null } = props.data || {};
  return (
    <div className="nav-links-wrapper">
      {links &&
        links.length &&
        links.map((link, index) => {
          let { LinkTarget: linkTarget = null } = link.fields;
          return (
            <div key={`link-${index}`}>
              <Link field={linkTarget} />
            </div>
          );
        })}
    </div>
  );
};

export default NavFooter;
