import React, { Component, Fragment } from "react";
import Select from "react-select";
import axios from "axios";
import { getCreateEditUser, getRetailerList } from "../../../../api/UsersAPI";
import { validate } from "../../../../helper/UserHelper";
import FormInput from "../../../Base/FormInput";
import RadioButton from "../../../Base/RadioButton";
import FormErrorMessage from "../../../Base/FormErrorMessage";

// import getRetailerListData from "./getRetailerListData.json";
// import getCreateEditUserData from "./getCreateEditUserData.json";

class FeatureRetailerPortalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: null,
        email: null,
        centres: [],
        retailers: [],
        permission: null,
      },
      showLoader: true,
      isRetailersDisabled: false,
      isCentresDisabled: false,
      retailersList: [],
      centresList: [],
      disableBtn: false,
      noOptionsMessage: "Loading retailers. Please wait.",
      formError: {},
      formErrorMessage: "",
      showErrorMessage: false,
    };
  }

  componentDidMount() {
    this.getRetailerList();
    this.getData();
    this.handleFormData();
  }

  getData = async () => {
    const { Username } = this.props;
    const response = await getCreateEditUser({ Username });
    // const response = {
    //   data: getCreateEditUserData
    // }
    if (response && response.data) {
      const { FullName, Centres, EmailAddress, Retailers, Permission } =
        response.data.User || {};
      const { CentreList } = response.data;

      let centresList = [];
      let centres = [];
      let retailers = [];

      if (Centres && Centres.length) {
        Centres.map((centre) => {
          const { CentreName, CentreId } = centre;
          centres.push({ value: CentreId.value, label: CentreName.value });
        });
      }

      if (Retailers && Retailers.length) {
        Retailers.map((retailer) => {
          const { RetailerName, RetailerId } = retailer;
          retailers.push({
            value: RetailerId.value,
            label: RetailerName.value,
          });
        });
      }

      if (CentreList && CentreList.length) {
        CentreList.map((centre) => {
          const { CentreName, CentreId } = centre;
          centresList.push({ value: CentreId.value, label: CentreName.value });
        });
      }

      this.setState(
        {
          formData: {
            name: (FullName && FullName.value) || "",
            email: (EmailAddress && EmailAddress.value) || "",
            centres,
            retailers,
            permission:
              (Permission &&
                Permission.PermissionValue &&
                Permission.PermissionValue.value) ||
              "",
          },
          centresList,
          showLoader: false,
        },
        () =>
          Permission &&
          Permission.PermissionValue &&
          Permission.PermissionValue.value &&
          this.handlePermission(Permission.PermissionValue.value)
      );
    } else {
      this.setState({ showLoader: false });
    }
  };

  getRetailerList = async () => {
    let retailers = [];
    try {
      const response = await getRetailerList();
      if (response && response.data && response.data.Retailers) {
        response.data.Retailers.map((retailer) => {
          const { RetailerName, RetailerId } = retailer;
          retailers.push({
            value: RetailerId.value,
            label: RetailerName.value,
          });
        });
      }
      this.setState({ retailersList: retailers });
    } catch (e) {
      console.error("Get Retailer List Error: " + e.response.statusText);
      // const response = {
      //   data: getRetailerListData
      // };
      // if (response && response.data && response.data.Retailers) {
      //   response.data.Retailers.map((retailer) => {
      //     const { RetailerName, RetailerId } = retailer;
      //     retailers.push({ value: RetailerId.value, label: RetailerName.value });
      //   });
      // }
      // this.setState({ retailersList: retailers });
    }
  };

  getOptions = (options) => {
    let finalOptions = [];
    options.map((item) => {
      const {
        PermissionName: name = null,
        PermissionValue: summary = null,
      } = item.fields;
      finalOptions.push({
        Id: summary,
        Name: name,
      });
    });
    return finalOptions;
  };

  handleFormUpdate = (value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...value,
      },
    });
  };

  validateForm = async () => {
    this.setState(
      {
        disableBtn: true,
      },
      this.handleFormData
    );
    const { isRetailersDisabled, isCentresDisabled } = this.state;

    let formError = validate(
      this.state.formData,
      !isRetailersDisabled,
      !isCentresDisabled
    );
    let response;
    if (Object.entries(formError).length !== 0) {
      this.setState(
        {
          formError,
          disableBtn: false,
          showErrorMessage: true,
          formErrorMessage: "Please fill out all mandatory fields marked below",
        },
        this.handleFormData
      );
      this.triggerBackToTop();
      response = {
        isSuccess: false,
      };
    } else {
      this.setState(
        {
          formError,
          disableBtn: false,
          showErrorMessage: false,
        },
        this.handleFormData
      );
      response = await this.saveForm().then((result) => {
        if (result.isSuccess) {
          return { isSuccess: true };
        }
      });
    }
    return response;
  };

  saveForm = async () => {
    const { name, email, retailers, centres, permission } = this.state.formData;
    const { Username } = this.props;
    const antiForgeryToken = this.props.fields.antiForgeryToken;
    
    const formData = new FormData();
    formData.append("fullName", name);
    formData.append("email", email);
    if (retailers && retailers.length) {
      retailers.map((retailer) => {
        formData.append("retailers", retailer.value);
      });
    }
    if (centres && centres.length) {
      centres.map((centre) => {
        formData.append("centres", centre.value);
      });
    }
    formData.append("permission", permission);
    if(antiForgeryToken) {
      formData.append(antiForgeryToken.name, antiForgeryToken.value);
    }

    const url = `/api/sitecore/user/SaveUser?username=${Username}`;

    try {
      let response = await axios({
        method: "POST",
        url,
        data: formData,
      });

      if (response && response.data) {
        if (response.data.IsSuccess && response.data.IsSuccess.value) {
          this.setState({
            formErrorMessage: "",
            showErrorMessage: false,
          });
          return {
            isSuccess: true,
            successMessage: response.data.Message.value,
          };
        } else {
          this.setState({
            formErrorMessage: response.data.Message.value,
            showErrorMessage: true,
          });
          return {
            isSuccess: false,
          };
        }
      } else {
        this.setState({
          formErrorMessage: "Update user failed. Please try again",
          showErrorMessage: true,
        });
        this.triggerBackToTop();
        return {
          isSuccess: false,
        };
      }
    } catch (e) {
      this.setState({
        formErrorMessage: "Update user failed. Please try again",
        showErrorMessage: true,
      });
      console.log(e);
      this.triggerBackToTop();
      return {
        isSuccess: false,
      };
    }
  };

  triggerBackToTop = () => {
    let backToTop;
    if (document) {
      backToTop = document.querySelector(".form__modal--back-to-top-button");
      if (backToTop) {
        backToTop.click();
      }
    }
  };

  handleFormData = () => {
    this.props.handleFormData(this.state.disableBtn, this.validateForm);
  };

  handlePermission = (permission) => {
    if (permission.indexOf("L1") > -1) {
      this.setState(
        {
          isRetailersDisabled: true,
          isCentresDisabled: true,
        },
        () =>
          this.setState({
            formData: {
              ...this.state.formData,
              retailers: null,
              centres: null,
            },
          })
      );
    } else if (permission.indexOf("L2") > -1) {
      this.setState(
        { isRetailersDisabled: true, isCentresDisabled: false },
        () => this.handleFormUpdate({ retailers: null })
      );
    } else {
      this.setState({ isRetailersDisabled: false, isCentresDisabled: false });
    }
    this.handleFormUpdate({ permission });
  };

  responseText = (field, type) => {
    return (
      field && (
        <div
          className={`response-text error ${
            type && type.length ? "with-items" : ""
          }`}
        >
          {field}
        </div>
      )
    );
  };

  noOptionsMessage = () => {
    const { retailersList } = this.state;
    let message = "Loading...";
    if (retailersList && retailersList.length) {
      message = "No options";
    }
    return <div className="loading-text">{message}</div>;
  };

  formatOptionLabelRetailer = ({ label }, { inputValue }) => {
    let highlighted = label;
    if (inputValue && inputValue.length > 1) {
      highlighted = label.replace(
        new RegExp(inputValue, "gi"),
        (highlighted) => `<b>${highlighted}</b>`
      );
    }
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  formatOptionLabelCentre = ({ label }, { inputValue }) => {
    let highlighted = label;
    if (inputValue && inputValue.length > 1) {
      highlighted = label.replace(
        new RegExp(inputValue, "gi"),
        (highlighted) => `<b>${highlighted}</b>`
      );
    }
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  render() {
    const { name, email, centres, retailers, permission } = this.state.formData;
    const {
      centresList,
      retailersList,
      isRetailersDisabled,
      isCentresDisabled,
      formError,
      showLoader,
      showErrorMessage,
      formErrorMessage,
    } = this.state;
    const { PermissionLevels } = this.props.fields || {};

    return (
      <div className="user-container">
        {showLoader && (
          <div className="loader-wrapper container">
            <div className="loader" />
          </div>
        )}
        {showErrorMessage && <FormErrorMessage error={formErrorMessage} />}
        {!showLoader && (
          <Fragment>
            <div className="user-form__title">
              {this.props.Username ? "Edit user" : "New user"}
            </div>
            <div className="form__container">
              <label className="form__header">User details</label>
              <FormInput
                type="text"
                label="Name"
                error={formError.name}
                value={name}
                required
                onInputChange={(name) => {
                  this.handleFormUpdate({ name });
                }}
              />
              <FormInput
                type="email"
                label="Email address"
                required
                value={email}
                error={formError.email}
                onInputChange={(email) => {
                  this.handleFormUpdate({ email });
                }}
              />
              <div className="form-group">
                <div className="form__label">Retailers</div>
                <div
                  className={`react-select-container ${
                    isRetailersDisabled ? "select-disabled" : ""
                  }`}
                >
                  <Select
                    isMulti
                    className={formError.retailers ? "error" : ""}
                    placeholder="Search retailers"
                    options={retailersList}
                    value={retailers}
                    onChange={(retailers) =>
                      this.handleFormUpdate({ retailers })
                    }
                    components={{ NoOptionsMessage: this.noOptionsMessage }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#f5f5f6",
                      },
                    })}
                    isClearable={false}
                    backspaceRemovesValue={false}
                    formatOptionLabel={this.formatOptionLabelRetailer}
                    isDisabled={isRetailersDisabled}
                  />
                  {this.responseText(formError.retailers, retailers)}
                </div>
              </div>
              <div className="form__label">Centres</div>
              <div
                className={`react-select-container ${
                  isCentresDisabled ? "select-disabled" : ""
                }`}
              >
                <Select
                  isMulti
                  className={formError.centres ? "error" : ""}
                  placeholder="Select centres"
                  options={centresList}
                  value={centres}
                  onChange={(centres) => this.handleFormUpdate({ centres })}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#f5f5f6",
                    },
                  })}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  formatOptionLabel={this.formatOptionLabelCentre}
                  isDisabled={isCentresDisabled}
                />
                {this.responseText(formError.centres, centres)}
              </div>
            </div>
            <div className="form__container is-permission">
              <div className="h2-bold">{"Permissions"}</div>
              <div className="form__label">
                {
                  "User can manage events, offers, articles & store information for:"
                }
              </div>
              <RadioButton
                defaultSelected={permission}
                radioGroupName="PermissionList"
                options={this.getOptions(PermissionLevels)}
                onChange={(Source) => {
                  this.handlePermission(Source);
                }}
              />
              {this.responseText(formError.permission)}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default FeatureRetailerPortalUser;
