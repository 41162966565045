import React from "react";
import axios from "axios";
import Loadable from "react-loadable";
import TagManager from "react-gtm-module";
import { TimePicker } from "antd";
import DropDown from "../../../Base/DropDown";
import ImageUpload from "../../../Base/ImageUpload";
import FormInput from "../../../Base/FormInput";
import FormDropDown from "../../../Base/FormDropDown";
import FormCheckbox from "../../../Base/FormCheckbox";
import FormFile from "../../../Base/FormFile";
import Button from "../../../Base/Button";
import { get } from "lodash";
import Modal from "../../../Base/Modal";
import { getStorefrontData } from "../../../../api/StorefrontAPI";
import bitmap from "../../../../assets/icons/png/bitmap@3x.png";
import { buildFormData } from "../../../../helper/StorefrontHelper";
import moment from "moment";
import "antd/dist/antd.css";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "../../../../helper/ScrollHelper";

const RichTextEditor = Loadable({
  loader: () => import("../../../Base/RichTextEditor"),
  loading: () => null,
});

class FeatureRetailerPortalStorefront extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        StoreID: "",
        Name: "",
        StorefrontDescription: "",
        ContactNumber: "",
        MenuUrl: "",
        OnlineBookingUrl: "",
        BestParking: "",
        AcceptsAfterPay: false,
        AcceptsGiftCards: false,
        IsKidsFriendly: false,
        IsVegetarian: false,
        RetailerName: "",
        RetailerDescription: "",
        Website: "",
        Instagram: "",
        Facebook: "",
        LogoImage: null,
        HeroImage: null,
        ImageGallery: [],
        ImageGalleryChecker: [],
        MondayOpeningHours: "",
        MondayClosingHours: "",
        MondaySecondaryOpeningHours: "",
        MondaySecondaryClosingHours: "",
        MondayIsClosed: "",
        TuesdayOpeningHours: "",
        TuesdayClosingHours: "",
        TuesdaySecondaryOpeningHours: "",
        TuesdaySecondaryClosingHours: "",
        TuesdayIsClosed: "",
        WednesdayOpeningHours: "",
        WednesdayClosingHours: "",
        WednesdaySecondaryOpeningHours: "",
        WednesdaySecondaryClosingHours: "",
        WednesdayIsClosed: "",
        ThursdayOpeningHours: "",
        ThursdayClosingHours: "",
        ThursdaySecondaryOpeningHours: "",
        ThursdaySecondaryClosingHours: "",
        ThursdayIsClosed: "",
        FridayOpeningHours: "",
        FridayClosingHours: "",
        FridaySecondaryOpeningHours: "",
        FridaySecondaryClosingHours: "",
        FridayIsClosed: "",
        SaturdayOpeningHours: "",
        SaturdayClosingHours: "",
        SaturdaySecondaryOpeningHours: "",
        SaturdaySecondaryClosingHours: "",
        SaturdayIsClosed: "",
        SundayOpeningHours: "",
        SundayClosingHours: "",
        SundaySecondaryOpeningHours: "",
        SundaySecondaryClosingHours: "",
        SundayIsClosed: "",
        StorefrontHeroImage: null,
      },
      StoreOpeningHours: "",
      RetailerType: "",
      formType: null,
      componentReady: false,
      selectedStoreName: "Select a Store",
      storeList: [],
      sourceList: [
        { name: "Store", value: "Store" },
        { name: "Centre", value: "Centre" },
      ],
      carparkList: null,
      iconList: null,
      selectedCarpark: "Select an option",
      selectedIcon: "Select an option",
      showModal: false,
      modalContent: null,
    };
  }

  componentDidMount() {
    const { Stores, CarParkList } = this.props.fields;
    let selectedStoreName = null;
    let filterResult = null;
    let selectedStoreId = null;
    if (typeof Storage !== "undefined") {
      selectedStoreId = sessionStorage.getItem("storeId");
    }
    let storeList = this.setStoreList(Stores);
    let parkList = this.setCarparkList(CarParkList);
    if (selectedStoreId) {
      if (
        typeof Storage !== "undefined" &&
        sessionStorage.getItem("global") === "true"
      ) {
        selectedStoreName = "All";
        selectedStoreId = "";
        storeList = "";
      } else {
        filterResult = storeList.filter((el) => el.value === selectedStoreId);
        selectedStoreName =
          filterResult && filterResult[0] && filterResult[0].name
            ? filterResult[0].name
            : null;
      }
    }

    this.setState(
      {
        componentReady: true,
        selectedStoreName: selectedStoreName
          ? selectedStoreName
          : this.state.selectedStoreName,
        storeList,
        carparkList: parkList,
      },
      () => {
        if (selectedStoreName) {
          this.getStoreData(selectedStoreId);
        }
      }
    );
  }

  onTimeChange = (time, timeString, day, info) => {
    let dateTime;
    if (timeString) {
      dateTime =
        moment().format("YYYYMMDDT") +
        moment(timeString, ["h:mm A"])
          .format("HH:mm")
          .replace(":", "") +
        "00";
    }
    this.handleFormUpdate({
      [day + info]: dateTime ? dateTime : null,
    });
  };

  resetHours = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        MondayOpeningHours: null,
        MondayClosingHours: null,
        MondaySecondaryOpeningHours: null,
        MondaySecondaryClosingHours: null,
        TuesdayOpeningHours: null,
        TuesdayClosingHours: null,
        TuesdaySecondaryOpeningHours: null,
        TuesdaySecondaryClosingHours: null,
        WednesdayOpeningHours: null,
        WednesdayClosingHours: null,
        WednesdaySecondaryOpeningHours: null,
        WednesdaySecondaryClosingHours: null,
        ThursdayOpeningHours: null,
        ThursdayClosingHours: null,
        ThursdaySecondaryOpeningHours: null,
        ThursdaySecondaryClosingHours: null,
        FridayOpeningHours: null,
        FridayClosingHours: null,
        FridaySecondaryOpeningHours: null,
        FridaySecondaryClosingHours: null,
        SaturdayOpeningHours: null,
        SaturdayClosingHours: null,
        SaturdaySecondaryOpeningHours: null,
        SaturdaySecondaryClosingHours: null,
        SundayOpeningHours: null,
        SundayClosingHours: null,
        SundaySecondaryOpeningHours: null,
        SundaySecondaryClosingHours: null,
      },
    });
  };

  renderModal = () => {
    disableBodyScroll();
    const { title, body, cta } = this.state.modalContent;
    return (
      <Modal
        type="small"
        title={title}
        show={() =>
          this.setState({ showModal: false, modalContent: null }, () =>
            enableBodyScroll()
          )
        }
      >
        <div className="">{body}</div>
        {cta ? (
          <div className="cta">
            <Button
              color="primary"
              onClick={() => (window.location.href = cta.url)}
            >
              {cta.label}
            </Button>
          </div>
        ) : (
            ""
          )}
      </Modal>
    );
  };

  saveForm = async () => {
    const { formData } = this.state;
    var timezoneId = new Date().getTimezoneOffset();
    const url = "/api/sitecore/store/SaveStorefrontData";
    let data = buildFormData({ ...formData, timeZoneId: timezoneId });

    try {
      let response = await axios({
        method: "POST",
        url,
        data,
        params: {
          storeId: formData.StoreID,
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });
      if (response) {
        if (get(response.data, "IsSuccess.value", false)) {
          let successMessage = {
            title: "Form Saved",
            body: get(response.data, "message.value", "Successful"),
          };
          this.setState({
            showModal: true,
            modalContent: successMessage,
          });
          TagManager.dataLayer({
            dataLayer: {
              event: "updateStoreInfo",
            },
            dataLayerName: "dataLayer",
          });
        } else {
          let errorMessage = {
            title: "Form Not Saved",
            body: get(response.data, "message.value", "Invalid response"),
          };
          this.setState({
            showModal: true,
            modalContent: errorMessage,
          });
        }
      }
    } catch (e) {
      console.log(e.response.statusText);
      let successMessage = {
        title: "Form Saved",
        body: get(e.response.data, "message.value", "Successful"),
      };
      this.setState({
        showModal: true,
        modalContent: successMessage,
      });
    }
  };

  decodeHTML = (html) => {
    if (document) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }
  };

  getStoreData = async (storeId) => {
    let response = await getStorefrontData({ storeId });
    const $this = this;
    if (response.status === 200 && response.data) {
      const ImageGallery = get(response.data, "ImageGallery", []);
      this.setState({
        formData: {
          Name: get(response.data, "Name.value", null),
          StorefrontDescription: $this.decodeHTML(
            get(response.data, "Description.value", "")
          ),
          ContactNumber: get(response.data, "ContactNumber.value", ""),
          MenuUrl: get(response.data, "MenuUrl.value", ""),
          OnlineBookingUrl: get(response.data, "OnlineBookingUrl.value", ""),
          BestParking: get(response.data, "BestParking.value", ""),
          AcceptsAfterPay: get(response.data, "AcceptsAfterPay.value", false),
          AcceptsGiftCards: get(response.data, "AcceptsGiftCards.value", false),
          IsKidsFriendly: get(response.data, "IsKidsFriendly.value", false),
          IsVegetarian: get(response.data, "IsVegetarian.value", false),
          RetailerName: get(response.data, "RetailerDetails.Name.value", null),
          RetailerDescription: $this.decodeHTML(
            get(response.data, "RetailerDetails.Description.value", "")
          ),
          Website: get(response.data, "RetailerDetails.Website.value", null),
          Instagram: get(
            response.data,
            "RetailerDetails.Instagram.value",
            null
          ),
          Facebook: get(response.data, "RetailerDetails.Facebook.value", null),
          LogoImage: get(
            response.data,
            "RetailerDetails.LogoImage.value.src",
            null
          ),
          HeroImage: get(
            response.data,
            "RetailerDetails.HeroImage.value.src",
            null
          ),
          StorefrontHeroImage: get(
            response.data,
            "StorefrontHeroImage.value.src",
            null
          ),
          ImageGalleryChecker: ImageGallery
            ? ImageGallery.map((el) => el.Id.value)
            : [],
          ImageGallery: ImageGallery
            ? ImageGallery.map((el) => {
              return {
                name: el.Name.value,
                id: el.Id.value,
                src: el.Src.value,
              };
            })
            : [],
          StoreID: get(response.data, "StoreID.value", null),
          IsTemporarilyClosed: get(
            response.data,
            "IsTemporarilyClosed.value",
            null
          ),
          IsOpeningSoon: get(response.data, "IsTemporarilyClosed.value", null)
            ? false
            : get(response.data, "IsOpeningSoon.value", null),
          Source: get(response.data, "Source.value", null),
          MondayOpeningHours: get(
            response.data,
            "StoreOpeningHours.MondayOpeningHours.value",
            null
          ),
          MondayClosingHours: get(
            response.data,
            "StoreOpeningHours.MondayClosingHours.value",
            null
          ),
          MondaySecondaryOpeningHours: get(
            response.data,
            "StoreOpeningHours.MondaySecondaryOpeningHours.value",
            null
          ),
          MondaySecondaryClosingHours: get(
            response.data,
            "StoreOpeningHours.MondaySecondaryClosingHours.value",
            null
          ),
          MondayIsClosed: get(
            response.data,
            "StoreOpeningHours.MondayIsClosed.value",
            null
          ),
          TuesdayOpeningHours: get(
            response.data,
            "StoreOpeningHours.TuesdayOpeningHours.value",
            null
          ),
          TuesdayClosingHours: get(
            response.data,
            "StoreOpeningHours.TuesdayClosingHours.value",
            null
          ),
          TuesdaySecondaryOpeningHours: get(
            response.data,
            "StoreOpeningHours.TuesdaySecondaryOpeningHours.value",
            null
          ),
          TuesdaySecondaryClosingHours: get(
            response.data,
            "StoreOpeningHours.TuesdaySecondaryClosingHours.value",
            null
          ),
          TuesdayIsClosed: get(
            response.data,
            "StoreOpeningHours.TuesdayIsClosed.value",
            null
          ),
          WednesdayOpeningHours: get(
            response.data,
            "StoreOpeningHours.WednesdayOpeningHours.value",
            null
          ),
          WednesdayClosingHours: get(
            response.data,
            "StoreOpeningHours.WednesdayClosingHours.value",
            null
          ),
          WednesdaySecondaryOpeningHours: get(
            response.data,
            "StoreOpeningHours.WednesdaySecondaryOpeningHours.value",
            null
          ),
          WednesdaySecondaryClosingHours: get(
            response.data,
            "StoreOpeningHours.WednesdaySecondaryClosingHours.value",
            null
          ),
          WednesdayIsClosed: get(
            response.data,
            "StoreOpeningHours.WednesdayIsClosed.value",
            null
          ),
          ThursdayOpeningHours: get(
            response.data,
            "StoreOpeningHours.ThursdayOpeningHours.value",
            null
          ),
          ThursdayClosingHours: get(
            response.data,
            "StoreOpeningHours.ThursdayClosingHours.value",
            null
          ),
          ThursdaySecondaryOpeningHours: get(
            response.data,
            "StoreOpeningHours.ThursdaySecondaryOpeningHours.value",
            null
          ),
          ThursdaySecondaryClosingHours: get(
            response.data,
            "StoreOpeningHours.ThursdaySecondaryClosingHours.value",
            null
          ),
          ThursdayIsClosed: get(
            response.data,
            "StoreOpeningHours.ThursdayIsClosed.value",
            null
          ),
          FridayOpeningHours: get(
            response.data,
            "StoreOpeningHours.FridayOpeningHours.value",
            null
          ),
          FridayClosingHours: get(
            response.data,
            "StoreOpeningHours.FridayClosingHours.value",
            null
          ),
          FridaySecondaryOpeningHours: get(
            response.data,
            "StoreOpeningHours.FridaySecondaryOpeningHours.value",
            null
          ),
          FridaySecondaryClosingHours: get(
            response.data,
            "StoreOpeningHours.FridaySecondaryClosingHours.value",
            null
          ),
          FridayIsClosed: get(
            response.data,
            "StoreOpeningHours.FridayIsClosed.value",
            null
          ),
          SaturdayOpeningHours: get(
            response.data,
            "StoreOpeningHours.SaturdayOpeningHours.value",
            null
          ),
          SaturdayClosingHours: get(
            response.data,
            "StoreOpeningHours.SaturdayClosingHours.value",
            null
          ),
          SaturdaySecondaryOpeningHours: get(
            response.data,
            "StoreOpeningHours.SaturdaySecondaryOpeningHours.value",
            null
          ),
          SaturdaySecondaryClosingHours: get(
            response.data,
            "StoreOpeningHours.SaturdaySecondaryClosingHours.value",
            null
          ),
          SaturdayIsClosed: get(
            response.data,
            "StoreOpeningHours.SaturdayIsClosed.value",
            null
          ),
          SundayOpeningHours: get(
            response.data,
            "StoreOpeningHours.SundayOpeningHours.value",
            null
          ),
          SundayClosingHours: get(
            response.data,
            "StoreOpeningHours.SundayClosingHours.value",
            null
          ),
          SundaySecondaryOpeningHours: get(
            response.data,
            "StoreOpeningHours.SundaySecondaryOpeningHours.value",
            null
          ),
          SundaySecondaryClosingHours: get(
            response.data,
            "StoreOpeningHours.SundaySecondaryClosingHours.value",
            null
          ),
          SundayIsClosed: get(
            response.data,
            "StoreOpeningHours.SundayIsClosed.value",
            null
          ),
        },
        RetailerType: get(
          response.data,
          "RetailerDetails.RetailerType.value",
          ""
        ),
        formType:
          storeId === ""
            ? ""
            : get(response.data, "RetailerDetails.RetailerType.value", ""),
      });
    } else {
      alert("Getting store data failed");
    }
  };

  setStoreList = (list) => {
    let temp = [];
    list.forEach((el) =>
      temp.push({
        name: el.fields.StoreName.value,
        value: el.fields.StoreItemId.value,
      })
    );
    return temp;
  };

  setCarparkList = (list) => {
    let temp = [];
    list.forEach((el) =>
      temp.push({
        name: el.fields.CarParkName.value,
        value: el.fields.CarParkId.value,
      })
    );
    return temp;
  };

  handleFormUpdate = (value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...value,
      },
    });
  };

  setDropdownValue = (value, options) => {
    if (value && options && options.length) {
      return options.filter((el) => el.value === value)[0].name;
    } else return null;
  };

  render() {
    const {
      formData,
      formType,
      componentReady,
      selectedStoreName,
      storeList,
      sourceList,
      carparkList,
      iconList,
      showModal,
    } = this.state;
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return (
      <div className="storefront-container">
        {showModal && this.renderModal()}
        <div className="body-section-title">Update Storefront</div>
        {storeList && storeList.length && storeList.length > 1 && (
          <div className="form-type-wrapper">
            <DropDown
              theme="light"
              options={storeList}
              defaultSelected={selectedStoreName}
              handleSelectionChange={(selectedStoreName) => {
                const selectedStoreId = storeList.filter(
                  (el) => el.name === selectedStoreName
                )[0].value;
                this.getStoreData(selectedStoreId);
              }}
            />
          </div>
        )}
        {/*---------------- global retailer info ----------------*/}
        {formType === "" && (
          <div className="global-retailer-info">
            <div className="form-images-container">
              <div className="thumbnail-wrapper">
                <div className="form-label">Retailer Logo</div>
                <ImageUpload
                  type="retailer"
                  labelOne="*Images must be a min of "
                  labelTwo="800x800 px "
                  labelThree="(1:1 ratio)"
                  placeholder={bitmap}
                  value={formData.LogoImage}
                  handleFileUpload={(LogoImage, errorMessage) => {
                    this.handleFormUpdate({ LogoImage });
                  }}
                />
              </div>
              <div className="hero-wrapper">
                <div className="form-label">Header Image</div>
                <ImageUpload
                  type="hero"
                  labelOne="*Images must be a min of "
                  labelTwo="1920x1080 px "
                  labelThree="(16:9 ratio)"
                  placeholder={bitmap}
                  value={formData.HeroImage}
                  handleFileUpload={(HeroImage, errorMessage) => {
                    this.handleFormUpdate({ HeroImage });
                  }}
                />
              </div>
            </div>
            <FormInput
              type="text"
              placeholder="Type retailer Name."
              label="Retailer Name"
              value={formData.RetailerName}
              onInputChange={(RetailerName) => {
                this.handleFormUpdate({ RetailerName });
              }}
            />
            {componentReady && (
              <RichTextEditor
                label={"Retailer Description"}
                initialValue={formData.RetailerDescription}
                onInputChange={(RetailerDescription) => {
                  this.handleFormUpdate({ RetailerDescription });
                }}
              />
            )}
          </div>
        )}
        {(formType === "Retail" || formType === "Food") && (
          <div className="global-retailer-info">
            <div className="form-images-container">
              <div className="hero-wrapper">
                <div className="form-label">Header Image</div>
                <ImageUpload
                  type="hero"
                  labelOne="*Images must be a min of "
                  labelTwo="1920x1080 px "
                  labelThree="(16:9 ratio)"
                  placeholder={bitmap}
                  value={formData.StorefrontHeroImage}
                  handleFileUpload={(StorefrontHeroImage) => {
                    this.handleFormUpdate({ StorefrontHeroImage });
                  }}
                  removeImage
                />
              </div>
            </div>
          </div>
        )}
        {(formType === "Retail" || formType === "Food") && (
          <div className="store-info">
            <FormInput
              type="text"
              placeholder="Type store Name."
              label="Store Name"
              value={formData.Name}
              onInputChange={(Name) => {
                this.handleFormUpdate({ Name });
              }}
            />
            {componentReady && (
              <RichTextEditor
                label={"Retailer Description"}
                checkIfBlank={true}
                initialValue={formData.StorefrontDescription}
                onInputChange={(StorefrontDescription) => {
                  this.handleFormUpdate({ StorefrontDescription });
                }}
              />
            )}
          </div>
        )}
        {/*---------------- common info ----------------*/}
        {(formType === "" || formType === "Retail" || formType === "Food") && (
          <div className="common-retailer-info">
            <FormInput
              type="text"
              icon="website"
              placeholder="Insert link (e.g. http://...)"
              label="Website"
              value={formData.Website}
              onInputChange={(Website) => {
                this.handleFormUpdate({ Website });
              }}
            />
            <FormInput
              type="text"
              icon="instagram"
              placeholder="http://instagram.com"
              label="Instagram"
              value={formData.Instagram}
              onInputChange={(Instagram) => {
                this.handleFormUpdate({ Instagram });
              }}
            />
            <FormInput
              type="text"
              icon="facebook"
              placeholder="http://facebook.com"
              label="Facebook"
              value={formData.Facebook}
              onInputChange={(Facebook) => {
                this.handleFormUpdate({ Facebook });
              }}
            />
          </div>
        )}
        {/*---------------- store info ----------------*/}
        {(formType === "Retail" || formType === "Food") && (
          <div>
            <div className="store-contact-section">
              <FormInput
                type="text"
                icon="contact-details"
                placeholder="(88) 888-8888"
                label="Contact Details"
                value={formData.ContactNumber}
                onInputChange={(ContactNumber) =>
                  this.handleFormUpdate({ ContactNumber })
                }
              />
              {formType === "Food" && (
                <FormInput
                  type="text"
                  icon="view-menu"
                  placeholder="e.g. http://view-menu.com"
                  label="View Menu (URL)"
                  value={formData.MenuUrl}
                  onInputChange={(MenuUrl) =>
                    this.handleFormUpdate({ MenuUrl })
                  }
                />
              )}
              <FormInput
                type="text"
                icon="calendar"
                placeholder="e.g. http://online-booking.com"
                label="Online Booking (URL)"
                value={formData.OnlineBookingUrl}
                onInputChange={(OnlineBookingUrl) =>
                  this.handleFormUpdate({ OnlineBookingUrl })
                }
              />
            </div>
            <div className="best-parking">
              <div className="form-label">Best Parking</div>
              <FormDropDown
                theme="light"
                placeholder="Select a car park"
                noOptionsMessage="No car park found"
                options={carparkList}
                defaultSelected={this.setDropdownValue(
                  formData.BestParking,
                  carparkList
                )}
                handleSelectionChange={(selectedBestParking) => {
                  const BestParking = carparkList.filter(
                    (el) => el.name === selectedBestParking
                  )[0].value;
                  this.handleFormUpdate({ BestParking });
                }}
              />
            </div>

            <div>
              <div className="form-label">Other</div>
              {formType === "Food" && (
                <div className="category-list food-retailer">
                  <FormCheckbox
                    value={""}
                    label={"Vegetarian"}
                    icon="vegetarian"
                    checked={formData.IsVegetarian}
                    onCheckboxChange={(value, IsVegetarian) =>
                      this.handleFormUpdate({ IsVegetarian })
                    }
                  />
                  <FormCheckbox
                    value={""}
                    label={"Kids-Friendly"}
                    icon="kids-friendly"
                    checked={formData.IsKidsFriendly}
                    onCheckboxChange={(value, IsKidsFriendly) =>
                      this.handleFormUpdate({ IsKidsFriendly })
                    }
                  />
                  <FormCheckbox
                    value={""}
                    label={"Gift Cards"}
                    icon="gift-cards"
                    checked={formData.AcceptsGiftCards}
                    onCheckboxChange={(value, AcceptsGiftCards) =>
                      this.handleFormUpdate({ AcceptsGiftCards })
                    }
                  />
                </div>
              )}
              {formType === "Retail" && (
                <div className="category-list">
                  <FormCheckbox
                    label={"Afterpay"}
                    icon="afterpay"
                    checked={formData.AcceptsAfterPay}
                    onCheckboxChange={(value, AcceptsAfterPay) =>
                      this.handleFormUpdate({ AcceptsAfterPay })
                    }
                  />
                  <FormCheckbox
                    value={""}
                    label={"Gift Cards"}
                    icon="gift-cards"
                    checked={formData.AcceptsGiftCards}
                    onCheckboxChange={(value, AcceptsGiftCards) =>
                      this.handleFormUpdate({ AcceptsGiftCards })
                    }
                  />
                </div>
              )}
            </div>
            <div className="form-openinghours-container">
              <div className="form-label">Opening hours</div>
              <FormCheckbox
                value={""}
                label="Opening soon"
                checked={formData.IsOpeningSoon}
                onCheckboxChange={(value, IsOpeningSoon) =>
                  this.handleFormUpdate({ IsOpeningSoon })
                }
                disabled={formData.IsTemporarilyClosed}
              />
              <FormCheckbox
                value={""}
                label="Temporarily closed"
                checked={formData.IsTemporarilyClosed}
                onCheckboxChange={(value, IsTemporarilyClosed) =>
                  this.handleFormUpdate({ IsTemporarilyClosed })
                }
                disabled={formData.IsOpeningSoon}
              />
              <div className="form-sub-label">Source</div>
              <DropDown
                theme="light"
                options={sourceList}
                defaultSelected={formData.Source}
                handleSelectionChange={(selectedSourceName) => {
                  const Source = sourceList.filter(
                    (el) => el.name === selectedSourceName
                  )[0].value;
                  this.handleFormUpdate({ Source });
                }}
              />
              {formData && formData.Source && formData.Source === "Store" ? (
                <div className="storehours-container">
                  <div className="form-sub-header">
                    <div className="form-sub-label">Update store hours</div>
                    <div
                      className="form-sub-label-right"
                      onClick={() => this.resetHours()}
                    >
                      Reset hours
                    </div>
                  </div>
                  <div className="hours-table">
                    <div className="row">
                      <div className="col-3">DAY</div>
                      <div className="col-4">OPENING HOURS</div>
                      <div className="col-4">SECONDARY HOURS</div>
                      <div className="col-1">CLOSED</div>
                    </div>
                    {days.map((day, index) => {
                      let newInfo = [];
                      const infoList = [
                        "OpeningHours",
                        "ClosingHours",
                        "SecondaryOpeningHours",
                        "SecondaryClosingHours",
                        "IsClosed",
                      ];
                      infoList.map((info) => {
                        newInfo.push(formData[day + info]);
                      });
                      const primaryOpeningHour = newInfo[0];
                      const primaryClosingHour = newInfo[1];
                      const secondaryOpeningHour = newInfo[2];
                      const secondaryClosingHour = newInfo[3];
                      const closed = newInfo[4];
                      return (
                        <div className="row" key={`StoreOpeningHours${index}`}>
                          <div className="col-3">{day}</div>
                          <div className="col-4">
                            <TimePicker
                              use12Hours
                              format="h:mm A"
                              size="large"
                              allowClear={false}
                              value={
                                primaryOpeningHour
                                  ? moment(primaryOpeningHour)
                                  : null
                              }
                              onChange={(time, timeString) =>
                                this.onTimeChange(
                                  time,
                                  timeString,
                                  day,
                                  infoList[0]
                                )
                              }
                              suffixIcon={
                                <i className="icon icon-outlined-chevron-down" />
                              }
                              placeholder="Not set"
                            />
                            <TimePicker
                              use12Hours
                              format="h:mm A"
                              size="large"
                              allowClear={false}
                              value={
                                primaryClosingHour
                                  ? moment(primaryClosingHour)
                                  : null
                              }
                              onChange={(time, timeString) =>
                                this.onTimeChange(
                                  time,
                                  timeString,
                                  day,
                                  infoList[1]
                                )
                              }
                              suffixIcon={
                                <i className="icon icon-outlined-chevron-down" />
                              }
                              placeholder="Not set"
                            />
                          </div>
                          <div className="col-4">
                            <TimePicker
                              use12Hours
                              format="h:mm A"
                              size="large"
                              allowClear={false}
                              value={
                                secondaryOpeningHour
                                  ? moment(secondaryOpeningHour)
                                  : null
                              }
                              onChange={(time, timeString) =>
                                this.onTimeChange(
                                  time,
                                  timeString,
                                  day,
                                  infoList[2]
                                )
                              }
                              suffixIcon={
                                <i className="icon icon-outlined-chevron-down" />
                              }
                              placeholder="Not set"
                            />
                            <TimePicker
                              use12Hours
                              format="h:mm A"
                              size="large"
                              allowClear={false}
                              value={
                                secondaryClosingHour
                                  ? moment(secondaryClosingHour)
                                  : null
                              }
                              onChange={(time, timeString) =>
                                this.onTimeChange(
                                  time,
                                  timeString,
                                  day,
                                  infoList[3]
                                )
                              }
                              suffixIcon={
                                <i className="icon icon-outlined-chevron-down" />
                              }
                              placeholder="Not set"
                            />
                          </div>
                          <div className="col-1">
                            <FormCheckbox
                              checked={closed}
                              onCheckboxChange={(value, isClosed) =>
                                this.handleFormUpdate({
                                  [day + infoList[4]]: isClosed,
                                })
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="hint">
                    <i className="icon icon-outlined-info" />
                    <div>
                      <span>Secondary hours</span>
                      <span>
                        : The retailer will open and close twice in a single day
                        (eg restaurants: Sunday 9am - 12pm, 4pm - 6pm)
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                  ""
                )}
            </div>
            <div className="form-imagegallery-container">
              <div className="form-label">Image gallery</div>
              <FormFile
                icon="plus"
                label="Add Image"
                value={formData.ImageGallery}
                handleAttachments={(ImageGallery, errorMessage) =>
                  this.handleFormUpdate({ ImageGallery })
                }
              />
            </div>
          </div>
        )}
        {/*---------------- form controls ----------------*/}
        {formType !== null && (
          <div className="form-controls-container">
            <Button
              color="secondary"
              onClick={() => (window.location.href = `/stores`)}
            >
              CANCEL
            </Button>
            <Button
              disable={false}
              color="primary"
              onClick={() => this.saveForm()}
            >
              SAVE
            </Button>
          </div>
        )}
      </div>
    );
  }
}
export default FeatureRetailerPortalStorefront;
