import React, { Component } from "react";

class Input extends Component {
  render() {
    const {
      label,
      value,
      id,
      size,
      onInputChange,
      type,
      placeholder,
      disabled,
      onInputKeyDown,
      required,
      responseText,
      style,
      autoComplete,
      blank,
      autoFocus,
    } = this.props;
    return (
      <div className="retailer-input-container" style={style}>
        {label && (
          <div className="label-container">
            <label>{label}</label>
          </div>
        )}
        <div className="input-container">
          <input
            className={`retailer-input form-control ${
              value ? "has-value" : ""
            } ${size ? size : ""} ${
              placeholder === "Password" ? "hide-password" : ""
            } ${blank ? blank : ""}`}
            id={id}
            value={value}
            onChange={(e) => onInputChange(e.target.value)}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            onKeyDown={(e) => (onInputKeyDown ? onInputKeyDown(e) : null)}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
          />
          {responseText && (
            <div className={`response-text ${responseText.type}`}>
              {responseText.value}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Input;
