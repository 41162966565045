import React from "react";
import { Text, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import Button from "../Button";
import Label from "../Label";
import FormCheckbox from "../FormCheckbox";
import { getAncestorElement } from "../../../helper/GetAncestorElementHelper";
import logoPlaceholder from "../../../assets/img/logoPlaceholder.png";
import { Fragment } from "react";

class ListItem extends React.Component {
  /*
    ** Sample List Item
        <ListItem key={`itemId-${index}`}
            listItemOnClick={"Insert listItem onClick event"}
            logo={{ "bgColor": "#000", "src": "http://placekitten.com/100/100" }}
            label={"label"}
            title={"title"}
            description={"description"}
            status={"DRAFT"}
            previewBtn={{ "label": "Preview", "color": "secondary" }}
            previewBtnOnClick={"Insert preview onClick event"}
            btnOne={{ "icon": "trash-2", "color": "secondary", "tooltip": "Delete" }}
            btnOneOnClick={"Insert btnOne onClick event"}
        />
    ** Sample List Item using Approval
        <ListItem key={`itemId-${index}`}
            listItemOnClick={"Insert listItem onClick event"}
            logo={{ "bgColor": "#000", "src": "http://placekitten.com/100/100" }}
            label={"label"}
            title={"title"}
            description={"description"}
            status={"DRAFT"}
            previewBtn={{ "label": "Preview", "color": "secondary" }}
            previewBtnOnClick={"Insert preview onClick event"}
            btnOne={{ "icon": "thumbs-up", "color": "primary", "tooltip": "Approve" }}
            btnOneOnClick={"Insert btnOne onClick event"}
            btnTwo={{ "icon": "thumbs-down", "color": "red", "tooltip": "Reject" }}
            btnTwoOnClick={"Insert btnTwo onClick event"}
            checkboxValue={"Sample Value"}
            checkboxOnChange={(value, checked) =>
                this.handleSelected(value, checked)
            }
        />
    */

  handleOnClickCheckbox = (element) => {
    let parentElement = "";
    let checkboxElement = "";
    if (!/btn|icon|form-checkbox-container|checkmark/.test(element.className)) {
      if (/js-list-item/.test(element.className)) {
        checkboxElement = element.querySelector(".form-checkbox-container");
      } else {
        parentElement = getAncestorElement(element, "js-list-item");
        checkboxElement = parentElement.querySelector(
          ".form-checkbox-container"
        );
      }
      checkboxElement.click();
    }
  };

  convertString = (string) => {
    return string
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'");
  };

  handleOnClickListItem = (e) => {
    const { listItemOnClick, checkboxValue, checkboxOnChange } =
      this.props || {};

    const element = e.target;
    if ((window && window.outerWidth >= 992) || this.props.noActionBtn) {
      if (listItemOnClick) {
        listItemOnClick(e);
      } else if (checkboxValue && checkboxOnChange) {
        this.handleOnClickCheckbox(e.target);
      }
    } else {
      let currentActiveItem = document.querySelector(".js-list-item.active");
      if (currentActiveItem) {
        currentActiveItem.classList.remove("active");
      }
      let elemListItem = "";
      if (/js-list-item/.test(element.className)) {
        elemListItem = element;
      } else {
        elemListItem = getAncestorElement(element, "js-list-item");
      }
      elemListItem.classList.add("active");
    }
  };

  render() {
    const {
      logoBgColor,
      logoSrc,
      label,
      showNotif,
      notification,
      title,
      description,
      storeDescription,
      status,
      listItemOnClick,
      previewBtn,
      previewBtnOnClick,
      btnOne,
      btnOneOnClick,
      btnTwo,
      btnTwoOnClick,
      checkboxValue,
      checkboxOnChange,
      isSelected,
    } = this.props || {};

    let descriptionVal = "";

    if (description && description.value) {
      descriptionVal = this.convertString(description.value);
    }

    return (
      <div
        className={`list-item js-list-item ${status && status.value ? status.value.toLowerCase() : ""} 
            ${checkboxValue && checkboxOnChange ? "has-checkbox" : ""}
            ${isSelected ? "selected" : ""} `}
        onClick={this.handleOnClickListItem}
      >
        {checkboxValue && checkboxOnChange && (
          <div className="list-item__checkbox">
            <FormCheckbox
              value={checkboxValue}
              onCheckboxChange={checkboxOnChange}
            />
          </div>
        )}
        {logoSrc ? (
          <div
            className={`list-item__logo
                    ${logoBgColor ? "has-bg-color" : ""}
                    `}
            style={{ backgroundColor: logoBgColor }}
          >
            <img
              alt={`${title && title.value ? title.value : "list-item-logo"}`}
              src={logoPlaceholder}
              data-src={logoSrc}
              className="lazyload"
            />
          </div>
        ) : (
            <div className="list-item__logo no-logo" />
          )}

        <div className="list-item__details">
          {label && label.value && (
            <div className="list-item__label">
              <Text field={label} />
            </div>
          )}
          {title && title.value && (
            <div className="list-item__title">
              {showNotif ? (
                <Fragment>
                  <div
                    className={`show-notif ${notification ? "unread" : ""}`}
                  />
                  <span>
                    <Text field={title} />
                  </span>
                </Fragment>
              ) : (
                  <Text field={title} />
                )}
            </div>
          )}
          {description && description.value && (
            <div className="list-item__description">
              <RichText field={{ value: descriptionVal }} />
            </div>
          )}
          {storeDescription && (
            <div className="list-item__description">{storeDescription}</div>
          )}
        </div>
        {status && status.value && (
          <div className="list-item__status">
            <Label color={status.value.toLowerCase()}>{status.value}</Label>
          </div>
        )}

        <div className="list-item__actions">
          <Button
            className={"js-btn-edit"}
            color={"secondary"}
            size="small"
            icon={"edit"}
            onClick={listItemOnClick}
          />
          {previewBtn && (
            <Button
              className="btn-preview"
              color={previewBtn.color ? previewBtn.color : "secondary"}
              size="small"
              icon={"eye"}
              onClick={previewBtnOnClick}
            >
              {previewBtn.label}
            </Button>
          )}
          {btnOne && (
            <Button
              color={btnOne.color ? btnOne.color : "secondary"}
              size="small"
              icon={btnOne.icon}
              tooltip={btnOne.tooltip}
              onClick={btnOneOnClick}
            />
          )}
          {btnTwo && (
            <Button
              color={btnTwo.color ? btnTwo.color : "secondary"}
              size="small"
              icon={btnTwo.icon}
              tooltip={btnTwo.tooltip}
              onClick={btnTwoOnClick}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ListItem;
