import { Text } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
import Button from "../Button";

const Card = (props) => {
  const {
    className,
    title,
    content = null,
    textContent,
    btnText,
    btnDisabled,
    btnOnClick,
  } = props;
  return (
    <div className={`card-wrapper ${className}`}>
      <div className="h2-bold">{title}</div>
      <div className="card-content">
        {textContent && textContent.value && (
          <div>
            <Text field={textContent} />
          </div>
        )}
        {content}
        <div className="card-button">
          <Button
            color="secondary"
            size="small-full"
            disabled={btnDisabled}
            onClick={btnOnClick}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Card;
