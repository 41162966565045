import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";

class FoundationLayoutMain extends React.Component {
  componentDidMount() {
    if (window) {
      window.addEventListener("resize", this.handleResize);
    }
    if (document) {
      this.handleResize();
    }
  }

  handleResize = () => {
    let bodyWrapper = ""

    if (document) {
      bodyWrapper = document.querySelector(".body-wrapper");
    }

    if (window.outerWidth >= 992) {
      bodyWrapper.style.height = "calc(100vh - 68px)";

    } else {
      bodyWrapper.style.height = window.innerHeight + "px";
    }
  };

  render() {
    return (
      <div className="main-layout">
        <div className="top-nav">
          <Placeholder name="jss-rp-top" rendering={this.props.rendering} />
        </div>
        <div className="left-nav">
          <Placeholder name="jss-rp-left" rendering={this.props.rendering} />
        </div>
        <div className="body-container">
          <div className="body-wrapper">
            <div className="container">
              <Placeholder
                name="jss-rp-body"
                rendering={this.props.rendering}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FoundationLayoutMain;
