import React, { Component } from "react";

class Popup extends Component {
  render() {
    const { show } = this.props;
    return (
      <React.Fragment>
        <div className="modal-blur" />
        <div className="portal-modal-container">
          <div className="portal-modal">
            {show && (
              <div
                className="btn btn--tertiary btn--icon close-button"
                onClick={() => {
                  show();
                }}
              >
                <i className="icon icon-close" />
              </div>
            )}
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Popup;
