import BaseURL, { DevURL } from "./BaseURL";

const isDev = process.env.REACT_APP_DEV || false;

export const getNotifications = () => {
  const endpoint = isDev ? "/GetNotifications" : "/notification/GetNotifications";
   return BaseURL.get(endpoint);
};

export const getNotificationContent = (params) => {
  const endpoint = isDev ? "/GetNotificationContent" : "/notification/GetNotificationsContent";
  return BaseURL.get(endpoint, { params: { ...params } });
};

export const updateMemoStatus = (params) => {
  return BaseURL.get("/notification/MemoViewed", { params: { ...params } });
};

export const acknowledgeMemo = (params) => {
  return BaseURL.get("/notification/Acknowledged", { params: { ...params } });
};
