import BaseURL from "./BaseURL";

export const login = (data) => {
  return BaseURL.post("/account/login", { ...data });
  // return DevURL.get("/GetEvents", { ...data });
};

export const logout = () => {
  return BaseURL.post("/account/logout");
};
