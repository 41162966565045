export const hideBackToTop = (isHidden) => {
    if (document) {
        const backToTopWrapper = document.querySelector(".js-back-to-top");
        if (backToTopWrapper) {
            if (isHidden) {
                backToTopWrapper.classList.add("is-hidden");
            } else {
                backToTopWrapper.classList.remove("is-hidden");
            }
        }
    }
}