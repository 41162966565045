import React from "react";
import axios from "axios";
import Loadable from "react-loadable";
import TagManager from "react-gtm-module";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { get } from "lodash";

import FormInput from "../../../Base/FormInput";
import Checkbox from "../../../Base/Checkbox";
import FormSelect from "../../../Base/FormSelect";
import FormCheckbox from "../../../Base/FormCheckbox";
import FormImageGallery from "../../../Base/FormImageGallery";
import RadioButton from "../../../Base/RadioButton";
import FormImageUpload from "../../../Base/FormImageUpload";
import FormListbox from "../../../Base/FormListbox";
import FormErrorMessage from "../../../Base/FormErrorMessage";
import PopupPreview from "../../../Base/Popup/PopupPreview";
import FormListCheckbox from "../../../Base/FormListCheckbox";

import {
  getStorefrontData,
  getCarParkList,
  getCategoryList
} from "../../../../api/StorefrontAPI";
import { buildFormData } from "../../../../helper/StorefrontHelper";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "../../../../helper/ScrollHelper";

//getStoreData
//getRetailerData
// import getStorefrontDataJson from "./getStoreData.json";
// import getCarParkListJson from "./getCarParkListJson.json";
// import getCategoryListJson from "./getCategoryListJson.json";

const RichTextEditor = Loadable({
  loader: () => import("../../../Base/RichTextEditor"),
  loading: () => null,
});

class FormStoreFront extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        StoreID: "",
        Name: "",
        StorefrontDescription: "",
        ContactNumber: "",
        MenuUrl: "",
        BestParking: "",
        AcceptsAfterPay: false,
        AcceptsGiftCards: false,
        IsKidsFriendly: false,
        IsVegetarian: false,
        RetailerName: "",
        RetailerDescription: "",
        Website: "",
        Instagram: "",
        Facebook: "",
        LogoImage: null,
        HeroImage: null,
        ImageGallery: [],
        ImageGalleryChecker: [],
        MondayOpeningHours: "",
        MondayClosingHours: "",
        MondaySecondaryOpeningHours: "",
        MondaySecondaryClosingHours: "",
        MondayIsClosed: "",
        TuesdayOpeningHours: "",
        TuesdayClosingHours: "",
        TuesdaySecondaryOpeningHours: "",
        TuesdaySecondaryClosingHours: "",
        TuesdayIsClosed: "",
        WednesdayOpeningHours: "",
        WednesdayClosingHours: "",
        WednesdaySecondaryOpeningHours: "",
        WednesdaySecondaryClosingHours: "",
        WednesdayIsClosed: "",
        ThursdayOpeningHours: "",
        ThursdayClosingHours: "",
        ThursdaySecondaryOpeningHours: "",
        ThursdaySecondaryClosingHours: "",
        ThursdayIsClosed: "",
        FridayOpeningHours: "",
        FridayClosingHours: "",
        FridaySecondaryOpeningHours: "",
        FridaySecondaryClosingHours: "",
        FridayIsClosed: "",
        SaturdayOpeningHours: "",
        SaturdayClosingHours: "",
        SaturdaySecondaryOpeningHours: "",
        SaturdaySecondaryClosingHours: "",
        SaturdayIsClosed: "",
        SundayOpeningHours: "",
        SundayClosingHours: "",
        SundaySecondaryOpeningHours: "",
        SundaySecondaryClosingHours: "",
        SundayIsClosed: "",
        StorefrontHeroImage: null,
        Categories: [],
      },
      StoreOpeningHours: "",
      RetailerType: "",
      formType: null,
      componentReady: false,
      sourceList: [
        {
          Id: {
            value: "Centre",
          },
          Name: {
            value: "Match " + props.centreName + " Shopping Centre hours",
          },
        },
        {
          Id: {
            value: "Store",
          },
          Name: {
            value: "Set custom hours",
          },
        },
      ],
      openingHoursList: [
        {
          Id: {
            value: "IsOpeningSoon",
          },
          Name: {
            value: "Opening soon",
          },
        },
        {
          Id: {
            value: "IsTemporarilyClosed",
          },
          Name: {
            value: "Temporarily closed",
          },
        },
        {
          Id: {
            value: "Open",
          },
          Name: {
            value: "Open",
          },
        },
      ],
      carparkList: [],
      iconList: [],
      categoryList: [],
      modalContent: null,
      showImageGallery: false,
      disableBtn: true,
      showSaveHint: false,
      imageGalleryHolder: [],
      showErrorMessage: false,
      formErrorMessage: "",
      isStoreDataReady: false,
      showModal: false,
    };
  }

  componentDidMount() {
    const { storeId, centreName } = this.props;
    this.setState(
      {
        componentReady: true,
      },
      () => this.getStoreData(storeId)
    );
    this.getCategoryData();
    if (centreName) {
      this.getCarParkData(centreName);
    }
    this.handleFormData();
  }

  decodeHTML = (html) => {
    if (document) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }
  };

  getStoreData = async (storeId) => {
    try {
      // const $this = this;
      // let response = {
      //   data: getStorefrontDataJson
      // }
      // if (response.status !== 200 && response.data) {
      let response = await getStorefrontData({ storeId });
      const $this = this;
      if (response.status === 200 && response.data) {
        const ImageGallery = get(response.data, "ImageGallery", []);
        const Categories = get(response.data, "Categories", []);
        this.setState({
          formData: {
            Name: get(response.data, "Name.value", null),
            StorefrontDescription: $this.decodeHTML(
              get(response.data, "Description.value", "")
            ),
            ContactNumber: get(response.data, "ContactNumber.value", ""),
            MenuUrl: get(response.data, "MenuUrl.value", ""),
            BestParking: get(response.data, "BestParking.value", ""),
            AcceptsAfterPay: get(response.data, "AcceptsAfterPay.value", false),
            AcceptsGiftCards: get(
              response.data,
              "AcceptsGiftCards.value",
              false
            ),
            IsKidsFriendly: get(response.data, "IsKidsFriendly.value", false),
            IsVegetarian: get(response.data, "IsVegetarian.value", false),
            RetailerName: get(
              response.data,
              "RetailerDetails.Name.value",
              null
            ),
            RetailerDescription: $this.decodeHTML(
              get(response.data, "RetailerDetails.Description.value", "")
            ),
            Website: get(response.data, "RetailerDetails.Website.value", null),
            Instagram: get(
              response.data,
              "RetailerDetails.Instagram.value",
              null
            ),
            Facebook: get(
              response.data,
              "RetailerDetails.Facebook.value",
              null
            ),
            LogoImage: get(
              response.data,
              "RetailerDetails.LogoImage.value.src",
              null
            ),
            HeroImage: get(
              response.data,
              "RetailerDetails.HeroImage.value.src",
              null
            ),
            StorefrontHeroImage: get(
              response.data,
              "StorefrontHeroImage.value.src",
              null
            ),
            ImageGalleryChecker:
              ImageGallery && ImageGallery.length > 0
                ? ImageGallery.map((el) => el.Id.value)
                : [],
            ImageGallery:
              ImageGallery && ImageGallery.length > 0
                ? ImageGallery.map((el) => {
                  return {
                    name: el.Name.value,
                    id: el.Id.value,
                    src: el.Src.value,
                  };
                })
                : [],
            StoreID: get(response.data, "StoreID.value", null),
            IsTemporarilyClosed: get(
              response.data,
              "IsTemporarilyClosed.value",
              null
            ),
            IsOpeningSoon: get(response.data, "IsTemporarilyClosed.value", null)
              ? false
              : get(response.data, "IsOpeningSoon.value", null),
            OpeningHoursStatus: get(
              response.data,
              "OpeningHoursStatus.value",
              null
            ),
            Source: get(response.data, "Source.value", null),
            MondayOpeningHours: get(
              response.data,
              "StoreOpeningHours.MondayOpeningHours.value",
              null
            ),
            MondayClosingHours: get(
              response.data,
              "StoreOpeningHours.MondayClosingHours.value",
              null
            ),
            MondaySecondaryOpeningHours: get(
              response.data,
              "StoreOpeningHours.MondaySecondaryOpeningHours.value",
              null
            ),
            MondaySecondaryClosingHours: get(
              response.data,
              "StoreOpeningHours.MondaySecondaryClosingHours.value",
              null
            ),
            MondayIsClosed: get(
              response.data,
              "StoreOpeningHours.MondayIsClosed.value",
              null
            ),
            TuesdayOpeningHours: get(
              response.data,
              "StoreOpeningHours.TuesdayOpeningHours.value",
              null
            ),
            TuesdayClosingHours: get(
              response.data,
              "StoreOpeningHours.TuesdayClosingHours.value",
              null
            ),
            TuesdaySecondaryOpeningHours: get(
              response.data,
              "StoreOpeningHours.TuesdaySecondaryOpeningHours.value",
              null
            ),
            TuesdaySecondaryClosingHours: get(
              response.data,
              "StoreOpeningHours.TuesdaySecondaryClosingHours.value",
              null
            ),
            TuesdayIsClosed: get(
              response.data,
              "StoreOpeningHours.TuesdayIsClosed.value",
              null
            ),
            WednesdayOpeningHours: get(
              response.data,
              "StoreOpeningHours.WednesdayOpeningHours.value",
              null
            ),
            WednesdayClosingHours: get(
              response.data,
              "StoreOpeningHours.WednesdayClosingHours.value",
              null
            ),
            WednesdaySecondaryOpeningHours: get(
              response.data,
              "StoreOpeningHours.WednesdaySecondaryOpeningHours.value",
              null
            ),
            WednesdaySecondaryClosingHours: get(
              response.data,
              "StoreOpeningHours.WednesdaySecondaryClosingHours.value",
              null
            ),
            WednesdayIsClosed: get(
              response.data,
              "StoreOpeningHours.WednesdayIsClosed.value",
              null
            ),
            ThursdayOpeningHours: get(
              response.data,
              "StoreOpeningHours.ThursdayOpeningHours.value",
              null
            ),
            ThursdayClosingHours: get(
              response.data,
              "StoreOpeningHours.ThursdayClosingHours.value",
              null
            ),
            ThursdaySecondaryOpeningHours: get(
              response.data,
              "StoreOpeningHours.ThursdaySecondaryOpeningHours.value",
              null
            ),
            ThursdaySecondaryClosingHours: get(
              response.data,
              "StoreOpeningHours.ThursdaySecondaryClosingHours.value",
              null
            ),
            ThursdayIsClosed: get(
              response.data,
              "StoreOpeningHours.ThursdayIsClosed.value",
              null
            ),
            FridayOpeningHours: get(
              response.data,
              "StoreOpeningHours.FridayOpeningHours.value",
              null
            ),
            FridayClosingHours: get(
              response.data,
              "StoreOpeningHours.FridayClosingHours.value",
              null
            ),
            FridaySecondaryOpeningHours: get(
              response.data,
              "StoreOpeningHours.FridaySecondaryOpeningHours.value",
              null
            ),
            FridaySecondaryClosingHours: get(
              response.data,
              "StoreOpeningHours.FridaySecondaryClosingHours.value",
              null
            ),
            FridayIsClosed: get(
              response.data,
              "StoreOpeningHours.FridayIsClosed.value",
              null
            ),
            SaturdayOpeningHours: get(
              response.data,
              "StoreOpeningHours.SaturdayOpeningHours.value",
              null
            ),
            SaturdayClosingHours: get(
              response.data,
              "StoreOpeningHours.SaturdayClosingHours.value",
              null
            ),
            SaturdaySecondaryOpeningHours: get(
              response.data,
              "StoreOpeningHours.SaturdaySecondaryOpeningHours.value",
              null
            ),
            SaturdaySecondaryClosingHours: get(
              response.data,
              "StoreOpeningHours.SaturdaySecondaryClosingHours.value",
              null
            ),
            SaturdayIsClosed: get(
              response.data,
              "StoreOpeningHours.SaturdayIsClosed.value",
              null
            ),
            SundayOpeningHours: get(
              response.data,
              "StoreOpeningHours.SundayOpeningHours.value",
              null
            ),
            SundayClosingHours: get(
              response.data,
              "StoreOpeningHours.SundayClosingHours.value",
              null
            ),
            SundaySecondaryOpeningHours: get(
              response.data,
              "StoreOpeningHours.SundaySecondaryOpeningHours.value",
              null
            ),
            SundaySecondaryClosingHours: get(
              response.data,
              "StoreOpeningHours.SundaySecondaryClosingHours.value",
              null
            ),
            SundayIsClosed: get(
              response.data,
              "StoreOpeningHours.SundayIsClosed.value",
              null
            ),
            Categories:
              Categories && Categories.length > 0
                ? Categories.map((el) => el.CategoryId.value)
                : [],
          },
          RetailerType: get(
            response.data,
            "RetailerDetails.RetailerType.value",
            ""
          ),
          formType:
            storeId === ""
              ? ""
              : get(response.data, "RetailerDetails.RetailerType.value", ""),
          imageGalleryHolder:
            ImageGallery && ImageGallery.length > 0
              ? ImageGallery.map((el) => {
                return {
                  name: el.Name.value,
                  id: el.Id.value,
                  src: el.Src.value,
                };
              })
              : [],
          showImageGallery:
            ImageGallery && ImageGallery.length > 0 ? true : false,
          isStoreDataReady: true,
        });
      }
    } catch (e) {
      console.error("Getting store data failed");
      this.setState({
        isStoreDataReady: true,
      });
    }
  };

  getCarParkData = async (centre) => {
    try {
      let response = await getCarParkList({ centre });
      if (response.status === 200 && response.data) {
        this.setState({
          carparkList: get(response.data, "CarParkList", []),
        });
      }
    } catch (e) {
      console.error("Getting car park data failed");
      // this.setState({
      //   carparkList: get(getCarParkListJson, "CarParkList", []),
      // });
    }
  };

  getCategoryData = async () => {
    try {
      // let response = {
      //   data: getCategoryListJson
      // }
      // let updateCategoryList = [];
      // if (response.status !== 200 && response.data) {
      let response = await getCategoryList();

      let updateCategoryList = [];
      if (response.status === 200 && response.data) {
        let categoryResponse = get(response.data, "Categories", []);

        if (categoryResponse.length > 0) {
          categoryResponse.forEach((el) => {
            updateCategoryList.push({
              name: el.CategoryName.value,
              value: el.CategoryId.value,
            });
          });
        }
        this.setState({
          categoryList: updateCategoryList,
        });
      }
    } catch (e) {
      console.error("Getting category list data failed");
    }
  };

  handleFormData = () => {
    const { showSaveHint, disableBtn } = this.state;
    this.props.handleFormData(
      showSaveHint,
      disableBtn,
      this.handleSaveForm,
      this.handleSubmitForm,
      this.handlePreviewForm
    );
  };

  handleFormUpdate = (value) => {
    this.setState(
      {
        disableBtn: true,
        formData: {
          ...this.state.formData,
          ...value,
        },
      },
      this.handleFormData
    );
  };

  handleSaveForm = async () => {
    const { formData } = this.state;
    var timezoneId = new Date().getTimezoneOffset();
    const url = "/api/sitecore/store/SaveStorefrontData";
    let data = buildFormData({ ...formData, timeZoneId: timezoneId });
    let backToTop = document.querySelector(
      ".form__modal--back-to-top-button"
    );
    if (backToTop) {
      backToTop.click();
    }
    this.setState(
      {
        showSaveHint: true,
        disableBtn: true,
      },
      this.handleFormData
    );

    try {
      let response = await axios({
        method: "POST",
        url,
        data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });

      if (response) {
        if (get(response.data, "IsSuccess.value", false)) {
          this.setState(
            {
              showSaveHint: false,
              disableBtn: false,
              showErrorMessage: false,
            },
            this.handleFormData
          );

          return true;
        } else {
          let errorMessage =
            "Form Not Saved: " +
            get(response.data, "message.value", "Invalid response");

          this.setState(
            {
              showSaveHint: false,
              disableBtn: true,
              formErrorMessage: errorMessage,
              showErrorMessage: true,
            },
            this.handleFormData
          );

          return false;
        }
      }
    } catch (e) {
      console.error("Form Save Error: " + e.response.statusText);
      let errorMessage = "Form Save Error: " + e.response.statusText;
      this.setState(
        {
          showSaveHint: false,
          disableBtn: true,
          formErrorMessage: errorMessage,
          showErrorMessage: true,
        },
        this.handleFormData
      );


      return false;
    }
  };

  handleSubmitForm = async () => {
    const { formData } = this.state;
    var timezoneId = new Date().getTimezoneOffset();
    const url = "/api/sitecore/store/SubmitStorefrontData";
    let data = buildFormData({ ...formData, timeZoneId: timezoneId });

    try {
      let response = await axios({
        method: "POST",
        url,
        data,
        params: {
          storeId: formData.StoreID,
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });

      if (response) {
        if (get(response.data, "IsSuccess.value", false)) {
          this.setState({
            showErrorMessage: false,
          });
          TagManager.dataLayer({
            dataLayer: {
              event: "updatedStoreInfo",
              storeInfoCentre: this.props.centreName,
              storeInfoRetailer: formData.RetailerName,
              storeInfoName: formData.Name,
            },
            dataLayerName: "dataLayer",
          });
          return true;
        } else {
          console.log(response.data);

          let errorMessage =
            "Form Not Submitted: " +
            get(response.data, "message.value", "Invalid response");

          this.setState({
            formErrorMessage: errorMessage,
            showErrorMessage: true,
          });

          let backToTop = document.querySelector(
            ".form__modal--back-to-top-button"
          );
          if (backToTop) {
            backToTop.click();
          }
          return false;
        }
      }
    } catch (e) {
      console.error("Form Submit Error: " + e.response.statusText);
      let errorMessage = "Form Submit Error: " + e.response.statusText;

      this.setState({
        formErrorMessage: errorMessage,
        showErrorMessage: true,
      });

      return false;
    }
  };

  handlePreviewForm = () => {
    this.setState({
      showModal: true,
    });
  };

  renderBulkPreview = () => {
    const { previewList } = this.props || {};
    const { LogoImage } = this.state.formData;
    return (
      <div className="article-modal">
        {previewList && previewList.length > 0 && (
          <PopupPreview
            previewTitle={"Preview"}
            previewLogo={LogoImage}
            previewList={previewList}
            previewListChildName="Storefronts"
            btnLabel={"Submit"}
            btnOnClick={() => {
              let submitBtn = document.querySelector(".store-submit-btn");
              if (submitBtn) {
                submitBtn.click();
              }
            }}
            showPreviewModal={() => this.setState({ showModal: false })}
          />
        )}
      </div>
    );
  };

  // saveForm = async () => {
  //     const { formData } = this.state;
  //     var timezoneId = new Date().getTimezoneOffset();
  //     const url = "/api/sitecore/store/SaveStorefrontData";
  //     let data = buildFormData({ ...formData, timeZoneId: timezoneId });
  //     try {
  //         let response = await axios({
  //             method: "POST",
  //             url,
  //             data,
  //             params: {
  //                 storeId: formData.StoreID,
  //             },
  //             config: { headers: { "Content-Type": "multipart/form-data" } },
  //         });
  //         if (response) {
  //             if (get(response.data, "IsSuccess.value", false)) {
  //                 let successMessage = {
  //                     title: "Form Saved",
  //                     body: get(response.data, "message.value", "Successful"),
  //                 };
  //                 this.setState({
  //                     showModal: true,
  //                     modalContent: successMessage,
  //                 });
  //                 TagManager.dataLayer({
  //                     dataLayer: {
  //                         event: "updateStoreInfo",
  //                     },
  //                     dataLayerName: "dataLayer",
  //                 });

  //                 return true;
  //             } else {
  //                 let errorMessage = {
  //                     title: "Form Not Saved",
  //                     body: get(response.data, "message.value", "Invalid response"),
  //                 };
  //                 this.setState({
  //                     showModal: true,
  //                     modalContent: errorMessage,
  //                 });
  //                 return false;
  //             }
  //         }
  //     } catch (e) {
  //         console.log(e.response.statusText);
  //         let successMessage = {
  //             title: "Form Saved",
  //             body: get(e.response.data, "message.value", "Successful"),
  //         };
  //         this.setState({
  //             showModal: true,
  //             modalContent: successMessage,
  //         });
  //         return false;
  //     }
  // };

  renderStoreHours = (formData) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return (
      <div className="storehours-container">
        <div className="form-sub-header">
          <div className="form-sub-label">Customise store hours</div>
          <div
            className="form-sub-label-right"
            onClick={() => this.resetHours()}
          >
            {"Reset hours"}
          </div>
        </div>
        <div className="hours-table">
          <div className="row is-tablet-desktop">
            <div className="col col-3">Day</div>
            <div className="col col-4">Opening hours</div>
            <div className="col col-4">Secondary hours</div>
            <div className="col col-1">Closed</div>
          </div>
          {days.map((day, index) => {
            let newInfo = [];
            const infoList = [
              "OpeningHours",
              "ClosingHours",
              "SecondaryOpeningHours",
              "SecondaryClosingHours",
              "IsClosed",
            ];
            infoList.map((info) => {
              newInfo.push(formData[day + info]);
            });
            const primaryOpeningHour = newInfo[0];
            const primaryClosingHour = newInfo[1];
            const secondaryOpeningHour = newInfo[2];
            const secondaryClosingHour = newInfo[3];
            const closed = newInfo[4];
            return (
              <div className="row" key={`StoreOpeningHours${index}`}>
                <div className="col col-3 is-tablet-desktop">{day}</div>
                <input className="storehours-day-checkbox js-openinghours is-mobile" type="checkbox" id={day + index} name={day + index} value={day} />
                <label className="storehours-day-label is-mobile" htmlFor={day + index}>
                  <span>{day}</span>
                  <i className="icon icon-chevron-down-small" />
                </label>
                <div className="storehours-time-label is-mobile">{"Opening hours"}</div>
                <div className="col col-4">
                  <TimePicker
                    use12Hours
                    format="h:mma"
                    size="large"
                    allowClear={false}
                    value={
                      primaryOpeningHour ? moment(primaryOpeningHour) : null
                    }
                    onChange={(time, timeString) =>
                      this.onTimeChange(time, timeString, day, infoList[0])
                    }
                    suffixIcon={<i className="icon icon-chevron-down-small" />}
                    placeholder="Not set"
                    disabled={closed}
                  />
                  <TimePicker
                    use12Hours
                    format="h:mma"
                    size="large"
                    allowClear={false}
                    value={
                      primaryClosingHour ? moment(primaryClosingHour) : null
                    }
                    onChange={(time, timeString) =>
                      this.onTimeChange(time, timeString, day, infoList[1])
                    }
                    suffixIcon={<i className="icon icon-chevron-down-small" />}
                    placeholder="Not set"
                    disabled={closed}
                  />
                </div>
                <div className="storehours-time-label is-secondary is-mobile">{"Secondary hours"}</div>
                <div className="col col-4">
                  <TimePicker
                    use12Hours
                    format="h:mma"
                    size="large"
                    allowClear={false}
                    value={
                      secondaryOpeningHour ? moment(secondaryOpeningHour) : null
                    }
                    onChange={(time, timeString) =>
                      this.onTimeChange(time, timeString, day, infoList[2])
                    }
                    suffixIcon={<i className="icon icon-chevron-down-small" />}
                    placeholder="Not set"
                    disabled={closed}
                  />
                  <TimePicker
                    use12Hours
                    format="h:mma"
                    size="large"
                    allowClear={false}
                    value={
                      secondaryClosingHour ? moment(secondaryClosingHour) : null
                    }
                    onChange={(time, timeString) =>
                      this.onTimeChange(time, timeString, day, infoList[3])
                    }
                    suffixIcon={<i className="icon icon-chevron-down-small" />}
                    placeholder="Not set"
                    disabled={closed}
                  />
                </div>
                <div className="col col-1">
                  <FormCheckbox
                    checked={closed}
                    onCheckboxChange={(value, isClosed) => {
                      this.handleFormUpdate({
                        [day + infoList[4]]: isClosed,
                      });
                    }}
                    className="storehours-close is-mobile"
                    label={"Closed"}
                  />
                </div>
              </div>
            );
          })}
          <div className="row">
            <div className="col col-12">
              <div className="hint">
                <i className="icon icon-info" />
                <span>
                  {
                    "Secondary hours: The retailer will close and re-open in a single day (eg: Sunday 9am - 12pm, 4pm - 6pm)"
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onTimeChange = (time, timeString, day, info) => {
    let dateTime;
    if (timeString) {
      dateTime =
        moment().format("YYYYMMDDT") +
        moment(timeString, ["h:mm A"])
          .format("HH:mm")
          .replace(":", "") +
        "00";
    }
    this.handleFormUpdate({
      [day + info]: dateTime ? dateTime : null,
    });
  };

  resetHours = () => {
    this.setState(
      {
        disableBtn: true,
        formData: {
          ...this.state.formData,
          MondayOpeningHours: null,
          MondayClosingHours: null,
          MondaySecondaryOpeningHours: null,
          MondaySecondaryClosingHours: null,
          TuesdayOpeningHours: null,
          TuesdayClosingHours: null,
          TuesdaySecondaryOpeningHours: null,
          TuesdaySecondaryClosingHours: null,
          WednesdayOpeningHours: null,
          WednesdayClosingHours: null,
          WednesdaySecondaryOpeningHours: null,
          WednesdaySecondaryClosingHours: null,
          ThursdayOpeningHours: null,
          ThursdayClosingHours: null,
          ThursdaySecondaryOpeningHours: null,
          ThursdaySecondaryClosingHours: null,
          FridayOpeningHours: null,
          FridayClosingHours: null,
          FridaySecondaryOpeningHours: null,
          FridaySecondaryClosingHours: null,
          SaturdayOpeningHours: null,
          SaturdayClosingHours: null,
          SaturdaySecondaryOpeningHours: null,
          SaturdaySecondaryClosingHours: null,
          SundayOpeningHours: null,
          SundayClosingHours: null,
          SundaySecondaryOpeningHours: null,
          SundaySecondaryClosingHours: null,
        },
      },
      () => {
        this.handleFormData();
        const storeCheckboxList = document.querySelectorAll(".js-openinghours");
        if (storeCheckboxList.length > 0) {
          Array.prototype.slice.call(storeCheckboxList).map((item, index) => {
            item.checked = true;
          })
        }
      }
    );
  };

  handleListbox = (id, field, isCheckbox) => {
    const { formData } = this.state;
    let options;
    let selected = [];
    if (document) {
      if (isCheckbox) {
        options = document.querySelectorAll(`#${id} input:checked`);
      } else {
        options = document.querySelectorAll(`#${id} option:checked`);
      }
      if (options) {
        for (let i = 0; i < options.length; i++) {
          selected.push(options[i].value);
        }
      }
    }
    this.setState(
      {
        disableBtn: true,
        formData: {
          ...formData,
          [field]: selected,
        },
      },
      this.handleFormData
    );
  };

  handleSelect = (isSelect, field, options) => {
    const { formData } = this.state;
    this.setState(
      {
        disableBtn: true,
        formData: {
          ...formData,
          [field]: isSelect ? options.map((el) => el.value) : [],
        },
      },
      this.handleFormData
    );
  };

  setDropdownValue = (value, options) => {
    if (value && options && options.length) {
      let optionValue = options.filter((el) => el.Id.value === value);
      let dropdownValue = "";
      if (optionValue.length > 0 && optionValue[0].Id.value) {
        dropdownValue = optionValue[0].Id.value;
      }
      return dropdownValue;
    } else return null;
  };

  render() {
    const {
      formData,
      formType,
      componentReady,
      sourceList,
      openingHoursList,
      carparkList,
      iconList,
      showImageGallery,
      categoryList,
      imageGalleryHolder,
      formErrorMessage,
      showErrorMessage,
      isStoreDataReady,
      showModal,
    } = this.state;
    return (
      <div className="form-storefront storefront-container">
        {showModal && this.renderBulkPreview()}
        {showErrorMessage && <FormErrorMessage error={formErrorMessage} />}
        {(formType === "" || formType === null) && (
          <div className="form-storefront__retailer">
            <div className="form__container">
              <FormInput
                className={"is-box"}
                title
                type="text"
                placeholder="Type retailer name."
                label="Retailer Name"
                value={formData.RetailerName}
                onInputChange={(RetailerName) => {
                  this.handleFormUpdate({ RetailerName });
                }}
              />
            </div>
            <div className="form__container">
              <label className="form__header">{"Retailer information"}</label>
              {componentReady && isStoreDataReady && (
                <RichTextEditor
                  label={"Description"}
                  initialValue={formData.RetailerDescription}
                  onInputChange={(RetailerDescription) => {
                    this.handleFormUpdate({ RetailerDescription });
                  }}
                />
              )}
            </div>
          </div>
        )}

        {(formType === "Retail" || formType === "Food") && (
          <div className="form-storefront__store">
            <div className="form__container">
              <FormInput
                className={"is-box"}
                title
                type="text"
                placeholder="Type retailer name."
                label="Retailer Name"
                value={formData.Name}
                onInputChange={(Name) => {
                  this.handleFormUpdate({ Name });
                }}
              />
            </div>
            <div className="form__container">
              <label className="form__header">{"Retailer information"}</label>
              {componentReady && (
                <RichTextEditor
                  label={"Description"}
                  checkIfBlank={true}
                  initialValue={formData.StorefrontDescription}
                  onInputChange={(StorefrontDescription) => {
                    this.handleFormUpdate({ StorefrontDescription });
                  }}
                />
              )}
            </div>
            <div className="form__container">
              <label className="form__header">{"Contact details"}</label>
              <FormInput
                type="text"
                placeholder="(88) 888-8888"
                label="Phone number"
                value={formData.ContactNumber}
                onInputChange={(ContactNumber) =>
                  this.handleFormUpdate({ ContactNumber })
                }
              />
              {formType === "Food" && (
                <FormInput
                  className="viewMenuMargin"
                  type="text"
                  placeholder="e.g. http://view-menu.com"
                  label="View Menu URL"
                  value={formData.MenuUrl}
                  onInputChange={(MenuUrl) =>
                    this.handleFormUpdate({ MenuUrl })
                  }
                />
              )}
            </div>
          </div>
        )}

        <div className="form-storefront__common">
          <div className="form__container">
            <label className="form__header">{"Social Media"}</label>
            <FormInput
              type="text"
              placeholder="Insert link (e.g. http://...)"
              label="Website"
              value={formData.Website}
              onInputChange={(Website) => {
                this.handleFormUpdate({ Website });
              }}
            />
            <FormInput
              type="text"
              placeholder="http://facebook.com"
              label="Facebook"
              value={formData.Facebook}
              onInputChange={(Facebook) => {
                this.handleFormUpdate({ Facebook });
              }}
            />
            <FormInput
              type="text"
              placeholder="http://instagram.com"
              label="Instagram"
              value={formData.Instagram}
              onInputChange={(Instagram) => {
                this.handleFormUpdate({ Instagram });
              }}
            />
          </div>
        </div>

        {(formType === "Retail" || formType === "Food") && (
          <div className="form-storefront__store">
            <div className="form__container">
              <label className="form__header">{"Available services"}</label>
              {formType === "Retail" && (
                <FormCheckbox
                  label={"Afterpay"}
                  checked={formData.AcceptsAfterPay}
                  onCheckboxChange={(value, AcceptsAfterPay) =>
                    this.handleFormUpdate({ AcceptsAfterPay })
                  }
                />
              )}
              {formType === "Food" && (
                <div>
                  <FormCheckbox
                    label={"Vegetarian"}
                    checked={formData.IsVegetarian}
                    onCheckboxChange={(value, IsVegetarian) =>
                      this.handleFormUpdate({ IsVegetarian })
                    }
                  />
                  <FormCheckbox
                    label={"Kids-Friendly"}
                    checked={formData.IsKidsFriendly}
                    onCheckboxChange={(value, IsKidsFriendly) =>
                      this.handleFormUpdate({ IsKidsFriendly })
                    }
                  />
                </div>
              )}
              <FormCheckbox
                label={"Gift Cards"}
                checked={formData.AcceptsGiftCards}
                onCheckboxChange={(value, AcceptsGiftCards) =>
                  this.handleFormUpdate({ AcceptsGiftCards })
                }
              />
            </div>
            <div className="form__container">
              <label className="form__header">{"Parking"}</label>
              <FormSelect
                label={"Best parking"}
                placeholder={"Select a car park"}
                defaultValue={this.setDropdownValue(
                  formData.BestParking,
                  carparkList
                )}
                options={carparkList}
                onChange={(BestParking) =>
                  this.handleFormUpdate({ BestParking })
                }
                noOptionsMessage={"No car park found"}
              />
            </div>
            <div className="form__container">
              <label className="form__header">{"Opening hours"}</label>
              <div className="opening-hours-status">
                <RadioButton
                  defaultSelected={formData.OpeningHoursStatus}
                  radioGroupName="Opening Hours List"
                  options={openingHoursList}
                  onChange={(OpeningHoursStatus) => {
                    this.handleFormUpdate({ OpeningHoursStatus });
                  }}
                />
              </div>
              <label className="form__label">{"Set opening hours"}</label>
              <RadioButton
                defaultSelected={formData.Source}
                radioGroupName="Source List"
                options={sourceList}
                onChange={(Source) => {
                  this.handleFormUpdate({ Source });
                }}
              />

              {formData &&
                formData.Source &&
                formData.Source === "Store" &&
                this.renderStoreHours(formData)}
            </div>
            <div className="form__container">
              <label className="form__header">{"Meta information"}</label>

              <div className="store-list-box is-desktop">
                <FormListbox
                  label="Category"
                  id="Storefront_Category_Desktop"
                  options={categoryList}
                  field="Categories"
                  selected={formData.Categories}
                  hint="Press [ctrl] + click to select multiple"
                  handleListbox={(id, field) => this.handleListbox(id, field)}
                  handleSelect={(isSelect, field, options) =>
                    this.handleSelect(isSelect, field, options)
                  }
                />
              </div>

              <div className="is-tablet">
                <FormListCheckbox
                  label="Category"
                  id="Storefront_Category_Mobile"
                  options={categoryList}
                  field="Categories"
                  selected={formData.Categories}
                  handleListbox={(id, field) => this.handleListbox(id, field, true)}
                  handleSelect={(isSelect, field, options) =>
                    this.handleSelect(isSelect, field, options)
                  }
                />
              </div>
            </div>
            <div className="form__container">
              <FormImageUpload
                type="hero"
                label={"Header image"}
                guidelines={
                  "To keep the quality of our websites high, the hero banner image needs to be:"
                }
                guidelinesOne={"• At least 1920px wide"}
                guidelinesTwo={"• At least 1080px tall."}
                guidelinesThree={
                  "• To avoid your image being cropped, use a ratio of 16:9"
                }
                value={formData.StorefrontHeroImage}
                handleFileUpload={(StorefrontHeroImage) => {
                  this.handleFormUpdate({ StorefrontHeroImage });
                }}
                removeImage
              />
            </div>
            <div className="form__container">
              <label className="form__header">{"Image gallery"}</label>
              <Checkbox
                checked={showImageGallery}
                label={"Display an additional image gallery?"}
                onCheckboxChange={(showImageGallery) => {
                  this.setState({
                    showImageGallery,
                  });
                  if (showImageGallery) {
                    this.handleFormUpdate({ ImageGallery: imageGalleryHolder });
                  } else {
                    this.handleFormUpdate({ ImageGallery: [] });
                  }
                }}
              />
              {showImageGallery && (
                <FormImageGallery
                  value={formData.ImageGallery}
                  handleAttachments={(ImageGallery) => {
                    this.setState({
                      imageGalleryHolder: ImageGallery,
                    });
                    this.handleFormUpdate({ ImageGallery });
                  }}
                />
              )}
            </div>
          </div>
        )}

        {(formType === "" || formType === null) && (
          <div className="form-storefront__retailer">
            <div className="form__container">
              {isStoreDataReady && (
                <FormImageUpload
                  label={"Thumbnail image"}
                  guidelines={"The hero thumbnail image needs to be:"}
                  guidelinesOne={"• At least 800px wide"}
                  guidelinesTwo={"• At least 800px tall."}
                  guidelinesThree={
                    "• To avoid your image being cropped, use a ratio of 1:1"
                  }
                  value={formData.StorefrontHeroImage}
                  value={formData.LogoImage}
                  handleFileUpload={(LogoImage) => {
                    this.handleFormUpdate({ LogoImage });
                  }}
                />
              )}
            </div>
            <div className="form__container">
              {isStoreDataReady && (
                <FormImageUpload
                  type="hero"
                  label={"Header image"}
                  guidelines={
                    "To keep the quality of our websites high, the hero banner image needs to be:"
                  }
                  guidelinesOne={"• At least 1920px wide"}
                  guidelinesTwo={"• At least 1080px tall."}
                  guidelinesThree={
                    "• To avoid your image being cropped, use a ratio of 16:9"
                  }
                  value={formData.HeroImage}
                  handleFileUpload={(HeroImage) => {
                    this.handleFormUpdate({ HeroImage });
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default FormStoreFront;
