import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import { logout } from "../../../../api/LoginAPI";

const FeatureRetailerPortalFooter = props => {
  const { FooterTitle, FooterLinks } = props.fields;
  const logoutUser = async () => {
    const response = await logout();
    if (response) {
      if (
        response.status === 200 &&
        response.data &&
        response.data.StatusCode === 200
      ) {
        window.history.pushState(null, "Town Square", window.location.pathname);
        window.location.pathname = "home/login";
      }
    }
  };
  return (
    <div className="footer-nav-wrapper">
      <div onClick={() => logoutUser()} className="nav-button-logout">
        <i className="icon icon-logout" />
        <span>Logout</span>
      </div>

      <div className="nav-footer-container">
        <div className="nav-footer-title">
          <i className="icon icon-qicgre" />
          <span>
            <Text field={FooterTitle} />
          </span>
        </div>
        <div className="nav-footer-links-wrapper">
          {FooterLinks &&
            FooterLinks.length &&
            FooterLinks.map((link, index) => {
              let { LinkTarget: LinkTarget = null } = link.fields;
              return (
                <span key={`link-${index}`}>
                  <u>
                    <Link field={LinkTarget} />
                  </u>
                  {/* <i className="icon icon-external" /> */}
                </span>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FeatureRetailerPortalFooter;
