import React from "react";

const Label = props => {
  let color;
  switch (props.color.toLowerCase()) {
    case "new":
      color = "new";
      break;
    case "draft":
      color = "draft";
      break;
    case "pending approval":
      color = "pending";
      break;
    case "awaiting approval":
      color = "pending";
      break;
    case "rejected":
      color = "rejected";
      break;
    case "approved":
      color = "approved";
      break;
    case "live":
      color = "approved";
      break;
    case "embargoed":
      color = "rejected";
      break;
    default:
      color = "default";
  }

  return (
    <div className={`label-wrapper ${color}`}>
      {props.children}
    </div>
  );
};

export default Label;
