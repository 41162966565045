import React, { Component } from "react";

class Button extends Component {
  render() {
    /**
     * Color - primary, secondary, tertiary, tertiary-grey, tertiary-custom, red
     * Size - full, small, small-full
     **/
    const {
      color = null,
      size = null,
      onClick = null,
      icon = null,
      disabled = false,
      tooltip = null,
      className = null,
    } = this.props || {};

    return (
      <button
        className={`btn 
        ${color ? "btn--" + color : "btn--primary"}
        ${size ? "btn--" + size : ""}
        ${icon ? "btn--icon" : ""}
        ${className ? className : ""}
        `}
        onClick={onClick}
        disabled={disabled}
      >
        {tooltip &&
          <span className="btn__tooltip">{tooltip}</span>
        }
        {icon &&
          <i className={`icon icon-${icon}`} />
        }
        <span className="btn__label">
          {this.props.children}
        </span>
      </button>
    );
  }
}

export default Button;
