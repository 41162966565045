import axios from "axios";

const isDev = process.env.REACT_APP_DEV || false;
export default axios.create({
  baseURL: isDev ? "http://localhost:3001" : "/api/sitecore"
});

export const DevURL = axios.create({
  baseURL: "http://localhost:3001",
});

export const DevURL2 = axios.create({
  baseURL: "http://localhost:3002",
});

export const DevURL3 = axios.create({
  baseURL: "http://localhost:3003",
});
