import React, { Component } from "react";

class BackToTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBackToTop: false,
        };
    }
    componentDidMount() {
        if (document) {
            const backToTopWrapper = document.querySelector(".body-wrapper")
            if (backToTopWrapper) {
                backToTopWrapper.addEventListener('scroll', this.handleOnScroll);
            }
        }
    }

    handleOnScroll = (e) => {
        const element = e.target;
        const bodyWrapperContainer = element.querySelector(".container");

        if (bodyWrapperContainer) {
            let elemPos = bodyWrapperContainer.getBoundingClientRect().top;
            let bodyWrapperMargin,
                bodyContainerMargin;
            let backToTopPos = 0;

            if (window && window.outerWidth >= 992) {
                if (elemPos < 94) {
                    this.setState({
                        showBackToTop: true,
                    });
                    const backToTopWrapper = document.querySelector(".back-to-top__wrapper");
                    const bodyContainer = document.querySelector(".body-container.is-filter-shown");

                    if (bodyContainer) {
                        bodyWrapperMargin = window.getComputedStyle(element).getPropertyValue("padding-left").replace("px", "");
                        bodyContainerMargin = window.getComputedStyle(bodyContainer).getPropertyValue("margin-left").replace("px", "");
                        backToTopPos = (parseInt(bodyWrapperMargin) + parseInt(bodyContainerMargin)) + "px";
                    }
                    else {
                        bodyWrapperMargin = window.getComputedStyle(element).getPropertyValue("padding-left");
                        backToTopPos = bodyWrapperMargin;
                    }
                    backToTopWrapper.style.right = backToTopPos;
                } else {
                    this.setState({
                        showBackToTop: false,
                    });
                }
            } else {
                if (elemPos < 0) {
                    this.setState({
                        showBackToTop: true,
                    });
                }
                else {
                    this.setState({
                        showBackToTop: false,
                    });
                }
            }

        }

        // if (document && document.documentElement && document.documentElement.scrollTop > 20) {
        //     this.setState({ showBackToTop: true });
        //     const backToTopWrapper = document.querySelector(".back-to-top__wrapper")
        //     const bodyWrapper = document.querySelector(".body-wrapper");
        //     const bodyContainer = document.querySelector(".body-container.is-filter-shown");
        //     let bodyWrapperMargin,
        //         bodyContainerMargin;
        //     let backToTopPos = 0;

        //     if (bodyWrapper && bodyContainer) {
        //         bodyWrapperMargin = window.getComputedStyle(bodyWrapper).getPropertyValue("margin-left").replace("px", "");
        //         bodyContainerMargin = window.getComputedStyle(bodyContainer).getPropertyValue("margin-left").replace("px", "");
        //         backToTopPos = (parseInt(bodyWrapperMargin) + parseInt(bodyContainerMargin)) + "px";
        //     }
        //     else if (bodyWrapper) {
        //         bodyWrapperMargin = window.getComputedStyle(bodyWrapper).getPropertyValue("margin-left");
        //         backToTopPos = bodyWrapperMargin;
        //     }
        //     backToTopWrapper.style.right = backToTopPos;
        // }
        // else {
        //     this.setState({ showBackToTop: false });
        // }
    }

    handleOnClick = () => {
        // Check if using IE browser
        const ua = window.navigator.userAgent.toLowerCase();
        if (document) {
            const bodyWrapper = document.querySelector(".body-wrapper");
            if ((/msie|trident/.test(ua))) {
                bodyWrapper.scroll(0, 0);
            } else {
                bodyWrapper.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
        // if ((/msie|trident/.test(ua))) {
        //     window.scroll(0, 0);
        // } else {
        //     window.scroll({
        //         top: 0,
        //         left: 0,
        //         behavior: 'smooth'
        //     });
        // }
    }
    render() {
        const {
            showBackToTop
        } = this.state;
        return (
            <div>
                {showBackToTop &&
                    <div className="back-to-top__wrapper js-back-to-top">
                        <div className="container">
                            <button onClick={this.handleOnClick} className="back-to-top__button">
                                <span>Back to top</span>
                                <i className="icon icon-chevron-up"></i>
                            </button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default BackToTop;
