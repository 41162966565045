import React, { Component } from "react";

class FormListbox extends Component {
  render() {
    const {
      label = null,
      id = null,
      options = null,
      hint = null,
      handleListbox = null,
      handleSelect = null,
      field = null,
      selected = null,
      required = null,
      error = null,
      disabled = null,
    } = this.props;
    return (
      <div className={`form-group form__listbox
      ${error ? "has-error" : ""}
      `}>
        <div className="form__listbox--header">
          {(label || required) &&
            <label className="form__label">
              {label}
              {required &&
                <span className="form__asterisk">*</span>
              }
            </label>
          }
          <div className={`form__listbox--action
          ${disabled ? "disabled" : ""}
          `}>
            <div className="form__listbox--action-btn"
              onClick={() => {
                if (disabled) {
                  return
                }
                handleSelect(true, field, options)
              }}
            >
              {"Select all"}
            </div>
            <div className="form__listbox--action-btn"
              onClick={() => {
                if (disabled) {
                  return
                }
                handleSelect(false, field, options)
              }}
            >
              {"Deselect all"}
            </div>
          </div>
        </div>
        <div className="form__listbox--field">
          <select
            multiple
            id={id}
            onChange={() => handleListbox(id, field)}
            disabled={disabled}
            value={selected}
          >
            {options.map((item, index) => {
              const {
                value = null,
                name = null
              } = item;

              let optionLayout = "";

              if (value && name) {
                optionLayout =
                  <option key={`list-${value}-${index}`}
                    value={value}
                  >
                    {name}
                  </option>
              }
              return optionLayout;
            })}
          </select>
        </div>

        <div className="form__validation-message">
          {error}
        </div>

        {hint && (
          <div className="form__hint">
            <i className="icon icon-info" />
            <span>{hint}</span>
          </div>
        )}
      </div>
    );
  }
}

export default FormListbox;
