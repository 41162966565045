import React, { Component } from "react";
import { values } from "lodash";
import bitmap from "../../../assets/icons/png/bitmap@3x.png";
class FormFile extends Component {
  constructor(props) {
    super(props);
    this.inputFile = React.createRef(null);
  }

  state = {
    files: this.props.value,
    errorMessage: null,
  };

  handleAttachments = () => {
    this.props.handleAttachments(this.state.files, this.state.errorMessage);
  };

  setList = files => {
    if (files && files.length) {
      let lists = [];
      let isError = false;
      for (let i = 0; i < files.length; i++) {
        if (
          files[i] &&
          (files[i].type === "image/png" || files[i].type === "image/jpeg")
        ) {
          lists.push(files[i]);
        } else {
          isError = true;
        }
      }

      this.setState(
        {
          files: [...this.state.files, ...values(lists)],
          errorMessage: isError
            ? "One or more files could not be uploaded. Only files with jpeg and png extension are allowed."
            : null,
        },
        this.handleAttachments
      );
    }
  };

  updateFile(imageFile, filename) {
    const { files } = this.state;
    let listItem = [];
    files.map((item, index) => {
      if (item.name == filename) {
        listItem.push(imageFile)
      } else {
        listItem.push(item);
      }
    })
    let newList = listItem;
    this.setState(
      { files: newList, errorMessage: null },
      this.handleAttachments
    );
  }

  removeFile(filename) {
    const { files } = this.state;
    if (document) {
      document.getElementsByClassName("file-btn")[0].querySelector("input").value = null;
      const uploadInput = Array.prototype.slice.apply(
        document.getElementsByClassName("file-list")[0].querySelectorAll("input")
      );
      uploadInput.map((item, index) => {
        item.value = null;
      })
      let newList = files.filter(file => file.name !== filename);
      this.setState(
        {
          files: newList,
          errorMessage: null,
        },
        this.handleAttachments
      );
    }
  }

  renderList = () => {
    const { files } = this.state;

    if (files.length > 0) {
      let list = [];
      for (let i = 0; i < files.length; i++) {
        const element = files[i];
        list.push(element);
      }

      return (
        <div className="file-list">
          {list.map((item, index) => {
            let imgSrc;
            if (item.src) {
              imgSrc = item.src
            } else {
              imgSrc = URL.createObjectURL(item)
            }
            return (
              <React.Fragment key={`fileId-${index}`}>
                <div className="file-item">
                  <div className="file-item__action">
                    <i
                      className="icon icon-trash"
                      onClick={() => this.removeFile(item.name)}
                    />
                  </div>
                  <div className="file-item__image">
                    <img src={imgSrc} alt="upload source" />
                    <label className="image-gallery-btn-change" htmlFor={item.name + index}>
                      <span>
                        {"Change"}
                      </span>
                      <input
                        id={item.name + index}
                        type="file"
                        accept="image/png,image/jpeg"
                        onChange={e => this.updateFile(e.target.files[0], item.name)}
                      />
                    </label>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  render() {
    const { files } = this.state;

    return (
      <div className={`form-file-wrapper ${files.length > 0 ? "": "no-image"}`}>
        <div className="form-file">
          {this.renderList()}
          <div className="file-btn">
            <label className="image-gallery-btn">
              {"Add image"}
              <input
                ref={this.inputFile}
                type="file"
                multiple
                accept="image/png,image/jpeg"
                onChange={e => this.setList(e.target.files)}
              />
            </label>
          </div>
        </div>
        <div className="imageupload-text">
          <div className="text-container">
            <div>
              {"To keep the quality of our websites high, the hero banner image needs to be:"}
            </div>
            <br />
            <div>
              {"• At least 1920px wide"}
            </div>
            <div>
              {"• At least 1080px tall."}
            </div>
            <div>
              {"• To avoid your image being cropped, use a ratio of 16:9"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormFile;
