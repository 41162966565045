import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormDate = props => {
  const {
    icon,
    error,
    label,
    required,
    ...properties
  } = props;

  return (
    <div className="form__date--details">
      {(label || required) &&
        <label className="form__label">
          {label}
          {required &&
            <span className="form__asterisk">*</span>
          }
        </label>
      }
      <div className={`form__date-picker--wrapper ${error ? "error" : "no-error"}`}>
        <DatePicker className="form__date-picker--field" {...properties} />
        {icon &&
          <i className={`icon icon-${icon}`} />
        }
      </div>
      <div className="form__validation-message">
        {error}
      </div>
    </div>
  );
};

export default FormDate;
