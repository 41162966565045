import React from 'react';
import Select from "react-select";
import RadioButton from "../../../Base/RadioButton";
import FormModal from "../../../Base/FormModal";
import Popup from "../../../Base/Popup";
import PopupAction from "../../../Base/Popup/PopupAction";
import SuccessMessage from "../../../Base/SuccessMessage";
import Articles from "../../../Modules/Articles";
import {
  deleteArticle,
} from "../../../../api/ArticlesAPI";
import CustomFilter from '../../../Base/CustomFilter';
import CustomIframe from '../../../Base/CustomIframe';
import FormArticles from "../../../Modules/Forms/FormArticles";
import { addClassLazyload } from "../../../../helper/AddClassLazyloadHelper";
import { scrollToTop } from "../../../../helper/ScrollHelper";
import { removeActiveState } from "../../../../helper/RemoveActiveState";
import { hideBackToTop } from "../../../../helper/HideBackToTopHelper";

class FeatureRetailerPortalArticleListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDocumentReady: false,
      isWindowReady: false,
      content: null,
      articles: null,
      showPreviewModal: false,
      showFormModal: false,
      showDeleteModal: false,
      previewModalData: null,
      formModalData: null,
      deleteModalData: null,
      showFilter: false,
      statusOption: [
        {
          Id: {
            value: "allStatus"
          },
          Name: {
            value: "All"
          }
        },
        {
          Id: {
            value: "DraftPromotions"
          },
          Name: {
            value: "Draft"
          }
        },
        {
          Id: {
            value: "LivePromotions"
          },
          Name: {
            value: "Live"
          }
        },
        {
          Id: {
            value: "AwaitingApprovalPromotions"
          },
          Name: {
            value: "Awaiting approval"
          }
        },
        {
          Id: {
            value: "ScheduledPromotions"
          },
          Name: {
            value: "Scheduled"
          }
        },
        {
          Id: {
            value: "RejectedPromotions"
          },
          Name: {
            value: "Rejected"
          }
        }
      ],
      filterCentre: null,
      filterStatus: "allStatus",
      disableCancelBtn: false,
      disableDeleteBtn: false,
      articleList: [],
      articlePreviewUrlHolder: "",
      articlePreviewTitleHolder: "",
      isDashboardUpdate: false,
      isMobile: false,
      dataCount: 0,
      centreListOption: [],
      filterByCentre: "allCentre"
    };
  }

  componentDidMount() {
    if (document) {
      this.setState({
        isDocumentReady: true,
        content: "articles"
      },
        () => {
          this.handleResize()
          this.updateCentreOption()
        }
      );

    }

    if (window) {
      window.addEventListener("resize", this.handleResize);
    }
  }

  handleResize = () => {
    if (window.outerWidth >= 992) {
      this.setState({
        isMobile: false
      })
    } else {
      this.setState({
        isMobile: true
      })
    }
  }

  updateCentreOption = () => {
    const {
      CentreList,
    } = this.props.fields || {};

    let centreListOption = [
      {
        value: "allCentre",
        label: "All centres"
      }
    ]
    if (CentreList && CentreList.length > 0) {
      CentreList.map((item, index) => {
        const {
          Name
        } = item.fields
        centreListOption.push({ value: Name.value, label: Name.value });
      })
    }

    this.setState({ centreListOption });
  }

  renderPreviewModal = () => {
    const {
      title,
      url
    } = this.state.previewModalData || {};

    const {
      previewUrl,
      previewTitle,
    } = this.state.formModalData || {}

    const {
      showFormModal,
      showPreviewModal
    } = this.state;

    hideBackToTop(showPreviewModal || showFormModal);

    return (
      <FormModal
        isPreview
        showModal={showPreviewModal}
        label={previewTitle ? previewTitle : title ? title : ""}
        closeOnClick={() => this.setState({ showPreviewModal: false })}
      >
        {showPreviewModal &&
          <CustomIframe
            title={previewTitle ? previewTitle : title ? title : ""}
            src={previewUrl ? previewUrl : url ? url : ""}
          />
        }
      </FormModal>
    );
  }

  renderFormModal = () => {
    const {
      title,
      content,
      itemId,
      previewUrl,
      showSaveHint,
      disableBtn,
      onClickSave,
      onClickPreview,
      onClickSubmit,
      isNewArticle,
    } = this.state.formModalData || {}

    const {
      showFormModal,
      isDashboardUpdate,
      isMobile,
      showPreviewModal
    } = this.state;

    const {
      updateDashboard
    } = this.props

    if (!showFormModal && isDashboardUpdate && updateDashboard) {
      this.setState({
        isDashboardUpdate: false
      },
        () => updateDashboard(true))
    }

    hideBackToTop(showPreviewModal || showFormModal);

    return (
      <FormModal
        showModal={showFormModal}
        label={title}
        closeOnClick={() => this.setState({ showFormModal: false })}
        closeClassName={"article-close-btn"}
        showSaveHint={showSaveHint}
        saveBtn={"Save draft"}
        saveBtnOnClick={() => this.updateArticle(onClickSave)}
        secondaryBtn={`Preview${isNewArticle && !isMobile ? "…" : ""}`}
        secondaryBtnOnClick={() => {
          if (previewUrl) {
            this.setState({
              showPreviewModal: true,
            })
          } else {
            onClickPreview();
          }
        }
        }
        disabledSecondaryBtn={disableBtn}
        primaryBtn={"Submit"}
        primaryBtnClassName={"article-submit-btn"}
        primaryBtnOnClick={() => this.updateArticle(onClickSubmit, true)}
        disabledPrimaryBtn={disableBtn}
      >
        {showFormModal &&
          <FormArticles
            itemId={itemId}
            handleFormData={(showSaveHint, disableBtn, previewUrl, previewTitle, onClickSave, onClickPreview, onClickSubmit) => {
              this.handleFormUpdate(
                showSaveHint,
                disableBtn,
                previewUrl,
                previewTitle,
                onClickSave,
                onClickPreview,
                onClickSubmit,
              );
            }}
          />
        }
      </FormModal>
    );
  }

  handleFormUpdate = (showSaveHint, disableBtn, previewUrl, previewTitle, onClickSave, onClickPreview, onClickSubmit) => {
    this.setState({
      formModalData: {
        ...this.state.formModalData,
        showSaveHint,
        disableBtn,
        onClickSave,
        onClickPreview,
        onClickSubmit,
        previewUrl,
        previewTitle
      }
    })
  }

  updateArticle = (onClickUpdate, isSubmit) => {
    if (isSubmit) {
      onClickUpdate().then((result) => {
        if (result && result.isSuccess) {
          let articlePreviewUrl = "",
            articlePreviewTitle = "";

          if (result.articlePreviewUrl) {
            articlePreviewUrl = result.articlePreviewUrl
          }
          if (result.articlePreviewTitle) {
            articlePreviewTitle = result.articlePreviewTitle
          }
          let filterAllStatus = document.getElementById("allStatus");
          let filterAllCentreRadio = document.getElementById("allCentre");
          let filterAllCentreDropdown = document.querySelector(".is-filter-centre .form__select--field");
          if (filterAllStatus) {
            filterAllStatus.click()
          }
          if (filterAllCentreRadio) {
            filterAllCentreRadio.click()
          } else if (filterAllCentreDropdown) {
            filterAllCentreDropdown.selectedIndex = 0;
          }

          this.setState({
            formModalData: {
              ...this.state.formModalData,
              successMessage: "Article successfully updated.",
              previewUrl: articlePreviewUrl,
              previewTitle: articlePreviewTitle,
            },
            showFormModal: false,
            showSuccessMessage: true,
            filterCentre: [],
            articlePreviewUrlHolder: articlePreviewUrl,
            articlePreviewTitleHolder: articlePreviewTitle,
            isDashboardUpdate: true
          },
            () => {
              scrollToTop()
              addClassLazyload("article-listing__content")
            }
          )
        }
      });
    } else {
      onClickUpdate().then((result) => {
        if (result && result.isSuccess) {
          let filterAllStatus = document.getElementById("allStatus");
          let filterAllCentreRadio = document.getElementById("allCentre");
          let filterAllCentreDropdown = document.querySelector(".is-filter-centre .form__select--field");
          if (filterAllStatus) {
            filterAllStatus.click()
          }
          if (filterAllCentreRadio) {
            filterAllCentreRadio.click()
          } else if (filterAllCentreDropdown) {
            filterAllCentreDropdown.selectedIndex = 0;
          }
          this.setState({
            filterCentre: [],
            isDashboardUpdate: true
          },
            () => addClassLazyload("article-listing__content")
          )
        }
      });
    }
  }

  renderDelete = () => {
    const {
      deleteModalData,
      disableCancelBtn,
      disableDeleteBtn,
    } = this.state;

    return (
      <Popup>
        <PopupAction
          label={{ value: `Delete article?` }}
          description={{ value: `Are you sure you’d like to delete this article?<br/>This cannot be undone.` }}
          icon="trash-2"
          iconBgColor="#d83b42"
          secondaryBtn="Cancel"
          disabledSecondaryBtn={disableCancelBtn}
          secondaryBtnOnClick={() => this.setState({ showDeleteModal: false })}
          primaryBtn={`Delete article`}
          primaryBtnOnClick={() => this.deleteArticle(deleteModalData)}
          primaryBtnColor="red"
          disabledPrimaryBtn={disableDeleteBtn}
        />
      </Popup>
    );
  };

  deleteArticle = async deleteModalData => {
    const {
      isDashboard = false,
      updateDashboard
    } = this.props || {}
    const {
      id,
      status
    } = deleteModalData;
    const {
      content,
      dataCount
    } = this.state;
    this.setState({
      disableCancelBtn: true,
      disableDeleteBtn: true
    })
    let successMessage = "";
    try {
      let response = await deleteArticle({ itemId: id });
      if (response.status === 200 && response.data) {
        if (response.data.IsSuccess.value) {
          successMessage = "Article successfully deleted.";

          if (!isDashboard) {
            let statusVar = "";
            if (status.toLowerCase() === "live") {
              statusVar = "LivePromotions";
            }
            else if (status.toLowerCase() === "scheduled") {
              statusVar = "ScheduledPromotions";
            }
            else if (status.toLowerCase() === "awaiting approval") {
              statusVar = "AwaitingApprovalPromotions";
            }
            else if (status.toLowerCase() === "draft") {
              statusVar = "DraftPromotions";
            }
            else if (status.toLowerCase() === "rejected") {
              statusVar = "RejectedPromotions";
            }

            let newData =
              this.state[content][statusVar].filter(item => {
                return item.ItemId.value !== id;
              });

            this.setState(
              {
                [this.state.content]: {
                  ...this.state[content],
                  [statusVar]: newData
                },
                showDeleteModal: false,
                showSuccessMessage: true,
                disableCancelBtn: false,
                disableDeleteBtn: false,
                formModalData: {
                  ...this.state.formModalData,
                  successMessage
                },
                dataCount: dataCount - 1
              },
              () => {
                scrollToTop()
                addClassLazyload("article-listing__content");
              }
            );
          } else {
            this.setState(
              {
                showDeleteModal: false,
                showSuccessMessage: true,
                disableCancelBtn: false,
                disableDeleteBtn: false,
                formModalData: {
                  ...this.state.formModalData,
                  successMessage
                }
              },
              () => {
                scrollToTop()
                if (updateDashboard) {
                  updateDashboard(true)
                }
                addClassLazyload("article-listing__content");
              }
            );
          }
        } else {
          successMessage = "Article delete failed."

          this.setState({
            showDeleteModal: false,
            showSuccessMessage: true,
            disableCancelBtn: false,
            disableDeleteBtn: false,
            formModalData: {
              ...this.state.formModalData,
              successMessage
            }
          },
            () => scrollToTop()
          );
        }
      }
      else {
        successMessage = "Article delete failed."

        this.setState({
          showDeleteModal: false,
          showSuccessMessage: true,
          formModalData: {
            ...this.state.formModalData,
            successMessage
          }
        },
          () => scrollToTop()
        );
      }
      removeActiveState();
    }
    catch (e) {
      console.error("Article Delete Error: " + e.response.statusText);
      successMessage = "Article delete error.";
      this.setState({
        showDeleteModal: false,
        showSuccessMessage: true,
        disableCancelBtn: false,
        disableDeleteBtn: false,
        formModalData: {
          ...this.state.formModalData,
          successMessage
        }
      },
        () => scrollToTop()
      )
      removeActiveState();
      // this.setState(
      //   {
      //     showDeleteModal: false,
      //     showSuccessMessage: true,
      //     disableCancelBtn: false,
      //     disableDeleteBtn: false,
      //     formModalData: {
      //       ...this.state.formModalData,
      //       successMessage
      //     }
      //   },
      //   () => {
      //     if (updateDashboard) {
      //       updateDashboard(true)
      //     }
      //   }
      // );
    }
  };

  handleOnClickFilter = (type, value) => {
    if (type === "status") {
      this.setState({
        filterStatus: value
      })
    } else if (type === "centre") {
      this.setState({
        filterCentre: value === "allCentre" ? [] : value,
      })
    }
    addClassLazyload("article-listing__content");
    scrollToTop();
  }

  setCentreListDropdown = (CentreList) => {
    let tempCentreList = [];
    let allOption = {
      Id: { value: "allCentre" },
      Name: { value: "All centres" }
    }
    tempCentreList.push(allOption)

    CentreList.map((item) => {
      const {
        Name
      } = item.fields;

      if (Name && Name.value) {
        let centreOption = {
          Id: { value: Name.value },
          Name: { value: Name.value }
        }
        tempCentreList.push(centreOption)
      }
    })
    return tempCentreList;
  }

  renderCustomFilter = (CentreList) => {
    const {
      showFilter,
      statusOption,
      centreListOption,
      filterByCentre,
      isMobile
    } = this.state;

    return (
      <CustomFilter
        showFilter={showFilter}
        onCloseFilter={() => this.setState({ showFilter: false })}
      >
        <div className="custom-filter__group is-filter-status">
          <div className="custom-filter__label">
            {"Filter by status"}
          </div>
          <RadioButton
            defaultSelected="allStatus"
            options={statusOption}
            radioGroupName="articleListing"
            onChange={(id) => this.handleOnClickFilter("status", id)}
            plain
          />
        </div>
        <div className="custom-filter__group is-filter-centre">
          <div className="custom-filter__label">
            {"Filter by centre"}
          </div>
          {CentreList &&
            CentreList.length > 0 && (
              (CentreList.length > 5 || isMobile) ?
                <Select
                  onChange={(option) => this.handleOnClickFilter("centre", option.value)}
                  defaultValue={{ value: "allCentre", label: "All centres" }}
                  options={centreListOption}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#536760",
                      primary25: "#f5f5f6",
                    },
                  })}
                  isSearchable={false}
                  maxMenuHeight={200}
                  menuPlacement="auto"
                /> :
                <RadioButton
                  defaultSelected="allCentre"
                  options={this.setCentreListDropdown(CentreList)}
                  radioGroupName="articleListingCentreList"
                  onChange={(id) => this.handleOnClickFilter("centre", id)}
                  plain
                />
            )
          }
        </div>
      </CustomFilter>
    )
  }

  renderContent = (HideCreateButton) => {
    const {
      isDashboard = false,
      articleList = [],
      noStore = false
    } = this.props || {}

    const {
      articles,
      filterStatus,
      filterCentre,
      dataCount
    } = this.state;

    let noSelectedStore = false;
    if (noStore.toString() === "true" || (HideCreateButton && HideCreateButton.value.toString() === "true")) {
      noSelectedStore = true;
    }

    return (
      <Articles
        data={(articleList && articleList.length > 0) ? { dashboardArticles: articleList } : articles}
        filterStatus={filterStatus}
        filterCentre={filterCentre}
        showPreviewModal={previewModalData =>
          this.setState({
            showPreviewModal: true,
            previewModalData,
            formModalData: {
              ...this.state.formModalData,
              previewUrl: "",
              previewTitle: "",
            },
          })
        }
        showFormModal={formModalData =>
          this.setState({ showFormModal: true, formModalData })
        }
        showDeleteModal={deleteModalData =>
          this.setState({
            showDeleteModal: true,
            deleteModalData,
            formModalData: {
              ...this.state.formModalData,
              previewUrl: "",
              previewTitle: "",
            },
          })
        }
        showFilter={() => {
          this.setState({ showFilter: true })
        }}
        dataUpdate={articles => this.setState({ articles })}
        noSelectedStore={noSelectedStore}
        isDashboard={isDashboard}
        dataTotalCount={dataCount => this.setState({ dataCount })}
        hideFilter={dataCount == 0}
        totalListCount={dataCount}
      />
    );
  };

  render() {
    const {
      content,
      showDeleteModal,
      showSuccessMessage,
      formModalData,
      isDocumentReady,
      articlePreviewUrlHolder,
      articlePreviewTitleHolder
    } = this.state;
    const {
      CentreList,
      HideCreateButton,
    } = this.props.fields || {};


    const {
      noStore = false
    } = this.props || {}

    return (
      <div className={`article-listing__container ${showSuccessMessage ? "has-success-message" : ""}`}>
        {showDeleteModal && this.renderDelete()}
        {isDocumentReady &&
          this.renderPreviewModal()
        }
        {isDocumentReady &&
          this.renderFormModal()
        }

        {isDocumentReady &&
          CentreList &&
          CentreList.length > 0 &&
          this.renderCustomFilter(CentreList)
        }

        {showSuccessMessage &&
          <SuccessMessage
            showPreviewLink={formModalData && formModalData.previewUrl}
            label={formModalData.successMessage}
            handleOnClick={() => {
              if (formModalData.previewUrl) {
                this.setState({
                  showPreviewModal: true,
                  formModalData: {
                    ...this.state.formModalData,
                    previewUrl: articlePreviewUrlHolder,
                    previewTitle: articlePreviewTitleHolder,
                  },
                })
              } else {
                console.log("Preview Group")
              }
            }}
            handleShowSuccess={(showSuccessMessage) => {
              this.setState({
                showSuccessMessage
              })
            }}
          />
        }

        <div className={`article-listing__content ${(noStore.toString() === "true" || (HideCreateButton && HideCreateButton.value.toString() === "true")) ? "hide-create-btn" : ""}`}>
          {content && this.renderContent(HideCreateButton)}
        </div>
      </div>
    );
  }
}
export default FeatureRetailerPortalArticleListing;