import React, { Component } from "react";
class SuccessMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false,
      timer: 4000,
      timerOnHide: null,
    };
  }

  componentDidMount() {
    this.handleShowSuccess(4000);
  }

  handleMouseOver = () => {
    this.handleShowSuccess(0, true);
  };

  handleMouseOut = () => {
    this.handleShowSuccess(2000);
  };

  handleShowSuccess = (timer, onHover) => {
    if (!onHover) {
      this.setState({
        timerOnHide: setTimeout(() => {
          this.props.handleShowSuccess(false);
        }, timer),
      });
    } else {
      this.setState({
        timerOnHide: clearTimeout(this.state.timerOnHide),
      });
    }
  };

  render() {
    const {
      label = null,
      linkLabel = null,
      handleOnClick = null,
      showPreviewLink = null,
      hideIcon = null,
    } = this.props || {};
    return (
      <React.Fragment>
        <div
          className="success-message"
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          {!hideIcon && <i className="icon icon-check-small" />}
          <span className={`success-message__description ${showPreviewLink ? "has-view" : ""}`}>
            {label ? label : "Success update"}
          </span>
          {showPreviewLink && (
            <a className="success-message__link" onClick={handleOnClick}>
              {linkLabel ? linkLabel : "View"}
            </a>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default SuccessMessage;
