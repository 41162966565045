import React from "react";
import axios from "axios";
import TagManager from "react-gtm-module";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import BackgroundImage from "../../../../assets/img/rp-bg.png";
import Input from "../../../Base/Input";
import Button from "../../../Base/Button";
import { login } from "../../../../api/LoginAPI";
import ForgotPassword from "../../../Base/ForgotPassword";
import logoPlaceholder from "../../../../assets/img/logoPlaceholder.png";

class FeatureRetailerPortalLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      showModal: false,
      browser: "",
      passwordSent: false,
      forgotPassword: {
        value: "",
        successMessage: "",
        errorMessage: "",
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    this.checkBrowser();
    if (sessionStorage.getItem("RPCentreList")) {
      sessionStorage.removeItem("RPCentreList");
    }

    if (window) {
      window.addEventListener("resize", this.handleResize);
    }
    if (document) {
      this.handleResize();
    }
  }

  handleResize = () => {
    let loginWrapper = "";

    if (document) {
      loginWrapper = document.querySelector(".login-container");
    }

    if (window.outerWidth >= 992) {
      loginWrapper.style.height = "auto";
    } else {
      loginWrapper.style.height = window.innerHeight + "px";
    }
  };

  clearSessionStorageSetPassword = () => {
    if (sessionStorage.getItem("promptPassword")) {
      sessionStorage.removeItem("promptPassword");
    }
    if (sessionStorage.getItem("setPassword")) {
      sessionStorage.removeItem("setPassword");
    }
  };

  checkBrowser = () => {
    if (
      navigator &&
      navigator.userAgent &&
      navigator.userAgent.toUpperCase().indexOf("CHROME") != -1 &&
      navigator.vendor &&
      navigator.vendor.toUpperCase().indexOf("GOOGLE") != -1
    ) {
      this.setState({ browser: "chrome" });
    } else this.setState({ browser: "other" });
  };

  submitForm = async () => {
    const data = { ...this.state };
    // const { username } = this.state;
    const response = await login(data);
    if (response) {
      if (
        response.status === 200 &&
        response.data &&
        response.data.StatusCode === 200
      ) {
        this.clearSessionStorageSetPassword();
        window.location.pathname = "";
        TagManager.dataLayer({
          dataLayer: {
            event: "successfulLogin",
          },
          dataLayerName: "dataLayer",
        });
      } else {
        alert("Login Failed");
      }
    }
  };

  forgotPassword = (val) => {
    this.setState({
      showModal: val,
      forgotPassword: {
        value: "",
        successMessage: "",
        errorMessage: "",
      },
    });
  };

  sendForgotPassword = async () => {
    const { forgotPassword } = this.state;
    if (this.form.current.reportValidity() && forgotPassword.value) {
      const formData = new FormData();
      formData.append("email", forgotPassword.value);

      const url = "/api/sitecore/account/ForgotPassword";

      try {
        let response = await axios({
          method: "POST",
          url,
          data: formData,
        });

        if (response) {
          this.setState({
            forgotPassword: {
              ...forgotPassword,
              successMessage: "Please check your email",
              errorMessage: "",
            },
          });
          this.setState({ passwordSent: true });
        } else {
          this.setState({
            forgotPassword: {
              ...forgotPassword,
              successMessage: "",
              errorMessage: "The email is invalid or does not exist",
            },
          });
        }
      } catch (e) {
        console.log(e);
        this.setState({
          forgotPassword: {
            ...forgotPassword,
            successMessage: "",
            errorMessage: "Something went wrong. Please try again.",
          },
        });
      }
    }
  };

  onEnterKeyPress = (event) => {
    if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
      this.submitForm();
    }
  };

  renderModal = (forgotPasswordMessage) => {
    const { errorMessage, successMessage } = this.state.forgotPassword;
    const { passwordSent } = this.state;
    return (
      <ForgotPassword
        bg={BackgroundImage}
        title="Forgot password"
        show={(val) => this.forgotPassword(val)}
      >
        <div className="forgot-password-modal">
          <div className="header">
            <div
              className="back-button"
              onClick={() =>
                this.setState({ showModal: false, passwordSent: false })
              }
            >
              <span>
                <i className="icon icon-back" />
                BACK
              </span>
            </div>
          </div>
          <div className="body">
            <div className="title">
              {passwordSent ? "Password sent!" : "Forgot password"}
            </div>
            {passwordSent ? (
              <div className="message">
                <RichText field={forgotPasswordMessage} />
              </div>
            ) : (
              <form ref={this.form} onSubmit={(e) => e.preventDefault()}>
                <Input
                  type="email"
                  placeholder="E-mail address"
                  autoFocus={true}
                  required
                  onInputChange={(value) =>
                    this.setState({
                      forgotPassword: { ...this.state.forgotPassword, value },
                    })
                  }
                  responseText={{
                    type: successMessage
                      ? "success"
                      : errorMessage
                        ? "error"
                        : "",
                    value: successMessage
                      ? successMessage
                      : errorMessage
                        ? errorMessage
                        : "",
                  }}
                />
              </form>
            )}
            <div className="footer-button">
              {passwordSent ? (
                <Button
                  size="send-password"
                  color="light-tertiary"
                  onClick={() =>
                    this.setState({ showModal: false, passwordSent: false })
                  }
                >
                  Done
                </Button>
              ) : (
                <Button
                  size="send-password"
                  color="light-tertiary"
                  disabled={this.state.forgotPassword.value ? false : true}
                  onClick={() => this.sendForgotPassword()}
                >
                  Send password
                </Button>
              )}
            </div>
          </div>
        </div>
      </ForgotPassword>
    );
  };

  render() {
    const {
      // TopNavigationLinks = null,
      SiteLogo = null,
      SiteLogoClassName = null,
      LoginPageBlurb = null,
      FooterBlurb = null,
      ForgotPasswordMessage = null,
    } = this.props.fields || {};
    // let navLink =
    //   TopNavigationLinks &&
    //   TopNavigationLinks.fields &&
    //   TopNavigationLinks.fields.RetailerSiteLogo &&
    //   TopNavigationLinks.fields.RetailerSiteLogo.fields &&
    //   TopNavigationLinks.fields.RetailerSiteLogo.fields.Link &&
    //   TopNavigationLinks.fields.RetailerSiteLogo.fields.Link.value
    //     ? TopNavigationLinks.fields.RetailerSiteLogo.fields.Link.value
    //     : {};
    let mainLink =
      SiteLogo &&
        SiteLogo.fields &&
        SiteLogo.fields.Link &&
        SiteLogo.fields.Link.value
        ? SiteLogo.fields.Link.value
        : {};
    const logo = (SiteLogoClassName && SiteLogoClassName.value) || "";
    let blurb = LoginPageBlurb && LoginPageBlurb.value ? LoginPageBlurb : "";
    let footerBlurb = FooterBlurb && FooterBlurb.value ? FooterBlurb : "";
    let forgotPasswordMessage =
      ForgotPasswordMessage && ForgotPasswordMessage.value
        ? ForgotPasswordMessage
        : "";
    return (
      <div className="login-container">
        {this.state.showModal ? this.renderModal(forgotPasswordMessage) : null}
        <img className="bg-image" src={BackgroundImage} alt="Background" />
        <div className="container">
          <div className="header-wrapper">
            <div className="header-logo">
              {/* {navLink ? (
                <a {...navLink}>
                  <i className="icon icon-qicgre" />
                </a>
              ) : (
                <i className="icon icon-qicgre" />
              )} */}
            </div>
          </div>
          <div className="form-logo">
            {mainLink ? (
              <a {...mainLink}>
                <img
                  alt={"sitecore-logo"}
                  src={logoPlaceholder}
                  data-src={logo}
                  className="lazyload form-logo-image"
                />
              </a>
            ) : (
              <img
                alt={"sitecore-logo"}
                src={logoPlaceholder}
                data-src={logo}
                className="lazyload form-logo-image"
              />
            )}
          </div>
          <div className="login-wrapper">
            <div className="form-blurb">
              <RichText field={blurb} />
            </div>
            <div className="form-wrapper">
              <div className="form-title">Sign in</div>
              <div className="form-group">
                <Input
                  type="text"
                  placeholder="Email address"
                  onInputChange={(username) => this.setState({ username })}
                />
                <Input
                  type="text"
                  placeholder="Email address"
                  onInputChange={(username) => this.setState({ username })}
                  style={{ display: "none" }}
                />
              </div>
              <div className="form-group">
                {this.state.browser === "chrome" && (
                  <Input
                    type="text"
                    placeholder="Password"
                    onInputChange={(password) => this.setState({ password })}
                    onInputKeyDown={(event) => this.onEnterKeyPress(event)}
                  />
                )}
                {this.state.browser === "other" && (
                  <Input
                    type="password"
                    placeholder="Password"
                    onInputChange={(password) => this.setState({ password })}
                    onInputKeyDown={(event) => this.onEnterKeyPress(event)}
                    autoComplete="new-password"
                  />
                )}
              </div>
              <div className="form-submit">
                <Button
                  onClick={() => this.submitForm()}
                  size="full"
                  color="light-green"
                >
                  Sign in
                </Button>
              </div>

              <div className="form-controls-wrapper">
                <div
                  className="form-forgot-password"
                  onClick={() => this.forgotPassword(true)}
                >
                  Forgot password?
                </div>
              </div>
            </div>
          </div>
          {footerBlurb && (
            <div className="form-footer">
              <RichText field={footerBlurb} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FeatureRetailerPortalLogin;
