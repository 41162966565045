import React from "react";

const NoResultMessage = props => {
    const {
        label = null,
        description = null
    } = props;

    return (
        <div className="no-result-message">
            <div className="no-result-message__details">
                <div className="no-result-message__label">
                    {label ? label : "No results"}
                </div>
                {description &&
                    <div className="no-result-message__description">
                        {description}
                    </div>
                }
            </div>
        </div>
    );
};

export default NoResultMessage;
