import React, { Fragment } from "react";
import axios from "axios";
import Button from "../../../Base/Button";
import Input from "../../../Base/Input";
import AvatarUpload from "../../../Base/AvatarUpload";
import SuccessMessage from "../../../Base/SuccessMessage";

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePicture: "",
      fullName: "",
      contactNumber: "",
      emailAddress: "",
      address: "",
      permissions: "",
      errorMessage: "",
      formSubmit: false,
      removeProfilePicture: false,
      showMiniModal: false,
      hideIcon: null,
      modalMessage: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const {
      ProfilePicture,
      FullName,
      ContactNumber,
      EmailAddress,
      Address,
      Permissions,
    } = this.props.data;
    this.setState({
      profilePicture:
        (ProfilePicture && ProfilePicture.value && ProfilePicture.value.src) ||
        null,
      fullName: FullName.value,
      contactNumber: ContactNumber.value,
      emailAddress: EmailAddress.value,
      address: Address.value,
      permissions: (Permissions && Permissions.value) || "",
    });
  };

  saveData = async () => {
    const {
      profilePicture,
      fullName,
      contactNumber,
      address,
      removeProfilePicture,
    } = this.state;
    this.setState({ formSubmit: true });

    if (fullName && contactNumber && address) {
      const formData = new FormData();
      const antiForgeryToken = this.props.antiForgeryToken;
      formData.append("ProfilePicture", profilePicture);
      formData.append("FullName", fullName);
      formData.append("ContactNumber", contactNumber);
      formData.append("Address", address);
      formData.append("RemoveProfilePicture", removeProfilePicture);
      if(antiForgeryToken) {
        formData.append(antiForgeryToken.name, antiForgeryToken.value);
      } 

      const url = "/api/sitecore/account/SaveUserProfile";

      try {
        let response = await axios({
          method: "POST",
          url,
          data: formData,
        });

        if (response && response.data) {
          if (response.data.IsSuccess && response.data.IsSuccess.value) {
            this.setState({
              modalMessage: "Update profile successful.",
              showMiniModal: true,
              hideIcon: false,
              errorMessage: "",
            });
            this.props.refresh(true);
          } else {
            this.setState({
              modalMessage: response.data.Message.value,
              showMiniModal: true,
              hideIcon: true,
              errorMessage: response.data.Message.value,
            });
          }
        } else {
          this.setState({
            modalMessage: "Update profile failed.",
            showMiniModal: true,
            hideIcon: true,
            errorMessage: "Update profile failed.",
          });
        }
      } catch (e) {
        console.log(e);

        this.setState({
          modalMessage: "Update profile failed.",
          hideIcon: true,
          showMiniModal: true,
        });
      }
    } else {
      this.setState({
        modalMessage: "Please fill out all fields.",
        hideIcon: true,
        showMiniModal: true,
        errorMessage: "Please fill out all fields.",
      });
    }
    this.setState({ formSubmit: false });
  };

  renderMiniModal = () => {
    const { modalMessage, hideIcon } = this.state;
    return (
      <SuccessMessage
        label={modalMessage}
        hideIcon={hideIcon}
        handleShowSuccess={(showMiniModal) => {
          this.setState({
            showMiniModal,
          });
        }}
      />
    );
  };

  render() {
    const {
      profilePicture,
      fullName,
      contactNumber,
      emailAddress,
      address,
      errorMessage,
      formSubmit,
      showMiniModal,
    } = this.state;
    const { show } = this.props;
    return (
      <Fragment>
        {showMiniModal && this.renderMiniModal()}
        <div className="btn btn--tertiary profile-btn d-flex d-lg-none">
          <i className="icon icon-close" onClick={() => show()} />
          <div className="d-flex d-lg-none">
            <Button color="secondary" onClick={() => show()}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => this.saveData()}
              disabled={formSubmit}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="editprofile-container">
          <div className="title">Edit profile</div>
          <div className="form-container clearfix">
            <div className="subtitle">Profile picture</div>
            <AvatarUpload
              value={profilePicture}
              handleFileUpload={(profilePicture) =>
                this.setState({ profilePicture })
              }
              removeImage={(removeProfilePicture) =>
                this.setState({ removeProfilePicture })
              }
            />
            <div className="subtitle">Name</div>
            <Input
              id="EP_Name"
              placeholder=" "
              type="text"
              required
              value={fullName}
              onInputChange={(fullName) =>
                this.setState({ fullName, errorMessage: "" })
              }
              blank={errorMessage && !fullName ? "error" : ""}
            />
            <div className="subtitle">Email address</div>
            <Input
              id="EP_Email"
              placeholder=" "
              type="email"
              disabled
              required
              value={emailAddress}
              onInputChange={(emailAddress) => this.setState({ emailAddress })}
            />
            <div className="subtitle">Contact number</div>
            <Input
              id="EP_Contact"
              placeholder=" "
              type="tel"
              required
              value={contactNumber}
              onInputChange={(contactNumber) =>
                this.setState({ contactNumber, errorMessage: "" })
              }
              blank={errorMessage && !contactNumber ? "error" : ""}
            />
            <div className="subtitle">Address</div>
            <Input
              id="EP_Address"
              placeholder=" "
              type="text"
              required
              value={address}
              onInputChange={(address) =>
                this.setState({ address, errorMessage: "" })
              }
              blank={errorMessage && !address ? "error" : ""}
            />
            <div className="float-right d-none d-lg-flex">
              <Button color="secondary" onClick={() => show()}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => this.saveData()}
                disabled={formSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditProfile;
