const imageMimeTypes = [
    'image/webp',
    'image/tiff',
    'image/svg+xml',
    'image/jpeg',
    'image/jpg',
    'image/png'
  ];

export const validateImageSize = (file, max = 5000000) => {
    return file.size <= max;
}

export const validateImageType = (file, allowedTypes = imageMimeTypes) => {
    return allowedTypes.includes(file.type);
}

