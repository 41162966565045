const isEmpty = (value) => {
  return value ? false : true;
};

export const validate = (state, isRetailerRequired, isCentreRequired) => {
  let retailerRule = "not-required";
  let centreRule = "not-required";
  if (isRetailerRequired) {
    retailerRule = "required-select";
  }
  if (isCentreRequired) {
    centreRule = "required-select";
  }
  let list = [
    {
      name: "name",
      value: state.name,
      rules: "required",
    },
    {
      name: "email",
      value: state.email,
      rules: "required",
    },
    {
      name: "centres",
      value: state.centres,
      rules: centreRule,
    },
    {
      name: "retailers",
      value: state.retailers,
      rules: retailerRule,
    },
    {
      name: "permission",
      value: state.permission,
      rules: "required",
    },
  ];

  let result = {};
  list.forEach((element) => {
    if (element.rules === "required") {
      let message = null;
      switch (element.name) {
        case "name":
          message = "Please enter a name";
          break;
        case "email":
          message = "Please enter an email address";
          break;
        case "permission":
          message = "Please select a permission level";
          break;
        default:
          message = null;
      }
      if (isEmpty(element.value)) {
        result[element.name] = message;
      }

      if (element.value && element.value.length && element.value.length < 1) {
        result[element.name] = message;
      }

      if (element.name === "email" && element.value && element.value.length) {
        if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(element.value))) {
          result[element.name] = "Please enter a valid email address";
        }
      }
    }
    if (element.rules === "required-select") {
      let message = null;
      switch (element.name) {
        case "centres":
          message = "Please select a centre";
          break;
        case "retailers":
          message = "Please select a retailer";
          break;
        default:
          message = null;
      }
      if (element.value && element.value.length) {
      } else {
        result[element.name] = message;
      }
    }
    //Other Validation Rules
  });
  return result;
};
