import React from "react";
import axios from "axios";
import Loadable from "react-loadable";
import logoPlaceholder from "../../../../assets/img/logoPlaceholder.png";
import TagManager from "react-gtm-module";
import FormImageGallery from "../../../Base/FormImageGallery";
import FormInput from "../../../Base/FormInput";
import FormInputMultiple from "../../../Base/FormInputMultiple";
import FormDate from "../../../Base/FormDate";
import FormCheckbox from "../../../Base/FormCheckbox";
import FormListbox from "../../../Base/FormListbox";
import FormListCheckbox from "../../../Base/FormListCheckbox";
import Checkbox from "../../../Base/Checkbox";
import FormErrorMessage from "../../../Base/FormErrorMessage";
import FormImageUpload from "../../../Base/FormImageUpload";
import PopupPreview from "../../../Base/Popup/PopupPreview";
import { get } from "lodash";
import {
  validate,
  setPromotionData,
  buildFormData,
  formDataFields,
} from "../../../../helper/PromotionHelper";
import { getUrlParameter } from "../../../../helper/UrlHelper";
import { getAncestorElement } from "../../../../helper/GetAncestorElementHelper";
import {
  getPromotionData,
  getCentreStores,
  getCategoriesAndHolidays,
} from "../../../../api/PromotionAPI";

// import promotionProps from "./jsonResponse/editPropsJson.json";
// import newSaveResponse from "./jsonResponse/newSaveResponse.json";
// import getCentreStoresResponseApi from "./jsonResponse/getCentreStoresResponseApi.json";
// import getCategoriesAndHolidaysApi from "./jsonResponse/getCategoriesAndHolidaysApi.json";
// import GetPromotionDataResponse from "./jsonResponse/GetPromotionDataResponse.json";

const RichTextEditor = Loadable({
  loader: () => import("../../../Base/RichTextEditor"),
  loading: () => null,
});

class FormPromotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "new",
      showModal: false,
      formError: {},
      centreStoreList: [],
      categories: [],
      holidays: [],
      formData: { ...formDataFields },
      componentReady: false,
      showScheduleDate: false,
      showTermsCondition: false,
      showImageGallery: false,
      disableBtn: true,
      showSaveHint: false,
      showErrorMessage: false,
      formErrorMessage: "",
      imageGalleryHolder: [],
      displayStartDateHolder: null,
      displayEndDateHolder: null,
      termsAndConditionsLinkHolder: "",
      termsAndConditionsCopyHolder: "",
      promotionPreviewUrl: "",
      promotionPreviewTitle: "",
      promotionList: [],
      getPromotionDataReady: false,
      previewBulkLogo: "",
      promotionIdList: [],
      retailerId: null,
      consentToSharing: false,
      retailerHandles: "",
      retailerHashtags: "",
      socialFeedImage: "",
      socialStoryImage: "",
      retailerId: null
    };
  }

  componentDidMount() {
    const { itemId, dashboardType } = this.props;

    let type = "";

    if (dashboardType) {
      type = dashboardType.charAt(0).toUpperCase() + dashboardType.slice(1);
    } else {
      type = getUrlParameter("type").replace("?sc_mode=normal", "");
    }

    this.setState({
      type,
      componentReady: true,
    });

    if (itemId) {
      this.getData(itemId);
    } else {
      this.getCentreStoreList();
    }
    this.getCategoriesAndHolidays();
    this.handleFormData();
  }

  getData = async (itemId) => {
    try {
      let response = await getPromotionData({ itemId });
      if (response.status === 200 && response.data) {
        let promotionData = setPromotionData(response.data.Promotion);

        this.setState({
          mode: "edit",
          getPromotionDataReady: true,
          formData: { ...this.state.formData, ...promotionData },
          showScheduleDate:
            promotionData.displayStartDate || promotionData.displayEndDate
              ? true
              : false,
          showTermsCondition:
            promotionData.termsAndConditionsLink ||
              promotionData.termsAndConditionsCopy
              ? true
              : false,
          showImageGallery:
            promotionData.imageGallery && promotionData.imageGallery.length > 0
              ? true
              : false,
          consentToSharing: promotionData.consentToSharing
        });
      }
    } catch (e) {
      console.error("Get Promotion Data Error: " + e);
      this.setState({
        getPromotionDataReady: true,
      });
      // if (itemId) {
      //   let promotionData = setPromotionData(GetPromotionDataResponse.Promotion);

      //   this.setState({
      //     mode: "edit",
      //     getPromotionDataReady: true,
      //     formData: { ...this.state.formData, ...promotionData },
      //     showScheduleDate: (promotionData.displayStartDate || promotionData.displayEndDate) ? true : false,
      //     showExternalPage: (promotionData.bookNowLink || promotionData.bookNowLinkCaption) ? true : false,
      //     showTermsCondition: (promotionData.termsAndConditionsLink || promotionData.termsAndConditionsCopy) ? true : false,
      //     showImageGallery: (promotionData.imageGallery.length > 0) ? true : false
      //   });
      // }
    }
  };

  getCategoriesAndHolidays = async () => {
    try {
      let response = await getCategoriesAndHolidays();
      if (response.status === 200 && response.data) {
        let categoryListData = response.data.Categories;
        let holidayListData = response.data.GlobalSpecialHoliday;
        let categoryList = [];
        let holidayList = [];
        if (categoryListData.length > 0) {
          categoryListData.forEach((el) =>
            categoryList.push({
              name: el.CategoryName.value,
              value: el.CategoryId.value,
            })
          );
        }
        if (holidayListData.length > 0) {
          holidayListData.forEach((el) =>
            holidayList.push({
              name: el.HolidayName.value,
              value: el.HolidayId.value,
            })
          );
        }
        this.setState({
          categories: categoryList,
          holidays: holidayList,
        });
      }
    } catch (e) {
      console.error(
        "Get Categories And Holidays Error: " + e.response.statusText
      );
      // let categoryListData = getCategoriesAndHolidaysApi.Categories;
      // let holidayListData = getCategoriesAndHolidaysApi.GlobalSpecialHoliday;
      // let categoryList = [];
      // let holidayList = [];
      // if (categoryListData.length > 0) {
      //   categoryListData.forEach((el) =>
      //     categoryList.push({
      //       name: el.CategoryName.value,
      //       value: el.CategoryId.value,
      //     })
      //   );
      // }
      // if (holidayListData.length > 0) {
      //   holidayListData.forEach((el) =>
      //     holidayList.push({
      //       name: el.HolidayName.value,
      //       value: el.HolidayId.value,
      //     })
      //   );
      // }
      // this.setState({
      //   categories: categoryList,
      //   holidays: holidayList,
      // });
    }
  };

  getCentreStoreList = async () => {
    try {
      let response = await getCentreStores();
      if (response.status === 200 && response.data) {
        if (response.data.IsSuccess.value) {
          this.setState({
            centreStoreList: get(response.data, "CentreStoreList", []),
            retailerId: get(response.data, "Retailer.RetailerId.value", ""),
            getPromotionDataReady: true,
          });
        }
      }
    } catch (e) {
      console.error("Get centre stores data error: " + e.response.statusText);
      this.setState({
        getPromotionDataReady: true,
      });
      // this.setState({
      //   centreStoreList: get(getCentreStoresResponseApi, "CentreStoreList", []),
      //   retailerId: get(getCentreStoresResponseApi, "Retailer.RetailerId.value", ""),
      //   getPromotionDataReady: true,
      // });
    }
  };

  handleListbox = (id, field, isCheckbox) => {
    const { formData } = this.state;
    let options;
    let selected = [];
    if (document) {
      if (isCheckbox) {
        options = document.querySelectorAll(`#${id} input:checked`);
      } else {
        options = document.querySelectorAll(`#${id} option:checked`);
      }
      if (options) {
        for (let i = 0; i < options.length; i++) {
          selected.push(options[i].value);
        }
      }
    }
    this.setState({
      formData: {
        ...formData,
        [field]: selected,
      },
    });
  };

  handleSelect = (isSelect, field, options) => {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        [field]: isSelect ? options.map((el) => el.value) : [],
      },
    });
  };

  handleInputCheckbox = (value) => {
    const { formData } = this.state;
    if (formData.selectedCategories.indexOf(value) > -1) {
      this.setState({
        formData: {
          ...formData,
          selectedCategories: formData.selectedCategories.filter(
            (el) => el !== value
          ),
        },
      });
    } else {
      this.setState({
        formData: {
          ...formData,
          selectedCategories: [...formData.selectedCategories, value],
        },
      });
    }
  };

  handleStoreListCheckbox = (value, selectAllStores) => {
    const { formData, centreStoreList } = this.state;

    if (value === "0") {
      let allStores = [];
      centreStoreList.map((centre) => {
        centre.StoreList.map((store) => {
          allStores.push(store.StoreItemId.value);
        });
      });

      this.setState({
        formData: {
          ...formData,
          selectedStores: selectAllStores ? allStores : [],
        },
      });
    } else if (formData.selectedStores.indexOf(value) > -1) {
      this.setState({
        formData: {
          ...formData,
          selectedStores: formData.selectedStores.filter((el) => el !== value),
        },
      });
    } else {
      this.setState({
        formData: {
          ...formData,
          selectedStores: [...formData.selectedStores, value],
        },
      });
    }
  };

  handleFormUpdate = (value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...value,
      },
    });
  };

  validateForm = async () => {
    let formError = validate(
      this.state.formData,
      this.state.mode === "edit" ? ["selectedRetailer"] : []
    );
    let response;
    if (Object.entries(formError).length !== 0) {
      this.setState({
        formError,
        showErrorMessage: true,
        formErrorMessage: "Please fill out all mandatory fields marked below",
      });
      this.triggerBackToTop();
      response = {
        isSuccess: false,
      };
    } else {
      this.setState({
        formError,
      });
      response = await this.saveForm().then((result) => {
        if (result.isSuccess) {
          return { isSuccess: true };
        }
      });
    }
    return response;
  };

  saveForm = async () => {
    const { formData, mode, type, promotionIdList } = this.state;
    var timezoneId = new Date().getTimezoneOffset();
    const url = "/api/sitecore/promotion/SavePromotion";
    let data = buildFormData({
      ...formData,
      timeZoneId: timezoneId,
    });

    this.triggerBackToTop();

    this.setState(
      {
        showSaveHint: true,
        disableBtn: true,
      },
      this.handleFormData
    );

    try {
      let response = await axios({
        method: "POST",
        url,
        data,
        params: {
          type,
          ...(mode === "edit"
            ? { itemId: formData.itemId }
            : { itemId: promotionIdList.toString() }),
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });

      if (response) {
        if (response.data.IsSuccess.value && mode === "new") {
          const promotionList = response.data.PromotionList;
          const promotionsItem = promotionList[0].Promotions;
          const itemIds = this.extractItemIds(promotionList);
          let previewBulkLogo;

          this.setGoogleAnalytics(promotionList);

          if (response.data.RetailerLogo && response.data.RetailerLogo.value) {
            previewBulkLogo = response.data.RetailerLogo.value;
          }

          if (promotionList.length === 1 && promotionsItem.length === 1) {
            this.setState(
              {
                formData: {
                  ...formData,
                  itemIds,
                },
                showSaveHint: false,
                disableBtn: false,
                showErrorMessage: false,
                promotionPreviewUrl: promotionsItem[0].PreviewUrl.value,
                // promotionPreviewTitle: promotionsItem[0].Store.value,
                promotionPreviewTitle: formData.promotionName,
                promotionList: promotionList,
                previewBulkLogo: "",
                promotionIdList: this.setPromotionIdList(promotionList),
              },
              this.handleFormData
            );
          } else {
            this.setState(
              {
                formData: {
                  ...formData,
                  itemIds,
                },
                showSaveHint: false,
                disableBtn: false,
                showErrorMessage: false,
                promotionPreviewUrl: "",
                promotionPreviewTitle: "",
                promotionList: promotionList,
                previewBulkLogo,
                promotionIdList: this.setPromotionIdList(promotionList),
              },
              this.handleFormData
            );
          }

          return {
            isSuccess: true,
          };
        } else if (response.data.IsSuccess.value && mode === "edit") {
          const promotionList = response.data.PromotionList[0];
          const promotion = promotionList.Promotions[0];
          const promotionPreviewUrl = promotion.PreviewUrl.value;
          const promotionPreviewTitle =
            promotion.Store.value + " " + promotionList.Centre.value;
          this.setState(
            {
              formData: {
                ...formData,
                itemIds: [`${promotion.ItemId.value}`],
              },
              showErrorMessage: false,
              showSaveHint: false,
              disableBtn: false,
              promotionPreviewUrl,
              promotionPreviewTitle: formData.promotionName,
            },
            this.handleFormData
          );

          return {
            isSuccess: true,
          };
        } else {
          this.setState(
            {
              showSaveHint: false,
              disableBtn: true,
              showErrorMessage: true,
              formErrorMessage:
                "Promotion Not Saved " + response.data.Message.value,
            },
            this.handleFormData
          );

          return {
            isSuccess: false,
          };
        }
      }
    } catch (e) {
      console.error("Promotion Save Error: " + e.response.statusText);
      // Condition of catch
      this.setState({
        showErrorMessage: true,
        formErrorMessage: "Promotion Save Error: " + e.response.statusText,
      });
      return {
        isSuccess: false,
      };

      // Condition on mode === edit
      // const promotionList = newSaveResponse.PromotionList[0]
      // const promotion = promotionList.Promotions[0];
      // const promotionPreviewUrl = promotion.PreviewUrl.value;
      // const promotionPreviewTitle = promotion.Store.value + " " + promotionList.Centre.value;

      // this.setState(
      //     {
      //         formData: {
      //             ...formData,
      //             itemIds: [`${promotion.ItemId.value}`]
      //         },
      //         showErrorMessage: false,
      //         showSaveHint: false,
      //         disableBtn: false,
      //         promotionPreviewUrl,
      //         promotionPreviewTitle: formData.promotionName
      //     },
      //     this.handleFormData
      // );

      // return {
      //     isSuccess: true
      // };

      // condition new
      // const promotionList = newSaveResponse.PromotionList;
      // const promotionsItem = promotionList[0].Promotions;
      // const itemIds = this.extractItemIds(promotionList);
      // let previewBulkLogo;

      // if (newSaveResponse.RetailerLogo && newSaveResponse.RetailerLogo.value) {
      //     previewBulkLogo = newSaveResponse.RetailerLogo.value;
      // }
      // this.setState({
      //     formData: {
      //         ...formData,
      //         itemIds
      //     },
      //     showSaveHint: false,
      //     disableBtn: false,
      // },
      //     this.handleFormData
      // );

      // if (promotionList.length === 1 && promotionsItem.length === 1) {
      //     this.setState({
      //         formData: {
      //             ...formData,
      //             itemIds
      //         },
      //         showSaveHint: false,
      //         disableBtn: false,
      //         showErrorMessage: false,
      //         promotionPreviewUrl: promotionsItem[0].PreviewUrl.value,
      //         // promotionPreviewTitle: promotionsItem[0].Store.value,
      //         promotionPreviewTitle: formData.promotionName,
      //         promotionList: promotionList,
      //         previewBulkLogo: "",
      //         promotionIdList: this.setPromotionIdList(promotionList)
      //     },
      //         this.handleFormData
      //     );
      // } else {
      //     this.setState({
      //         formData: {
      //             ...formData,
      //             itemIds
      //         },
      //         showSaveHint: false,
      //         disableBtn: false,
      //         showErrorMessage: false,
      //         promotionPreviewUrl: "",
      //         promotionPreviewTitle: "",
      //         promotionList: promotionList,
      //         previewBulkLogo,
      //         promotionIdList: this.setPromotionIdList(promotionList)
      //     },
      //         this.handleFormData
      //     );
      // }

      // return {
      //     isSuccess: true
      // };
    }
  };

  submitForm = async () => {
    const {
      formData,
      mode,
      promotionPreviewUrl,
      promotionPreviewTitle,
    } = this.state;
    const url = "/api/sitecore/promotion/SubmitPromotion";
    let data = buildFormData({
      itemIds: mode === "edit" ? [`${formData.itemId}`] : formData.itemIds,
    });
    this.triggerBackToTop();

    try {
      let response = await axios({
        method: "POST",
        url,
        data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });

      if (response) {
        if (response.data.IsSuccess.value) {
          this.setState({
            showErrorMessage: false,
          });
          return {
            isSuccess: true,
            promotionPreviewUrl: mode === "edit" ? promotionPreviewUrl : "",
            promotionPreviewTitle: mode === "edit" ? promotionPreviewTitle : "",
          };
        } else {
          this.setState({
            showErrorMessage: true,
            formErrorMessage:
              "Promotion Not Submitted " + response.data.Message.value,
          });
          return {
            isSuccess: false,
          };
        }
      }
    } catch (e) {
      console.error("Promotion Submit Error: " + e.response.statusText);
      this.setState({
        showErrorMessage: true,
        formErrorMessage: "Promotion Submit Error: " + e.response.statusText,
      });
      return {
        isSuccess: false,
      };

      // this.setState({
      //     showErrorMessage: false,
      // });
      // return {
      //     isSuccess: true,
      //     promotionPreviewUrl: mode === "edit" ? promotionPreviewUrl : "",
      //     promotionPreviewTitle: mode === "edit" ? promotionPreviewTitle : ""
      // };
    }
  };

  triggerBackToTop = () => {
    let backToTop;
    if (document) {
      backToTop = document.querySelector(".form__modal--back-to-top-button");
      if (backToTop) {
        backToTop.click();
      }
    }
  };

  setGoogleAnalytics = (promotionList) => {
    promotionList.map((item) => {
      const { Centre, Promotions } = item;
      Promotions.map((promotion) => {
        TagManager.dataLayer({
          dataLayer: {
            event: "createdPromotions",
            promotionType: this.state.type,
            promotionCentre: Centre && Centre.value,
            promotionRetailer:
              promotion && promotion.Store && promotion.Store.value,
          },
          dataLayerName: "dataLayer",
        });
      });
    });
  };

  setPromotionIdList = (promotionList) => {
    let promotionIdList = [];

    if (promotionList.length > 0) {
      promotionList.map((item) => {
        const { Promotions } = item;

        if (Promotions.length > 0) {
          Promotions.map((item) => {
            const { ItemId } = item;

            if (ItemId && ItemId.value) {
              promotionIdList.push(ItemId.value);
            }
          });
        }
      });
    }

    return promotionIdList;
  };

  extractItemIds = (centreList) => {
    let itemIds = [];
    centreList.map((center) => {
      center.Promotions.map((promotion) => {
        itemIds.push(promotion.ItemId.value);
      });
    });
    return itemIds;
  };

  handlePreviewForm = () => {
    this.setState({
      showModal: true,
    });
  };

  renderModal = () => {
    const { promotionList, showModal, previewBulkLogo } = this.state;

    const { promotionName } = this.state.formData || {};

    return (
      <div className="promotion-modal">
        {promotionList && promotionList.length > 0 && (
          <PopupPreview
            previewTitle={"Preview"}
            previewLogo={previewBulkLogo}
            previewList={promotionList}
            previewListChildName="Promotions"
            previewFormLabel={promotionName}
            btnLabel={"Submit"}
            btnOnClick={(e) => {
              let showModalWrapper = getAncestorElement(
                e.target,
                "form__modal"
              );
              if (showModalWrapper) {
                let submitBtn = showModalWrapper.querySelector(
                  ".promotion-submit-btn"
                );
                if (submitBtn) {
                  submitBtn.click();
                }
              }
            }}
            showPreviewModal={() => this.setState({ showModal: false })}
          />
        )}
      </div>
    );
  };

  handleFormData = () => {
    const {
      showSaveHint,
      disableBtn,
      promotionPreviewUrl,
      promotionPreviewTitle,
    } = this.state;
    this.props.handleFormData(
      showSaveHint,
      disableBtn,
      promotionPreviewUrl,
      promotionPreviewTitle,
      this.validateForm,
      this.handlePreviewForm,
      this.submitForm
    );
  };

  buildCentreStoreList = (centreStoreList) => {
    const { type, formData } = this.state;

    return (
      <div className="form__multiple-stores">
        <div className="form__multiple-stores--header">
          <label className="form__label">
            {`Publish this ${type.toLowerCase()} for the multiple centres`}
          </label>
          <div className="form__multiple-stores--action">
            <div
              className="form__multiple-stores--action-btn"
              onClick={() => this.handleStoreListCheckbox("0", true)}
            >
              {"Select all"}
            </div>
            <div
              className="form__multiple-stores--action-btn"
              onClick={() => this.handleStoreListCheckbox("0", false)}
            >
              {"Deselect all"}
            </div>
          </div>
        </div>
        <div className="form__multiple-stores--content">
          {centreStoreList.map((centre, index) => {
            const { CentreName, CentreIcon, StoreList } = centre;
            return (
              <div key={`centreStoreList-container-${index}`} className="row">
                <div className="col col-12 col-md-6">
                  <div className="form__multiple-stores--logo">
                    {CentreIcon && CentreIcon.value ? (
                      <img
                        alt={`CentreIcon-${index}`}
                        src={logoPlaceholder}
                        data-src={CentreIcon.value}
                        className="lazyload"
                      />
                    ) : (
                      <div className="no-image" />
                    )}
                  </div>
                  <div className="form__multiple-stores--label">
                    {CentreName.value}
                  </div>
                </div>
                <div className="col col-12 col-md-6">
                  {StoreList.map((store, index) => {
                    const { StoreItemId, StoreName } = store;
                    return (
                      <FormCheckbox
                        key={`store-${StoreItemId.value}-${index}`}
                        value={StoreItemId.value}
                        label={StoreName.value}
                        checked={
                          formData.selectedStores.indexOf(StoreItemId.value) >
                          -1
                        }
                        onCheckboxChange={(value) =>
                          this.handleStoreListCheckbox(value)
                        }
                        plain
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const {
      itemId = null
    } = this.props;
    const {
      formError,
      formData,
      categories,
      type,
      showModal,
      componentReady,
      centreStoreList,
      holidays,
      showScheduleDate,
      showTermsCondition,
      showImageGallery,
      showErrorMessage,
      formErrorMessage,
      imageGalleryHolder,
      displayStartDateHolder,
      displayEndDateHolder,
      termsAndConditionsLinkHolder,
      termsAndConditionsCopyHolder,
      getPromotionDataReady,
      promotionIdList,
      retailerId,
      consentToSharing,
      retailerHandles,
      retailerHashtags,
      socialFeedImage,
      socialStoryImage
    } = this.state;
    return (
      <div
        className={`promotion-container 
                ${type && type.toLowerCase() === "offer"
            ? "is-offer"
            : "is-event"
          }`}
      >
        {showModal && this.renderModal()}
        {showErrorMessage && <FormErrorMessage error={formErrorMessage} />}
        <div className="form__container">
          <FormInput
            title
            type="text"
            placeholder={`${type} title`}
            label="Promotion Name"
            value={formData.promotionName}
            required
            error={formError.promotionName}
            onInputChange={(promotionName) =>
              this.handleFormUpdate({ promotionName })
            }
          />
        </div>
        <div className="form__container">
          <label className="form__header">{`${type} duration`}</label>
          <div
            className={`form-group form__date--container
                        ${formError.startDate || formError.endDate
                ? "has-error"
                : ""
              }
                    `}
          >
            <div className="form__date--wrapper">
              <FormDate
                required
                label="Start Date"
                icon="calendar"
                placeholderText="MM/DD/YYYY"
                showTimeSelect
                timeCaption="Time"
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="d MMM yyyy - h:mmaa"
                value={formData.startDate && new Date(formData.startDate)}
                error={formError.startDate}
                selected={formData.startDate && new Date(formData.startDate)}
                onChange={(startDate) => this.handleFormUpdate({ startDate })}
              />
              <div className="form__date--divider" />
              <FormDate
                required
                label="End Date"
                icon="calendar"
                placeholderText="MM/DD/YYYY"
                showTimeSelect
                timeCaption="Time"
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="d MMM yyyy - h:mmaa"
                value={formData.endDate && new Date(formData.endDate)}
                error={formError.endDate}
                selected={formData.endDate && new Date(formData.endDate)}
                onChange={(endDate) => this.handleFormUpdate({ endDate })}
              />
            </div>
            <div className="form__hint">*Maximum of 31 days(1 month)</div>
          </div>
          <Checkbox
            checked={showScheduleDate}
            label={"Schedule a different publish date"}
            onCheckboxChange={(showScheduleDate) => {
              this.setState({
                showScheduleDate,
              });
              if (showScheduleDate) {
                this.handleFormUpdate({
                  displayStartDate: displayStartDateHolder,
                  displayEndDate: displayEndDateHolder
                });
              } else {
                this.handleFormUpdate({
                  displayStartDate: null,
                  displayEndDate: null
                });
              }
            }}
          />
          {showScheduleDate && (
            <div className="form-group form__date--container">
              <div className="form__date--wrapper">
                <FormDate
                  label="Publish from"
                  icon="calendar"
                  placeholderText="MM/DD/YYYY"
                  showTimeSelect
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="d MMM yyyy - h:mmaa"
                  error={formError.displayStartDate}
                  value={
                    formData.displayStartDate &&
                    new Date(formData.displayStartDate)
                  }
                  selected={
                    formData.displayStartDate &&
                    new Date(formData.displayStartDate)
                  }
                  onChange={(displayStartDate) => {
                    this.setState({
                      displayStartDateHolder: displayStartDate,
                    });
                    this.handleFormUpdate({ displayStartDate });
                  }}
                />
                <div className="form__date--divider" />
                <FormDate
                  label="Publish until"
                  icon="calendar"
                  placeholderText="MM/DD/YYYY"
                  showTimeSelect
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="d MMM yyyy - h:mmaa"
                  value={
                    formData.displayEndDate && new Date(formData.displayEndDate)
                  }
                  error={formError.endDate}
                  selected={
                    formData.displayEndDate && new Date(formData.displayEndDate)
                  }
                  onChange={(displayEndDate) => {
                    this.setState({
                      displayEndDateHolder: displayEndDate,
                    });
                    this.handleFormUpdate({ displayEndDate });
                  }}
                />
              </div>
            </div>
          )}
        </div>
        

        {type && type.toLowerCase() === "event" && (
          <div className="form__container">
            <label className="form__header">{`${type} details`}</label>
            <FormInput
              type="text"
              icon="calendar"
              placeholder="Eg: Every Monday"
              label="Schedule"
              value={formData.schedule}
              error={formError.schedule}
              onInputChange={(schedule) => this.handleFormUpdate({ schedule })}
            />

            <FormInput
              type="text"
              placeholder="Eg: HH:MM"
              label="Duration"
              icon="time"
              value={formData.duration}
              width="min"
              onInputChange={(duration) => this.handleFormUpdate({ duration })}
            />

            <FormInput
              type="text"
              placeholder="Eg: 10"
              label="Capacity"
              icon="capacity"
              value={formData.capacity}
              width="min"
              onInputChange={(capacity) => this.handleFormUpdate({ capacity })}
            />

            <FormInput
              type="number"
              icon="price"
              placeholder="Eg: 0.00"
              label="Price"
              currency
              value={formData.price}
              width="min"
              onInputChange={(price) => this.handleFormUpdate({ price })}
            />
          </div>
        )}

        <div className="form__container">
          <label className="form__header">{`${type} description`}</label>
          {type && type.toLowerCase() === "offer" && (
            <FormInput
              type="text"
              placeholder="Eg: 50% off"
              label="Badge label"
              width="badge"
              margin="custom"
              value={formData.offerHighlight}
              maxLength={7}
              onInputChange={(offerHighlight) =>
                this.handleFormUpdate({ offerHighlight })
              }
            />
          )}
          <FormInputMultiple
            label="Sub title"
            placeholder="Provide a short description"
            required
            value={formData.summary}
            maxLength={120}
            error={formError.summary}
            onInputChange={(summary) => this.handleFormUpdate({ summary })}
          />
          {getPromotionDataReady && (
            <RichTextEditor
              required
              width="max"
              label={"Description"}
              placeholder="Provide a description"
              initialValue={formData.description}
              error={formError.description}
              onInputChange={(description) =>
                this.handleFormUpdate({ description })
              }
              retailerId={retailerId}
              itemId={itemId}
              promotionType={type}
            />
          )}

          <div className="form__container--small">
            <label className="form__header--small">
              {"Terms and conditions"}
            </label>
            <Checkbox
              checked={showTermsCondition}
              label={"Display link to an external terms and conditions page?"}
              onCheckboxChange={(showTermsCondition) => {
                this.setState({
                  showTermsCondition,
                });

                if (showScheduleDate) {
                  this.handleFormUpdate({
                    termsAndConditionsLink: termsAndConditionsLinkHolder,
                    termsAndConditionsCopy: termsAndConditionsCopyHolder
                  });

                } else {
                  this.handleFormUpdate({
                    termsAndConditionsLink: "",
                    termsAndConditionsCopy: ""
                  });
                }
              }}
            />
            {showTermsCondition && (
              <div>
                <FormInput
                  type="text"
                  placeholder="Eg: www.terms-and-conditions.com"
                  label="External link"
                  value={formData.termsAndConditionsLink}
                  onInputChange={(termsAndConditionsLink) => {
                    this.setState({
                      termsAndConditionsLinkHolder: termsAndConditionsLink,
                    });
                    this.handleFormUpdate({ termsAndConditionsLink });
                  }}
                />

                <FormInput
                  type="text"
                  placeholder="Eg: Please view terms and conditions"
                  label="Link label"
                  value={formData.termsAndConditionsCopy}
                  onInputChange={(termsAndConditionsCopy) => {
                    this.setState({
                      termsAndConditionsCopyHolder: termsAndConditionsCopy,
                    });
                    this.handleFormUpdate({ termsAndConditionsCopy });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="form__container">
          {type && type.toLowerCase() === "offer" && (
          <>
          <label className="form__header">{"QIC Social & Digital Use"}</label>
          <Checkbox
            checked={consentToSharing}
            label={"I give permission for centre marketing teams to share this content to QIC centre owned social pages (Facebook and Instagram)"}
            onCheckboxChange={(consentToSharing) => {
              this.setState({
                consentToSharing,
              });
              if (consentToSharing) {
                this.handleFormUpdate({
                  socialFeedImage: socialFeedImage,
                  socialStoryImage: socialStoryImage,
                  retailerHandles: retailerHandles,
                  retailerHashtags: retailerHashtags,
                  consentToSharing: consentToSharing
                });

              } else {
                this.handleFormUpdate({
                  socialFeedImage: '',
                  socialStoryImage: '',
                  retailerHandles: "",
                  retailerHashtags: "",
                  consentToSharing: false,
                  embargoEndDate: "",
                  embargoStartDate: ""
                });

                this.setState({
                  retailerHandles: "",
                  retailerHashtags: ""
                })
              }
            }}
          />
          {consentToSharing && type.toLowerCase() === "offer" && (
            <div className="embargo-group">
            <div className="form-group form__date--container">
              <div className="form__date--wrapper">
              <div className="form__container">
              <label className="form__header">{`Embargo Period`}</label>
              <div className="form__label embargo-fields">
              <p><i>Note</i> - An Embargo Period is the period of time in which an offer <strong>cannot be shared publicly</strong>.<br /> This period must end before the offer Start Date. </p>
            </div>
          <div
            className={`form-group form__date--container
                        ${
              formError.embargoStartDate || formError.embargoEndDate
                ? "has-error"
                : ""
              }
                    `}
          >
            <div className="form__date--wrapper">
              <FormDate
                label="Embargo - Start Date"
                icon="calendar"
                placeholderText="MM/DD/YYYY"
                showTimeSelect
                timeCaption="Time"
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="d MMM yyyy - h:mmaa"
                value={formData.embargoStartDate && new Date(formData.embargoStartDate)}
                error={formError.embargoStartDate}
                selected={formData.embargoStartDate && new Date(formData.embargoStartDate)}
                onChange={(embargoStartDate) => this.handleFormUpdate({ embargoStartDate })}
              />
              <div className="form__date--divider" />
              <FormDate
                label="Embargo - End Date"
                icon="calendar"
                placeholderText="MM/DD/YYYY"
                showTimeSelect
                timeCaption="Time"
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="d MMM yyyy - h:mmaa"
                value={formData.embargoEndDate && new Date(formData.embargoEndDate)}
                error={formError.embargoEndDate}
                selected={formData.embargoEndDate && new Date(formData.embargoEndDate)}
                onChange={(embargoEndDate) => this.handleFormUpdate({ embargoEndDate })}
              />
            </div>
            <div className="form__hint">*Maximum of 31 days(1 month)</div>
          </div>
          </div>
          <div className="form-group">
          <div className={`form__container ${
              formError.socialFeedImage || formError.socialFeedFileType || formError.socialFeedFileSize
                ? "has-error"
                : ""
              }`}>
                <FormImageUpload
                  type="thumbnail"
                  label={"Social Feed Image"}
                  guidelines={
                    "To ensure the quality of the shared content, the social feed image needs to be:"
                  }
                  guidelinesOne={"• At least 1080px wide"}
                  guidelinesTwo={"• At least 1080px tall."}
                  guidelinesThree={
                    "• To avoid your image being cropped, use a ratio of 1:1"
                  }
                  error={formError.socialFeedImage || formError.socialFeedFileType || formError.socialFeedFileSize}
                  value={formData.socialFeedImage}
                  handleFileUpload={(socialFeedImage) =>
                    this.handleFormUpdate({ socialFeedImage })
                  }
                />
                </div> 
                </div>
                <div className={`form__container ${
              formError.socialStoryImage || formError.socialStoryFileType || formError.socialStoryFileSize
                ? "has-error"
                : ""
              }`}>                  <FormImageUpload
                    type="thumbnail"
                    label={"Social Stories Image"}
                    guidelines={
                      "To ensure the quality of the shared content, the social stories image needs to be:"
                    }
                    guidelinesOne={"• At least 1080px wide"}
                    guidelinesTwo={"• At least 1920px tall."}
                    guidelinesThree={
                      "• To avoid your image being cropped, use a ratio of 9:16"
                    }
                    error={formError.socialStoryImage || formError.socialStoryFileType || formError.socialStoryFileSize}
                    value={formData.socialStoryImage}
                    handleFileUpload={(socialStoryImage) =>
                      this.handleFormUpdate({ socialStoryImage })
                    }
                  />
                </div>
                <FormInput
                  type="text"
                  placeholder="Eg: @"
                  label="Retailer Handles"
                  value={formData.retailerHandles}
                  onInputChange={(retailerHandles) => {
                    this.setState({
                      retailerHandles: retailerHandles,
                    });
                    this.handleFormUpdate({retailerHandles});
                  }}
                />
                <FormInput
                  type="text"
                  placeholder="Eg: #"
                  label="Retailer Hashtags"
                  value={formData.retailerHashtags}
                  onInputChange={(retailerHashtags) => {
                    this.setState({
                      retailerHashtags: retailerHashtags,
                    });
                    this.handleFormUpdate({ retailerHashtags });
                  }}
                />
              </div>
            </div>
            </div>
          )}
          </>
          )}
          {(categories && categories.length > 0 ||
            (holidays && holidays.length > 0)) && (
              categories && categories.length > 0 && (
                <div>
                  <div className="is-desktop">
                    <FormListbox
                      label="Category"
                      id="LB_Cat_Desktop"
                      required
                      error={formError.selectedCategories}
                      options={categories}
                      field="selectedCategories"
                      selected={formData.selectedCategories}
                      hint="Press [ctrl] + click to select multiple"
                      handleListbox={(id, field) => this.handleListbox(id, field)}
                      handleSelect={(isSelect, field, options) =>
                        this.handleSelect(isSelect, field, options)
                      }
                    />
                  </div>
                  <div className="is-tablet">
                    <FormListCheckbox
                      label="Category"
                      id="LB_Cat_Mobile"
                      required
                      error={formError.selectedCategories}
                      options={categories}
                      field="selectedCategories"
                      selected={formData.selectedCategories}
                      handleListbox={(id, field) => this.handleListbox(id, field, true)}
                      handleSelect={(isSelect, field, options) =>
                        this.handleSelect(isSelect, field, options)
                      }
                    />
                  </div>
                </div>))}
          {holidays && holidays.length > 0 && (
            <div>
              <div className="is-desktop">
                <FormListbox
                  label="Special promotions"
                  id="LB_SH_Desktop"
                  options={holidays}
                  field="selectedSpecialPromotion"
                  selected={formData.selectedSpecialPromotion}
                  hint="Press [ctrl] + click to select multiple"
                  handleListbox={(id, field) => this.handleListbox(id, field)}
                  handleSelect={(isSelect, field) =>
                    this.handleSelect(isSelect, field, holidays)
                  }
                />
              </div>
              <div className="is-tablet">
                <FormListCheckbox
                  label="Special promotions"
                  id="LB_SH_Mobile"
                  options={holidays}
                  field="selectedSpecialPromotion"
                  selected={formData.selectedSpecialPromotion}
                  handleListbox={(id, field) => this.handleListbox(id, field, true)}
                  handleSelect={(isSelect, field, options) =>
                    this.handleSelect(isSelect, field, options)
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className={`form__container ${formError.heroImage || formError.heroImageFileSize || formError.heroImageFileType
          ? "has-error"
          : ""
          }`}>
          {getPromotionDataReady && (
            <FormImageUpload
              required
              type="hero"
              label={"Hero image"}
              guidelines={
                "To keep the quality of our websites high, the hero banner image needs to be:"
              }
              guidelinesOne={"• At least 1920px wide"}
              guidelinesTwo={"• At least 1080px tall."}
              guidelinesThree={
                "• To avoid your image being cropped, use a ratio of 16:9"
              }
              error={formError.heroImage || formError.heroImageFileType || formError.heroImageFileSize}
              value={formData.heroImage}
              handleFileUpload={(heroImage) =>
                this.handleFormUpdate({ heroImage })
              }
            />
          )}
        </div>

        <div className={`form__container ${formError.thumbnail || formError.thumbnailFileSize || formError.thumbnailFileType
          ? "has-error"
          : ""
          }`}>
          {getPromotionDataReady && (
            <FormImageUpload
              label={"Thumbnail image"}
              guidelines={"The hero thumbnail image needs to be:"}
              guidelinesOne={"• At least 800px wide"}
              guidelinesTwo={"• At least 800px tall."}
              guidelinesThree={
                "• To avoid your image being cropped, use a ratio of 1:1"
              }
              error={formError.thumbnail || formError.thumbnailFileType || formError.thumbnailFileSize}
              value={formData.thumbnail}
              handleFileUpload={(thumbnail) =>
                this.handleFormUpdate({ thumbnail })
              }
            />
          )}
        </div>

        <div className={`form__container ${formError.imageGalleryFileType || formError.imageGalleryFileSize
          ? "has-error"
          : ""
          }`}>          <label className="form__header">{"Image gallery"}</label>
          <Checkbox
            checked={showImageGallery}
            label={"Display an additional image gallery?"}
            onCheckboxChange={(showImageGallery) => {
              this.setState({
                showImageGallery,
              });
              if (showImageGallery) {
                this.handleFormUpdate({ imageGallery: imageGalleryHolder });
              } else {
                this.handleFormUpdate({ imageGallery: [] });
              }
            }}
          />
          {showImageGallery && (
            <FormImageGallery
              value={formData.imageGallery}
              error={formError.imageGalleryFileType || formError.imageGalleryFileSize}
              handleAttachments={(imageGallery) => {
                this.setState({
                  imageGalleryHolder: imageGallery,
                });
                this.handleFormUpdate({ imageGallery });
              }}
            />
          )}
        </div>

        {centreStoreList && centreStoreList.length > 0 && (
          <div
            className={`form__container ${promotionIdList.length > 0 ? "is-hidden" : ""
              }`}
          >
            <label className="form__header">{`${type} visibility`}</label>
            {this.buildCentreStoreList(centreStoreList)}
          </div>
        )}
      </div>
    );
  }
}

export default FormPromotions;
