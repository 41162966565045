import React, { Component, Fragment } from "react";
import { getWorkboxItems } from "../../../../api/WorkflowAPI";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Button from "../../../Base/Button";
import Select from "react-select";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "../../../../helper/ScrollHelper";
import { getDuration, getStartDate } from "../../../../helper/DateHelper";
import { addClassLazyload } from "../../../../helper/AddClassLazyloadHelper";
import { scrollToTop } from "../../../../helper/ScrollHelper";
import axios from "axios";
import logoPlaceholder from "../../../../assets/img/logoPlaceholder.png";
import ReactPaginate from "react-paginate";
import FormModal from "../../../Base/FormModal";
import Popup from "../../../Base/Popup";
import ListItem from "../../../Base/ListItem";
import SuccessMessage from "../../../Base/SuccessMessage";
import CustomFilter from "../../../Base/CustomFilter";
import FilterRadioButton from "../../../Base/CustomFilter/FilterRadioButton";
import NoResultMessage from "../../../Base/NoResultMessage";
import PopupPreview from "../../../Base/Popup/PopupPreview";
import CustomIframe from "../../../Base/CustomIframe";
import PopupAction from "../../../Base/Popup/PopupAction";
import { hideBackToTop } from "../../../../helper/HideBackToTopHelper";

class FeatureRetailerPortalApprovals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePageNum: 0,
      totalPageNumber: null,
      totalResults: null,
      showPagination: false,
      showLoader: true,
      showRejectModal: false,
      showApproveModal: false,
      showPreviewModal: false,
      showFilter: false,
      modalMessage: null,
      hideIcon: true,
      workboxItems: null,
      selectedItems: null,
      selectedItem: null,
      typeOptions: null,
      centreOptions: null,
      storeOptions: null,
      filterType: "all",
      filterStore: "all",
      filterCentre: "all",
      isDocumentReady: false,
      previewTitle: null,
      previewUrl: null,
      previewUrlList: [],
      previewItemId: null,
      filteredItems: null,
      itemCount: null,
      itemTotal: null,
      showBulkPreview: false,
      retailerLogo: null,
      rejectMessage: "",
      paginationRange: 7,
      isDesktop: true,
    };
  }

  getData = async (params) => {
    this.setState({
      showLoader: true,
    });

    try {
      const response = await getWorkboxItems(params);
      if (response && response.data) {
        const { WorkboxItems, TotalPageNumber, TotalResults } = response.data;
        this.setState(
          {
            workboxItems: WorkboxItems,
            filteredItems: WorkboxItems,
            totalPageNumber: TotalPageNumber.value,
            totalResults: TotalResults.value,
            itemTotal: TotalResults.value,
            showPagination: true,
            showLoader: false,
          },
          () => {
            this.handleChecked();
            this.getFilters();
            this.handleItems(0);
          }
        );
      }
    } catch (e) {
      console.error(
        ("Get Workbox Items Error: " + e &&
          e.response &&
          e.response.statusText) ||
        ""
      );
    }
  };

  getOptions = (items, value, index, options, all) => {
    if (value && value.indexOf("Store") === 0) {
      value = "Store";
    }
    if (value && !items.includes(value)) {
      items.push(value);
    }
    if (index === this.state.workboxItems.length - 1) {
      options.push({ value: "all", label: all });
      items
        .sort((a, b) => (a > b ? 1 : -1))
        .map((el) => options.push({ value: el, label: el }));
    }
  };

  getFilters = () => {
    const { workboxItems } = this.state;
    let typeItems = [];
    let centreItems = [];
    let storeItems = [];
    let typeOptions = [];
    let centreOptions = [];
    let storeOptions = [];
    if (workboxItems && workboxItems.length) {
      workboxItems.map((el, index) => {
        const { Type, Centre, Store } = el;
        this.getOptions(
          typeItems,
          Type && Type.value,
          index,
          typeOptions,
          "All"
        );
        this.getOptions(
          centreItems,
          Centre && Centre.value,
          index,
          centreOptions,
          "All centres"
        );
        this.getOptions(
          storeItems,
          Store && Store.value,
          index,
          storeOptions,
          "All stores"
        );
      });
      this.setState({ typeOptions, centreOptions, storeOptions });
    }
  };

  componentDidMount() {
    if (window) {
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
    }
    if (document) {
      this.setState({ isDocumentReady: true });
    }
    let params = {
      pageNumber: 1,
    };
    this.getData(params);
  }

  handleResize = () => {
    if (window.outerWidth >= 768) {
      this.setState({
        paginationRange: 7,
      });
    } else {
      this.setState({
        paginationRange: 3,
      });
    }
    if (window.outerWidth >= 992) {
      this.setState({
        isDesktop: true,
      });
    } else {
      this.setState({
        isDesktop: false,
      });
    }
  };

  handleFilter = (filter, id) => {
    this.setState({ [filter]: id, activePageNum: 0 }, () => {
      this.uncheckAll();
      this.handleItems(0);
      addClassLazyload("approvals-item-container");
      scrollToTop();
    });
  };

  handleItems = (selected) => {
    const {
      filterCentre,
      filterStore,
      filterType,
      workboxItems,
      totalPageNumber,
    } = this.state;

    let unfiltered = workboxItems;
    if (unfiltered) {
      if (filterType !== "all") {
        unfiltered = unfiltered.filter(
          (item) =>
            item && item.Type && item.Type.value.indexOf(filterType) === 0
        );
      }
      if (filterCentre !== "all") {
        unfiltered = unfiltered.filter(
          (item) => item && item.Centre && item.Centre.value === filterCentre
        );
      }
      if (filterStore !== "all") {
        unfiltered = unfiltered.filter(
          (item) => item && item.Type && item.Store.value === filterStore
        );
      }
      const currentPage = selected + 1;
      const until = currentPage * 10;
      const from = until - 10;
      const list = unfiltered.slice(from, until);

      const listLength = unfiltered && unfiltered.length;
      let itemCount = from + 1 + " – " + until;
      if (currentPage == totalPageNumber || listLength < 11) {
        itemCount = from + 1 + " – " + listLength;
      }
      if (listLength == 1) {
        itemCount = 1;
      }

      this.setState(
        {
          filteredItems: list,
          itemCount,
          totalPageNumber: listLength ? Math.ceil(listLength / 10) : null,
          itemTotal: listLength || null,
        },
        () => this.handleChecked()
      );
    }
  };

  uncheckAll = () => {
    let checks = document.querySelectorAll(
      ".approvals-item-container input:checked"
    );
    for (let i = 0; i < checks.length; i++) {
      checks[i].checked = false;
    }
    this.setState({ selectedItem: null, selectedItems: null });
  };

  handleSelected = (value, checked) => {
    let items = this.state.selectedItems || [];
    if (checked) {
      items.push(value);
    } else {
      items.map((item, index) =>
        item === value ? items.splice(index, 1) : null
      );
    }
    this.setState({ selectedItems: items });
  };

  handleChecked = () => {
    let items = this.state.selectedItems || [];
    if (items && items.length) {
      let checks = document.querySelectorAll(".approvals-item-container input");
      for (let i = 0; i < checks.length; i++) {
        let isMatch;
        items.map((item) => {
          if (item === checks[i].value) {
            isMatch = true;
          }
        });
        if (isMatch) {
          checks[i].checked = true;
        } else checks[i].checked = false;
      }
    }
  };

  renderRejectModal = () => {
    disableBodyScroll();
    const { selectedItems, selectedItem, rejectMessage } = this.state;
    let itemCount = selectedItems && selectedItems.length;
    let items = selectedItems;
    if (selectedItem && selectedItem.length) {
      itemCount = selectedItem.length;
      items = selectedItem;
    }
    const message =
      "You’re about to reject " +
      itemCount +
      " piece" +
      (itemCount === 1 ? "" : "s") +
      " of content.<br>" +
      "This cannot be undone.";
    return (
      <Popup>
        <PopupAction
          label={{ value: `Are you sure?` }}
          description={{ value: message }}
          icon="trash-2"
          iconBgColor="#d83b42"
          secondaryBtn="Cancel"
          secondaryBtnOnClick={() =>
            this.setState(
              {
                showRejectModal: false,
                selectedItem: null,
                rejectMessage: "",
              },
              () => enableBodyScroll()
            )
          }
          primaryBtn="Reject"
          primaryBtnOnClick={() => this.rejectItems(items)}
          primaryBtnColor="red"
          withRejectMessage={true}
          rejectMessage={rejectMessage}
          onRejectMessageChange={(value) =>
            this.setState({ rejectMessage: value })
          }
        />
      </Popup>
    );
  };

  renderApproveModal = () => {
    const { modalMessage, hideIcon } = this.state;
    return (
      <SuccessMessage
        label={modalMessage}
        hideIcon={hideIcon}
        handleShowSuccess={(showApproveModal) => {
          this.setState({
            showApproveModal,
          });
        }}
      />
    );
  };

  rejectItems = async (items) => {
    const { rejectMessage } = this.state;
    const formData = new FormData();
    formData.append("itemIds", items);
    formData.append("comments", rejectMessage);
    const url = "/api/sitecore/workflowapproval/RejectItems";
    this.setState({
      rejectMessage: "",
    });

    try {
      let response = await axios({
        method: "POST",
        url,
        data: formData,
      });
      if (response && response.data) {
        const { IsSuccess = null, Message = null } = response.data;
        if (IsSuccess && IsSuccess.value) {
          this.setState(
            {
              showRejectModal: false,
              hideIcon: true,
              modalMessage: Message.value,
              showApproveModal: true,
              showLoader: true,
            },
            () => {
              enableBodyScroll();
              this.resetState();
            }
          );
        } else {
          this.setState(
            {
              showRejectModal: false,
              hideIcon: true,
              modalMessage: Message.value,
              showApproveModal: true,
            },
            () => {
              enableBodyScroll();
            }
          );
        }
      } else {
        this.setState(
          {
            showRejectModal: false,
            hideIcon: true,
            modalMessage: "Something went wrong. Please try again.",
            showApproveModal: true,
          },
          () => {
            enableBodyScroll();
          }
        );
      }
      scrollToTop(true);
    } catch (e) {
      this.setState(
        {
          showRejectModal: false,
          hideIcon: true,
          modalMessage: "Something went wrong. Please try again.",
          showApproveModal: true,
        },
        () => {
          enableBodyScroll();
          scrollToTop(true);
        }
      );
    }
  };

  approveItems = async () => {
    const { selectedItems, selectedItem } = this.state;
    let items = selectedItems;
    if (selectedItem && selectedItem.length) {
      items = selectedItem;
    }
    const formData = new FormData();
    formData.append("itemIds", items);

    const url = "/api/sitecore/workflowapproval/ApproveItems";

    try {
      let response = await axios({
        method: "POST",
        url,
        data: formData,
      });
      if (response && response.data) {
        const { IsSuccess = null, Message = null } = response.data;
        if (IsSuccess && IsSuccess.value) {
          this.setState(
            {
              modalMessage: Message.value,
              showApproveModal: true,
              hideIcon: false,
              showLoader: true,
            },
            () => this.resetState()
          );
        } else {
          this.setState({
            modalMessage: Message.value,
            showApproveModal: true,
          });
        }
      } else {
        this.setState({
          modalMessage: "Something went wrong. Please try again.",
          showApproveModal: true,
        });
      }
      scrollToTop(true);
    } catch (e) {
      this.setState({
        modalMessage: "Something went wrong. Please try again.",
        showApproveModal: true,
      },
        () => scrollToTop(true)
      );
    }
  };

  resetState = () => {
    this.setState(
      {
        activePageNum: 0,
        showFilter: false,
        selectedItems: null,
        selectedItem: null,
        typeOptions: null,
        centreOptions: null,
        storeOptions: null,
        filterType: "all",
        filterStore: "all",
        filterCentre: "all",
        modalIcon: true,
        previewTitle: null,
        previewUrl: null,
        previewItemId: null,
        filteredItems: null,
        itemCount: null,
        itemTotal: null,
      },
      () => {
        let params = {
          pageNumber: 1,
        };
        setTimeout(() => this.getData(params), 1000);
      }
    );
  };

  handlePaginationClick = (data) => {
    this.setState(
      {
        activePageNum: data.selected,
      },
      () => scrollToTop()
    );
    this.handleItems(data.selected);
    addClassLazyload("approvals-item-container");
  };

  renderPagination = () => {
    const { totalPageNumber, paginationRange } = this.state;
    return (
      totalPageNumber &&
      totalPageNumber > 1 && (
        <div className="react-paginate-wrapper">
          <ReactPaginate
            previousLabel={
              <div>
                <i className="icon icon-chevron-outline-left" />
                <span>Previous</span>
              </div>
            }
            nextLabel={
              <div>
                <i className="icon icon-chevron-outline-right" />
                <span>Next</span>
              </div>
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPageNumber}
            marginPagesDisplayed={0}
            pageRangeDisplayed={paginationRange}
            forcePage={this.state.activePageNum}
            onPageChange={this.handlePaginationClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      )
    );
  };

  renderPreviewModal = () => {
    const {
      previewTitle: title = null,
      previewUrl: url = null,
      previewItemId: id = null,
      showPreviewModal,
      previewUrlList,
    } = this.state || {};

    if (showPreviewModal) {
      disableBodyScroll();
    }

    hideBackToTop(showPreviewModal);

    return (
      <FormModal
        isPreview
        isDynamicHeader
        showModal={showPreviewModal}
        label={title || ""}
        closeOnClick={() =>
          this.setState(
            {
              showPreviewModal: false,
            },
            () => enableBodyScroll()
          )
        }
        primaryBtn={
          <Fragment>
            <span className="d-none d-lg-block">Approve</span>
            <i className="icon icon-thumbs-up d-block d-lg-none" />
          </Fragment>
        }
        primaryBtnOnClick={
          previewUrlList.length === 0
            ? () => {
              this.setState(
                {
                  selectedItem: [id],
                  showPreviewModal: false,
                },
                () => {
                  enableBodyScroll();
                  this.approveItems();
                }
              );
            }
            : null
        }
        redBtn={
          <Fragment>
            <span className="d-none d-lg-block">Reject</span>
            <i className="icon icon-thumbs-down d-block d-lg-none" />
          </Fragment>
        }
        redBtnOnClick={
          previewUrlList.length === 0
            ? () => {
              this.setState(
                {
                  selectedItem: [id],
                  showPreviewModal: false,
                  showRejectModal: true,
                },
                () => {
                  enableBodyScroll();
                }
              );
            }
            : null
        }
      >
        {showPreviewModal && <CustomIframe title={title || ""} src={url} />}
      </FormModal>
    );
  };

  renderItems = () => {
    const { filteredItems, selectedItems } = this.state;
    return (
      <div className="approvals-item-container">
        {filteredItems && filteredItems.length ? (
          filteredItems.map((item, index) => {
            const {
              ItemId,
              Name,
              Type,
              Centre,
              StartDate,
              EndDate,
              PreviewUrl,
              PreviewUrlList,
              Store,
              Thumbnail,
            } = item;
            let isSelected = false;
            let title = "";
            let url = "";
            let globalStoreList = [];
            if (selectedItems && selectedItems.length) {
              selectedItems.map((selected) =>
                selected === ItemId.value ? (isSelected = true) : null
              );
            }

            if (PreviewUrlList && PreviewUrlList.length > 0) {
              const storeList = PreviewUrlList;
              const storeItem = storeList[0].Storefronts;

              if (storeList.length === 1 && storeItem.length === 1) {
                title =
                  storeItem[0].Store.value + " " + storeList[0].Centre.value;
                url = storeItem[0].PreviewUrl.value;
              } else {
                globalStoreList = PreviewUrlList;
              }
            } else {
              if (Name && Name.value) {
                title = Name.value;
              } else if (Store && Store.value && Centre && Centre.value) {
                title = Store.value + " " + Centre.value;
              }
              if (PreviewUrl && PreviewUrl.value) {
                url = PreviewUrl.value;
              }
            }

            return (
              <ListItem
                key={`itemId-${index}`}
                isSelected={isSelected}
                noActionBtn={true}
                logoSrc={
                  Thumbnail && Thumbnail.value && Thumbnail.value.src
                    ? Thumbnail.value.src
                    : logoPlaceholder
                }
                label={{
                  value: (
                    <Fragment>
                      <Text field={Store} />
                      {Centre && Centre.value && Store && Store.value && " – "}
                      <Text field={Centre} />
                    </Fragment>
                  ),
                }}
                title={{
                  value: (
                    <Fragment>
                      <Text field={Type} />
                      {Type && Type.value && Name && Name.value && " – "}
                      <Text field={Name} />
                    </Fragment>
                  ),
                }}
                description={{
                  value:
                    StartDate && StartDate.value && EndDate && EndDate.value
                      ? getDuration(StartDate.value, EndDate.value)
                      : StartDate && StartDate.value
                        ? getStartDate(StartDate.value)
                        : null,
                }}
                previewBtn={{
                  label:
                    globalStoreList && globalStoreList.length > 0
                      ? "Preview..."
                      : "Preview",
                }}
                previewBtnOnClick={() => {
                  if (globalStoreList && globalStoreList.length > 0) {
                    this.handleBulkPreviewForm(globalStoreList, Thumbnail);
                  } else {
                    this.setState({
                      showPreviewModal: true,
                      previewTitle: title,
                      previewUrl: url,
                      previewUrlList: globalStoreList,
                      previewItemId: ItemId.value,
                    });
                  }
                }}
                btnOne={{
                  icon: "thumbs-up",
                  color: "primary",
                  tooltip: "Approve",
                }}
                btnOneOnClick={() => {
                  this.setState(
                    {
                      selectedItem: [ItemId.value],
                    },
                    () => this.approveItems()
                  );
                }}
                btnTwo={{
                  icon: "thumbs-down",
                  color: "red",
                  tooltip: "Reject",
                }}
                btnTwoOnClick={() =>
                  this.setState({
                    selectedItem: [ItemId.value],
                    showRejectModal: true,
                  })
                }
                checkboxValue={ItemId.value}
                checkboxOnChange={(value, checked) =>
                  this.handleSelected(value, checked)
                }
              />
            );
          })
        ) : (
            <NoResultMessage label="No approvals" />
          )}
      </div>
    );
  };

  handleBulkPreviewForm = (globalStoreList, logo) => {
    this.setState({
      showBulkPreview: true,
      previewUrlList: globalStoreList,
      retailerLogo:
        logo && logo.value && logo.value.src ? logo.value.src : null,
    },
      () => hideBackToTop(true)
    );
  };

  handleHeaderIconClick = (clearItem) => {
    if (clearItem) {
      this.uncheckAll();
    } else {
      const leftNavWrapper = document.querySelector(".left-nav");
      const topNavWrapper = document.querySelector(".top-nav .top-nav-wrapper");

      if (leftNavWrapper && topNavWrapper) {
        leftNavWrapper.classList.add("show-nav-mobile");
        topNavWrapper.classList.add("show-nav-mobile");
      }
    }
  };

  renderBulkPreview = () => {
    const { previewUrlList, retailerLogo } = this.state;
    return (
      <div className="approvals-modal">
        {previewUrlList && previewUrlList.length > 0 && (
          <PopupPreview
            previewTitle={"Preview"}
            previewLogo={retailerLogo}
            previewList={previewUrlList}
            previewListChildName="Storefronts"
            showPreviewModal={() => this.setState({ showBulkPreview: false }, () => hideBackToTop(false))}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      selectedItems,
      showPagination,
      showLoader,
      showRejectModal,
      showApproveModal,
      showFilter,
      isDocumentReady,
      itemTotal,
      totalResults,
      typeOptions,
      centreOptions,
      storeOptions,
      itemCount,
      showBulkPreview,
      isDesktop,
      filterCentre,
      filterStore,
    } = this.state;
    const hasSelection = selectedItems && selectedItems.length;
    return (
      <div className={`approvals-container ${showApproveModal ? "has-success-message" : ""}`}>
        {showBulkPreview && this.renderBulkPreview()}
        <div className="approvals-header">
          <div className="approvals-header-icon">
            <button onClick={() => this.handleHeaderIconClick(hasSelection)}>
              <i className={`icon icon-${hasSelection ? "close" : "menu"}`} />
            </button>
            <div className="h1-regular">
              {hasSelection ? selectedItems.length + " selected" : "Approvals"}
            </div>
          </div>
          <div className="approvals-header-actions">
            {hasSelection ? (
              <Fragment>
                <div
                  className="header-cancel"
                  onClick={() => this.uncheckAll()}
                >
                  Cancel
                </div>
                <Button
                  color="primary"
                  size="small"
                  className="approvals-approve-btn"
                  onClick={() =>
                    this.setState({ selectedItem: null }, () =>
                      this.approveItems()
                    )
                  }
                >
                  <span className="d-none d-lg-block">Approve</span>
                  <i className="icon icon-thumbs-up" />
                </Button>

                <Button
                  color="red"
                  size="small"
                  className="approvals-reject-btn"
                  onClick={() =>
                    this.setState({
                      selectedItem: null,
                      showRejectModal: true,
                    })
                  }
                >
                  <span className="d-none d-lg-block">Reject</span>
                  <i className="icon icon-thumbs-down" />
                </Button>
              </Fragment>
            ) : (
                ""
              )}
            {!showFilter && !showLoader && totalResults && totalResults > 1 && (
              <Button
                color="secondary"
                className="approvals-filter-btn"
                size="small"
                onClick={() => this.setState({ showFilter: true })}
              >
                Filter
              </Button>
            )}
          </div>
        </div>
        {showRejectModal && this.renderRejectModal()}
        {isDocumentReady && this.renderPreviewModal()}
        {isDocumentReady && (
          <CustomFilter
            showFilter={showFilter}
            onCloseFilter={() => this.setState({ showFilter: false })}
          >
            <div className="custom-filter__group">
              <div className="custom-filter__label">Filter by type</div>
              {typeOptions && typeOptions.length ? (
                <FilterRadioButton
                  defaultSelected="all"
                  options={typeOptions}
                  radioGroupName="approvalType"
                  onChange={(id) => this.handleFilter("filterType", id)}
                  plain
                />
              ) : (
                  ""
                )}
            </div>
            <div className="custom-filter__group">
              <div className="custom-filter__label">Filter by centre</div>
              {centreOptions && centreOptions.length ? (
                centreOptions.length < 5 && isDesktop ? (
                  <FilterRadioButton
                    defaultSelected={filterCentre}
                    options={centreOptions}
                    radioGroupName="approvalCentre"
                    onChange={(id) => this.handleFilter("filterCentre", id)}
                    plain
                  />
                ) : (
                    <Select
                      onChange={(option) => {
                        this.handleFilter("filterCentre", option.value);
                      }}
                      defaultValue={
                        filterCentre === "all"
                          ? centreOptions[0]
                          : { value: filterCentre, label: filterCentre }
                      }
                      options={centreOptions}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#536760",
                          primary25: "#f5f5f6",
                        },
                      })}
                      isSearchable={false}
                      maxMenuHeight={200}
                      menuPlacement="auto"
                    />
                  )
              ) : (
                  ""
                )}
            </div>
            <div className="custom-filter__group">
              <div className="custom-filter__label">Filter by store</div>
              {storeOptions && storeOptions.length ? (
                storeOptions.length < 5 && isDesktop ? (
                  <FilterRadioButton
                    defaultSelected={filterStore}
                    options={storeOptions}
                    radioGroupName="approvalStore"
                    onChange={(id) => this.handleFilter("filterStore", id)}
                    plain
                  />
                ) : (
                    <Select
                      onChange={(option) => {
                        this.handleFilter("filterStore", option.value);
                      }}
                      defaultValue={
                        filterStore === "all"
                          ? storeOptions[0]
                          : { value: filterStore, label: filterStore }
                      }
                      options={storeOptions}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#536760",
                          primary25: "#f5f5f6",
                        },
                      })}
                      isSearchable={false}
                      maxMenuHeight={200}
                      menuPlacement="auto"
                    />
                  )
              ) : (
                  ""
                )}
            </div>
          </CustomFilter>
        )}
        {showApproveModal && this.renderApproveModal()}
        {!showLoader && this.renderItems()}
        {!showLoader && itemTotal && itemCount && (
          <div className="list-count">
            Showing {itemCount} of {itemTotal}
          </div>
        )}
        {showLoader && (
          <div className="loader-wrapper container">
            <div className="loader" />
          </div>
        )}
        {showPagination && <div>{this.renderPagination()}</div>}
      </div>
    );
  }
}

export default FeatureRetailerPortalApprovals;
