import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

class FormImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: props.value ? props.value : null,
            preview: props.value ? props.value : null,
            errorMessage: null,
            hasImageUpload: false
        };
    }

    handleFileUpload = () => {
        this.props.handleFileUpload(this.state.file);
    };

    setFile = file => {
        if (file && file.type.indexOf("image") !== -1) {
            this.setState(
                {
                    file,
                    preview: URL.createObjectURL(file),
                },
                this.handleFileUpload
            );

        } else {
            alert("Please select an image");
        }
    };

    removeFile = () => {
        this.setState(
            {
                file: null,
                preview: null
            },
            this.handleFileUpload
        );
    }

    renderGuideLines = (isDesktop) => {
        const {
            guidelines,
            guidelinesOne,
            guidelinesTwo,
            guidelinesThree,
        } = this.props;

        return (
            <div className={`form__image-upload--guidelines ${isDesktop ? "is-desktop" : "is-tablet"}`}>
                <div className="form__image-upload--guidelines-text">
                    <div>
                        {guidelines}
                    </div>
                    {(guidelinesOne || guidelinesTwo || guidelinesThree) &&
                        <br />
                    }
                    <div>
                        {guidelinesOne}
                    </div>
                    <div>
                        {guidelinesTwo}
                    </div>
                    <div>
                        {guidelinesThree}
                    </div>
                    <div>
                        {"• Accepted file types: JPG, PNG, SVG, WEBP, TIFF"}
                    </div>
                    <div>
                        {"• Maximum file size: 5MB"}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {
            preview,
        } = this.state;
        const {
            required,
            label,
            type,
            placeholder,
            error,
            removeImage = false
        } = this.props;
        return (
            <div className="form__container">
                {(label || required) &&
                    <label className="form__header">
                        {label}
                        {required &&
                            <span className="form__asterisk">*</span>
                        }
                    </label>
                }
                <div className={`form-group form__image-upload
                ${type ? type : ""}
                ${error ? "has-error" : ""}
                `}>
                    {this.renderGuideLines()}
                    <div className="form__image-upload--details">
                        {preview &&
                            <div className="form__image-upload--image">
                                <img
                                    src={preview ? preview : placeholder}
                                    alt={type ? type + "image" : label + " upload"}
                                />

                                <label className={`form__image-upload--btn btn-change ${removeImage ? "has-delete-btn" : ""}`}>
                                    {"Change"}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={e => this.setFile(e.target.files[0])}
                                    />
                                </label>
                                {removeImage &&
                                    <button className="form__image-upload--btn btn-delete"
                                        onClick={() => this.removeFile()}>
                                        <i className="icon icon-trash-2" />
                                    </button>
                                }
                            </div>
                        }
                        {!preview &&
                            <div className="form__image-upload--image">
                                <label className="form__image-upload--btn btn-choose">
                                    <span>
                                        {placeholder ? placeholder : "Choose image"}
                                    </span>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={e => this.setFile(e.target.files[0])}
                                    />
                                </label>
                            </div>
                        }
                    </div>
                    {this.renderGuideLines(true)}
                    <div className="form__validation-message">
                        {error}
                    </div>
                </div>
            </div>
        );
    }
}

export default FormImageUpload;
