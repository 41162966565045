import React, { Component } from "react";

class FilterRadioButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
    };
  }

  handleRadioClick = (id, onChange) => {
    if (onChange) {
      onChange(id);
    }

    this.setState({
      selectedId: id,
    });
  };

  render() {
    const {
      defaultSelected = null,
      options = null,
      radioGroupName = null,
      onChange = null,
      disabled = null,
      plain = null,
    } = this.props || {};

    const { selectedId } = this.state;

    return (
      <div>
        {options &&
          options.map((item, index) => {
            const { value, label } = item;
            return (
              <div
                key={`filter-rb-${index}-${label.value}${index}`}
                className={`filter-radiobutton ${plain ? "is-plain" : ""}`}
              >
                <label className={disabled ? "disabled" : ""}>
                  <input
                    type="radio"
                    className={
                      selectedId == value
                        ? "is-active"
                        : !selectedId && defaultSelected === value
                        ? "is-active"
                        : ""
                    }
                    id={value}
                    name={radioGroupName}
                    value={label}
                    onClick={() => this.handleRadioClick(value, onChange)}
                    disabled={disabled}
                  />
                  <span className="filter-radiobutton__checkmark" />
                  <span className="filter-radiobutton__name">{label}</span>
                </label>
              </div>
            );
          })}
      </div>
    );
  }
}

export default FilterRadioButton;
