import React from "react";
import FormModal from "../../../Base/FormModal";
import SuccessMessage from "../../../Base/SuccessMessage";
import CustomIframe from "../../../Base/CustomIframe";
import StoreInfo from "../../../Modules/StoreInfo";
import {
  addParameter
} from "../../../../helper/UrlHelper";
import {
  getStoreInfo,
} from "../../../../api/StoreAPI";
import {
  scrollToTop
} from "../../../../helper/ScrollHelper";
import { hideBackToTop } from "../../../../helper/HideBackToTopHelper";

class FeatureRetailerPortalStores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: null,
      showPreviewModal: false,
      showFormModal: false,
      storeInfo: null,
      modalData: null,
      formModalData: null,
      previewModalData: null,
      showSuccessMessage: false,
      activePageNum: 1,
      isDocumentReady: false,
      isDashboardUpdate: false,
      isMobile: false
    };
  }

  componentDidMount() {
    if (document) {
      this.setState({ isDocumentReady: true }, this.handleResize());
    }
    if (window) {
      window.addEventListener("resize", this.handleResize);
    }
  }

  handleResize = () => {
    if (window.outerWidth >= 768) {
      this.setState({
        isMobile: false
      })
    } else {
      this.setState({
        isMobile: true
      })
    }
  }

  renderContent = () => {
    const {
      isDashboard = false,
      storeList = []
    } = this.props
    const {
      storeInfo,
      selectedFilter
    } = this.state;

    return (
      <StoreInfo
        headerData={this.props.fields}
        data={storeInfo}
        data={(storeList && storeList.length > 0) ? { dashboardStores: storeList } : storeInfo}
        filter={selectedFilter}
        dataUpdate={(storeInfo, activePageNum) =>
          this.setState({ storeInfo, activePageNum })}
        showPreviewModal={previewModalData =>
          this.setState({ showPreviewModal: true, previewModalData })
        }
        showFormModal={formModalData => {
          this.setState({
            showFormModal: true,
            formModalData,
            previewModalData: {
              ...this.state.previewModalData,
              title: formModalData.previewTitle,
              url: formModalData.previewUrl,
            }
          })
        }
        }
        isDashboard={isDashboard}
      />
    );
  };

  renderPreviewModal = () => {
    const {
      title,
      url
    } = this.state.previewModalData || {};

    const {
      previewUrl,
      storeInfoTitle
    } = this.state.formModalData || {}

    const {
      showFormModal,
      showPreviewModal
    } = this.state;

    hideBackToTop(showPreviewModal || showFormModal);
    
    return (
      <FormModal
        isPreview
        showModal={showPreviewModal}
        label={title ? title : storeInfoTitle}
        closeOnClick={() =>
          this.setState({
            showPreviewModal: false
          })}
      >
        {showPreviewModal &&
          <CustomIframe
            title={title ? title : storeInfoTitle}
            src={url ? url : previewUrl}
          />
        }
      </FormModal>
    );
  }

  renderFormModal = () => {
    const {
      updateDashboard
    } = this.props || {};
    const {
      title,
      content,
      previewUrl,
      showSaveHint,
      disableBtn,
      onClickSave,
      onClickSubmit,
      onClickPreview,
      successMessage = "",
    } = this.state.formModalData || {}

    const {
      showFormModal,
      isDashboardUpdate,
      isMobile,
      showPreviewModal
    } = this.state

    if (!showFormModal && isDashboardUpdate && updateDashboard) {
      this.setState({
        isDashboardUpdate: false
      },
        () => updateDashboard(true))
    }

    hideBackToTop(showPreviewModal || showFormModal);

    return (
      <FormModal
        showModal={showFormModal}
        label={title}
        closeOnClick={() =>
          this.setState({
            showFormModal: false
          })
        }
        showSaveHint={showSaveHint}
        saveBtn={"Save draft"}
        saveBtnOnClick={() => {
          onClickSave().then((result) => {
            if (result) {
              if (!updateDashboard) {
                this.getStoreInfoUpdate();
              } else {
                this.setState({
                  isDashboardUpdate: true
                })
              }
            }
          });
        }}
        secondaryBtn={`Preview${Array.isArray(previewUrl) && !isMobile ? "…" : ""}`}
        secondaryBtnOnClick={() => {
          if (Array.isArray(previewUrl)) {
            onClickPreview();
          } else if (previewUrl) {
            this.setState({
              showPreviewModal: true,
            })
          }
        }
        }
        disabledSecondaryBtn={disableBtn}
        primaryBtn={"Submit"}
        primaryBtnClassName={"store-submit-btn"}
        primaryBtnOnClick={() => {
          onClickSubmit().then((result) => {
            if (result) {
              if (!updateDashboard) {
                this.getStoreInfoUpdate();
              }
              this.setState({
                showFormModal: false,
                showSuccessMessage: true,
                formModalData: {
                  ...this.state.formModalData,
                  successMessage
                },
                isDashboardUpdate: updateDashboard ? true : false
              },
                () => scrollToTop()
              )
            }
          });
        }}
        disabledPrimaryBtn={disableBtn}
      >
        {this.state.showFormModal &&
          content
        }
      </FormModal>
    );
  }

  getStoreInfoUpdate = async () => {
    const { activePageNum } = this.state;
    let params = {
      pageNumber: activePageNum
    };
    const url = window.location.href;
    window.history.pushState(null, "Store Info", addParameter(url, "date", Date.now()));
    let response = await getStoreInfo(params);
    if (response && response.status == 200) {
      this.setState({
        storeInfo: response.data
      })
    }
  }

  render() {
    const {
      showSuccessMessage,
      isDocumentReady,
    } = this.state;

    const {
      successMessage,
      previewUrl,
      isRetailer
    } = this.state.formModalData || {};

    return (
      <div className={`stores__container ${showSuccessMessage ? "has-success-message" : ""}`}>
        {isDocumentReady &&
          this.renderPreviewModal()
        }
        {isDocumentReady &&
          this.renderFormModal()
        }
        {showSuccessMessage &&
          <SuccessMessage
            showPreviewLink={previewUrl && !isRetailer}
            label={successMessage}
            handleOnClick={() => this.setState({
              showPreviewModal: true
            })}
            handleShowSuccess={(showSuccessMessage) => {
              this.setState({
                showSuccessMessage
              })
            }}
          />
        }
        <div className="stores__content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}
export default FeatureRetailerPortalStores;
