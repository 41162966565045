import React, { Component } from "react";

class Modal extends Component {
  render() {
    const { type, show } = this.props;
    let modalType = type ? `modal-${type}` : "modal-small";

    return (
      <React.Fragment>
        <div className="modal-bg-blur" />
        <div className="retailer-modal-container">
          <div className={`retailer-modal ${modalType}`}>
            <div className={`header ${modalType}`}>
              <div className="title">{this.props.title}</div>
              {show &&
                <div className="close-button" onClick={() => show()}>
                  <i className="icon icon-cancel" />
                </div>
              }
            </div>
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Modal;
