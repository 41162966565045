import React, { Fragment } from "react";
import NavButtons from "./NavButtons";
import NavLinks from "./NavLinks";

class FeatureRetailerPortalLeftNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { breakpoint: null };
  }

  componentDidMount() {
    const { CentreList } = this.props.fields || {};
    let list = [];
    if (CentreList && CentreList.length) {
      CentreList.map((item) => {
        const { CentreName: centre = null } = item.fields || {};
        if (centre && centre.value) {
          list.push(centre.value);
        }
      });
      sessionStorage.setItem("RPCentreList", list);
    }
    this.setState({ breakpoint: window.outerWidth });
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const { breakpoint } = this.state;
    if (
      (window.outerWidth < 992 && breakpoint < 992) ||
      (window.outerWidth >= 992 && breakpoint >= 992)
    ) {
    } else {
      this.closeNav();
    }
  };

  closeNav = () => {
    const leftNavWrapper = document.querySelector(".left-nav");
    const topNavWrapper = document.querySelector(".top-nav .top-nav-wrapper");
    const backButton = document.querySelector("#nav-mobile-back");
    if (leftNavWrapper && topNavWrapper) {
      leftNavWrapper.classList.remove("show-nav-mobile");
      topNavWrapper.classList.remove("show-nav-mobile");
    }
    if (backButton) {
      backButton.click();
    }
  };
  render() {
    return (
      <Fragment>
        <div className="left-nav-wrapper">
          <NavButtons data={this.props.fields} />
          <NavLinks data={this.props.fields} />
        </div>

        <div className="nav-blur-wrapper">
          <div className="nav-blur" />
        </div>
      </Fragment>
    );
  }
}

export default FeatureRetailerPortalLeftNavigation;
