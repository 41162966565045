import React from "react";
import axios from "axios";
import Loadable from "react-loadable";
import TagManager from "react-gtm-module";
import ImageUpload from "../../../Base/ImageUpload";
import FormFile from "../../../Base/FormFile";
import DropDown from "../../../Base/DropDown";
import FormInput from "../../../Base/FormInput";
import FormInputMultiple from "../../../Base/FormInputMultiple";
import FormDate from "../../../Base/FormDate";
import FormCheckbox from "../../../Base/FormCheckbox";
import Button from "../../../Base/Button";
import Modal from "../../../Base/Modal";
import { get } from "lodash";
import {
  disableBodyScroll,
  enableBodyScroll
} from "../../../../helper/ScrollHelper";
import {
  validate,
  setArticleData,
  buildFormData,
  formDataFields
} from "../../../../helper/ArticleHelper";
import { getUrlParameter } from "../../../../helper/UrlHelper";
import bitmap from "../../../../assets/icons/png/bitmap@3x.png";
import { getCentreStores } from "../../../../api/PromotionAPI";
import FormListbox from "../../../Base/FormListbox";

const RichTextEditor = Loadable({
  loader: () => import("../../../Base/RichTextEditor"),
  loading: () => null
});

class FeatureRetailerPortalArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "new",
      stores: null,
      retailers: null,
      showModal: false,
      modalContent: null,
      formError: {},
      storeList: null,
      centreStoreList: null,
      formData: { ...formDataFields },
      componentReady: false,
      selectAllStores: false,
      holidays: null,
      categories: null,
      specialHolidayListbox: null,
      status: "dirty",
      redirectUrl: "",
      retailerId: ""
    };
  }
  componentDidMount() {
    this.setState({
      centre: getUrlParameter("centre"),
      componentReady: true
    });
    this.getData();
    let retailerList = this.setRetailerList(this.props.fields.RetailerList);
    if (!getUrlParameter("itemId") && retailerList && retailerList.length) {
      this.getCentreStoreList(retailerList[0].value);
    }
  }

  getData = () => {
    const {
      RetailerList,
      Article,
      RedirectUrl,
      GlobalSpecialHoliday,
      Categories
    } = this.props.fields;
    let retailerList = this.setRetailerList(RetailerList);
    let holidayList = this.setHolidayList(GlobalSpecialHoliday);
    let categoryList = this.setCategoryList(Categories);
    if (getUrlParameter("itemId")) {
      let articleData = setArticleData(Article.fields);
      this.setState({
        mode: "edit",
        retailers: retailerList,
        categories: categoryList,
        formData: { ...this.state.formData, ...articleData },
        redirectUrl: RedirectUrl.value,
        holidays: holidayList
      });
    } else {
      this.setState({
        retailers: retailerList,
        categories: categoryList,
        redirectUrl: RedirectUrl && RedirectUrl.value ? RedirectUrl.value : "",
        holidays: holidayList
      });
    }
  };

  setRetailerList = list => {
    let temp = [];
    list.forEach(el =>
      temp.push({
        name: el.fields.RetailerName.value,
        value: el.fields.RetailerId.value
      })
    );
    return temp;
  };

  setHolidayList = list => {
    let temp = [];
    if (list && list.length) {
      list.forEach(el =>
        temp.push({
          name: el.fields.HolidayName.value,
          value: el.fields.HolidayId.value
        })
      );
    }
    return temp;
  };

  setCategoryList = list => {
    let temp = [];
    list.forEach(el =>
      temp.push({
        name: el.fields.CategoryName.value,
        value: el.fields.CategoryId.value
      })
    );
    return temp;
  };

  handleListbox = (id, field) => {
    const { formData } = this.state;
    let options;
    let selected = [];
    if (document) {
      options = document.querySelectorAll(`#${id} option:checked`);
      if (options) {
        for (let i = 0; i < options.length; i++) {
          selected.push(options[i].value);
        }
      }
    }
    this.setState({
      formData: {
        ...formData,
        [field]: selected
      }
    });
  };

  handleSelect = (isSelect, field, options) => {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        [field]: isSelect ? options.map(el => el.value) : []
      }
    });
  };

  handleFormUpdate = value => {
    this.setState({ formData: { ...this.state.formData, ...value } });
  };

  renderModal = () => {
    disableBodyScroll();
    const { mode, status, redirectUrl } = this.state;
    const { title, body, cta } = this.state.modalContent;
    return (
      <Modal
        type="small"
        title={title}
        show={() => {
          if (status === "submitted" || status === "saved") {
            window.location.href = `${redirectUrl}`;
          } else {
            this.setState({ showModal: false, modalContent: null }, () =>
              enableBodyScroll()
            );
          }
        }}
      >
        <div className="modal-body">{body}</div>
        {(mode === "edit" || status === "submitted") && cta && (
          <div className="cta modal-controls-container">
            <Button
              color="primary"
              onClick={() => (window.location.href = cta.url)}
            >
              {cta.label}
            </Button>
          </div>
        )}
        {mode === "new" && status === "saved" && (
          <div className="modal-controls-container">
            <Button
              color="secondary"
              onClick={() => (window.location.href = `${redirectUrl}`)}
            >
              CANCEL
            </Button>
            <Button
              disabled={false}
              color="primary"
              onClick={() => this.submitForm()}
            >
              SUBMIT
            </Button>
          </div>
        )}
        {mode === "new" && status === "dirty" && (
          <div className="modal-controls-container">
            <Button
              color="secondary"
              onClick={() =>
                this.setState({ showModal: false, modalContent: null }, () =>
                  enableBodyScroll()
                )
              }
            >
              CLOSE
            </Button>
          </div>
        )}
      </Modal>
    );
  };

  validateForm = () => {
    let formError = validate(
      this.state.formData,
      this.state.mode === "edit" ? ["selectedRetailer"] : []
    );
    let errorMessage = {
      title: "Form Saving Failed",
      body: "Required fields are missing"
    };
    if (Object.entries(formError).length !== 0) {
      this.setState({ formError, showModal: true, modalContent: errorMessage });
    } else {
      this.saveForm();
    }
  };

  extractItemIds = centreList => {
    let itemIds = [];
    centreList.map(center => {
      center.Articles.map(article => {
        itemIds.push(article.ItemId.value);
      });
    });
    return itemIds;
  };

  buildPreviewUrls = centreList => {
    let preview = "";
    preview = centreList.map(centre => {
      let centrePreview = [];
      centrePreview = centre.Articles.map(article => {
        return (
          <div className="preview-link">
            <i className={`icon icon-external`} />
            <a href={article.PreviewUrl.value} target="_blank">
              {article.Store.value}
            </a>
          </div>
        );
      });
      TagManager.dataLayer({
        dataLayer: {
          event: "createdArticles",
          centreName: centre.Centre.value
        },
        dataLayerName: "dataLayer"
      });
      return (
        <div className="col-3">
          <div className="centre-name">{centre.Centre.value}</div>
          <div className="centre-preview-list">{centrePreview}</div>
        </div>
      );
    });
    return <div className="row">{preview}</div>;
  };

  saveForm = async () => {
    const { formData, mode } = this.state;
    var timezoneId = new Date().getTimezoneOffset();
    const url = "/api/sitecore/article/SaveArticle";
    let data = buildFormData({
      ...formData,
      timeZoneId: timezoneId
    });
    try {
      let response = await axios({
        method: "POST",
        url,
        data,
        params: {
          ...(mode === "edit" ? { itemId: `&itemId=${formData.itemId}` } : "")
        },
        config: { headers: { "Content-Type": "multipart/form-data" } }
      });

      if (response) {
        if (response.data.IsSuccess.value && mode === "new") {
          let successMessage = {
            title: "Article drafts have been created",
            body: this.buildPreviewUrls(response.data.ArticleList)
          };
          const itemIds = this.extractItemIds(response.data.ArticleList);
          this.setState({
            showModal: true,
            modalContent: successMessage,
            formData: { ...formData, itemIds },
            status: "saved"
          });
        } else if (response.data.IsSuccess.value && mode === "edit") {
          let successMessage = {
            title: "Form Saved",
            body: response.data.Message.value
          };
          const article = response.data.ArticleList[0].Articles[0];
          this.setState(
            {
              showModal: true,
              modalContent: successMessage,
              formData: { ...formData, itemIds: [`${article.ItemId.value}`] }
            },
            () => {
              const win = window.open(article.PreviewUrl.value, "_blank");
              if (win != null) {
                win.focus();
              }
            }
          );
        } else {
          let errorMessage = {
            title: "Form Not Saved",
            body: response.data.Message.value
          };

          this.setState({
            showModal: true,
            modalContent: errorMessage
          });
        }
      }
    } catch (e) {
      console.log(e.response.statusText);
    }
  };

  submitForm = async () => {
    const { formData, mode } = this.state;
    const url = "/api/sitecore/article/SubmitArticle";
    let data = buildFormData({
      itemIds: mode === "edit" ? [`${formData.itemId}`] : formData.itemIds
    });

    try {
      let response = await axios({
        method: "POST",
        url,
        data,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      });

      if (response) {
        if (response.data.IsSuccess.value) {
          let successMessage = {
            title: "Form Submitted",
            body: response.data.Message.value,
            cta: {
              label: "Article list",
              url: response.data.RedirectUrl.value
            }
          };
          this.setState({
            showModal: true,
            modalContent: successMessage,
            status: "submitted"
          });
        } else {
          let errorMessage = {
            title: "Form Not Submitted",
            body: response.data.Message.value
          };

          this.setState({
            showModal: true,
            modalContent: errorMessage
          });
        }
      }
    } catch (e) {
      console.log(e.response.statusText);
    }
  };

  getCentreStoreList = async retailerId => {
    let response = await getCentreStores({ retailerId });
    if (response.status === 200 && response.data) {
      this.setState({
        centreStoreList: get(response.data, "CentreStoreList", []),
        retailerId
      });
    } else {
      alert("Getting centre stores data failed");
    }
  };

  handleStoreListCheckbox = value => {
    const { formData, selectAllStores, centreStoreList } = this.state;
    if (value === "0") {
      let allStores = [];
      centreStoreList.map(centre => {
        centre.StoreList.map(store => {
          allStores.push(store.StoreItemId.value);
        });
      });

      this.setState({
        selectAllStores: !selectAllStores,
        formData: {
          ...formData,
          selectedStores: !selectAllStores ? allStores : []
        }
      });
    } else if (formData.selectedStores.indexOf(value) > -1) {
      this.setState({
        formData: {
          ...formData,
          selectedStores: formData.selectedStores.filter(el => el !== value)
        }
      });
    } else {
      this.setState({
        formData: {
          ...formData,
          selectedStores: [...formData.selectedStores, value]
        }
      });
    }
  };

  buildCentreStoreList = param => {
    // const cetnreStoreList = getCentreStoreList
    const { formData, centreStoreList, selectAllStores } = this.state;
    if (centreStoreList && centreStoreList.length) {
      return (
        <div className="form-stores">
          <div className="form-label">Multiple Stores</div>
          <div className="content">
            <div className="row line">
              <div className="col-3">
                <FormCheckbox
                  // key={`store-${store.StoreItemId.value}`}
                  value="0"
                  label="Select All"
                  checked={selectAllStores}
                  onCheckboxChange={value =>
                    this.handleStoreListCheckbox(value)
                  }
                />
              </div>
            </div>
            <div className="row">
              {centreStoreList.map((centre, index) => {
                return (
                  <div className="col-3">
                    <div className="form-label">{centre.CentreName.value}</div>
                    {centre.StoreList.map(store => {
                      return (
                        <FormCheckbox
                          // key={`store-${store.StoreItemId.value}`}
                          value={store.StoreItemId.value}
                          label={store.StoreName.value}
                          // checked={false}
                          checked={
                            formData.selectedStores.indexOf(
                              store.StoreItemId.value
                            ) > -1
                          }
                          // onCheckboxChange={value => this.getCentreStoreList(value)}
                          onCheckboxChange={value =>
                            this.handleStoreListCheckbox(value)
                          }
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const {
      mode,
      formError,
      formData,
      retailers,
      showModal,
      componentReady,
      centreStoreList,
      redirectUrl,
      holidays,
      categories
    } = this.state;

    if (!retailers) {
      return null;
    }

    return (
      <div className="article-container">
        {showModal && this.renderModal()}
        <div className="body-section-title">
          {mode === "new" ? `Create an Article` : `Update an Article`}
        </div>
        {mode === "new" && (
          <div className="form-type-wrapper d-none">
            <DropDown
              theme="light"
              options={retailers}
              error={formError.selectedRetailer}
              defaultSelected={retailers[0].name}
              // defaultSelected="Select Retailer"
              handleSelectionChange={selectedRetailerName => {
                this.getCentreStoreList(
                  retailers.filter(el => el.name === selectedRetailerName)[0]
                    .value
                );
              }}
            />
          </div>
        )}

        {centreStoreList && this.buildCentreStoreList(centreStoreList)}

        <div className="form-images-container">
          <div className="hero-wrapper">
            <div className="form-label">
              Hero Image<span>*</span>
            </div>
            <ImageUpload
              type="hero"
              labelOne="*Images must be a min of "
              labelTwo="1920x1080 px "
              labelThree="(16:9 ratio)"
              placeholder={bitmap}
              error={formError.heroImage}
              value={formData.heroImage}
              handleFileUpload={(heroImage, errorMessage) =>
                this.handleFormUpdate({ heroImage })
              }
            />
            {/* <div className="form-subtext">
              *Please do not use images that contain text
            </div> */}
          </div>
          <div className="thumbnail-wrapper">
            <div className="form-label">Thumbnail Image</div>
            <ImageUpload
              type="thumbnail"
              labelOne="*Images must be a min of "
              labelTwo="800x800 px "
              labelThree="(1:1 ratio)"
              placeholder={bitmap}
              error={formError.thumbnail}
              value={formData.thumbnail}
              handleFileUpload={(thumbnail, errorMessage) =>
                this.handleFormUpdate({ thumbnail })
              }
            />
          </div>
        </div>
        {/* <FormInput
          type="text"
          placeholder="Please add a category tag."
          label="Category Tag"
          value={formData.categoryTag}
          error={formError.categoryTag}
          onInputChange={categoryTag => this.handleFormUpdate({ categoryTag })}
        /> */}

        {categories && categories.length ? (
          <FormListbox
            label="Category"
            id="LB_Cat"
            required
            error={formError.selectedCategories}
            options={categories}
            field="selectedCategories"
            selected={formData.selectedCategories}
            hint="Press [ctrl] + click to select multiple"
            handleListbox={(id, field) => this.handleListbox(id, field)}
            handleSelect={(isSelect, field, options) =>
              this.handleSelect(isSelect, field, options)
            }
          />
        ) : (
            ""
          )}

        {holidays && holidays.length ? (
          <FormListbox
            label="Special Promotions"
            id="LB_SH"
            options={holidays}
            field="selectedSpecialPromotion"
            selected={formData.selectedSpecialPromotion}
            hint="Press [ctrl] + click to select multiple"
            handleListbox={(id, field) => this.handleListbox(id, field)}
            handleSelect={(isSelect, field) =>
              this.handleSelect(isSelect, field, holidays)
            }
          />
        ) : (
            ""
          )}
        <FormInput
          type="text"
          placeholder="Please add an article title."
          label="Article Name"
          value={formData.articleName}
          required
          error={formError.articleName}
          onInputChange={articleName => this.handleFormUpdate({ articleName })}
        />
        <FormInputMultiple
          label="Subheading"
          required
          value={formData.subheading}
          maxLength={120}
          error={formError.subheading}
          onInputChange={subheading => this.handleFormUpdate({ subheading })}
        />

        <div className="form-dates-container">
          <div className="form-dates-wrapper">
            <div className="date-wrapper">
              <div className="form-label">
                Publish Date<span>*</span>
              </div>
              <FormDate
                icon="calendar"
                placeholderText="MM/DD/YYYY"
                dateFormat="MM/dd/yyyy"
                value={formData.publishDate}
                error={formError.publishDate}
                selected={
                  formData.publishDate && new Date(formData.publishDate)
                }
                onChange={publishDate => this.handleFormUpdate({ publishDate })}
              />
            </div>
            <div className="date-wrapper">
              <div className="form-label">End Date</div>
              <FormDate
                icon="calendar"
                placeholderText="MM/DD/YYYY"
                dateFormat="MM/dd/yyyy"
                value={formData.endDate}
                selected={formData.endDate && new Date(formData.endDate)}
                onChange={endDate => this.handleFormUpdate({ endDate })}
              />
            </div>
          </div>
        </div>

        {componentReady && (
          <RichTextEditor
            required
            label={"Body"}
            initialValue={formData.body}
            error={formError.body}
            onInputChange={body => this.handleFormUpdate({ body })}
            retailerId={this.state.retailerId}
          />
        )}

        <div className="form-imagegallery-container">
          <div className="form-label">Image Gallery</div>
          <FormFile
            icon="plus"
            label="Add Image"
            // value={[{ name: "sample.png" }]}
            value={formData.imageGallery}
            handleAttachments={(imageGallery, errorMessage) =>
              this.handleFormUpdate({ imageGallery })
            }
          />
        </div>

        <div className="form-controls-container">
          {mode === "new" && (
            <Button
              color="secondary"
              onClick={() => (window.location.href = `${redirectUrl}`)}
            >
              CANCEL
            </Button>
          )}
          <Button
            size="medium"
            color={mode === "edit" ? "secondary" : "primary"}
            onClick={() => this.validateForm()}
          // onClick={() => this.saveForm()}
          >
            {mode === "edit" ? "SAVE & PREVIEW" : "SAVE"}
          </Button>
          {mode === "edit" && (
            <Button
              disabled={formData.itemId ? false : true}
              color="primary"
              onClick={() => this.submitForm()}
            >
              SUBMIT
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default FeatureRetailerPortalArticles;
