import React, { Component } from "react";
import FormCheckbox from "../FormCheckbox";

class FormListCheckbox extends Component {
    render() {
        const {
            label = null,
            id = null,
            options = null,
            handleListbox = null,
            handleSelect = null,
            field = null,
            selected = null,
            required = null,
            error = null,
            disabled = null,
        } = this.props;
        return (
            <div className={`form-group form__listcheckbox
                ${error ? "has-error" : ""}
            `}>
                <div className="form__listcheckbox--header">
                    {(label || required) &&
                        <label className="form__label">
                            {label}
                            {required &&
                                <span className="form__asterisk">*</span>
                            }
                        </label>
                    }
                    <div className={`form__listcheckbox--action
                        ${disabled ? "disabled" : ""}
                    `}>
                        <div className="form__listcheckbox--action-btn"
                            onClick={() => {
                                if (disabled) {
                                    return
                                }
                                handleSelect(true, field, options)
                            }}
                        >
                            {"Select all"}
                        </div>
                        <div className="form__listcheckbox--action-btn"
                            onClick={() => {
                                if (disabled) {
                                    return
                                }
                                handleSelect(false, field, options)
                            }}
                        >
                            {"Deselect all"}
                        </div>
                    </div>
                </div>
                <div className="form__listcheckbox--field">
                    {options &&
                        options.length > 0 &&
                        <div id={id} className="form__listcheckbox--option">
                            {options.map((item, index) => {
                                const {
                                    name,
                                    value
                                } = item || {}

                                return (
                                    <FormCheckbox
                                        key={id + index}
                                        label={name}
                                        value={value}
                                        onCheckboxChange={() => handleListbox(id, field)}
                                        plain
                                        checked={selected.length > 0 &&
                                            selected.indexOf(
                                                value
                                            ) > -1
                                        }
                                    />
                                )
                            })}
                        </div>
                    }
                </div>

                <div className="form__validation-message">
                    {error}
                </div>
            </div>
        );
    }
}

export default FormListCheckbox;
