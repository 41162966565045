import BaseURL, { DevURL } from "./BaseURL";

const isDev = process.env.REACT_APP_DEV || false;

export const getStorefrontData = params => {
  const endpoint = isDev ? "/GetStorefrontData" : "/store/GetStorefrontData";
   return BaseURL.get(endpoint, { params: { ...params } });

};

export const getCarParkList = params => {
  const endpoint = isDev ? "/GetStorefrontData" : "/store/GetCarParkList";
   return BaseURL.get(endpoint, { params: { ...params } });

};

export const getBookingIcon = params => {
  const endpoint = isDev ? "/GetStorefrontData" : "/store/GetBookingIcons";
   return BaseURL.get(endpoint, { params: { ...params } });

};

export const getCategoryList = params => {
  const endpoint = isDev ? "/GetStorefrontData" : "/store/GetCategoryList";
  return BaseURL.get(endpoint, { params: { ...params } });

};