import BaseURL from "./BaseURL";

const isDev = process.env.REACT_APP_DEV || false;

export const getArticles = (params) => {
  const endpoint = isDev ? "/GetArticles" : "/article/GetArticles";
   return BaseURL.get(endpoint, { params: { ...params}});

};

export const deleteArticle = params => {
  return BaseURL.get("/article/DeleteArticle", { params: { ...params } });
};

export const getArticleData = params => {
   const endpoint = isDev ? "/GetCentreStores" : "/article/GetArticleData";
   return BaseURL.get(endpoint, { params: { ...params}});
};