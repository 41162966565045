import React from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  base64StringtoFile
} from "../../../helper/ImageConversionHelper";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.value ? props.value : null,
      preview: props.value ? props.value : null,
      errorMessage: null,
      showCrop: false,
      croppedImageUrl: null,
      crop: {
        unit: '%',
        width: 50,
        aspect: 16 / 9,
      },
    };
  }

  handleFileUpload = () => {
    this.props.handleFileUpload(this.state.file, this.state.errorMessage);
  };

  removeFile = () => {
    this.setState(
      {
        file: null,
        preview: null
      },
      this.handleFileUpload
    );
  }

  setFile = file => {
    if (file && file.type.indexOf("image") !== -1) {
      this.setState(
        {
          file,
          preview: URL.createObjectURL(file),
          showCrop: false,
          croppedImageUrl: null
        },
        this.handleFileUpload
      );

    } else {
      alert("Please select an image");
    }
  };

  handleCrop = (e, type) => {
    this.setState({
      showCrop: !this.state.showCrop,
      crop: {
        unit: '%',
        width: 80,
        x: 10,
        y: 10,
        aspect: type === "hero" ? 16 / 9 : 1,
      },
    });
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
    this.convertCroptoFile(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop: crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const canvasImage = this.getCroppedImg(
        this.imageRef,
        crop
      );
      const croppedImageUrl = await new Promise((resolve, reject) => {
        canvasImage.toBlob(blob => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = 'cropImage.jpeg';
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        }, 'image/jpeg');
      });

      this.setState({
        croppedImageUrl: croppedImageUrl
      })
    }
  }

  convertCroptoFile(crop) {
    const { file } = this.state;
    let name, lastDot, fileName, fileExtension;
    const canvasImage = this.getCroppedImg(
      this.imageRef,
      crop
    );
    if (canvasImage.width > 0 && canvasImage.height > 0) {
      if (file.name) {
        name = file.name;
      }
      else {
        name = file.split('/').pop().split('?')[0];

      }
      lastDot = name.lastIndexOf('.');
      fileName = name.substring(0, lastDot);
      fileExtension = name.substring(lastDot + 1);
      const imageData64 = canvasImage.toDataURL("image/" + (fileExtension === "jpg" ? "jpeg" : fileExtension));
      const cropFileName = fileName + "." + fileExtension;
      const newCropFile = base64StringtoFile(imageData64, cropFileName);
      this.setState(
        {
          file: newCropFile
        },
        this.handleFileUpload
      );
    }
  }
  getCroppedImg(image, crop) {
    if (document) {
      const canvas = document.createElement('canvas');
      let scaleX, scaleY;
      scaleX = image.naturalWidth / image.width;
      scaleY = image.naturalHeight / image.height;

      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
      return canvas;
    }
  }

  render() {
    const { preview, showCrop, crop, croppedImageUrl } = this.state;
    const {
      type,
      error,
      placeholder,
      labelOne,
      labelTwo,
      labelThree,
      removeImage = false
    } = this.props;
    return (
      <div className={"imageupload-container " + type}>
        <div className={`image-container ${preview ? "" : "default"}`}>
          <img
            src={croppedImageUrl ? croppedImageUrl : preview ? preview : placeholder}
            alt=""
            className={`image ${type ? type : ""} ${error ? "error" : ""} ${preview ? "" : "default"} ${showCrop ? "hide" : ""}`}
          />
          {showCrop &&
            (preview) && (
              <ReactCrop
                src={preview}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                keepSelection={true}
              />
            )}
        </div>
        <div className="imageupload-details">
          <label className="imageupload-btn btn btn-light-primary">
            {preview ? "Change image" : "Upload an image"}
            <input
              type="file"
              accept="image/*"
              onChange={e => this.setFile(e.target.files[0])}
            />
          </label>
          {(removeImage && preview) &&
            <button className="imageupload-btn imageupload-btn--delete btn btn-light-primary"
              onClick={() => this.removeFile()}>
              <i
                className="icon icon-trash"
              />
            </button>
          }
          <button className="imageupload-crop btn btn-light-primary"
            onClick={e => this.handleCrop(e, type)}
            disabled={preview ? false : true}
          >
            <i className={`icon ${showCrop ? "icon-check" : "icon-crop"}`}></i>
          </button>
          <div className="text-container">
            <span>{labelOne}</span>
            <span className="bold">{labelTwo}</span>
            <span>{labelThree}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageUpload;
