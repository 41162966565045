import React, { Component } from "react";
import { Link } from "@sitecore-jss/sitecore-jss-react";
import { getUrlParameter } from "../../../../../helper/UrlHelper";
import { getWorkboxItems } from "../../../../../api/WorkflowAPI";

class NavButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "",
      tab: "",
      type: "",
      NavLinks: props.data.Links,
      newApprovals: null,
      // hideApprovalsCount:
      //   props.data.HideContentApprovalLink &&
      //   props.data.HideContenAtpprovalLink.value,
    };
  }
  componentDidMount() {
    this.setState({
      currentPage: window.location.pathname.toLowerCase(),
      type: getUrlParameter("type"),
    });
    // const { hideApprovalsCount } = this.state;
    // if (!hideApprovalsCount) {
    //   let params = {
    //     pageNumber: 1,
    //     type: "",
    //   };
    //   this.getNewApprovals(params);
    // }
  }

  getNewApprovals = async (params) => {
    const response = await getWorkboxItems(params);
    if (
      response &&
      response.data &&
      response.data.TotalResults &&
      response.data.TotalResults.value
    ) {
      const newApprovals = response.data.TotalResults.value;
      if (newApprovals != 0) {
        this.setState({ newApprovals });
      }
    }
  };

  render() {
    const { HideUserManagementLink, HideContentApprovalLink } = this.props.data;
    const { NavLinks, newApprovals } = this.state;
    return (
      <div className="nav-buttons-wrapper">
        {NavLinks &&
          NavLinks.length &&
          NavLinks.map((link, index) => {
            const { Navigation: Navigation = null } = link.fields;
            if (
              Navigation &&
              Navigation.value &&
              Navigation.value.text &&
              Navigation.value.href
            ) {
              let Nav = Navigation.value.text.toLowerCase();
              if (
                (Nav === "users" &&
                  HideUserManagementLink &&
                  HideUserManagementLink.value) ||
                (Nav === "approvals" &&
                  HideContentApprovalLink &&
                  HideContentApprovalLink.value)
              )
                return null;
              let {
                currentPage: currentPage = null,
                type: type = null,
              } = this.state;
              if (type) {
                type = type.toLowerCase() + "s";
              }
              return (
                <Link
                  field={{
                    ...Navigation.value,
                    href: `${Navigation.value.href}?${
                      Navigation.value.querystring
                        ? Navigation.value.querystring + "&"
                        : ""
                    }sc_mode=normal`,
                  }}
                  key={`NavLink-${index}`}
                >
                  <div
                    className={`nav-button ${
                      (currentPage.indexOf("promotion") !== -1 &&
                        type.indexOf(Nav) !== -1) ||
                      (currentPage.indexOf("store") !== -1 &&
                        Nav.indexOf("store") !== -1) ||
                      currentPage.indexOf(Nav) !== -1
                        ? "current"
                        : ""
                    }`}
                  >
                    <i
                      className={`icon icon-${
                        Nav.indexOf("store") !== -1 ? "stores" : Nav
                      }`}
                    >
                      {/* {Nav === "approvals" && newApprovals && (
                        <div id={`approvals-count`} className="approvals-count"/>
                      )} */}
                    </i>
                    <span>{Nav}</span>
                  </div>
                </Link>
              );
            } else return null;
          })}
      </div>
    );
  }
}
export default NavButtons;
