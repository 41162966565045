import { get } from "lodash";
import { validateImageType, validateImageSize } from "./ImageUploadHelper";

const isEmpty = value => {
  return value ? false : true;
};

const isObject = (value) => typeof value === 'object' && value !== null;


export const validate = (state, removeValidate) => {
  let list = [
    // {
    //   name: "selectedStore",
    //   value: state.selectedStore,
    //   rules: "required"
    // },
    {
      name: "articleName",
      value: state.articleName,
      rules: "required"
    },
    {
      name: "subheading",
      value: state.subheading,
      rules: "required"
    },
    {
      name: "publishDate",
      value: state.publishDate,
      rules: "required"
    },
    {
      name: "body",
      value: state.body,
      rules: "required"
    },
    {
      name: "heroImage",
      value: state.heroImage,
      rules: "required"
    },
    {
      name: "selectedStores",
      value: state.selectedStores,
      rules: "required"
    },
    {
      name: "selectedCategories",
      value: state.selectedCategories,
      rules: "required-array"
    },
    {
      name: "thumbnailFileType",
      value: state.thumbnail,
      rules: "image-file-type"
    },
    {
      name: "heroImageFileType",
      value: state.heroImage,
      rules: "image-file-type"
    },
    {
      name: "imageGalleryFileType",
      value: state.imageGallery,
      rules: "image-gallery-file-type"
    },
    {
      name: "thumbnailFileSize",
      value: state.thumbnail,
      rules: "image-file-size"
    },
    {
      name: "heroImageFileSize",
      value: state.heroImage,
      rules: "image-file-size"
    },
    {
      name: "socialFeedFileSize",
      value: state.socialFeedImage,
      rules: "image-file-size"
    },
    {
      name: "socialStoryFileSize",
      value: state.socialStoryImage,
      rules: "image-file-size"
    },
    {
      name: "imageGalleryFileSize",
      value: state.imageGallery,
      rules: "image-gallery-file-size"
    }
  ];

  let result = {};
  list.forEach(element => {
    if (removeValidate.indexOf(element.name) > -1) {
      return;
    }
    if (element.rules === "required") {
      if (isEmpty(element.value)) {
        result[element.name] = "Required";
      }

      if (element.value && element.value.length && element.value.length < 1) {
        result[element.name] = "Required";
      }
    }

    if (element.rules === "required-richtext") {
      if (isEmpty(element.value)) {
        result[element.name] = "Required";
      }

      if (element.value && element.value.trim().length < 8) {
        result[element.name] = "Required";
      }
    }

    if (element.rules === "required-array") {
      if (element.value && !element.value.length && element.value.length < 1) {
        result[element.name] = "Required";
      }
    }

    if (element.rules === 'image-file-type') {
      if(isObject(element.value)){ 
        const imageValidity = validateImageType(element.value);
          if (imageValidity === false) {
            result[element.name] = "Invalid file type";
          }
      } else {}
    }

    if (element.rules === 'image-file-size') {
      if(isObject(element.value)){ 
      const imageValidity = validateImageSize(element.value);
        if (imageValidity === false) {
          result[element.name] = "Your file is too large. File upload image size limit is 5MB";
        }
    }
  }

  if (element.rules === 'image-gallery-file-type') {
    if (!isEmpty(element.value)) {
      let invalidImages = [];
      element.value.forEach(elem => {
        if(isObject(elem)){
          let imageValidity = validateImageType(elem);
          if(imageValidity === false) {
            invalidImages.push(elem);
          }
        }
      });
      if (invalidImages.length > 0) {
        result[element.name] = "Invalid file type";
      }
    }
  }

  if (element.rules === 'image-gallery-file-size') {
    if (!isEmpty(element.value)) {
      let invalidImages = [];
      element.value.forEach(elem => {
        if(isObject(elem)){
        let imageValidity = validateImageSize(elem);
        if(imageValidity === false) {
          invalidImages.push(elem);
        }
      }
      });
      if (invalidImages.length > 0) {
        result[element.name] =  "A file is too large. File upload image size limit is 5MB";
      }
    }
  }

    //Other Validation Rules
  });
  return result;
};

export const formDataFields = {
  itemId: null,
  selectedStore: null,
  categoryTag: "",
  articleName: "",
  subheading: "",
  body: "",
  publishDate: "",
  endDate: "",
  hidePublishDate: false,
  heroImage: "",
  thumbnail: "",
  imageGalleryChecker: [],
  imageGallery: [],
  selectedStores: [],
  selectedSpecialPromotion: [],
  selectedCategories: [],
  itemIds: []
};

export const setArticleData = data => {
  return {
    itemId: data.ItemId.value,
    heroImage: get(data, "HeroImage.value.src", null),
    thumbnail: get(data, "Thumbnail.value.src", null),
    articleName: data.Title.value,
    subheading: data.Subheading.value,
    body: data.Body.value,
    publishDate: data.PublishDate.value,
    endDate: data.EndDate.value,
    hidePublishDate: data.HidePublishDate.value,
    categoryTag: get(data, "CategoryTag.value", null),
    selectedSpecialPromotion: data.LinkedSpecialHolidays.map(
      el => el.HolidayId.value
    ),
    selectedCategories: data.LinkCategories.map(
      el => el.CategoryId.value
    ),
    imageGalleryChecker: data.ImageGallery.map(el => el.Id.value),
    imageGallery: data.ImageGallery.map(el => {
      return {
        name: el.Name.value,
        id: el.Id.value,
        src: el.Src.value
      };
    })
  };
};

export const buildFormData = postData => {
  const formData = new FormData();
  Object.keys(postData).forEach(function (key) {
    if (key === "itemId") {
      return;
    }

    if (key === "publishDate" || (key === "endDate" && postData[key])) {
      return formData.append(key, new Date(postData[key]).toISOString());
    }

    if (key === "imageGalleryChecker") {
      if (postData["imageGallery"].length > 0) {
        return postData["imageGallery"].map((item) =>
          formData.append(key, item.id ? item.id : null)
        );
      }
      return;
    }

    if (key === "imageGallery") {
      if (postData[key].length > 0) {
        let newimageGallery = postData[key].filter(item => item.size)
        return newimageGallery.map((file) => formData.append(key, file));
      }
      return;
    }

    if (key === "selectedSpecialPromotion" && postData[key].length) {
      return postData[key].map(promotion => formData.append(key, promotion));
    }

    if (key === "selectedCategories" && postData[key].length) {
      return postData[key].map(category => formData.append(key, category));
    }

    if (key === "heroImage" || key === "thumbnail") {
      if (typeof postData[key] !== "string") {
        return formData.append(key, postData[key]);
      }
      return;
    }

    if (key === "selectedStores" && postData[key].length) {
      return postData[key].map(storeId => formData.append(key, storeId));
    }

    if (key === "itemIds" && postData[key].length) {
      return postData[key].map(itemId => formData.append(key, itemId));
    }

    return formData.append(key, postData[key]);
  });

  return formData;
};
