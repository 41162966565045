import React, { Fragment } from "react";
import axios from "axios";
import Button from "../../../Base/Button";
import Input from "../../../Base/Input";
import SuccessMessage from "../../../Base/SuccessMessage";

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPass: "",
      confNewPass: "",
      browser: "",
      formSubmit: false,
      showMiniModal: false,
      hideIcon: null,
      modalMessage: null,
    };
  }

  sendChangePassword = async () => {
    const { newPass, confNewPass } = this.state;
    this.setState({
      formSubmit: true,
    });

    if (newPass === confNewPass) {
      const formData = new FormData();
      const antiForgeryToken = this.props.antiForgeryToken;
      formData.append("password", newPass);

      if(antiForgeryToken) {
        formData.append(antiForgeryToken.name, antiForgeryToken.value);

      }

      const url = "/api/sitecore/account/updatepassword";

      try {
        let response = await axios({
          method: "POST",
          url,
          data: formData,
        });

        if (response) {
          this.setState({
            modalMessage: "Password reset successful.",
            showMiniModal: true,
            hideIcon: false,
          });
        } else {
          this.setState({
            modalMessage: "Password reset failed.",
            showMiniModal: true,
            hideIcon: true,
          });
        }
      } catch (e) {
        this.setState({
          modalMessage: "Password reset failed.",
          showMiniModal: true,
          hideIcon: true,
        });
        console.log(e);
      }
    } else {
      this.setState({
        modalMessage: "Password does not match.",
        showMiniModal: true,
        hideIcon: true,
      });
    }
    this.setState({ formSubmit: false });
  };

  renderMiniModal = () => {
    const { modalMessage, hideIcon } = this.state;
    return (
      <SuccessMessage
        label={modalMessage}
        hideIcon={hideIcon}
        handleShowSuccess={(showMiniModal) => {
          this.setState({
            showMiniModal,
          });
        }}
      />
    );
  };

  render() {
    const { newPass, confNewPass, showMiniModal } = this.state;
    let { show } = this.props;
    return (
      <Fragment>
        {showMiniModal && this.renderMiniModal()}

        <div className="btn btn--tertiary profile-btn d-flex d-lg-none">
          <i className="icon icon-close" onClick={() => show()} />
          <div className="d-flex d-lg-none">
            <Button color="secondary" onClick={() => show()}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={newPass && confNewPass ? false : true}
              onClick={() => this.sendChangePassword()}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="updatepassword-container">
          <i className="icon icon-lock" />
          <div className="form-container clearfix">
            <div className="title">
              <i className="icon icon-lock" />
              Change password
            </div>
            <input type="password" className="fakePasswordField d-none" />
            <div className="subtitle">New password</div>
            <Input
              id="new-pw-1"
              placeholder=" "
              type="password"
              required
              autoComplete="new-password"
              onInputChange={(newPass) => this.setState({ newPass })}
            />
            <div className="subtitle">Confirm password</div>
            <Input
              placeholder=" "
              type="password"
              required
              autoComplete="new-password"
              onInputChange={(confNewPass) => this.setState({ confNewPass })}
            />
            <div className="float-right d-none d-lg-flex">
              <Button color="secondary" onClick={() => show()}>
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={newPass && confNewPass ? false : true}
                onClick={() => this.sendChangePassword()}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UpdatePassword;
