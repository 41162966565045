import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Button from "../../Base/Button";

class PageHeader extends React.Component {
  handleOnClick = () => {
    const leftNavWrapper = document.querySelector(".left-nav");
    const topNavWrapper = document.querySelector(".top-nav .top-nav-wrapper");

    if (leftNavWrapper && topNavWrapper) {
      leftNavWrapper.classList.add("show-nav-mobile");
      topNavWrapper.classList.add("show-nav-mobile");
    }
  };

  render() {
    const {
      title = null,
      primaryBtnLabel = null,
      primaryBtnColor = null,
      primaryBtnOnClick = null,
      primaryBtnClassName = null,
      primaryBtnIcon = null,
      secondaryBtnLabel = null,
      secondaryBtnColor = null,
      secondaryBtnOnClick = null,
      secondaryBtnClassName = null,
    } = this.props || {};
    return (
      <div className="page-header__wrapper">
        <div className="page-header__hamburger">
          <button onClick={this.handleOnClick}>
            <i className="icon icon-menu" />
          </button>
        </div>
        <div className="page-header__title">
          <Text field={title} />
        </div>
        <div className="page-header__button">
          {secondaryBtnLabel && secondaryBtnOnClick && (
            <Button
              onClick={secondaryBtnOnClick}
              size="small"
              color={secondaryBtnColor ? secondaryBtnColor : "secondary"}
              className={secondaryBtnClassName}
            >
              {secondaryBtnLabel}
            </Button>
          )}
          {primaryBtnLabel && primaryBtnOnClick && (
            <Button
              onClick={primaryBtnOnClick}
              size="small"
              color={primaryBtnColor ? primaryBtnColor : "primary"}
              className={primaryBtnClassName}
              icon={primaryBtnIcon}
            >
              {primaryBtnLabel}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default PageHeader;
