import React, { Component, Fragment } from "react";

class CustomIframe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isIframeReady: false,
        };
    }

    render() {
        const {
            title,
            src,
        } = this.props || {};

        const {
            isIframeReady
        } = this.state;
        return (
            <Fragment>
                <div className="custom-iframe">
                    <iframe
                        frameBorder="0"
                        className={`${isIframeReady ? "" : "is-hidden"}`}
                        allowFullScreen=""
                        src={src}
                        title={title}
                        onLoad={() => {
                            this.setState({ isIframeReady: true })
                        }}
                    >
                    </iframe>
                    {!isIframeReady &&
                        <div className="loader-wrapper container">
                            <div className="loader" />
                        </div>
                    }
                </div>
            </Fragment>
        );
    }

};

export default CustomIframe;
