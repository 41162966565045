import React from "react";
import { acknowledgeMemo } from "../../../api/NotificationAPI";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import pdf from "../../../assets/icons/svg/pdf.svg";
import file from "../../../assets/icons/svg/file.svg";
import ListItem from "../../Base/ListItem";
import SuccessMessage from "../../Base/SuccessMessage";
import FormCheckbox from "../../Base/FormCheckbox";
import Button from "../../Base/Button";
import TagManager from "react-gtm-module";
import logoPlaceholder from "../../../assets/img/logoPlaceholder.png";

class MemoItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acknowledged: null,
      checked: null,
      disableBtn: true,
      disabledCb: false,
      message: null,
      showModal: false,
      modalMessage: null,
    };
  }
  componentDidMount() {
    const { Acknowledged = null } = this.props.data || {};
    this.setState({
      acknowledged: Acknowledged && Acknowledged.value,
      checked: Acknowledged && Acknowledged.value,
      message:
        Acknowledged && Acknowledged.value ? "This has been acknowledged." : "",
    });
  }

  renderAttachments = (attachments) => {
    if (attachments && attachments.length) {
      return (
        <div className="attachments-container">
          {attachments.map((item, index) => {
            const { Name, Url, Size } = item;
            const type = this.getFileType(Url);
            return (
              <ListItem
                key={`attachmentMemo-${index}`}
                logoSrc={(type && pdf) || file}
                label={{ value: type }}
                title={Name}
                description={Size}
                btnTwo={{
                  icon: "download",
                  color: "secondary",
                  tooltip: "Download",
                }}
                listItemOnClick={() =>
                  window && Url && Url.value && window.open(Url.value)
                }
                noActionBtn={true}
              />
            );
          })}
        </div>
      );
    } else return null;
  };

  onChange = (val) => {
    this.setState({ checked: val, disableBtn: !val });
  };

  acknowledgeMemo = async (itemId) => {
    this.setState({
      disableBtn: true,
      disabledCb: true,
    });
    try {
      const response = await acknowledgeMemo({ itemId });
      if (response && response.data) {
        const { IsSuccess, message } = response.data;
        if (IsSuccess && IsSuccess.value) {
          this.setState({
            modalMessage: message && message.value,
            message: "This has been acknowledged.",
            acknowledged: true,
            showModal: true,
          });
          TagManager.dataLayer({
            dataLayer: {
              event: "correspondenceAcknowledged",
            },
            dataLayerName: "dataLayer",
          });
        } else {
          this.setState({
            modalMessage: message && message.value,
            disableBtn: false,
            disabledCb: false,
            hideIcon: true,
            showModal: true,
          });
        }
      } else {
        this.setState({
          modalMessage: "Something went wrong. Please try again.",
          disableBtn: false,
          disabledCb: false,
          hideIcon: true,
          showModal: true,
        });
      }
    } catch (e) {
      this.setState({
        modalMessage: "Something went wrong. Please try again.",
        disableBtn: false,
        disabledCb: false,
        hideIcon: true,
        showModal: true,
      });
    }
  };

  renderAcknowledgement = (id, type) => {
    const { hideAcknowledgement } = this.props;
    const {
      acknowledged,
      checked,
      disableBtn,
      disabledCb,
      message,
    } = this.state;
    if (
      !hideAcknowledgement &&
      type &&
      type &&
      type.value &&
      type.value.toLowerCase().indexOf("compliance") > -1
    ) {
      if (message) {
        return (
          <div className="memo-footer">
            <div className="memo-message">{message}</div>
          </div>
        );
      } else {
        return (
          <div className="memo-footer">
            {!acknowledged && (
              <div className="memo-acknowledgment">
                <FormCheckbox
                  plain
                  label="Acknowledge"
                  checked={checked}
                  onCheckboxChange={(val, checked) => this.onChange(checked)}
                  disabled={disabledCb}
                />
                <Button
                  color="primary"
                  onClick={() => this.acknowledgeMemo(id && id.value)}
                  disabled={disableBtn}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        );
      }
    }
  };

  renderMiniModal = () => {
    const { modalMessage, hideIcon } = this.state;

    return (
      <SuccessMessage
        label={modalMessage}
        hideIcon={hideIcon}
        handleShowSuccess={(showModal) => {
          this.setState({ showModal });
        }}
      />
    );
  };

  getFileType = (url) => {
    const file = url && url.value;
    let type = null;

    if (file) {
      const isIB = file.includes("intelligencebank.com");

      if (!isIB) {
        type = file.slice(file.lastIndexOf(".") + 1).toUpperCase();
      }
    }

    return type;
  };

  render() {
    const { ItemId, Title, MemoImage, Content, Attachments, MemoType } =
      this.props.data || {};
    const { showModal } = this.state;
    return (
      <div className="memoitem-container">
        {showModal && this.renderMiniModal()}
        <div className="memo-title">
          <Text field={Title} />
        </div>
        {MemoImage && MemoImage.value && MemoImage.value.src && (
          <div className="memo-img">
            <img
              alt={`${
                MemoImage.value.alt ? MemoImage.value.alt : "Memo-image-alt"
              }`}
              src={logoPlaceholder}
              data-src={MemoImage.value.src}
              className="lazyload"
            />
          </div>
        )}
        {Content && Content.value && (
          <div className="memo-content">
            <Text field={Content} />
          </div>
        )}
        {this.renderAttachments(Attachments)}
        {this.renderAcknowledgement(ItemId, MemoType)}
      </div>
    );
  }
}

export default MemoItem;
