import React, { Component } from "react";

class FormCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false
    };
  }

  onInputChange = checked => {
    this.props.onCheckboxChange(this.props.value, checked);
    this.setState({
      isChecked: checked
    })
  };

  render() {
    const {
      disabled = null,
      icon = null,
      className = null,
      label = null,
      value = "",
      checked = null,
      tile = null,
      plain = null
    } = this.props || {};
    const {
      isChecked
    } = this.state;
    return (
      <React.Fragment>
        <label className={`form-checkbox-container 
        ${disabled ? "disabled" : ""}
        ${tile ? "is-tile" : ""}
        ${isChecked ? "is-checked" : ""}
        ${plain ? "is-plain" : ""}
        `}>
          {icon ? (
            <i className={`icon icon-${icon}`} />
          ) : (
              ""
            )}
          <span className={className ? className : ""}>
            {label}
          </span>
          <input
            value={value}
            onChange={e => this.onInputChange(e.target.checked)}
            type="checkbox"
            checked={checked}
            disabled={disabled}
          />
          <span className="checkmark" />
        </label>
      </React.Fragment>
    );
  }
}

export default FormCheckbox;
