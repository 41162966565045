import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Button from "../../Button";
import Popup from "../../Popup";
import FormModal from "../../FormModal";
import CustomIframe from "../../CustomIframe";
import logoPlaceholder from "../../../../assets/img/logoPlaceholder.png";

class PopupPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewGroupTitle: "",
            previewGroupUrl: "",
            showPreviewGroupModal: false
        };
    }

    render() {
        const {
            previewTitle,
            previewLogo,
            previewList,
            previewListChildName,
            btnLabel,
            btnOnClick,
            showPreviewModal,
            previewFormLabel,
        } = this.props;

        const {
            previewGroupTitle,
            previewGroupUrl,
            showPreviewGroupModal
        } = this.state;

        return (
            <React.Fragment>
                <div className="popup-preview">
                    <Popup show={() => showPreviewModal()} >
                        <div className="preview">
                            <div className="preview__title">
                                {previewTitle ? previewTitle : "Preview"}
                            </div>

                            <div className="preview__group">
                                <div className="preview__logo">
                                    {previewLogo ?
                                        <img
                                            alt="preview-logo-alt"
                                            src={logoPlaceholder}
                                            data-src={previewLogo}
                                            className="lazyload"
                                        />
                                        :
                                        <div className="no-image">
                                        </div>
                                    }
                                </div>
                                <div className="preview__content">
                                    {previewList.map((item, index) => {
                                        const {
                                            Centre,
                                        } = item || {}

                                        let previewListArr = item[previewListChildName];
                                        return (
                                            <div key={`preview-details-${index}`}
                                                className="preview__details">
                                                <div className="preview__list">
                                                    <div className="preview__list--label">
                                                        <Text field={Centre} />
                                                    </div>
                                                </div>
                                                {previewListArr.map((item, index) => {
                                                    const {
                                                        ItemId,
                                                        Store,
                                                        PreviewUrl
                                                    } = item

                                                    let title = Store.value + " " + Centre.value;
                                                    return (
                                                        <div key={`preview-list-${index}`}
                                                            className="preview__list">
                                                            <div className="preview__list--label">
                                                                <Text field={Store} />
                                                            </div>
                                                            <div className="preview__list--link"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        previewGroupTitle: title,
                                                                        previewGroupUrl: PreviewUrl.value,
                                                                        showPreviewGroupModal: true,
                                                                    })
                                                                }}
                                                            >
                                                                {"Preview"}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            {btnOnClick &&
                                <div className="preview__button">
                                    <Button
                                        color="primary"
                                        onClick={btnOnClick}
                                    >
                                        {btnLabel}
                                    </Button>
                                </div>
                            }
                        </div>
                    </Popup>
                    <FormModal
                        isPreview
                        showModal={showPreviewGroupModal}
                        label={previewFormLabel ? previewFormLabel : previewGroupTitle}
                        closeOnClick={() =>
                            this.setState({ showPreviewGroupModal: false })
                        }
                    >
                        {this.state.showPreviewGroupModal &&
                            <CustomIframe
                                title={previewFormLabel ? previewFormLabel : previewGroupTitle}
                                src={previewGroupUrl}
                            />
                        }
                    </FormModal>
                </div>

            </React.Fragment>
        );
    }
}

export default PopupPreview;
