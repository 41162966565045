import React from "react";
import {
  Placeholder,
  VisitorIdentification
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import TagManager from "react-gtm-module";
import "./assets/app.css";
import BackToTop from "./components/Base/BackToTop";
import objectFitImages from "object-fit-images";
import "lazysizes";

class Layout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    TagManager.initialize({ gtmId: "GTM-WN8K6F" });

    if (document) {
      objectFitImages();
    }
  }

  render() {
    const route = this.props.route;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {route.fields &&
              route.fields.PageTitle &&
              route.fields.PageTitle.value
              ? route.fields.PageTitle.value
              : ""}
          </title>
        </Helmet>

        <VisitorIdentification />

        <Placeholder name="jss-rp-main" rendering={route} />

        <BackToTop />
      </React.Fragment>
    );
  }
}

export default Layout;
