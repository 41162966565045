import React from "react";

const CharacterCount = props => {
  const { remaining } = props;
  return (
    <div className="character-count">
      <span>{remaining}</span>
      {" remaining"}
    </div>
  );
};

export default CharacterCount;
