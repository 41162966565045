import React, { Component, Fragment } from "react";

class FilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selected: props.defaultSelected,
    };
  }

  handleToggle = (e) => {
    e.target.focus();
    this.setState({ show: !this.state.show });
  };

  handleBlur = () => {
    if (this.state.show) {
      setTimeout(() => {
        this.setState({ show: false });
      }, 250);
    }
  };

  render() {
    const {
      theme,
      error,
      title,
      icon,
      options,
      placeholder,
      noOptionsMessage,
      addMargin,
    } = this.props;
    const { selected, show } = this.state;
    return (
      <Fragment>
        <div
          className="filter-dropdown-container"
          style={{ marginBottom: show && addMargin ? "15rem" : "0" }}
        >
          {icon ? <i className={`icon icon-${icon}`} /> : ""}
          {title ? <div className="title">{title}</div> : ""}
          <label
            className={`arrow ${theme ? theme : ""} ${error ? "error" : ""}`}
          >
            <div className="dropdown-placeholder">
              <input
                type="button"
                value={selected ? selected : placeholder}
                className="dropdown-btn"
                onClick={(e) => this.handleToggle(e)}
                onBlur={(e) => this.handleBlur(e)}
              />
            </div>
            <i className="icon icon-chevron-down-small" />
            <ul className={`dropdown-list ${show ? "" : "d-none"}`}>
              {options && options.length ? (
                options.map((item, index) => {
                  const { value, label } = item;

                  return (
                    <li
                      key={`optionId${index}`}
                      onClick={() => {
                        this.setState({ selected: label, show: false }, () => {
                          this.props.handleSelectionChange(value);
                        });
                      }}
                    >
                      <span>{label}</span>
                    </li>
                  );
                })
              ) : (
                <li className="empty">
                  {noOptionsMessage ? noOptionsMessage : "No options found"}
                </li>
              )}
            </ul>
          </label>
        </div>
      </Fragment>
    );
  }
}

export default FilterDropdown;
