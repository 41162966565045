import BaseURL from "./BaseURL";


export const getDashboardCorrespondences = params => {
  return BaseURL.get("/dashboard/GetDashboardCorrespondences", { params: { ...params } });
};

export const getDashboardStoreInfos = params => {
  return BaseURL.get("/dashboard/GetDashboardStoreInfos", { params: { ...params } });
};

export const getDashboardEvents = params => {
  return BaseURL.get("/dashboard/GetDashboardEvents", { params: { ...params } });
};

export const getDashboardOffers = params => {
  return BaseURL.get("/dashboard/GetDashboardOffers", { params: { ...params } });
};

export const getDashboardArticles = params => {
  return BaseURL.get("/dashboard/GetDashboardArticles", { params: { ...params } });
};