import React from "react";

const Checkbox = props => {
  const {
    label,
    value,
    checked,
    disabled,
    onCheckboxChange
  } = props || {};
  
  return (
    <React.Fragment>
      <label className={`checkbox-container ${disabled ? "":""}`}>
        {label}
        <input
          value={value ? value : ""}
          onChange={e => onCheckboxChange(e.target.checked)}
          type="checkbox"
          checked={checked}
          disabled={disabled}
        />
        <span className="checkmark" />
      </label>
    </React.Fragment>
  );
};

export default Checkbox;
