import React from "react";
import TagManager from "react-gtm-module";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import BackgroundImage from "../../../../assets/img/rp-bg.png";
import Input from "../../../Base/Input";
import Button from "../../../Base/Button";
import { RecoverPassword } from "../../../../api/RecoverPasswordAPI";
import logoPlaceholder from "../../../../assets/img/logoPlaceholder.png";

class FeatureRetailerPortalUpdatePassword extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  confirmPassword: null,
                  password: null,
                  username: null,
                  token: null,
                  browser: "",
                  formSubmitted: false,
                  errorStatus: false,
                  errorMessage: "",
                  validationMessage: "",
                  showLoader: false
            };
            this.form = React.createRef();
      }

      componentDidMount() {
            this.checkBrowser();
            this.handleServerError();
            this.decodeUsername();
            this.decodeToken();

            if (window) {
                  window.addEventListener("resize", this.handleResize);
            }
            if (document) {
                  this.handleResize();
            }
      }

      handleResize = () => {
            let loginWrapper = "";

            if (document) {
                  loginWrapper = document.querySelector(".login-container");
            }

            if (window.outerWidth >= 992) {
                  loginWrapper.style.height = "auto";
            } else {
                  loginWrapper.style.height = window.innerHeight + "px";
            }
      };

      handleServerError = () => {
            if(this.props.fields.ValidationMessage && this.props.fields.ValidationMessage.value) {
                  this.setState({
                        errorMessage: this.props.fields.ValidationMessage.value,
                        errorStatus: true
                  })
            }
      }
      decodeUsername = () => {
            let params = new URLSearchParams(window.location.search);
            let user = params.get('user');
            if (user) {
                  this.setState({ username: user });
            } else {
                  this.setState({
                        errorStatus: true,
                        errorMessage: "Invalid username"

                  })
            }
      }

      decodeToken = () => {
            let params = new URLSearchParams(window.location.search);
            let tokenVal = params.get('token');
            if(this.props.fields.IsValidUrlToken && !this.props.fields.IsValidUrlToken.value) {
                  this.setState({
                        errorStatus: true,
                        errorMessage: this.props.fields.ValidationMessage.value || "Invalid session"

                  })
            } else {
                  if (tokenVal) {
                        this.setState({ token: tokenVal });
                  } else {
                        this.setState({
                              errorStatus: true,
                              errorMessage: "Invalid session"
      
                        })
                  }
            }
      }

      validateLength = (password) => {
            const validCharacters = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
            if (password.length >= 5 && validCharacters.test(password)) {
                  return true;
            } else {
                  return false;
            }
      }

      validatePassword = (password) => {
            this.setState({ password });
            const isValidLength = this.validateLength(password);
            const matchesConfPassword = (password === this.state.confirmPassword);
            if (!isValidLength || !matchesConfPassword) {
                  if (!isValidLength) {
                        this.setState({ validationMessage: "Password is required to be at least 5 characters in length" })
                  } else if (!matchesConfPassword) {
                        this.setState({ validationMessage: "Passwords do not match" })
                  }
                  return false;
            } else {
                  this.setState({ validationMessage: "" });
                  return true;
            }
      }


      checkBrowser = () => {
            if (
                  navigator &&
                  navigator.userAgent &&
                  navigator.userAgent.toUpperCase().indexOf("CHROME") != -1 &&
                  navigator.vendor &&
                  navigator.vendor.toUpperCase().indexOf("GOOGLE") != -1
            ) {
                  this.setState({ browser: "chrome" });
            } else this.setState({ browser: "other" });
      };

      submitForm = async () => {
            let data = {
                  username: this.state.username,
                  password: this.state.password,
                  token: this.state.token
            };

            if (this.validateLength(this.state.password)) {
                  if (data.password === this.state.confirmPassword) {
                        const antiForgeryToken = this.props.fields.antiForgeryToken;

                        if (antiForgeryToken) {
                              data = { ...data, '__RequestVerificationToken': antiForgeryToken.value }
                        }

                        this.setState({
                              showLoader: true
                        })

                        const response = await RecoverPassword(data);


                        if (response) {
                              this.setState({showLoader: false})
                              if (
                                    response.status === 200 &&
                                    response.data &&
                                    response.data.StatusCode === 200
                              ) {
                                    TagManager.dataLayer({
                                          dataLayer: {
                                                event: "successfulPasswordReset",
                                          },
                                          dataLayerName: "dataLayer",
                                    });
                                    this.setState({
                                          formSubmitted: true
                                    })
                              } else {
                                    this.setState({
                                          errorMessage: "Unable to reset password successfully",
                                          errorStatus: true
                                    })
                              }
                        }
                  }
            } else {
                  this.setState({
                        errorStatus: true,
                        errorMessage: "Chosen password is invalid"
                  })
            }


      };

      onEnterKeyPress = (event) => {
            if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
                  this.submitForm();
            }
      };

      render() {
            const {
                  // TopNavigationLinks = null,
                  SiteLogo = null,
                  SiteLogoClassName = null,
                  UpdatePasswordPageBlurb = null,
                  FooterBlurb = null,
            } = this.props.fields || {};

            let mainLink =
                  SiteLogo &&
                        SiteLogo.fields &&
                        SiteLogo.fields.Link &&
                        SiteLogo.fields.Link.value
                        ? SiteLogo.fields.Link.value
                        : {};
            const logo = (SiteLogoClassName && SiteLogoClassName.value) || "";
            let blurb = UpdatePasswordPageBlurb && UpdatePasswordPageBlurb.value ? UpdatePasswordPageBlurb : "";
            let footerBlurb = FooterBlurb && FooterBlurb.value ? FooterBlurb : "";

            return (
                  <div className="login-container">
                        <img className="bg-image" src={BackgroundImage} alt="Background" />
                        <div className="container">
                              <div className="header-wrapper">
                                    <div className="header-logo">
                                    </div>
                              </div>
                              <div className="form-logo">
                                    {mainLink ? (
                                          <a {...mainLink}>
                                                <img
                                                      alt={"sitecore-logo"}
                                                      src={logoPlaceholder}
                                                      data-src={logo}
                                                      className="lazyload form-logo-image"
                                                />
                                          </a>
                                    ) : (
                                          <img
                                                alt={"sitecore-logo"}
                                                src={logoPlaceholder}
                                                data-src={logo}
                                                className="lazyload form-logo-image"
                                          />
                                    )}
                              </div>
                              <div className="login-wrapper">
                                    <div className="form-blurb">
                                          <RichText field={blurb} />
                                    </div>
                                    <div className="form-wrapper">
                                          {this.state.showLoader ?
                                                (<>
                                                      <div className="loader-wrapper">
                                                            <div className="loader">
                                                            </div>
                                                      </div>
                                                </>) :
                                                (this.state.errorStatus ? (
                                                      <>
                                                            <div className="form-title">{this.state.errorMessage}</div>
                                                            <div className="form-controls-wrapper">
                                                                  <a
                                                                        className="form-forgot-password"
                                                                        href="/login"
                                                                  >
                                                                        Back to Login &gt;
                                                                  </a>
                                                            </div>
                                                      </>)
                                                      :
                                                      ((!this.setState.errorStatus && !this.state.formSubmitted) &&
                                                            <>
                                                                  <div className="form-title">Create your new password</div>
                                                                  <div className="form-group">
                                                                        <Input
                                                                              type="password"
                                                                              placeholder="New Password"
                                                                              onInputChange={(confirmPassword) => this.setState({ confirmPassword })}
                                                                        />
                                                                        <Input
                                                                              type="password"
                                                                              placeholder="New Password"
                                                                              onInputChange={(confirmPassword) => this.setState({ confirmPassword })}
                                                                              style={{ display: "none" }}
                                                                        />
                                                                  </div>
                                                                  <div className="form-group">
                                                                        {this.state.browser === "chrome" && (
                                                                              <Input
                                                                                    type="password"
                                                                                    placeholder="Confirm Password"
                                                                                    onInputChange={(password) => this.validatePassword(password)}
                                                                                    onInputKeyDown={(event) => this.onEnterKeyPress(event)}
                                                                              />
                                                                        )}
                                                                        {this.state.browser === "other" && (
                                                                              <Input
                                                                                    type="password"
                                                                                    placeholder="Confirm Password"
                                                                                    onInputChange={(password) => this.validatePassword(password)}
                                                                                    onInputKeyDown={(event) => this.onEnterKeyPress(event)}
                                                                                    autoComplete="new-password"
                                                                              />
                                                                        )}
                                                                  </div>
                                                                  <div className="form-submit">
                                                                        <Button
                                                                              onClick={() => this.submitForm()}
                                                                              disabled={(!this.state.password && !this.state.confirmPassword) || (this.state.password !== this.state.confirmPassword) || (this.state.password.length < 5)}
                                                                              size="full"
                                                                              type="submit"
                                                                              color="light-green"
                                                                        >
                                                                              Save Password
                                                                        </Button>
                                                                        <div className="form__label" style={{ marginTop: '10px' }}> {this.state.validationMessage}</div>
                                                                  </div>
                                                            </>)
                                                )}
                                          {this.state.formSubmitted && !this.state.errorStatus &&
                                                <>
                                                      <div className="form-title">Password updated successfully</div>
                                                      <div className="form-controls-wrapper">
                                                            <a
                                                                  className="form-forgot-password"
                                                                  href="/login"
                                                            >
                                                                  Back to Login &gt;
                                                            </a>
                                                      </div>
                                                </>
                                          }

                                    </div>
                              </div>
                              {footerBlurb && (
                                    <div className="form-footer">
                                          <RichText field={footerBlurb} />
                                    </div>
                              )}
                        </div>
                  </div>
            );
      }
}

export default FeatureRetailerPortalUpdatePassword;
