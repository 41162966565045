import React, { Component } from "react";
import Button from "../Button";
import { getAncestorElement } from "../../../helper/GetAncestorElementHelper";

class FormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBackToTop: false,
    };
  }

  handleScroll = (event) => {
    const element = event.target;
    const headerContainer = element.querySelector(".form__modal--header");
    const headerSpace = element.querySelector(".form__modal--header-space");

    if (headerSpace && headerContainer) {
      let elemPos = headerSpace.getBoundingClientRect().top;
      if (elemPos < 0) {
        headerContainer.classList.add("is-fixed");
        this.setState({
          showBackToTop: true,
        });
      } else {
        headerContainer.classList.remove("is-fixed");
        this.setState({
          showBackToTop: false,
        });
      }
    }
  };

  handleOnClick = (event) => {
    const element = event.target;
    const parentElem = getAncestorElement(element, "form__modal");
    if (parentElem) {
      const headerSpace = parentElem.querySelector(
        ".form__modal--header-space"
      );
      if (headerSpace) {
        headerSpace.scrollIntoView();
      }
    }
  };

  render() {
    const {
      label = null,
      closeOnClick = null,
      closeClassName = null,
      showModal = null,
      showSaveHint = null,
      saveHint = null,
      saveBtn = null,
      saveBtnClassName = null,
      saveBtnOnClick = null,
      disabledSaveBtn = null,
      secondaryBtn = null,
      secondaryBtnClassName = null,
      secondaryBtnOnClick = null,
      disabledSecondaryBtn = null,
      primaryBtn = null,
      primaryBtnClassName = null,
      primaryBtnOnClick = null,
      disabledPrimaryBtn = null,
      redBtn = null,
      redBtnOnClick = null,
      disabledRedBtn = null,
      isPreview = null,
      isDynamicHeader = null,
    } = this.props;
    const { showBackToTop } = this.state;

    return (
      <div
        className={`form__modal 
            ${showModal ? "show-modal" : ""}
            ${isPreview ? "is-preview" : ""}
            `}
        onScroll={this.handleScroll}
      >
        <div className="form__modal--header">
          <div className="form__modal--close">
            <Button
              icon="close"
              size="small"
              color="tertiary"
              className={closeClassName}
              onClick={closeOnClick}
            />
          </div>
          {label && <div className="form__modal--path">{label}</div>}

          <div className="form__modal--buttons">
            {showSaveHint && (
              <div className="form__modal--buttons-hint">
                <i className="icon icon-upload-cloud" />
                <span>{saveHint ? saveHint : "Saving..."}</span>
              </div>
            )}
            {saveBtnOnClick && (
              <Button
                size="small"
                color="secondary"
                onClick={saveBtnOnClick}
                disabled={disabledSaveBtn}
                className={saveBtnClassName}
              >
                {saveBtn}
              </Button>
            )}
            {secondaryBtnOnClick && (
              <Button
                size="small"
                color="secondary"
                onClick={secondaryBtnOnClick}
                disabled={disabledSecondaryBtn}
                className={secondaryBtnClassName}
              >
                {secondaryBtn}
              </Button>
            )}
            {primaryBtnOnClick && (
              <Button
                size="small"
                color="primary"
                onClick={primaryBtnOnClick}
                disabled={disabledPrimaryBtn}
                className={primaryBtnClassName}
              >
                {primaryBtn}
              </Button>
            )}
            {redBtnOnClick && (
              <Button
                size="small"
                color="red"
                onClick={redBtnOnClick}
                disabled={disabledRedBtn}
              >
                {redBtn}
              </Button>
            )}
          </div>
        </div>

        {isDynamicHeader && label && (
          <div className="form__modal--path">{label}</div>
        )}
        <div
          className={`form__modal--header-space ${
            showSaveHint ? "has-save-hint" : ""
          }`}
        />
        <div className="form__modal--body container">{this.props.children}</div>
        {showBackToTop && showModal && (
          <div className="form__modal--back-to-top">
            <button
              onClick={this.handleOnClick}
              className="form__modal--back-to-top-button"
            >
              <span>Back to top</span>
              <i className="icon icon-chevron-up" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default FormModal;
