import React, { Component, Fragment } from "react";

// Input [{name: value}]

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selected: props.defaultSelected
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.defaultSelected != this.props.defaultSelected) {
      this.setState({
        selected: this.props.defaultSelected
      })
    }
  }

  handleToggle = e => {
    e.target.focus();
    this.setState({ show: !this.state.show });
  };

  handleBlur = () => {
    if (this.state.show) {
      setTimeout(() => {
        this.setState({ show: !this.state.show });
      }, 120);
    }
  };

  render() {
    const { theme, error } = this.props;
    return (
      <Fragment>
        <div className="dropdown-container">
          <label
            className={`arrow ${theme ? theme : ""} ${error ? "error" : ""}`}
          >
            <div className="dropdown-placeholder">
              <input
                type="button"
                value={this.state.selected}
                className="dropdown-btn"
                onClick={e => this.handleToggle(e)}
                // onBlur={e => this.handleBlur(e)}
              />
            </div>
            <i className="icon icon-chevron-down"></i>
            <ul className={`dropdown-list ${this.state.show ? "" : "d-none"}`}>
              {this.props.options && this.props.options.length ? (
                this.props.options.map((item, index) => {
                  return (
                    <li
                      key={`optionId${index}`}
                      onClick={() => {
                        this.setState(
                          { selected: item.name },
                          this.props.handleSelectionChange(item.name)
                        );
                      }}
                    >
                      <span>{item.name}</span>
                    </li>
                  );
                })
              ) : (
                <li className="empty">No store found</li>
              )}
            </ul>
          </label>
        </div>
      </Fragment>
    );
  }
}

export default DropDown;
