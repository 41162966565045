import React, { Component } from "react";
import avatar from "../../../../assets/icons/png/author.png";
import { getUsers, deleteUser } from "../../../../api/UsersAPI";
import ReactPaginate from "react-paginate";
import PageHeader from "../../../Base/PageHeader";
import CustomFilter from "../../../Base/CustomFilter";
import NoResultMessage from "../../../Base/NoResultMessage";
import ListItem from "../../../Base/ListItem";
import RadioButton from "../../../Base/RadioButton";
import Popup from "../../../Base/Popup";
import PopupAction from "../../../Base/Popup/PopupAction";
import SuccessMessage from "../../../Base/SuccessMessage";
import FormModal from "../../../Base/FormModal";
import FeatureRetailerPortalUser from "../User";
import { addClassLazyload } from "../../../../helper/AddClassLazyloadHelper";
import { scrollToTop } from "../../../../helper/ScrollHelper";
import { removeActiveState } from "../../../../helper/RemoveActiveState";
import { hideBackToTop } from "../../../../helper/HideBackToTopHelper";

// import getUsersData from "./getUsersData.json";

class FeatureRetailerPortalUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePageNum: 0,
      totalPageNumber: null,
      totalResults: null,
      showPagination: false,
      showLoader: true,
      showDeleteModal: false,
      showMiniModal: false,
      showFormModal: false,
      disableCancelBtn: false,
      disableDeleteBtn: false,
      users: null,
      userName: null,
      name: null,
      formModalData: null,
      filter: "all",
      showFilter: false,
      modalMessage: null,
      hideIcon: true,
      isDocumentReady: false,
      filteredItems: null,
      itemCount: null,
      itemTotal: null,
      paginationRange: 9,
    };
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("resize", this.handleResize);
    }
    if (document) {
      this.setState({ isDocumentReady: true }, this.handleResize());
    }
    let params = {
      pageNumber: 1,
    };
    this.getData(params);
  }

  handleResize = () => {
    if (window.outerWidth >= 768) {
      this.setState({
        paginationRange: 9,
      });
    } else {
      this.setState({
        paginationRange: 3,
      });
    }
  };

  getData = async (params) => {
    this.setState({
      showLoader: true,
    });

    try {
      const response = await getUsers(params);
      if (response && response.data) {
        const { Users, TotalPageNumber, TotalResults } = response.data;
        this.setState(
          {
            users: Users,
            filteredItems: Users,
            totalPageNumber: TotalPageNumber.value,
            totalResults: TotalResults.value,
            itemTotal: TotalResults.value,
            showPagination: true,
            showLoader: false,
          },
          () => {
            this.handleFilter(0);
          }
        );
      }
    } catch (e) {
      console.error("Get User Data Error: " + e.response.statusText);
      // const response = {
      //   data: getUsersData
      // };
      // if (response && response.data) {
      //   const { Users, TotalPageNumber, TotalResults } = response.data;
      //   this.setState(
      //     {
      //       users: Users,
      //       filteredItems: Users,
      //       totalPageNumber: TotalPageNumber.value,
      //       totalResults: TotalResults.value,
      //       itemTotal: TotalResults.value,
      //       showPagination: true,
      //       showLoader: false,
      //     },
      //     () => {
      //       this.handleFilter(0);
      //     }
      //   );
      // }
    }
  };

  setFilter = (levels, userLevel) => {
    let userList = [
      {
        Id: {
          value: "all",
        },
        Name: {
          value: "All",
        },
        Description: {
          value: null,
        },
      },
    ];

    levels.map((el) => {
      const {
        FilterId: name = null,
        FilterDescription: summary = null,
      } = el.fields;
      if (name && name.value && summary && summary.value) {
        userList.push({
          Id: { value: name.value },
          Name: { value: name.value },
          Description: { value: summary.value },
        });
      }
    });

    if (userLevel && userLevel.value) {
      if (userLevel.value.indexOf("2") > -1) {
        userList = userList.filter(
          (el) => el && el.Id && el.Id.value && el.Id.value.indexOf("1") < 0
        );
      } else if (userLevel.value.indexOf("3") > -1) {
        userList = userList.filter(
          (el) =>
            el &&
            el.Id &&
            el.Id.value &&
            el.Id.value.indexOf("1") < 0 &&
            el.Id.value.indexOf("2") < 0
        );
      }
    }
    return userList;
  };

  renderDelete = () => {
    const { userName, name, disableCancelBtn, disableDeleteBtn } = this.state;
    const message =
      "Are you sure you want to delete the user, " +
      name +
      "? You can’t undo this.";

    return (
      <Popup>
        <PopupAction
          label={{ value: `Delete user?` }}
          description={{ value: message }}
          icon="trash-2"
          iconBgColor="#d83b42"
          secondaryBtn="Cancel"
          disabledSecondaryBtn={disableCancelBtn}
          secondaryBtnOnClick={() => this.setState({ showDeleteModal: false })}
          primaryBtn="Delete user"
          primaryBtnOnClick={() => this.deleteUser(userName)}
          primaryBtnColor="red"
          disabledPrimaryBtn={disableDeleteBtn}
        />
      </Popup>
    );
  };

  renderMiniModal = () => {
    const { modalMessage, hideIcon } = this.state;
    return (
      <SuccessMessage
        label={modalMessage}
        hideIcon={hideIcon}
        handleShowSuccess={(showMiniModal) => {
          this.setState({
            showMiniModal,
          });
        }}
      />
    );
  };

  deleteUser = async (userName) => {
    this.setState({
      disableCancelBtn: true,
      disableDeleteBtn: true,
    });
    try {
      let response = await deleteUser({ userName });
      if (response.status === 200 && response.data) {
        const { IsSuccess = null, Message = null } = response.data;
        if (IsSuccess && IsSuccess.value) {
          this.setState(
            {
              modalMessage: Message.value,
              showDeleteModal: false,
              disableCancelBtn: false,
              disableDeleteBtn: false,
              showMiniModal: true,
              hideIcon: false,
            },
            () => {
              this.resetState();
              scrollToTop();
            }
          );
        } else {
          this.setState(
            {
              modalMessage: Message.value,
              showDeleteModal: false,
              disableCancelBtn: false,
              disableDeleteBtn: false,
              showMiniModal: true,
              hideIcon: true,
            },
            () => scrollToTop()
          );
        }
      } else {
        this.setState(
          {
            modalMessage: "Something went wrong. Please try again.",
            showDeleteModal: false,
            disableCancelBtn: false,
            disableDeleteBtn: false,
            showMiniModal: true,
            hideIcon: true,
          },
          () => scrollToTop()
        );
      }
    } catch (e) {
      this.setState(
        {
          modalMessage: "Something went wrong. Please try again.",
          showDeleteModal: false,
          disableCancelBtn: false,
          disableDeleteBtn: false,
          showMiniModal: true,
          hideIcon: true,
        },
        () => scrollToTop()
      );
    }
  };

  handleFilter = (page) => {
    const { filter, users, totalPageNumber } = this.state;
    if (users && users.length) {
      let unfiltered = users;
      if (filter !== "all") {
        unfiltered = unfiltered.filter(
          (item) => item && item.Permission && item.Permission.value === filter
        );
      }

      const currentPage = page + 1;
      const until = currentPage * 20;
      const from = until - 20;
      const list = unfiltered.slice(from, until);

      const listLength = unfiltered && unfiltered.length;
      let itemCount = from + 1 + " – " + until;
      if (currentPage == totalPageNumber || listLength < 21) {
        itemCount = from + 1 + " – " + listLength;
      }
      if (from + 1 == listLength) {
        itemCount = listLength;
      }

      this.setState({
        filteredItems: list,
        itemCount,
        totalPageNumber: listLength ? Math.ceil(listLength / 20) : null,
        itemTotal: listLength || null,
      });
    }
    addClassLazyload("users-content");
    removeActiveState();
    scrollToTop();
  };

  handlePaginationClick = (data) => {
    this.setState(
      {
        activePageNum: data.selected,
      },
      () => scrollToTop()
    );
    this.handleFilter(data.selected);
    addClassLazyload("users-content");
    removeActiveState();
  };

  renderFormModal = () => {
    const { disableBtn, onClickSave } = this.state.formModalData || {};

    const { showFormModal, userName } = this.state || {};

    hideBackToTop(showFormModal);
    
    return (
      <FormModal
        showModal={showFormModal}
        label={
          <div style={{ whiteSpace: "pre" }}>
            Manage users &nbsp;&nbsp;&nbsp;»&nbsp;&nbsp;&nbsp;{" "}
            {userName ? "Edit" : "New"} user
          </div>
        }
        closeOnClick={() => this.setState({ showFormModal: false })}
        primaryBtn="Save"
        primaryBtnOnClick={() => this.handleUser(onClickSave)}
        disabledPrimaryBtn={disableBtn}
        secondaryBtn="Cancel"
        secondaryBtnOnClick={() => this.setState({ showFormModal: false })}
      >
        {showFormModal && (
          <FeatureRetailerPortalUser
            Username={userName}
            fields={this.props.fields}
            antiForgeryToken={this.props.fields.antiForgeryToken}
            handleFormData={(disableBtn, onClickSave) => {
              this.handleFormUpdate(disableBtn, onClickSave);
            }}
          />
        )}
      </FormModal>
    );
  };

  handleFormUpdate = (disableBtn, onClickSave) => {
    this.setState({
      formModalData: {
        ...this.state.formModalData,
        disableBtn,
        onClickSave,
      },
    });
  };

  handleUser = (onClickUpdate) => {
    onClickUpdate().then((result) => {
      if (result && result.isSuccess) {
        setTimeout(() => {
          this.setState(
            {
              showFormModal: false,
              modalMessage: result.successMessage,
              showMiniModal: true,
              hideIcon: false,
            },
            () => {
              this.resetState();
              scrollToTop();
            }
          );
        }, 2000);
      }
    });
  };

  renderPagination = () => {
    const { totalPageNumber, paginationRange } = this.state;
    return (
      totalPageNumber &&
      totalPageNumber > 1 && (
        <ReactPaginate
          previousLabel={
            <div>
              <i className="icon icon-chevron-outline-left" />
              <span>{"Previous"}</span>
            </div>
          }
          nextLabel={
            <div>
              <i className="icon icon-chevron-outline-right" />
              <span>{"Next"}</span>
            </div>
          }
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPageNumber}
          marginPagesDisplayed={0}
          pageRangeDisplayed={paginationRange}
          onPageChange={this.handlePaginationClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      )
    );
  };

  resetState = () => {
    this.setState(
      {
        filter: "all",
        showLoader: true,
        showFilter: false,
        totalResults: null,
      },
      () => {
        let params = {
          pageNumber: 1,
        };
        setTimeout(() => this.getData(params), 2000);
      }
    );
  };

  renderList = () => {
    const { NoUsersText } = this.props.fields;
    const {
      filteredItems,
      showPagination,
      itemTotal,
      itemCount,
      totalResults,
      totalPageNumber,
    } = this.state;
    return (
      <div className="users-content">
        {filteredItems && filteredItems.length ? (
          <div className="list-wrapper">
            {filteredItems.map((item, index) => {
              const {
                UserName,
                FullName,
                Centres,
                Retailers,
                Permission,
                ProfilePicture,
              } = item;
              return (
                <ListItem
                  key={`itemId-${index}`}
                  logoSrc={
                    ProfilePicture &&
                      ProfilePicture.value &&
                      ProfilePicture.value.src
                      ? ProfilePicture.value.src
                      : avatar
                  }
                  label={Retailers}
                  title={FullName}
                  description={Centres}
                  status={Permission}
                  btnOne={{
                    icon: "trash-2",
                    color: "secondary",
                    tooltip: "Delete",
                  }}
                  btnOneOnClick={() => {
                    this.setState({
                      showDeleteModal: true,
                      userName: UserName && UserName.value,
                      name: FullName && FullName.value,
                    });
                  }}
                  listItemOnClick={(e) => {
                    if (
                      !/btn|icon/.test(e.target.className) ||
                      /js-btn-edit|icon-edit/.test(e.target.className)
                    ) {
                      this.setState({
                        showFormModal: true,
                        userName: UserName && UserName.value,
                      });
                    }
                  }}
                />
              );
            })}
            {itemTotal && itemCount && (
              <div
                className={`list-count ${
                  !(totalPageNumber && totalPageNumber > 1)
                    ? "no-pagination"
                    : ""
                  }`}
              >
                Showing {itemCount} of {itemTotal}
              </div>
            )}
            {showPagination && <div>{this.renderPagination()}</div>}
            <div />
          </div>
        ) : (
            <NoResultMessage
              label={
                totalResults
                  ? "No users found."
                  : (NoUsersText && NoUsersText.value) || "No users found."
              }
            />
          )}
      </div>
    );
  };

  render() {
    const {
      PermissionLevels: levels = null,
      UserLevel: userLevel = null,
    } = this.props.fields;
    const {
      showLoader,
      showDeleteModal,
      isDocumentReady,
      showFilter,
      showMiniModal,
      totalResults,
    } = this.state;
    return (
      <div className={`users-container ${showMiniModal ? "has-success-message" : ""}`}>
        <PageHeader
          title={{ value: "Manage users" }}
          primaryBtnLabel="New user"
          primaryBtnClassName="btn-form-create"
          primaryBtnOnClick={() =>
            this.setState({ showFormModal: true, userName: null })
          }
          secondaryBtnLabel={
            totalResults && totalResults > 1 && levels && levels.length
              ? "Filter"
              : null
          }
          secondaryBtnClassName="filter-btn"
          secondaryBtnOnClick={() => this.setState({ showFilter: true })}
        />
        {showDeleteModal && this.renderDelete()}
        {isDocumentReady && this.renderFormModal()}

        {isDocumentReady && (
          <CustomFilter
            showFilter={showFilter}
            onCloseFilter={() => this.setState({ showFilter: false })}
          >
            <div className="custom-filter__group">
              <div className="custom-filter__label">
                Filter by user permissions
              </div>
              {totalResults && totalResults > 1 && levels && levels.length && (
                <RadioButton
                  defaultSelected="all"
                  options={this.setFilter(levels, userLevel)}
                  radioGroupName="user-management"
                  onChange={(id) =>
                    this.setState({ filter: id }, () => this.handleFilter(0))
                  }
                  plain
                />
              )}
            </div>
          </CustomFilter>
        )}
        {showMiniModal && this.renderMiniModal()}
        {!showLoader && this.renderList()}
        {showLoader && (
          <div className="loader-wrapper container">
            <div className="loader" />
          </div>
        )}
      </div>
    );
  }
}

export default FeatureRetailerPortalUsers;
