import BaseURL, { DevURL } from "./BaseURL";

export const getWorkboxItems = params => {
  return BaseURL.get("/workflowapproval/GetWorkboxItems", {
    params: { ...params }
  });
  // return DevURL.get("/GetWorkboxItems", {
  //   params: { ...params }
  // });
};
