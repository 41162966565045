import React from "react";
import {
  getOffers
} from "../../../api/StoreAPI";
import PageHeader from "../../Base/PageHeader";
import ListItem from "../../Base/ListItem";
import NoResultMessage from "../../Base/NoResultMessage";

// import GetOffers_Response from "./GetOfferBackup.json";

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    const {
      isDashboard
    } = this.props

    if (!isDashboard) {
      let params = {
        centre: ""
      };
      this.getData(params, true);
    } else {
      this.setState({
        showLoader: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      filterCentre,
      isDashboard
    } = this.props

    if (!isDashboard) {
      if ((filterCentre !== prevProps.filterCentre)) {
        let params = {
          centre: filterCentre.toString()
        }
        this.getData(params);
      }
    }
  }

  getData = async (params, isOnLoad) => {
    this.setState({
      showLoader: true
    })

    try {
      const response = await getOffers(params);
      if (response.status === 200 && response.data) {
        this.props.dataUpdate(response.data);
        let totalResultCount = 0;
        Object.keys(response.data).map(item => {
          totalResultCount = totalResultCount + response.data[item].length
        })
        this.props.dataTotalCount(totalResultCount)
        this.setState({
          showLoader: false,
        });
      }
    }
    catch (e) {
      console.error("Get Offers Data Error: " + e.response.statusText);
      // this.props.dataUpdate(GetOffers_Response);
      // let totalResultCount = 0;
      // Object.keys(GetOffers_Response).map(item => {
      //   totalResultCount = totalResultCount + GetOffers_Response[item].length
      // })
      // this.props.dataTotalCount(totalResultCount)
      // this.setState({
      //   showLoader: false,
      // });
    }
  };

  renderItems = (data, filter) => {
    const {
      LivePromotions,
      ScheduledPromotions,
      AwaitingApprovalPromotions,
      DraftPromotions,
      RejectedPromotions,
      EmbargoedPromotions,
      dashboardPromotions
    } = data || {};

    let renderItemsLayout = "";

    if (dashboardPromotions && dashboardPromotions.length > 0) {
      renderItemsLayout =
        this.renderDashboardListItem(dashboardPromotions)
    } else {
      let livePromotionsLayout = "",
        ScheduledPromotionsLayout = "",
        EmbargoedPromotionsLayout = "",
        AwaitingApprovalPromotionsLayout = "",
        DraftPromotionsLayout = "",
        RejectedPromotionsLayout = "";

      if (LivePromotions && LivePromotions.length > 0) {
        livePromotionsLayout =
          this.renderListItem(LivePromotions, "Live", filter)
      }
      if (ScheduledPromotions && ScheduledPromotions.length > 0) {
        ScheduledPromotionsLayout =
          this.renderListItem(ScheduledPromotions, "Scheduled", filter)
      }
      if (AwaitingApprovalPromotions && AwaitingApprovalPromotions.length > 0) {
        AwaitingApprovalPromotionsLayout =
          this.renderListItem(AwaitingApprovalPromotions, "Awaiting approval", filter)
      }
      if (DraftPromotions && DraftPromotions.length > 0) {
        DraftPromotionsLayout =
          this.renderListItem(DraftPromotions, "Draft", filter)
      }
      if (RejectedPromotions && RejectedPromotions.length > 0) {
        RejectedPromotionsLayout =
          this.renderListItem(RejectedPromotions, "Rejected", filter)
      }

      if (EmbargoedPromotions && EmbargoedPromotions.length > 0) {
          EmbargoedPromotionsLayout =
          this.renderListItem(EmbargoedPromotions, "Embargoed", filter)
      }

      if (filter === "allStatus") {
        renderItemsLayout =
          <div>
            {livePromotionsLayout}
            {ScheduledPromotionsLayout}
            {EmbargoedPromotionsLayout}
            {AwaitingApprovalPromotionsLayout}
            {DraftPromotionsLayout}
            {RejectedPromotionsLayout}
          </div>
      }
      else if (filter === "LivePromotions") {
        renderItemsLayout = livePromotionsLayout;
      }
      else if (filter === "ScheduledPromotions") {
        renderItemsLayout = ScheduledPromotionsLayout;
      }
      else if (filter === "AwaitingApprovalPromotions") {
        renderItemsLayout = AwaitingApprovalPromotionsLayout;
      }
      else if (filter === "DraftPromotions") {
        renderItemsLayout = DraftPromotionsLayout;
      }
      else if (filter === "RejectedPromotions") {
        renderItemsLayout = RejectedPromotionsLayout;
      }
      else if (filter === "EmbargoedPromotions") {
        renderItemsLayout = EmbargoedPromotionsLayout;
      }
    }

    return renderItemsLayout;
  }

  renderDashboardListItem = (promotions) => {
    const {
      noSelectedStore
    } = this.props

    return (
      <div className="list__group">
        {promotions.map((item, index) => {
          const {
            ItemId,
            Thumbnail,
            CentreName,
            Name,
            Status,
            PreviewURL
          } = item || {};

          let imageSrc = "";

          if (Thumbnail && Thumbnail.value && Thumbnail.value.src) {
            imageSrc = Thumbnail.value.src;
          }

          return (
            <ListItem
              key={`offers-list-${ItemId + index}`}
              logoSrc={imageSrc}
              label={CentreName}
              title={Name}
              status={Status}
              previewBtn={{ "label": "Preview" }}
              previewBtnOnClick={() => {
                this.props.showPreviewModal({
                  url: PreviewURL.value,
                  title: (Name && Name.value) ? Name.value : "",
                })
              }}
              btnOne={{ "icon": "trash-2", "tooltip": "Delete" }}
              btnOneOnClick={() => {
                this.props.showDeleteModal({
                  id: ItemId.value,
                  name: Name.value,
                  centreName: CentreName.value,
                  status: Status.value,
                })
              }}
              listItemOnClick={(e) => {
                if (noSelectedStore) {
                  if (!(/btn|icon/.test(e.target.className))) {
                    this.props.showPreviewModal({
                      url: PreviewURL.value,
                      title: (Name && Name.value) ? Name.value : "",
                    })
                  }
                } else {
                  this.handleOnClickList(e, ItemId.value)
                }
              }
              }
            />
          )
        })
        }
      </div>
    )
  }

  renderListItem = (promotions, label, filter) => {
    const {
      noSelectedStore
    } = this.props
    return (
      <div className="list__group">
        {filter === "allStatus" &&
          <div className="list__header">
            {label}
          </div>
        }
        {promotions.map((item, index) => {
          const {
            ItemId,
            Thumbnail,
            CentreName,
            Name,
            StartDate,
            EndDate,
            Status,
            PreviewURL,
            OfferHighlight
          } = item || {};

          let imageSrc = "";

          if (Thumbnail && Thumbnail.value && Thumbnail.value.src) {
            imageSrc = Thumbnail.value.src;
          }

          let description = "";

          if (StartDate && StartDate.value || EndDate && EndDate.value) {
            description =
              <span>
                {StartDate.value}
                {EndDate && EndDate.value ? " - " : ""}
                {EndDate.value}
                {OfferHighlight && OfferHighlight.value ? ", " + OfferHighlight.value : ""}
              </span>
          }

          return (
            <ListItem
              key={`offers-list-${ItemId + index}`}
              logoSrc={imageSrc}
              label={CentreName}
              title={Name}
              storeDescription={description}
              status={Status}
              previewBtn={{ "label": "Preview" }}
              previewBtnOnClick={() => {
                this.props.showPreviewModal({
                  url: PreviewURL.value,
                  title: (Name && Name.value) ? Name.value : "",
                })
              }}
              btnOne={{ "icon": "trash-2", "tooltip": "Delete" }}
              btnOneOnClick={() => {
                this.props.showDeleteModal({
                  id: ItemId.value,
                  name: Name.value,
                  centreName: CentreName.value,
                  status: Status.value,
                })
              }}
              listItemOnClick={(e) => {
                if (noSelectedStore) {
                  if (!(/btn|icon/.test(e.target.className))) {
                    this.props.showPreviewModal({
                      url: PreviewURL.value,
                      title: (Name && Name.value) ? Name.value : "",
                    })
                  }
                } else {
                  this.handleOnClickList(e, ItemId.value)
                }
              }
              }
            />
          )
        })
        }
      </div>
    )
  }

  renderListCount = (data, totalListCount, filterStatus) => {
    let promotionCount = 0;
    Object.keys(data).map(item => {
      if (filterStatus !== "allStatus") {
        if (filterStatus === item) {
          promotionCount = data[item].length;
        }
      } else {
        promotionCount = promotionCount + data[item].length;
      }
    })

    return (
      <div>
        {promotionCount > 0 ?
          <div className="list-count no-pagination">
            {"Showing "}
            {promotionCount}
            {" of "}
            {totalListCount}
          </div> :
          <NoResultMessage
            label="No offers"
            description="Create an offer to boost customer engagement with your store!"
          />
        }
      </div>
    )
  }

  handleOnClickList = (e, itemId) => {
    let element = e.target;
    let formModalLabel = "";
    document.activeElement.blur();
    if (e.detail > 1) {
      return;
    }
    if ((/btn-form-create|btn__label|icon-plus/.test(element.className))) {
      if(/btn__label/.test(element.className) && !(/btn-form-create/.test(element.parentElement.className))) {
        return;
      }
      formModalLabel =
        <span>
          {"Website content"}
          &nbsp;&nbsp;&nbsp;
          {"»"}
          &nbsp;&nbsp;&nbsp;
          {"Offers"}
          &nbsp;&nbsp;&nbsp;
          {"»"}
          &nbsp;&nbsp;&nbsp;
          {"New offer"}
        </span>

      this.props.showFormModal({
        title: formModalLabel,
        itemId: "",
        isNewPromotion: true
      })
    }
    else if (!(/btn|icon/.test(element.className)) || (/js-btn-edit|icon-edit/.test(element.className))) {
      formModalLabel =
        <span>
          {"Website content"}
          &nbsp;&nbsp;&nbsp;
        {"»"}
          &nbsp;&nbsp;&nbsp;
        {"Offers"}
          &nbsp;&nbsp;&nbsp;
        {"»"}
          &nbsp;&nbsp;&nbsp;
        {"Update offer"}
        </span>

      this.props.showFormModal({
        title: formModalLabel,
        itemId: itemId,
        isNewPromotion: false
      })
    }
  }

  render() {
    const {
      data,
      showFilter,
      filterStatus,
      isDashboard,
      hideFilter,
      totalListCount
    } = this.props;

    const {
      showLoader,
    } = this.state;

    return (
      <div>
        <div className={`offers ${hideFilter ? "hide-filter" : ""}`}>
          <PageHeader
            title={{ value: "Offers" }}
            primaryBtnLabel={"New offer"}
            primaryBtnClassName="btn-form-create"
            primaryBtnIcon={"plus"}
            primaryBtnOnClick={(e) =>
              this.handleOnClickList(e)}
            secondaryBtnLabel={"Filter"}
            secondaryBtnClassName="filter-btn"
            secondaryBtnOnClick={() => showFilter()}
          />
          {showLoader &&
            <div className="loader-wrapper container">
              <div className="loader">
              </div>
            </div>
          }
          {!showLoader &&
            this.renderItems(data, filterStatus)
          }
          {!isDashboard &&
            !showLoader &&
            data &&
            this.renderListCount(data, totalListCount, filterStatus)
          }
        </div>
      </div>
    );
  }
}

export default Offers;
