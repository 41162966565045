// import { get } from "lodash";
// import { AllHtmlEntities } from "html-entities"
import { AllHtmlEntities as Entities } from "html-entities";

const isEmpty = (value) => {
  return value ? true : false;
};

export const validate = (state, removeValidate) => {
  let list = [
    {
      name: "selectedStore",
      value: state.selectedStore,
      rules: "required",
    },
    {
      name: "promotionName",
      value: state.promotionName,
      rules: "required",
    },
    {
      name: "startDate",
      value: state.startDate,
      rules: "required",
    },
    {
      name: "endDate",
      value: state.endDate,
      rules: "required",
    },
    {
      name: "heroImage",
      value: state.heroImage,
      rules: "required",
    },
  ];

  let result = {};
  list.forEach((element) => {
    if (removeValidate.indexOf(element.name) > -1) {
      return;
    }
    if (element.rules === "required") {
      if (!isEmpty(element.value)) {
        result[element.name] = "Required";
      }
    }

    //Other Validation Rules
  });
  return result;
};

// export const formDataFields = {
//   itemId: null,
//   selectedStore: null,
//   categoryTag: "",
//   promotionName: "",
//   summary: "",
//   startDate: "",
//   endDate: "",
//   location: "",
//   schedule: "",
//   duration: "",
//   capacity: "",
//   price: "",
//   description: "",
//   bookNowLink: "",
//   bookNowLinkCaption: "",
//   selectedCategories: [],
//   heroImage: "",
//   thumbnail: "",
//   imageGalleryChecker: [],
//   imageGallery: []
// };

// export const setPromotionData = data => {
//   return {
//     itemId: data.ItemId.value,
//     heroImage: get(data, "HeroImage.value.src", null),
//     thumbnail: get(data, "Thumbnail.value.src", null),
//     promotionName: data.Name.value,
//     duration: data.Duration.value,
//     capacity: data.Capacity.value,
//     location: data.Location.value,
//     categoryTag: data.CategoryTag.value,
//     summary: data.Summary.value,
//     startDate: data.StartDate.value,
//     endDate: data.EndDate.value,
//     schedule: data.Schedule.value,
//     price: data.Price.value,
//     description: data.Description.value,
//     bookNowLink: data.BookNowLink.value,
//     bookNowLinkCaption: data.BookNowLinkCaption.value,
//     selectedCategories: data.LinkCategories.map(
//       el => el.fields.CategoryId.value
//     ),
//     imageGalleryChecker: data.ImageGallery.map(el => el.fields.Id.value),
//     imageGallery: data.ImageGallery.map(el => {
//       return { name: el.fields.Name.value, id: el.fields.Id.value };
//     })
//   };
// };

export const buildFormData = (postData) => {
  const formData = new FormData();
  const entities = new Entities();
  Object.keys(postData).forEach(function (key) {
    // if ((key === "startDate" || key === "endDate") && postData[key]) {
    //   return formData.append(key, new Date(postData[key]).toISOString());
    // }
    if (key === "RetailerDescription" || key === "StorefrontDescription") {
      formData.append(key, entities.encode(postData[key]));
      return;
    }

    if (key === "ImageGalleryChecker") {
      if (postData["ImageGallery"].length > 0) {
        return postData["ImageGallery"].map((item) =>
          formData.append(key, item.id ? item.id : null)
        );
      }
      return;
    }

    if (key === "ImageGallery") {
      if (postData[key].length > 0) {
        let newimageGallery = postData[key].filter(item => item.size)
        return newimageGallery.map((file) => formData.append(key, file));
      }
      return;
    }

    if (key === "Categories" && postData[key].length) {
      return postData[key].map((category) => formData.append(key, category));
    }
    // if (key === "selectedCategories" && postData[key].length) {
    //   return postData[key].map(category => formData.append(key, category));
    // }
    // if (key === "RetailerDetails") {
    //   let retailerData = {};
    //   Object.keys((postData[key])).forEach(function(rkey) {
    //     const rData = postData[key];
    //     if (rkey === "LogoImage" || rkey === "HeroImage") {
    //       if (typeof rData[rkey] !== "string") {
    //         return formData.append(rkey, rData[rkey]);
    //       }
    //       return;
    //     } else {
    //       retailerData[rkey] = rData[rkey]
    //       // retailerData.push({rData[rkey]});
    //     }
    //     // return formData.append(key, postData[key]);
    //   })
    //   return formData.append(key, retailerData);
    // }
    if (key === "LogoImage" || key === "HeroImage") {
      if (typeof postData[key] !== "string") {
        return formData.append(key, postData[key]);
      }
      return;
    }

    if (key === "StorefrontHeroImage") {
      if (postData[key]) {
        return formData.append(key, postData[key]);
      } else {
        return formData.append("ClearStorefrontHeroImage", true);
      }
    }
    return formData.append(key, postData[key]);
  });

  return formData;
};
