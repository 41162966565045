export const addClassLazyload = (parentClass) => {
    const parentWrapper = document.getElementsByClassName(parentClass)[0];
    let imageList = "";
    if (parentWrapper) {
        imageList = parentWrapper.querySelectorAll("img");
        if (imageList.length > 0) {
            imageList.forEach((el) => {
                el.classList.add('lazyload');
            });
        }
    }
}