import React, { Component, Fragment } from "react";

class FormSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            label = null,
            required = null,
            disabled = null,
            error = null,
            onChange = null,
            options = [],
            defaultValue = null,
            noOptionsMessage = null,
            fullWidth = null,
            placeholder = null,
        } = this.props || {}

        return (
            <Fragment>
                <div className={`form-group form__select
                ${error ? "has-error" : ""}
                ${fullWidth ? "is-full-width" : ""}
                `}>
                    {(label || required) &&
                        <label className="form__label">
                            {label}
                            {required &&
                                <span className="form__asterisk">*</span>
                            }
                        </label>
                    }
                    {(options && options.length > 0) ?
                        <div className={`form__select--details
                        ${disabled ? "disabled" : ""}
                        `}>
                            <select className="form__select--field"
                                onChange={e => onChange(e.target.value)}
                                disabled={disabled}
                                defaultValue={defaultValue}
                            >
                                {!defaultValue &&
                                    <option value={""} hidden>
                                        {placeholder ? placeholder : "Select your option"}
                                    </option>
                                }
                                {options.map((item, index) => {
                                    const {
                                        Id: id = null,
                                        Name: name = null,
                                    } = item.fields || item;
                                    let optionLayout = ""

                                    if (id && id.value && name && name.value) {
                                        optionLayout =
                                            <option value={id.value}
                                                key={id.value + index}
                                            >
                                                {name.value}
                                            </option>
                                    }
                                    return optionLayout;
                                })
                                }
                            </select>
                        </div>
                        : <div className="form__select--no-option">
                            {noOptionsMessage ? noOptionsMessage : "No available option"}
                        </div>
                    }
                    <div className="form__validation-message">
                        {error}
                    </div>
                </div>
            </Fragment >
        );
    }
}

export default FormSelect;
