import React, { Component } from "react";

class RadioButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
        };
    }

    handleRadioClick = (id, onChange) => {
        if (onChange) {
            onChange(id);
        }

        this.setState({
            selectedId: id
        })
    }

    render() {
        const {
            defaultSelected = null,
            options = null,
            radioGroupName = null,
            onChange = null,
            disabled = null,
            plain = null,
        } = this.props || {};

        const {
            selectedId
        } = this.state;

        return (
            <div>
                {options &&
                    options.map((radioList, index) => {
                        const {
                            Id: id = null,
                            Name: name = null,
                            Description: description = null,
                            Selected: selected = null,
                        } = radioList.fields || radioList;

                        return (
                            <div key={`${name.value}-${name.id}-${index}-radiobutton`}
                                className={`form-radiobutton ${plain ? "is-plain" : ""}`}
                            >
                                <label key={`${name.value}-${name.id}-${index}-radiobutton`}
                                    className={disabled ? "disabled" : ""}>
                                    <input
                                        type="radio"
                                        className={selectedId == id.value ? "is-active" :
                                            (!selectedId && ((selected && selected.value === true) || (defaultSelected === id.value))) ? "is-active" : ""}
                                        id={id.value}
                                        name={radioGroupName}
                                        value={name.value}
                                        onClick={() => this.handleRadioClick(id.value, onChange)}
                                        disabled={disabled}
                                    />
                                    <span className="form-radiobutton__checkmark"></span>
                                    <span className="form-radiobutton__name">
                                        {name.value}
                                        {description &&
                                            description.value &&
                                            <div className="form-radiobutton__description">
                                                {description.value}
                                            </div>
                                        }
                                    </span>

                                </label>
                            </div>
                        );
                    })}
            </div>
        )
    }
};

export default RadioButton;