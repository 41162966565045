import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from "../Button";

class FormImageGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageFiles: [],
        };

    }

    componentDidMount() {
        const {
            value
        } = this.props || {}

        if (document) {
            let imageFiles = []
            if (value.length > 0) {
                imageFiles = value
            } else {
                let initialValue = {
                    name: Date.now().toString(),
                    id: Date.now().toString(),
                    src: null,
                    newItem: true
                }
                imageFiles.push(initialValue)
            }
            this.setState({
                imageFiles
            })
        }
    }

    handleAttachments = () => {
        const {
            imageFiles
        } = this.state
        let newImageFiles = imageFiles.filter((el) => {
            return !el.newItem
        })
        this.props.handleAttachments(newImageFiles);
    };

    updateFile(imageFile, itemIndex) {
        const { imageFiles } = this.state;
        let listItem = [];
        imageFiles.map((item, index) => {
            if (index == itemIndex) {
                listItem.push(imageFile)
            } else {
                listItem.push(item);
            }
        })
        let newImageFiles = listItem;
        this.setState({
            imageFiles: newImageFiles
        },
            this.handleAttachments
        );
    }

    removeFile(index) {
        const {
            imageFiles
        } = this.state;
        imageFiles.splice(index, 1);
        this.setState({
            imageFiles,
        },
            this.handleAttachments
        );

        if (imageFiles.length === 0) {
            let initialValue = {
                name: Date.now().toString(),
                id: Date.now().toString(),
                src: null,
                newItem: true
            }
            imageFiles.push(initialValue)
            this.setState({
                imageFiles
            })
        }
    }

    handleAddUpload = () => {
        const {
            imageFiles
        } = this.state;
        let newImageFiles = imageFiles;
        let addNewValue = {
            name: Date.now().toString(),
            id: Date.now().toString(),
            src: null,
            newItem: true
        }

        newImageFiles.push(addNewValue)

        this.setState({
            imageFiles: newImageFiles
        },
            this.handleAttachments)

    }

    handleOnDragEnd = (result) => {
        const {
            imageFiles
        } = this.state;
        if (!result.destination) return;

        const items = Array.from(imageFiles);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        this.setState({
            imageFiles: items
        },
            this.handleAttachments)
    }

    renderGalleryList = (imageGalleryList, placeholder) => {
        return (
            <React.Fragment>
                {imageGalleryList.length > 1 ?
                    <DragDropContext onDragEnd={this.handleOnDragEnd}>
                        <Droppable droppableId="form-image-gallery__drag-drop">
                            {(provided) => (
                                <div className="form-image-gallery__drag-drop" {...provided.droppableProps} ref={provided.innerRef}>
                                    {imageGalleryList.map((item, index) => {
                                        const {
                                            name,
                                            id,
                                            src,
                                            newItem
                                        } = item || {};
                                        let imgSrc = "";
                                        if (newItem) {
                                            imgSrc = ""
                                        }
                                        else if (item.src) {
                                            imgSrc = src
                                        } else {
                                            imgSrc = URL.createObjectURL(item)
                                        }
                                        return (
                                            <div className="form-image-gallery__list--container" key={id ? id : name + index}>
                                                <Draggable key={id ? id : name + index} draggableId={id ? id : name + index} index={index}>
                                                    {(provided, imageItem) => (
                                                        <div className={`form-image-gallery__list ${imageItem.isDragging ? "is-dragging" : ""}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className="form-image-gallery__action">
                                                                <i className="icon icon-move" />
                                                                <i
                                                                    className="icon icon-trash"
                                                                    onClick={() => this.removeFile(index)}
                                                                />
                                                            </div>
                                                            {imgSrc ?
                                                                <div className="form-image-gallery__image">
                                                                    <img alt={`${name}-${index}`}
                                                                        src={imgSrc ? imgSrc : ""}
                                                                    />
                                                                    <label className="image-gallery-btn-change" htmlFor={name + index}>
                                                                        <span>
                                                                            {"Change"}
                                                                        </span>
                                                                        <input
                                                                            id={name + index}
                                                                            type="file"
                                                                            accept="image/png,image/jpeg"
                                                                            onChange={e => this.updateFile(e.target.files[0], index)}
                                                                        />
                                                                    </label>
                                                                </div>
                                                                :
                                                                < div className="form-image-gallery__image">
                                                                    <label className="form-image-gallery__upload btn-choose">
                                                                        <span>
                                                                            {placeholder ? placeholder : "Choose image"}
                                                                        </span>
                                                                        <input
                                                                            type="file"
                                                                            accept="image/*"
                                                                            onChange={e => this.updateFile(e.target.files[0], index)}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            }
                                                        </div>
                                                    )}
                                                </Draggable>
                                            </div>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext > :
                    imageGalleryList.map((item, index) => {
                        const {
                            name,
                            id,
                            src,
                            newItem
                        } = item || {};
                        let imgSrc = "";
                        if (newItem) {
                            imgSrc = ""
                        }
                        else if (item.src) {
                            imgSrc = src
                        } else {
                            imgSrc = URL.createObjectURL(item)
                        }
                        return (
                            <div className="form-image-gallery__list" key={id ? id : name + index}>
                                <div className="form-image-gallery__action">
                                    <i
                                        className="icon icon-trash"
                                        onClick={() => this.removeFile(index)}
                                    />
                                </div>
                                {imgSrc ?
                                    <div className="form-image-gallery__image">
                                        <img alt={`${name}-${index}`}
                                            src={imgSrc ? imgSrc : ""}
                                        />
                                        <label className="image-gallery-btn-change" htmlFor={name + index}>
                                            <span>
                                                {"Change"}
                                            </span>
                                            <input
                                                id={name + index}
                                                type="file"
                                                accept="image/png,image/jpeg"
                                                onChange={e => this.updateFile(e.target.files[0], index)}
                                            />
                                        </label>
                                    </div>
                                    :
                                    < div className="form-image-gallery__image">
                                        <label className="form-image-gallery__upload btn-choose">
                                            <span>
                                                {placeholder ? placeholder : "Choose image"}
                                            </span>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={e => this.updateFile(e.target.files[0], index)}
                                            />
                                        </label>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </React.Fragment>
        )
    }

    renderGuidelines = (isDesktop) => {
        return (
            <div className={`form-image-gallery__guidelines ${isDesktop ? "is-desktop" : "is-tablet"}`}>
                <div className="form-image-gallery__guidelines-text">
                    <div>
                        {"To keep the quality of our websites high, the hero banner image needs to be:"}
                    </div>
                    <br />
                    <div>
                        {"• At least 1920px wide"}
                    </div>
                    <div>
                        {"• At least 1080px tall."}
                    </div>
                    <div>
                        {"• To avoid your image being cropped, use a ratio of 16:9"}
                    </div>
                    <div>
                        {"• Accepted file types: JPG, PNG, SVG, WEBP, TIFF"}
                    </div>
                    <div>
                        {"• Maximum file size: 5MB"}
                    </div>
                </div>
            </div >
        )
    }
    render() {
        const {
            imageFiles
        } = this.state;

        const {
            placeholder,
            error
        } = this.props;

        return (
            <div className={`form-group form-image-gallery
            ${error ? "has-error" : ""} `}>
                {this.renderGuidelines()}
                <div className="form-image-gallery__content">
                    {imageFiles &&
                        imageFiles.length > 0 &&
                        this.renderGalleryList(imageFiles, placeholder)
                    }
                    <div className="form-image-gallery__button">
                        <Button
                            color="tertiary-grey"
                            size="full"
                            onClick={this.handleAddUpload}
                        >
                            {"Add image"}
                        </Button>
                    </div>
                    <div className="form__validation-message">
                        {error}
                    </div>
                </div>
                {this.renderGuidelines(true)}
            </div>
        );
    }
}

export default FormImageGallery;
