import React, { Component, Fragment } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import TagManager from "react-gtm-module";
import axios from "axios";
import { getNavRetailerList } from "../../../../../api/NavigationAPI";
import logoPlaceholder from "../../../../../assets/img/logoPlaceholder.png";

class NavRetailer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRetailer: null,
      retailersList: [],
      newNotif: null,
      show: false,
      removeShowAll: false,
      breakpoint: null,
    };
  }

  componentDidMount() {
    this.getRetailerList();
    this.setState({ breakpoint: window.outerWidth });
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }
  componentDidUpdate() {
    this.handleDropdown();
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleResize = () => {
    const { breakpoint } = this.state;
    if (
      (window.outerWidth < 992 && breakpoint < 992) ||
      (window.outerWidth >= 992 && breakpoint >= 992)
    ) {
    } else {
      this.handleBlur();
    }

    if (window && window.outerWidth >= 992) {
      document.addEventListener("mousedown", this.handleClick, false);
    } else {
      document.removeEventListener("mousedown", this.handleClick, false);
    }
  };

  handleDropdown = () => {
    if (window && window.outerWidth >= 992) {
      const { retailersList, removeShowAll } = this.state;
      let sum = 0;
      if (
        retailersList &&
        retailersList.length &&
        retailersList.length > 1 &&
        document
      ) {
        const showAll = retailersList.length > 3 ? true : false;
        const initialList = document.querySelectorAll(
          ".nav-retailers-wrapper li"
        );
        if (showAll) {
          sum =
            initialList[0].offsetHeight +
            initialList[1].offsetHeight +
            initialList[2].offsetHeight;
        }
        document.querySelector(
          ".nav-retailers-wrapper .dropdown-list .options"
        ).style.maxHeight = showAll
          ? removeShowAll
            ? "22rem"
            : sum + "px"
          : "fit-content";
      }
    } else {
      const nav = document.querySelector(
        ".nav-retailers-wrapper .dropdown-list .options"
      );
      if (nav) {
        nav.style.maxHeight = "fit-content";
      }
    }
  };

  handleClick = (e) => {
    if (this.state.show) {
      if (this.node.contains(e.target)) {
        return;
      } else {
        this.handleBlur();
      }
    }
  };

  handleBlur = () => {
    setTimeout(() => {
      this.setState({ show: false });
    }, 120);
  };

  getRetailerList = async () => {
    const { RetailerName = null } = this.props.data || {};
    let retailerList = [];
    let selectedRetailer;
    try {
      const response = await getNavRetailerList();
      if (response && response.data && response.data.Retailers) {
        response.data.Retailers.map((retailer) => {
          const {
            RetailerId: id = null,
            RetailerName: name = null,
            RetailerLogo: logo = null,
          } = retailer;
          if (RetailerName && RetailerName.value == name.value) {
            selectedRetailer = {
              value: id.value,
              label: name.value,
              logo: logo && logo.value ? logo.value : null,
            };
          }
          retailerList.push({
            value: id.value,
            label: name.value,
            logo: logo && logo.value ? logo.value : null,
          });
        });

        this.setGoogleTagmanager(RetailerName);

        this.setState({
          selectedRetailer: selectedRetailer,
          retailersList: retailerList,
        });
      }
    } catch (e) {
      console.error(
        "getNavRetailerList Error: " + e && e.response && e.response.statusText
      );
    }
  };

  setGoogleTagmanager = (retailerName) => {
    if (
      document &&
      document.referrer &&
      document.referrer.toLowerCase().indexOf("/home/login") !== -1
    ) {
      const retailer = (retailerName && retailerName.value) || "Administrator";

      TagManager.dataLayer({
        dataLayer: {
          event: "loginRetailerInfo",
          loginRetailer: retailer,
        },
        dataLayerName: "dataLayer",
      });

      const centre = sessionStorage.getItem("RPCentreList");
      if (centre) {
        if (centre.indexOf(",") !== -1) {
          centre.split(",").map((item) => {
            TagManager.dataLayer({
              dataLayer: {
                event: "loginCentreInfo",
                loginCentre: item,
                loginRetailer: retailer,
              },
              dataLayerName: "dataLayer",
            });
          });
        } else {
          TagManager.dataLayer({
            dataLayer: {
              event: "loginCentreInfo",
              loginCentre: centre,
              loginRetailer: retailer,
            },
            dataLayerName: "dataLayer",
          });
        }
      }
    }
  };

  handleSwitchRetailer = async (retailer) => {
    const url = "/api/sitecore/account/SwitchRetailer";
    const data = {
      retailerId: retailer.value,
    };

    const { selectedRetailer } = this.state;

    TagManager.dataLayer({
      dataLayer: {
        prevRetailer:
          selectedRetailer && selectedRetailer.label
            ? selectedRetailer.label
            : "No previous retailer",
        newRetailer: retailer.label,
      },
      dataLayerName: "dataLayer",
    });

    try {
      let response = await axios({
        method: "POST",
        url,
        data,
      });

      if (response) {
        if (response.data.IsSuccess.value) {
          TagManager.dataLayer({
            dataLayer: {
              event: "switchedRetailer",
            },
            dataLayerName: "dataLayer",
          });
          window.location.reload(true);
          return false;
        }
      }
    } catch (e) {
      if (e && e.response && e.response.statusText) {
        console.error("Nav Retailer Error: " + e.response.statusText);
      } else console.log(e);
    }
  };

  render() {
    const { selectedRetailer, removeShowAll, retailersList, show } = this.state;
    const { RetailerName, RetailerLogo } = this.props.data;
    const isSingleRetailer =
      retailersList && retailersList.length && retailersList.length === 1
        ? true
        : false;
    const hasOptions =
      retailersList && retailersList.length && retailersList.length > 1
        ? true
        : false;
    const showAll = hasOptions && retailersList.length > 3 ? true : false;
    return (
      <div className="nav-retailer-wrapper">
        {retailersList && retailersList.length ? (
          <div
            className={`nav-retailers-wrapper ${
              hasOptions ? "has-options" : ""
            }`}
            ref={(node) => (this.node = node)}
          >
            <div
              className="retailers-wrapper"
              onClick={() => hasOptions && this.setState({ show: !show })}
            >
              {RetailerName && RetailerName.value ? (
                ""
              ) : (
                <i className="icon icon-list" />
              )}
              <div className="retailer-name">
                {RetailerName && RetailerName.value ? (
                  <Text field={RetailerName} />
                ) : (
                  "Select retailer"
                )}
              </div>
              {isSingleRetailer ? (
                ""
              ) : hasOptions ? (
                <Fragment>
                  <i className="icon icon-chevron-down-small" />
                  <i className="icon icon-chevron-outline-right" />
                </Fragment>
              ) : (
                <div className="nav-retailer-loader">
                  <div className="loader" />
                </div>
              )}
              {RetailerName && RetailerName.value ? (
                RetailerLogo && RetailerLogo.value ? (
                  <div className="retailer-logo">
                    <img
                      alt="logo"
                      src={logoPlaceholder}
                      data-src={RetailerLogo.value}
                      className="lazyload"
                    />
                  </div>
                ) : (
                  <div className="retailer-logo">
                    <img alt="no_logo" src={logoPlaceholder} />
                  </div>
                )
              ) : (
                ""
              )}
            </div>
            {!isSingleRetailer && (
              <ul className={`dropdown-list ${show ? "" : "d-none"}`}>
                <div
                  className="label-back"
                  id={show ? "nav-mobile-back" : ""}
                  onClick={() => this.handleBlur()}
                >
                  <i className="icon icon-chevron-outline-left" />
                  Back
                </div>
                <div className="label-primary">
                  {RetailerName && RetailerName.value
                    ? "Switch retailer"
                    : "Select retailer"}
                </div>
                <div
                  className={`options ${
                    showAll && removeShowAll ? "more-items" : "default-items"
                  }`}
                >
                  {retailersList.map((item, index) => {
                    return (
                      <li
                        key={`retailerList-${index}`}
                        onClick={() => {
                          this.setState({ show: false });
                          (!selectedRetailer ||
                            (selectedRetailer &&
                              selectedRetailer.value !== item.value)) &&
                            this.handleSwitchRetailer(item);
                        }}
                        className={
                          selectedRetailer &&
                          selectedRetailer.value === item.value
                            ? "selected"
                            : ""
                        }
                      >
                        <img
                          alt="icon"
                          src={logoPlaceholder}
                          data-src={item.logo ? item.logo : logoPlaceholder}
                          className="lazyload"
                        />
                        <span>{item.label}</span>
                        {selectedRetailer &&
                          selectedRetailer.value === item.value && (
                            <Fragment>
                              <i className="icon icon-check-small" />
                              <i className="icon icon-check-big" />
                            </Fragment>
                          )}
                      </li>
                    );
                  })}
                </div>
                {showAll && !removeShowAll && (
                  <div className="show-all">
                    <div className="last-option-border" />
                    <div
                      className="label-secondary"
                      id="nav-retailer-show-all"
                      onClick={() =>
                        this.setState({ removeShowAll: true, isBlur: false })
                      }
                    >
                      Show all
                    </div>
                  </div>
                )}
              </ul>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default NavRetailer;
