import React, { Component } from "react";
import Button from "../Button";

class CustomFilter extends Component {

    handleCloseFilter = () => {
        let bodyContainer;
        let backToTop;
        if (document) {
            bodyContainer = document.querySelector(".body-container");
            bodyContainer.classList.toggle("is-filter-shown");

            backToTop = document.querySelector(".back-to-top__wrapper");
            if (backToTop) {
                backToTop.style.right = "36px";
            }
        }
        this.props.onCloseFilter(false);
    }

    updateBodyClass = (showFilter) => {
        let bodyContainer = document.querySelector(".body-container");
        let backToTop = document.querySelector(".back-to-top__wrapper");
        if (showFilter) {
            bodyContainer.classList.add("is-filter-shown");
            if (backToTop) {
                backToTop.style.right = "296px";
            }
        } else {
            bodyContainer.classList.remove("is-filter-shown");
        }
    }

    render() {
        const {
            showFilter
        } = this.props;
        this.updateBodyClass(showFilter);
        return (
            <div className={`custom-filter ${(showFilter) ? "" : "is-hidden"}`}>
                <div className="custom-filter__blur"
                    onClick={this.handleCloseFilter}
                >
                </div>
                <div className="custom-filter__content">
                    <div className="custom-filter__header">
                        <div className="custom-filter__title">
                            {"Filter"}
                        </div>
                        <div className="custom-filter__btn is-tablet">
                            <Button
                                size="small"
                                color="tertiary"
                                icon="close"
                                onClick={this.handleCloseFilter}
                            />
                        </div>
                        <div className="custom-filter__btn is-desktop">
                            <Button
                                size="small"
                                color="tertiary"
                                icon="log-out"
                                onClick={this.handleCloseFilter}
                            />
                        </div>
                    </div>
                    <div className="custom-filter__wrapper">
                        {this.props.children}
                    </div>
                </div>

            </div>
        );
    }
}

export default CustomFilter;
