import { get } from "lodash";
import { AllHtmlEntities as Entities } from "html-entities";
import { validateImageSize, validateImageType } from "./ImageUploadHelper";

const isEmpty = (value) => {
  return value ? false : true;
};

const isObject = (value) => typeof value === 'object' && value !== null;

const isBlob = (value) =>  value instanceof Blob && value !== null;

export const validate = (state, removeValidate) => {
  let list = [
    // {
    //   name: "selectedStore",
    //   value: state.selectedStore,
    //   rules: "required"
    // },
    {
      name: "promotionName",
      value: state.promotionName,
      rules: "required",
    },
    {
      name: "summary",
      value: state.summary,
      rules: "required",
    },
    {
      name: "startDate",
      value: state.startDate,
      rules: "required",
    },
    {
      name: "endDate",
      value: state.endDate,
      rules: "required",
    },
    {
      name: "heroImage",
      value: state.heroImage,
      rules: "required",
    },
    {
      name: "selectedStores",
      value: state.selectedStores,
      rules: "required",
    },
    {
      name: "description",
      value: state.description,
      rules: "required-richtext",
    },
    {
      name: "selectedCategories",
      value: state.selectedCategories,
      rules: "required-array",
    },
    {
      name: "embargoStartDate",
      value: state.embargoStartDate,
      rules: "optional-before-start"
    },
    {
      name: "embargoEndDate",
      value: state.embargoEndDate,
      rules: "end-before-start"
    },
    {
      name: "embargoEndDate",
      value: state.embargoEndDate,
      rules: "optional-before-start"
    },
    {
      name: "embargoStartDate",
      value: state.embargoStartDate,
      rules: "start-after-end"
    },
    {
      name: "embargoEndDate",
      value: state.embargoEndDate,
      rules: "publish-before-embargo"
    },
    {
      name: "thumbnailFileType",
      value: state.thumbnail,
      rules: "image-file-type"
    },
    {
      name: "heroImageFileType",
      value: state.heroImage,
      rules: "image-file-type"
    },
    {
      name: "socialFeedFileType",
      value: state.socialFeedImage,
      rules: "image-file-type"
    },
    {
      name: "socialStoryFileType",
      value: state.socialStoryImage,
      rules: "image-file-type"
    },
    {
      name: "imageGalleryFileType",
      value: state.imageGallery,
      rules: "image-gallery-file-type"
    },
    {
      name: "thumbnailFileSize",
      value: state.thumbnail,
      rules: "image-file-size"
    },
    {
      name: "heroImageFileSize",
      value: state.heroImage,
      rules: "image-file-size"
    },
    {
      name: "socialFeedFileSize",
      value: state.socialFeedImage,
      rules: "image-file-size"
    },
    {
      name: "socialStoryFileSize",
      value: state.socialStoryImage,
      rules: "image-file-size"
    },
    {
      name: "imageGalleryFileSize",
      value: state.imageGallery,
      rules: "image-gallery-file-size"
    }
  ];

  let result = {};
  list.forEach((element) => {
    if (removeValidate.indexOf(element.name) > -1) {
      return;
    }
    if (element.rules === "required") {
      if (isEmpty(element.value)) {
        result[element.name] = "Required";
      }

      if (element.value && element.value.length && element.value.length < 1) {
        result[element.name] = "Required";
      }
    }

    if (element.rules === "required-richtext") {
      if (isEmpty(element.value)) {
        result[element.name] = "Required";
      }

      if (element.value && element.value.trim().length < 8) {
        result[element.name] = "Required";
      }
    }
    if (element.rules === "required-array") {
      if (element.value && !element.value.length && element.value.length < 1) {
        result[element.name] = "Required";
      }
    }

    if (element.rules === "optional-before-start") {
      let dateToValidate = new Date(element.value).getTime();
      let dateToCompare = new Date(state.startDate).getTime();
      if (dateToValidate && !(dateToValidate < dateToCompare)) {
        result[element.name] = "Embargo must end before offer can start";
      }
    }

    if (element.rules === "end-before-start") {
      let dateToValidate = new Date(element.value).getTime();
      let dateToCompare = new Date(state.embargoStartDate).getTime();
      if (dateToValidate < dateToCompare) {
        result[element.name] = "End date cannot be before start date";
      }
    }

    if (element.rules === "start-after-end") {
      let dateToValidate = new Date(element.value).getTime();
      let dateToCompare = new Date(state.embargoEndDate).getTime();
      if (dateToValidate > dateToCompare) {
        result[element.name] = "Start date cannot be before end date";
      }
    }

    if (element.rules === "publish-before-embargo") {
      let dateToValidate = new Date(element.value).getTime();
      let dateToCompare = new Date(state.displayStartDate).getTime();
      if (dateToCompare && (dateToValidate > dateToCompare)) {
        result[element.name] = "Cannot display during embargo period";
      }
    }

    if (element.rules === 'image-file-type') {
      if(isObject(element.value)){ 
        const imageValidity = validateImageType(element.value);
          if (imageValidity === false) {
            result[element.name] = "Invalid file type";
          }
      } else {}
    }

    if (element.rules === 'image-file-size') {
      if(isObject(element.value)){ 
      const imageValidity = validateImageSize(element.value);
        if (imageValidity === false) {
          result[element.name] = "Your file is too large. File upload image size limit is 5MB";
        }
    }
  }

    if (element.rules === 'image-gallery-file-type') {
      if (!isEmpty(element.value)) {
        let invalidImages = [];
        element.value.forEach(elem => {
          if(isObject(elem) && isBlob(elem)){
            let imageValidity = validateImageType(elem);
            if(imageValidity === false) {
              invalidImages.push(elem);
            }
          }
        });
        if (invalidImages.length > 0) {
          result[element.name] = "Invalid file type";
        }
      }
    }

    if (element.rules === 'image-gallery-file-size') {
      if (!isEmpty(element.value)) {
        let invalidImages = [];
        element.value.forEach(elem => {
          if(isObject(elem) && isBlob(elem)){
          let imageValidity = validateImageSize(elem);
          if(imageValidity === false) {
            invalidImages.push(elem);
          }
        }
        });
        if (invalidImages.length > 0) {
          result[element.name] =  "A file is too large. File upload image size limit is 5MB";
        }
      }
    }

    //Other Validation Rules
  });
  return result;
};

export const formDataFields = {
  itemId: null,
  selectedStore: null,
  categoryTag: "",
  offerHighlight: "",
  promotionName: "",
  summary: "",
  startDate: "",
  endDate: "",
  displayStartDate: null,
  displayEndDate: null,
  embargoStartDate: "",
  embargoEndDate: "",
  location: "",
  schedule: "",
  duration: "",
  capacity: "",
  price: "",
  description: "",
  termsAndConditionsCopy: "",
  termsAndConditionsLink: "",
  selectedCategories: [],
  heroImage: "",
  thumbnail: "",
  imageGalleryChecker: [],
  imageGallery: [],
  selectedStores: [],
  selectedSpecialPromotion: [],
  itemIds: [],
  socialFeedImage: "",
  socialStoryImage: "",
  retailerHandles: "",
  retailerHashtags: "",
  consentToSharing: false
};

export const setPromotionData = (data) => {
  return {
    itemId: data.ItemId.value,
    heroImage: get(data, "HeroImage.value.src", null),
    thumbnail: get(data, "Thumbnail.value.src", null),
    promotionName: data.Name.value,
    offerHighlight: data.OfferHighlight.value,
    duration: data.Duration.value,
    capacity: data.Capacity.value,
    location: data.Location.value,
    categoryTag: data.CategoryTag.value,
    summary: data.Summary.value,
    startDate: data.StartDate.value,
    endDate: data.EndDate.value,
    displayStartDate: data.DisplayStartDate.value,
    displayEndDate: data.DisplayEndDate.value,
    embargoStartDate: data.EmbargoStartDate.value,
    embargoEndDate: data.EmbargoEndDate.value,
    schedule: data.Schedule.value,
    price: data.Price.value,
    description: data.Description.value,
    selectedSpecialPromotion: data.LinkedSpecialHolidays.map(
      (el) => el.HolidayId.value
    ),
    termsAndConditionsCopy: get(data, "TermsAndConditionsCopy.value", null),
    termsAndConditionsLink: get(
      data,
      "TermsAndConditionsLink.value.href",
      null
    ),
    selectedCategories: data.LinkCategories.map(
      (el) => el.CategoryId.value
    ),
    imageGalleryChecker: data.ImageGallery.map((el) => el.Id.value),
    imageGallery: data.ImageGallery.map((el) => {
      return {
        name: el.Name.value,
        id: el.Id.value,
        src: el.Src.value,
      };
    }),
    socialFeedImage: get(data, "SocialFeedImage.value.src", null),
    socialStoryImage: get(data, "SocialStoryImage.value.src", null),
    retailerHandles: data.RetailerHandles.value,
    retailerHashtags: data.RetailerHashtags.value,
    consentToSharing: data.ConsentToSharing.value
  };
};

export const buildFormData = (postData) => {
  const formData = new FormData();
  const entities = new Entities();

  Object.keys(postData).forEach(function (key) {
    if (key === "itemId") {
      return
    }
    if (
      (key === "startDate" ||
        key === "embargoStartDate" ||
        key === "embargoEndDate" ||
        key === "endDate" ||
        key === "displayStartDate" ||
        key === "displayEndDate") &&
      postData[key]
    ) {
      return formData.append(key, new Date(postData[key]).toISOString());
    }

    if (key === "description") {
      formData.append(key, entities.encode(postData[key]));
      return;
    }

    if (key === "imageGalleryChecker") {
      // if (postData[key].length) {
      //   let newimageGalleryChecker = postData["imageGallery"].filter((item) => {
      //     if (postData[key].indexOf(item.id) > -1) {
      //       return item.id;
      //     } 
      //   });
      // return newimageGalleryChecker.map((item) =>
      //   formData.append(key, item.id)
      // );
      // }

      if (postData["imageGallery"].length > 0) {
        return postData["imageGallery"].map((item) =>
          formData.append(key, item.id ? item.id : null)
        );
      }
      return;
    }

    if (key === "imageGallery") {
      // if (postData[key].length > 0) {
      //   let newimageGallery = postData[key].filter((item) => {
      //     return item.size ? item : null;
      //   });

      //   return newimageGallery.map((file) => formData.append(key, file));
      // }
      if (postData[key].length > 0) {
        let newimageGallery = postData[key].filter(item => item.size)
        return newimageGallery.map((file) => formData.append(key, file));
      }
      return;
    }

    if (key === "selectedCategories" && postData[key].length) {
      return postData[key].map((category) => formData.append(key, category));
    }

    if (key === "selectedSpecialPromotion" && postData[key].length) {
      return postData[key].map((promotion) => formData.append(key, promotion));
    }

    if (key === "heroImage" || key === "thumbnail" || key === "socialStoryImage" || key === "socialFeedImage") {
      if (typeof postData[key] !== "string") {
        return formData.append(key, postData[key]);
      }
      return;
    }

    if (key === "selectedStores" && postData[key].length) {
      return postData[key].map((storeId) => formData.append(key, storeId));
    }

    if (key === "itemIds" && postData[key].length) {
      return postData[key].map((itemId) => formData.append(key, itemId));
    }

    return formData.append(key, postData[key]);
  });

  return formData;
};
