import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DefaultUserLogo from "../../../../../assets/icons/png/author.png";
import logoPlaceholder from "../../../../../assets/img/logoPlaceholder.png";

class NavInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      breakpoint: null,
    };
  }
  componentDidMount() {
    this.setState({ breakpoint: window.outerWidth });
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleResize = () => {
    const { breakpoint } = this.state;
    if (
      (window.outerWidth < 992 && breakpoint < 992) ||
      (window.outerWidth >= 992 && breakpoint >= 992)
    ) {
    } else {
      this.handleBlur();
    }

    if (window && window.outerWidth >= 992) {
      document.addEventListener("mousedown", this.handleClick, false);
    } else {
      document.removeEventListener("mousedown", this.handleClick, false);
    }
  };

  handleClick = (e) => {
    if (this.state.show) {
      if (this.node.contains(e.target)) {
        return;
      } else {
        this.handleBlur();
      }
    }
  };

  handleBlur = () => {
    setTimeout(() => {
      this.setState({ show: false });
    }, 120);
  };

  render() {
    const { UserName: name = null, UserPicture: thumbnail = null } =
      this.props.data || {};
    const options = ["Your profile", "Logout"];
    const { show } = this.state;
    return (
      <div className="nav-user-wrapper" ref={(node) => (this.node = node)}>
        <div
          className="user-wrapper"
          onClick={() => this.setState({ show: !show })}
        >
          <div className="user-thumbnail">
            {thumbnail && thumbnail.value && thumbnail.value.src ? (
              <img
                alt="icon"
                src={logoPlaceholder}
                data-src={thumbnail.value.src}
                className="lazyload"
              />
            ) : (
              <img src={DefaultUserLogo} alt="retailer" />
            )}
          </div>
          <div className="user-name">
            Hey, <Text field={name} />
          </div>
          <div className="edit-profile">
            <i className="icon icon-chevron-down-small" />
            <i className="icon icon-chevron-outline-right" />
          </div>
        </div>
        {show && (
          <ul className="dropdown-list">
            <li
              className="nav-back"
              id={show ? "nav-mobile-back" : ""}
              onClick={() => this.handleBlur()}
            >
              <i className="icon icon-chevron-outline-left" />
              Back
            </li>
            <li className="nav-name">
              <div>
                <div className="user-thumbnail">
                  {thumbnail && thumbnail.value && thumbnail.value.src ? (
                    <img
                      alt="icon"
                      src={logoPlaceholder}
                      data-src={thumbnail.value.src}
                      className="lazyload"
                    />
                  ) : (
                    <img src={DefaultUserLogo} alt="retailer" />
                  )}
                </div>
                <div className="user-name">
                  Hey, <Text field={name} />
                </div>
              </div>
            </li>
            {options.map((item, index) => {
              return (
                <React.Fragment key={`navUserId${index}`}>
                  {index === options.length - 1 && (
                    <div className="last-option-border" />
                  )}
                  <li
                    onClick={() => {
                      this.setState({ show: false });
                      this.props.optionSelected(item);
                    }}
                  >
                    <i
                      className={`icon icon-${
                        index === 0 ? "user" : "log-out"
                      }`}
                    />
                    <span>{item}</span>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default NavInfo;
