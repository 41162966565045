import BaseURL, { DevURL} from "./BaseURL";

const isDev = process.env.REACT_APP_DEV || false;


export const getPromotions = () => {
  const endpoint = isDev ? "/GetPromotions" : "/store/GetEvents";
   return BaseURL.get(endpoint);
};

export const getCentreStores = params => {
  const endpoint = isDev ? "/GetCentreStores" : "/promotion/GetCentreStores";
  return BaseURL.get(endpoint, { params: { ...params } });

};

export const getDashboardPromotions = () => {
  const endpoint = isDev ? "/GetDashboardPromotions" : "/promotion/GetDashboardPromotions";
  return BaseURL.get(endpoint);

};

export const getCategoriesAndHolidays = params => {
  const endpoint = isDev ? "/GetCategoriesAndHolidays" : "/promotion/GetCategoriesAndHolidays";
  return BaseURL.get(endpoint, { params: { ...params } });

};

export const getPromotionData = params => {
  const endpoint = isDev ? "/GetCentreStores" : "/promotion/GetPromotionData";
  return BaseURL.get(endpoint, { params: { ...params } });
};
