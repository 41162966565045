import moment from "moment";

export const getDate = (date, format) => {
  return moment(date).format(format);
};

export const getDuration = (startDate, endDate) => {
  let startTime = getDate(startDate, "hh:mm a");
  let startDay = getDate(startDate, "DD");
  let startMonth = getDate(startDate, "MMMM");
  let startYear = getDate(startDate, "YYYY");

  let endTime = getDate(endDate, "hh:mm a");
  let endDay = getDate(endDate, "DD");
  let endMonth = getDate(endDate, "MMMM");
  let endYear = getDate(endDate, "YYYY");

  let duration;
  let durationYear = startYear === endYear ? true : false;
  let durationMonth = startMonth === endMonth ? true : false;
  let durationDay = startDay === endDay ? true : false;

  if (durationYear && durationMonth && durationDay) {
    // 24 July 2019, 6:30pm - 7:30pm
    duration = `${startDay} ${startMonth} ${startYear}`;
  }

  if (durationYear && durationMonth && !durationDay) {
    // 15 - 24 July 2019, 6:30pm - 7:30pm
    duration = `${startDay}-${endDay} ${startMonth} ${startYear}`;
  }

  if (durationYear && !durationMonth) {
    // 15 July - 24 August 2019, 6:30pm - 7:30pm
    duration = `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
  }

  if (
    !durationYear &&
    ((!durationMonth && !durationDay) || (durationMonth && durationDay))
  ) {
    // 15 July 2018 - 24 August 2019, 6:30pm - 7:30pm
    // 15 July 2018 - 15 July 2019, 6:30pm - 7:30pm
    duration = `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
  }

  if (startTime === endTime) {
    duration = `${duration} ${startTime}`;
  } else {
    duration = `${duration} ${startTime} - ${endTime}`;
  }

  return duration;
};

export const getStartDate = startDate => {
  let startTime = getDate(startDate, "hh:mm a");
  let startDay = getDate(startDate, "DD");
  let startMonth = getDate(startDate, "MMMM");
  let startYear = getDate(startDate, "YYYY");

  return `${startDay} ${startMonth} ${startYear} ${startTime}`;
};
