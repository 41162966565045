import React from "react";

class AvatarUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.value ? props.value : null,
      preview: props.value ? props.value : null,
      remove: false,
      errorMessage: null,
    };
  }

  handleFileUpload = () => {
    this.props.handleFileUpload(this.state.file, this.state.errorMessage);
    this.props.removeImage(this.state.remove);
  };

  setFile = (file) => {
    if (file && file.type.indexOf("image") !== -1) {
      if (file.size / 1024 / 1024 <= 2) {
        this.setState(
          {
            file,
            preview: URL.createObjectURL(file),
          },
          this.handleFileUpload
        );
      } else {
        alert("The maximum file size allowed is 2 MB.");
      }
    } else {
      alert("Please select an image.");
    }
  };

  render() {
    const { preview } = this.state;
    const { value } = this.props;
    return (
      <div className="avatarupload-wrapper">
        <div className={`avatarupload-btn ${preview || value ? "" : "empty"}`}>
          {preview || value ? (
            <div className="img-container">
              <img src={preview || value} />
              <div className="controls-container">
                <label>
                  <div className="img-control">
                    <i className="icon icon-edit" />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      this.setState(
                        { remove: false },
                        this.setFile(e.target.files[0])
                      )
                    }
                  />
                </label>
                <div
                  className="img-control"
                  onClick={() =>
                    this.setState(
                      {
                        file: null,
                        preview: null,
                        remove: true,
                      },
                      this.handleFileUpload
                    )
                  }
                >
                  <i className="icon icon-trash-2" />
                </div>
              </div>
            </div>
          ) : (
            <label>
              <span>Choose image</span>
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  this.setState(
                    { remove: false },
                    this.setFile(e.target.files[0])
                  )
                }
              />
            </label>
          )}
        </div>
      </div>
    );
  }
}

export default AvatarUpload;
