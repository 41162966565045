import React, { Component } from "react";

class ForgotPassword extends Component {
  render() {

    return (
      <div className="forgot-password-container">
        <img
          className="forgot-password-bg"
          src={this.props.bg}
          alt="Background"
        />
        <div className="container">{this.props.children}</div>
      </div>
    );
  }
}

export default ForgotPassword;
