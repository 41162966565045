import React from "react";
import {
  getNotifications,
  updateMemoStatus,
  getNotificationContent,
} from "../../../../api/NotificationAPI";
import TagManager from "react-gtm-module";
import MemoItem from "../../../Modules/MemoItem";
import PageHeader from "../../../Base/PageHeader";
import ListItem from "../../../Base/ListItem";
import FormModal from "../../../Base/FormModal";
import NoResultMessage from "../../../Base/NoResultMessage";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "../../../../helper/ScrollHelper";
import { hideBackToTop } from "../../../../helper/HideBackToTopHelper";

class FeatureRetailerPortalNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      showContentLoader: true,
      notifications: null,
      showViewModal: false,
      modalData: null,
      acknowledge: false,
      hideAcknowledgeButton: false,
      memoContent: null,
      isFirefox: false,
    };
  }

  componentDidMount() {
    const { isDashboard, notificationsList, hideAcknowledgeButton } =
      this.props || {};
    if (
      navigator &&
      navigator.userAgent &&
      navigator.userAgent.toUpperCase().indexOf("FIREFOX") !== -1
    ) {
      this.setState({ isFirefox: true });
    }
    if (document) {
      this.setState({ isDocumentReady: true });
      if (!isDashboard) {
        this.getData();
      } else {
        this.setState({
          notifications: notificationsList,
          hideAcknowledgeButton,
          showLoader: false,
        });
      }
    }
  }

  getData = async () => {
    try {
      const response = await getNotifications();
      if (response && response.data) {
        const { Notifications, HideAcknowledgeButton } = response.data;
        this.setState({
          notifications: Notifications,
          hideAcknowledgeButton:
            HideAcknowledgeButton && HideAcknowledgeButton.value,
          showLoader: false,
        });
      }
    } catch (e) {
      if (e && e.response && e.response.statusText) {
        console.error("Get Notifications Data Error: " + e.response.statusText);
      } else console.log(e);
    }
  };

  getContent = async (itemId) => {
    try {
      const response = await getNotificationContent({ itemId });
      if (response && response.data) {
        const {
          ItemId,
          Title,
          Date,
          Image: MemoImage,
          Content,
          Attachments,
          MemoType,
          MemoViewed,
          Acknowledged,
        } = response.data;
        this.setState({
          memoContent: {
            ItemId,
            Title,
            Date,
            MemoImage,
            Content,
            Attachments,
            MemoType,
            MemoViewed,
            Acknowledged,
          },
          showContentLoader: false,
          itemId: ItemId,
        });
      }
      return null;
    } catch (e) {
      console.error("Get Notifications Data Error: " + e.response.statusText);
      return null;
    }
  };

  setMemoStatus = (itemId) => {
    const { notifications } = this.state;
    let items = [...notifications];
    if (items) {
      let i = items.findIndex((el) => el.ItemId.value === itemId);
      if (items[i] && items[i].MemoViewed && !items[i].MemoViewed.value) {
        items[i].MemoViewed.value = true;
      }

      if (items.find((el) => el && el.MemoViewed && !el.MemoViewed.value)) {
      } else {
        if (this.state.isDocumentReady) {
          document.getElementById("notif-count") &&
            document.getElementById("notif-count").remove();
        }
      }
    }
  };

  handleView = async (itemId, isViewed, dateAndCentre, title) => {
    if (!isViewed) {
      const response = await updateMemoStatus({ itemId });
      if (
        response &&
        response.data &&
        response.data.IsSuccess &&
        response.data.IsSuccess.value
      ) {
        TagManager.dataLayer({
          dataLayer: {
            event: "correspondenceViewed",
            correspondenceDateAndCentre: dateAndCentre,
            correspondenceTitle: title,
          },
          dataLayerName: "dataLayer",
        });
      }
    }
  };

  renderViewModal = () => {
    const {
      showViewModal,
      showContentLoader,
      memoContent,
      hideAcknowledgeButton,
    } = this.state || {};

    if (showViewModal) {
      disableBodyScroll();
    }

    hideBackToTop(showViewModal);

    return (
      <FormModal
        showModal={showViewModal}
        label={
          <span>
            {"Correspondence"}
            &nbsp;&nbsp;&nbsp;
            {"»"}&nbsp;&nbsp;&nbsp;{"Message"}
          </span>
        }
        closeOnClick={() =>
          this.setState(
            {
              showViewModal: false,
              memoContent: null,
            },
            () => enableBodyScroll()
          )
        }
      >
        {showContentLoader && (
          <div className="loader-wrapper container">
            <div className="loader" />
          </div>
        )}
        {showViewModal && !showContentLoader && memoContent && (
          <MemoItem
            data={memoContent}
            hideAcknowledgement={hideAcknowledgeButton}
          />
        )}
      </FormModal>
    );
  };

  renderList = () => {
    const { notifications } = this.state;
    if (notifications && notifications.length) {
      const itemCount = notifications.length;
      return (
        <div>
          <div className="notifications-item-container">
            {notifications.map((item, index) => {
              const { ItemId, Title, Header, Content, MemoViewed } = item;
              return (
                <ListItem
                  key={`notifId-${index}`}
                  label={Header}
                  showNotif={true}
                  notification={MemoViewed && !MemoViewed.value}
                  title={Title}
                  description={Content}
                  listItemOnClick={() => {
                    this.setState({
                      showViewModal: true,
                    });
                    this.getContent(ItemId && ItemId.value);
                    this.handleView(
                      ItemId && ItemId.value,
                      MemoViewed && MemoViewed.value,
                      Header && Header.value,
                      Title && Title.value
                    );
                    this.setMemoStatus(ItemId && ItemId.value);
                  }}
                  noActionBtn={true}
                />
              );
            })}
          </div>
          <div className="list-count">
            Showing {itemCount} of {itemCount}
          </div>
        </div>
      );
    } else {
      return <NoResultMessage label="No correspondence" />;
    }
  };

  render() {
    const { showLoader, isDocumentReady } = this.state;
    return (
      <div className="notifications-container">
        {isDocumentReady && this.renderViewModal()}
        <PageHeader title={{ value: "Correspondence" }} />
        {showLoader && (
          <div className="loader-wrapper container">
            <div className="loader" />
          </div>
        )}
        {!showLoader && this.renderList()}
      </div>
    );
  }
}

export default FeatureRetailerPortalNotifications;
