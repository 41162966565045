import React from "react";

class NavLogo extends React.Component {
  closeNav = () => {
    const leftNavWrapper = document.querySelector(".left-nav");
    const topNavWrapper = document.querySelector(".top-nav .top-nav-wrapper");
    const backButton = document.querySelector("#nav-mobile-back");
    if (leftNavWrapper && topNavWrapper) {
      leftNavWrapper.classList.remove("show-nav-mobile");
      topNavWrapper.classList.remove("show-nav-mobile");
    }
    if (backButton) {
      backButton.click();
    }
  };

  render() {
    const { LogoLink = null } = this.props.data || {};
    return (
      <div className="nav-logo-wrapper">
        {LogoLink && LogoLink.value ? (
          <a {...LogoLink.value}>
            <i className="icon icon-townsquare" />
          </a>
        ) : (
          <i className="icon icon-townsquare" />
        )}
        <i className="icon icon-close" onClick={() => this.closeNav()} />
      </div>
    );
  }
}

export default NavLogo;
