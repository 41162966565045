import React from "react";
import CharacterCount from "../CharacterCount";

const FormInput = props => {
  const {
    type = null,
    label = null,
    icon = null,
    placeholder = null,
    required = null,
    currency = null,
    error = null,
    value = null,
    onInputChange = null,
    maxLength = null,
    width = null,
    title = null,
    disabled = null,
    margin = null,
    className = null
  } = props;
  return (
    <div className={`form-group form__input 
    ${title ? "is-title" : ""}
    ${error ? "has-error" : ""}
    ${margin && margin === "custom" ? "is-custom-margin" : ""}
    ${className ? className : ""}
    `}>
      {(label || required) &&
        <label className="form__label">
          {label}
          {required &&
            <span className="form__asterisk">*</span>
          }
        </label>
      }
      <div className={`form__input--details 
      ${currency ? "has-currency" : ""}
      ${width && width == "max" ? "is-max" :
          width && width == "min" ? "is-min" :
            width && width == "badge" ? "is-badge" : ""}
      `}>
        {currency &&
          <div className="form__input--currency">
            {"$"}
          </div>
        }
        <input
          className="form__input--field"
          type={type ? type : "text"}
          value={value ? value : ""}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={e => onInputChange(e.target.value)}
          disabled={disabled}
        />
      </div>
      <div className="form__validation-message">
        {error}
      </div>
      {/* {maxLength && <CharacterCount remaining={maxLength - value.length} />} */}
    </div >
  );
};

export default FormInput;
