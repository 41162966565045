import BaseURL, {DevURL}  from "./BaseURL";

const isDev = process.env.REACT_APP_DEV || false;

export const getEvents = (params) => {
  const endpoint = isDev ? "/GetEvents" : "/store/GetEvents";
  return BaseURL.get(endpoint, { params: { ...params } });
;
};

export const getOffers = (params) => {
  const endpoint = isDev ? "/GetOffers" : "/store/GetOffers";
  return BaseURL.get(endpoint, { params: { ...params } });
};

export const deletePromotion = params => {
  return BaseURL.get("/store/DeletePromotion", { params: { ...params } });
};

export const createPromotion = params => {
  return BaseURL.get("/store/CreatePromotion", { params: { ...params } });
};

export const editPromotion = async () => {
  //   const response = await BaseURL.get("/store/EditPromotion");
};

export const savePromotion = async () => {
  //   const response = await BaseURL.post("/store/SavePromotion");
};

export const getStoreInfo = params => {
  const endpoint = isDev ? "/GetStoreInfo" : "/store/GetStoreInfo";
   return BaseURL.get(endpoint, { params: { ...params } });
};

export const updateStoreInfo = params => {
  // return BaseURL.get("/store/UpdateStoreInfo", { params: { ...params } });
  // return DevURL.get("/UpdateStoreInfo", { params: { ...params } });
};
