import BaseURL, { DevURL } from "./BaseURL";

const isDev = process.env.REACT_APP_DEV || false;

export const getUsers = (params) => {
  const endpoint = isDev ? "/GetUsers" : "/user/GetUsers";
   return BaseURL.get(endpoint, { params: { ...params } });
};

export const deleteUser = (params) => {
  return BaseURL.get("/user/DeleteUser", { params: { ...params } });
};

export const getRetailerList = () => {
  const endpoint = isDev ? "/GetRetailerList" : "/user/GetRetailerList";
  return BaseURL.get(endpoint);

};

export const getCreateEditUser = (params) => {
  const endpoint = isDev ? "/GetCreateEditUser" : "/user/GetCreateEditUser";
   return BaseURL.get(endpoint, { params: { ...params } });
};
