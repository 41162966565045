import React, { Component } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import Button from "../../Button";
import FormInput from "../../FormInput";

class PopupAction extends Component {
  render() {
    const {
      label,
      description,
      icon,
      iconBgColor,
      secondaryBtn,
      secondaryBtnOnClick,
      secondaryBtnColor,
      disabledSecondaryBtn,
      primaryBtn,
      primaryBtnOnClick,
      primaryBtnColor,
      disabledPrimaryBtn,
      rejectMessage,
      withRejectMessage,
      onRejectMessageChange,
    } = this.props;
    return (
      <React.Fragment>
        <div className="popup-action">
          <div className="popup-action__close is-tablet">
            {secondaryBtnOnClick && (
              <Button
                color="tertiary"
                icon="close"
                onClick={secondaryBtnOnClick}
              />
            )}
          </div>
          <div className="popup-action__content">
            <div className="popup-action__icon">
              <i
                className={`icon icon-${icon}`}
                style={{ backgroundColor: iconBgColor }}
              />
            </div>

            <div className="popup-action__text is-tablet">
              {label && label.value && (
                <div className="popup-action__label">
                  <Text field={label} />
                </div>
              )}
            </div>
            <div className="is-tablet">
              {description && description.value && (
                <div className="popup-action__description">
                  <RichText field={description} />
                </div>
              )}
              {withRejectMessage && (
                <FormInput
                  type="text"
                  placeholder=""
                  label="Comment"
                  value={rejectMessage}
                  onInputChange={(value) => onRejectMessageChange(value)}
                />
              )}
            </div>

            <div className="popup-action__text is-desktop">
              {label && label.value && (
                <div className="popup-action__label">
                  <Text field={label} />
                </div>
              )}
              {description && description.value && (
                <div className="popup-action__description">
                  <RichText field={description} />
                </div>
              )}
              {withRejectMessage && (
                <FormInput
                  type="text"
                  placeholder=""
                  label="Comment"
                  value={rejectMessage}
                  onInputChange={(value) => onRejectMessageChange(value)}
                />
              )}
            </div>
          </div>
          <div className="popup-action__buttons">
            {primaryBtn && primaryBtnOnClick && (
              <Button
                className="is-tablet"
                color={primaryBtnColor ? primaryBtnColor : "primary"}
                onClick={primaryBtnOnClick}
                disabled={disabledPrimaryBtn}
              >
                {primaryBtn}
              </Button>
            )}

            {secondaryBtn && secondaryBtnOnClick && (
              <Button
                color={secondaryBtnColor ? secondaryBtnColor : "secondary"}
                onClick={secondaryBtnOnClick}
                disabled={disabledSecondaryBtn}
              >
                {secondaryBtn}
              </Button>
            )}

            {primaryBtn && primaryBtnOnClick && (
              <Button
                className="is-desktop"
                color={primaryBtnColor ? primaryBtnColor : "primary"}
                onClick={primaryBtnOnClick}
                disabled={disabledPrimaryBtn}
              >
                {primaryBtn}
              </Button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PopupAction;
