import React, { Component, Fragment } from "react";

// Input [{name: value}]

class FormDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selected: props.defaultSelected,
    };
  }

  handleToggle = (e) => {
    e.target.focus();
    this.setState({ show: !this.state.show });
  };

  handleBlur = () => {
    if (this.state.show) {
      setTimeout(() => {
        this.setState({ show: !this.state.show });
      }, 120);
    }
  };

  render() {
    const {
      theme,
      error,
      title,
      icon,
      options,
      placeholder,
      noOptionsMessage,
    } = this.props;
    const { selected, show } = this.state;
    return (
      <Fragment>
        <div className="form-dropdown-container">
          {icon ? <i className={`icon icon-${icon}`} /> : ""}
          {title ? <div className="title">{title}</div> : ""}
          <label
            className={`arrow ${theme ? theme : ""} ${error ? "error" : ""}`}
          >
            <div className="dropdown-placeholder">
              <input
                type="button"
                value={selected ? selected : placeholder}
                className="dropdown-btn"
                onClick={(e) => this.handleToggle(e)}
                // onBlur={e => this.handleBlur(e)}
              />
            </div>
            <i className="icon icon-chevron-down" />
            <ul className={`dropdown-list ${show ? "" : "d-none"}`}>
              {options && options.length ? (
                options.map((item, index) => {
                  return (
                    <li
                      key={`optionId${index}`}
                      onClick={() => {
                        this.setState({ selected: item.name }, () => {
                          this.props.handleSelectionChange(item.name);
                        });
                      }}
                    >
                      <span>{item.name}</span>
                    </li>
                  );
                })
              ) : (
                <li className="empty">
                  {noOptionsMessage ? noOptionsMessage : "No store found"}
                </li>
              )}
            </ul>
          </label>
        </div>
      </Fragment>
    );
  }
}

export default FormDropDown;
