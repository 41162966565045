import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import Loadable from "react-loadable";
import logoPlaceholder from "../../../../assets/img/logoPlaceholder.png";
import TagManager from "react-gtm-module";
import FormImageGallery from "../../../Base/FormImageGallery";
import FormInput from "../../../Base/FormInput";
import FormInputMultiple from "../../../Base/FormInputMultiple";
import FormDate from "../../../Base/FormDate";
import FormCheckbox from "../../../Base/FormCheckbox";
import FormListbox from "../../../Base/FormListbox";
import FormListCheckbox from "../../../Base/FormListCheckbox";
import Checkbox from "../../../Base/Checkbox";
import FormErrorMessage from "../../../Base/FormErrorMessage";
import FormImageUpload from "../../../Base/FormImageUpload";
import PopupPreview from "../../../Base/Popup/PopupPreview";
import { get } from "lodash";
import {
  validate,
  setArticleData,
  buildFormData,
  formDataFields,
} from "../../../../helper/ArticleHelper";
import {
  getCentreStores,
  getCategoriesAndHolidays,
} from "../../../../api/PromotionAPI";
import { getArticleData } from "../../../../api/ArticlesAPI";

// import getCentreStoresResponseApi from "./jsonResponse/getCentreStoresResponseApi.json";
// import getCategoriesAndHolidaysApi from "./jsonResponse/getCategoriesAndHolidaysApi.json";
// import getArticleDataResponse from "./jsonResponse/getArticleDataResponse.json";
// import saveArticleResponse from "./jsonResponse/saveArticleResponse.json";
// import saveBulkArticleResponse from "./jsonResponse/saveBulkArticleResponse.json";

const RichTextEditor = Loadable({
  loader: () => import("../../../Base/RichTextEditor"),
  loading: () => null,
});

class FormArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "new",
      showModal: false,
      formError: {},
      centreStoreList: [],
      categories: [],
      holidays: [],
      formData: { ...formDataFields },
      showImageGallery: false,
      disableBtn: true,
      showSaveHint: false,
      showErrorMessage: false,
      formErrorMessage: "",
      imageGalleryHolder: [],
      articlePreviewUrl: "",
      articlePreviewTitle: "",
      articleList: [],
      getArticleDataReady: false,
      previewBulkLogo: "",
      articleIdList: [],
      retailerId: null
    };
  }

  componentDidMount() {
    const { itemId } = this.props;
    if (itemId) {
      this.getData(itemId);
    } else {
      this.getCentreStoreList();
    }
    this.getCategoriesAndHolidays();
    this.handleFormData();
  }

  getData = async (itemId) => {
    try {
      let response = await getArticleData({ itemId });
      if (response.status === 200 && response.data) {
        let articleData = setArticleData(response.data.Article);

        this.setState({
          mode: "edit",
          getArticleDataReady: true,
          formData: { ...this.state.formData, ...articleData },
          showImageGallery:
            articleData.imageGallery && articleData.imageGallery.length > 0
              ? true
              : false,
        });
      }
    } catch (e) {
      console.error("Get Article Data Error: " + e.response.statusText);
      this.setState({
        getArticleDataReady: true,
      });
      // if (itemId) {
      //     let articleData = setArticleData(getArticleDataResponse.Article);

      //     this.setState({
      //         mode: "edit",
      //         getArticleDataReady: true,
      //         formData: { ...this.state.formData, ...articleData },
      //         showImageGallery: (articleData.imageGallery && articleData.imageGallery.length > 0) ? true : false
      //     });
      // }
    }
  };

  getCategoriesAndHolidays = async () => {
    try {
      let response = await getCategoriesAndHolidays();
      if (response.status === 200 && response.data) {
        let categoryListData = response.data.Categories;
        let holidayListData = response.data.GlobalSpecialHoliday;
        let categoryList = [];
        let holidayList = [];
        if (categoryListData.length > 0) {
          categoryListData.forEach((el) =>
            categoryList.push({
              name: el.CategoryName.value,
              value: el.CategoryId.value,
            })
          );
        }
        if (holidayListData.length > 0) {
          holidayListData.forEach((el) =>
            holidayList.push({
              name: el.HolidayName.value,
              value: el.HolidayId.value,
            })
          );
        }
        this.setState({
          categories: categoryList,
          holidays: holidayList,
        });
      }
    } catch (e) {
      console.error(
        "Get Categories And Holidays Error: " + e.response.statusText
      );
      // let categoryListData = getCategoriesAndHolidaysApi.Categories;
      // let holidayListData = getCategoriesAndHolidaysApi.GlobalSpecialHoliday;
      // let categoryList = [];
      // let holidayList = [];
      // if (categoryListData.length > 0) {
      //     categoryListData.forEach((el) =>
      //         categoryList.push({
      //             name: el.CategoryName.value,
      //             value: el.CategoryId.value,
      //         })
      //     );
      // }
      // if (holidayListData.length > 0) {
      //     holidayListData.forEach((el) =>
      //         holidayList.push({
      //             name: el.HolidayName.value,
      //             value: el.HolidayId.value,
      //         })
      //     );
      // }
      // this.setState({
      //     categories: categoryList,
      //     holidays: holidayList,
      // });
    }
  };

  getCentreStoreList = async () => {
    try {
      let response = await getCentreStores();
      if (response.status === 200 && response.data) {
        if (response.data.IsSuccess.value) {
          this.setState({
            centreStoreList: get(response.data, "CentreStoreList", []),
            retailerId: get(response.data, "Retailer.RetailerId.value", ""),
            getArticleDataReady: true,
          });
        }
      }
    } catch (e) {
      console.error("Get centre stores data error: " + e.response.statusText);
      this.setState({
        getArticleDataReady: true,
      });
      // this.setState({
      //   centreStoreList: get(getCentreStoresResponseApi, "CentreStoreList", []),
      //   retailerId: get(getCentreStoresResponseApi, "Retailer.RetailerId.value", ""),
      //   getArticleDataReady: true,
      // });
    }
  };

  handleListbox = (id, field, isCheckbox) => {
    const { formData } = this.state;
    let options;
    let selected = [];
    if (document) {
      if (isCheckbox) {
        options = document.querySelectorAll(`#${id} input:checked`);
      } else {
        options = document.querySelectorAll(`#${id} option:checked`);
      }
      if (options) {
        for (let i = 0; i < options.length; i++) {
          selected.push(options[i].value);
        }
      }
    }
    this.setState({
      formData: {
        ...formData,
        [field]: selected,
      },
    });
  };

  handleSelect = (isSelect, field, options) => {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        [field]: isSelect ? options.map((el) => el.value) : [],
      },
    });
  };

  handleStoreListCheckbox = (value, selectAllStores) => {
    const { formData, centreStoreList } = this.state;

    if (value === "0") {
      let allStores = [];
      centreStoreList.map((centre) => {
        centre.StoreList.map((store) => {
          allStores.push(store.StoreItemId.value);
        });
      });

      this.setState({
        formData: {
          ...formData,
          selectedStores: selectAllStores ? allStores : [],
        },
      });
    } else if (formData.selectedStores.indexOf(value) > -1) {
      this.setState({
        formData: {
          ...formData,
          selectedStores: formData.selectedStores.filter((el) => el !== value),
        },
      });
    } else {
      this.setState({
        formData: {
          ...formData,
          selectedStores: [...formData.selectedStores, value],
        },
      });
    }
  };

  handleFormUpdate = (value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...value,
      },
    });
  };

  validateForm = async () => {
    let formError = validate(
      this.state.formData,
      this.state.mode === "edit" ? ["selectedRetailer"] : []
    );
    let response;
    if (Object.entries(formError).length !== 0) {
      this.setState({
        formError,
        showErrorMessage: true,
        formErrorMessage: "Please fill out all mandatory fields marked below",
      });
      this.triggerBackToTop();
      response = {
        isSuccess: false,
      };
    } else {
      this.setState({
        formError,
      });
      response = await this.saveForm().then((result) => {
        if (result.isSuccess) {
          return { isSuccess: true };
        }
      });
    }
    return response;
  };

  saveForm = async () => {
    const { formData, mode, articleIdList } = this.state;
    var timezoneId = new Date().getTimezoneOffset();
    const url = "/api/sitecore/article/SaveArticle";
    let data = buildFormData({
      ...formData,
      timeZoneId: timezoneId,
    });

    this.setState(
      {
        showSaveHint: true,
        disableBtn: true,
      },
      this.handleFormData
    );

    try {
      let response = await axios({
        method: "POST",
        url,
        data,
        params: {
          ...(mode === "edit"
            ? { itemId: formData.itemId }
            : { itemId: articleIdList.toString() }),
        },
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });

      if (response) {
        if (response.data.IsSuccess.value && mode === "new") {
          const articleList = response.data.ArticleList;
          const articlesItem = articleList[0].Articles;
          const itemIds = this.extractItemIds(articleList);
          let previewBulkLogo;

          this.setGoogleAnalytics(articleList);

          if (response.data.RetailerLogo && response.data.RetailerLogo.value) {
            previewBulkLogo = response.data.RetailerLogo.value;
          }

          if (articleList.length === 1 && articlesItem.length === 1) {
            this.setState(
              {
                formData: {
                  ...formData,
                  itemIds,
                },
                showSaveHint: false,
                disableBtn: false,
                showErrorMessage: false,
                articlePreviewUrl: articlesItem[0].PreviewUrl.value,
                // articlePreviewTitle: articlesItem[0].Store.value,
                articlePreviewTitle: formData.articleName,
                articleList: articleList,
                previewBulkLogo: "",
                articleIdList: this.setArticleIdList(articleList),
              },
              this.handleFormData
            );
          } else {
            this.setState(
              {
                formData: {
                  ...formData,
                  itemIds,
                },
                showSaveHint: false,
                disableBtn: false,
                showErrorMessage: false,
                articlePreviewUrl: "",
                articlePreviewTitle: "",
                articleList: articleList,
                previewBulkLogo,
                articleIdList: this.setArticleIdList(articleList),
              },
              this.handleFormData
            );
          }

          return {
            isSuccess: true,
          };
        } else if (response.data.IsSuccess.value && mode === "edit") {
          const articleList = response.data.ArticleList[0];
          const article = articleList.Articles[0];
          const articlePreviewUrl = article.PreviewUrl.value;
          const articlePreviewTitle =
            article.Store.value + " " + articleList.Centre.value;
          this.setState(
            {
              formData: {
                ...formData,
                itemIds: [`${article.ItemId.value}`],
              },
              showErrorMessage: false,
              showSaveHint: false,
              disableBtn: false,
              articlePreviewUrl,
              articlePreviewTitle: formData.articleName,
            },
            this.handleFormData
          );

          return {
            isSuccess: true,
          };
        } else {
          this.setState(
            {
              showSaveHint: false,
              disableBtn: true,
              showErrorMessage: true,
              formErrorMessage:
                "Article Not Saved " + response.data.Message.value,
            },
            this.handleFormData
          );

          this.triggerBackToTop();
          return {
            isSuccess: false,
          };
        }
      }
    } catch (e) {
      console.error("Article Save Error: " + e.response.statusText);
      this.setState({
        showErrorMessage: true,
        formErrorMessage: "Article Save Error: " + e.response.statusText,
      });
      this.triggerBackToTop();
      return {
        isSuccess: false,
      };

      // Condition on mode === new
      // const articleList = saveBulkArticleResponse.ArticleList;
      // const articlesItem = articleList[0].Articles;
      // const itemIds = this.extractItemIds(articleList);
      // let previewBulkLogo;

      // if (saveBulkArticleResponse.RetailerLogo && saveBulkArticleResponse.RetailerLogo.value) {
      //     previewBulkLogo = saveBulkArticleResponse.RetailerLogo.value;
      // }
      // this.setState({
      //     formData: {
      //         ...formData,
      //         itemIds
      //     },
      //     showSaveHint: false,
      //     disableBtn: false,
      // },
      //     this.handleFormData
      // );

      // if (articleList.length === 1 && articlesItem.length === 1) {
      //     this.setState({
      //         formData: {
      //             ...formData,
      //             itemIds
      //         },
      //         showSaveHint: false,
      //         disableBtn: false,
      //         showErrorMessage: false,
      //         articlePreviewUrl: articlesItem[0].PreviewUrl.value,
      //         articlePreviewTitle: formData.articleName,
      //         articleList: articleList,
      //         previewBulkLogo: "",
      //         articleIdList: this.setArticleIdList(articleList)
      //     },
      //         this.handleFormData
      //     );
      // } else {
      //     this.setState({
      //         formData: {
      //             ...formData,
      //             itemIds
      //         },
      //         showSaveHint: false,
      //         disableBtn: false,
      //         showErrorMessage: false,
      //         articlePreviewUrl: "",
      //         articlePreviewTitle: "",
      //         articleList: articleList,
      //         previewBulkLogo,
      //         articleIdList: this.setArticleIdList(articleList)
      //     },
      //         this.handleFormData
      //     );
      // }

      // return {
      //     isSuccess: true
      // };
    }
  };

  submitForm = async () => {
    const {
      formData,
      mode,
      articlePreviewUrl,
      articlePreviewTitle,
    } = this.state;
    const url = "/api/sitecore/article/SubmitArticle";
    let data = buildFormData({
      itemIds: mode === "edit" ? [`${formData.itemId}`] : formData.itemIds,
    });

    try {
      let response = await axios({
        method: "POST",
        url,
        data,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });

      if (response) {
        if (response.data.IsSuccess.value) {
          this.setState({
            showErrorMessage: false,
          });
          return {
            isSuccess: true,
            articlePreviewUrl: mode === "edit" ? articlePreviewUrl : "",
            articlePreviewTitle: mode === "edit" ? articlePreviewTitle : "",
          };
        } else {
          this.setState({
            showErrorMessage: true,
            formErrorMessage:
              "Article Not Submitted " + response.data.Message.value,
          });
          return {
            isSuccess: false,
          };
        }
      }
    } catch (e) {
      console.error("Article Submit Error: " + e.response.statusText);
      this.setState({
        showErrorMessage: true,
        formErrorMessage: "Article Submit Error: " + e.response.statusText,
      });
      this.triggerBackToTop();
      return {
        isSuccess: false,
      };

      // return {
      //     isSuccess: true,
      //     articlePreviewUrl: mode === "edit" ? articlePreviewUrl : "",
      //     articlePreviewTitle: mode === "edit" ? articlePreviewTitle : "",
      // };
    }
  };

  triggerBackToTop = () => {
    let backToTop;
    if (document) {
      backToTop = document.querySelector(".form__modal--back-to-top-button");
      if (backToTop) {
        backToTop.click();
      }
    }
  };

  setGoogleAnalytics = (articleList) => {
    articleList.map((item) => {
      const { Centre, Articles } = item;
      Articles.map((article) => {
        console.log(Centre.value, article.Store.value);
        TagManager.dataLayer({
          dataLayer: {
            event: "createdArticles",
            articleCentre: Centre && Centre.value,
            articleRetailer: article && article.Store && article.Store.value,
          },
          dataLayerName: "dataLayer",
        });
      });
    });
  };

  setArticleIdList = (articleList) => {
    let articleIdList = [];

    if (articleList.length > 0) {
      articleList.map((item) => {
        const { Articles } = item;

        if (Articles.length > 0) {
          Articles.map((item) => {
            const { ItemId } = item;

            if (ItemId && ItemId.value) {
              articleIdList.push(ItemId.value);
            }
          });
        }
      });
    }

    return articleIdList;
  };

  extractItemIds = (centreList) => {
    let itemIds = [];
    centreList.map((center) => {
      center.Articles.map((article) => {
        itemIds.push(article.ItemId.value);
      });
    });
    return itemIds;
  };

  handlePreviewForm = () => {
    this.setState({
      showModal: true,
    });
  };

  renderModal = () => {
    const { articleList, showModal, previewBulkLogo } = this.state;

    const { articleName } = this.state.formData || {};
    return (
      <div className="article-modal">
        {articleList && articleList.length > 0 && (
          <PopupPreview
            previewTitle={"Preview"}
            previewLogo={previewBulkLogo}
            previewList={articleList}
            previewListChildName="Articles"
            previewFormLabel={articleName}
            btnLabel={"Submit"}
            btnOnClick={() => {
              let submitBtn = document.querySelector(".article-submit-btn");
              if (submitBtn) {
                submitBtn.click();
              }
            }}
            showPreviewModal={() => this.setState({ showModal: false })}
          />
        )}
      </div>
    );
  };

  handleFormData = () => {
    const {
      showSaveHint,
      disableBtn,
      articlePreviewUrl,
      articlePreviewTitle,
    } = this.state;
    this.props.handleFormData(
      showSaveHint,
      disableBtn,
      articlePreviewUrl,
      articlePreviewTitle,
      this.validateForm,
      this.handlePreviewForm,
      this.submitForm
    );
  };

  buildCentreStoreList = (centreStoreList) => {
    const { type, formData } = this.state;

    return (
      <div className="form__multiple-stores">
        <div className="form__multiple-stores--header">
          <label className="form__label">
            {`Publish this article for the multiple centres`}
          </label>
          <div className="form__multiple-stores--action">
            <div
              className="form__multiple-stores--action-btn"
              onClick={() => this.handleStoreListCheckbox("0", true)}
            >
              {"Select all"}
            </div>
            <div
              className="form__multiple-stores--action-btn"
              onClick={() => this.handleStoreListCheckbox("0", false)}
            >
              {"Deselect all"}
            </div>
          </div>
        </div>
        <div className="form__multiple-stores--content">
          {centreStoreList.map((centre, index) => {
            const { CentreName, CentreIcon, StoreList } = centre;
            return (
              <div key={`centreStoreList-container-${index}`} className="row">
                <div className="col col-12 col-md-6">
                  <div className="form__multiple-stores--logo">
                    {CentreIcon && CentreIcon.value ? (
                      <img
                        alt={`CentreIcon-${index}`}
                        src={logoPlaceholder}
                        data-src={CentreIcon.value}
                        className="lazyload"
                      />
                    ) : (
                      <div className="no-image" />
                    )}
                  </div>
                  <div className="form__multiple-stores--label">
                    {CentreName.value}
                  </div>
                </div>
                <div className="col col-12 col-md-6">
                  {StoreList.map((store, index) => {
                    const { StoreItemId, StoreName } = store;
                    return (
                      <FormCheckbox
                        key={`store-${StoreItemId.value}-${index}`}
                        value={StoreItemId.value}
                        label={StoreName.value}
                        checked={
                          formData.selectedStores.indexOf(StoreItemId.value) >
                          -1
                        }
                        onCheckboxChange={(value) =>
                          this.handleStoreListCheckbox(value)
                        }
                        plain
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const {
      itemId = null
    } = this.props
    const {
      formError,
      formData,
      categories,
      showModal,
      centreStoreList,
      holidays,
      showImageGallery,
      showErrorMessage,
      formErrorMessage,
      imageGalleryHolder,
      getArticleDataReady,
      articleIdList,
      retailerId
    } = this.state;
    return (
      <div className="article-form">
        {showModal && this.renderModal()}
        {showErrorMessage && <FormErrorMessage error={formErrorMessage} />}
        <div className="form__container">
          <FormInput
            title
            type="text"
            placeholder={`Article title`}
            label="Article Name"
            value={formData.articleName}
            required
            error={formError.articleName}
            onInputChange={(articleName) =>
              this.handleFormUpdate({ articleName })
            }
          />
        </div>
        <div className="form__container">
          <label className="form__header">{`Article duration`}</label>
          <div
            className={`form-group form__date--container
                        ${formError.publishDate || formError.endDate
                ? "has-error"
                : ""
              }
                    `}
          >
            <div className="form__date--wrapper">
              <div className="form__date--duration--wrapper">
                <FormDate
                  required
                  label="Publish from"
                  icon="calendar"
                  placeholderText="MM/DD/YYYY"
                  showTimeSelect
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="d MMM yyyy - h:mmaa"
                  value={formData.publishDate && new Date(formData.publishDate)}
                  error={formError.publishDate}
                  selected={
                    formData.publishDate && new Date(formData.publishDate)
                  }
                  onChange={(publishDate) =>
                    this.handleFormUpdate({ publishDate })
                  }
                />
                <div className="form__date--divider" />
                <FormDate
                  label="Publish until"
                  icon="calendar"
                  placeholderText="MM/DD/YYYY"
                  showTimeSelect
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  dateFormat="d MMM yyyy - h:mmaa"
                  value={formData.endDate && new Date(formData.endDate)}
                  selected={formData.endDate && new Date(formData.endDate)}
                  onChange={(endDate) => this.handleFormUpdate({ endDate })}
                />
              </div>
              <Checkbox
                checked={formData.hidePublishDate}
                label={"Hide publish date"}
                onCheckboxChange={(hidePublishDate) => {
                  // console.log(hidePublishDate)
                  // console.log(formData)
                  this.handleFormUpdate({ hidePublishDate })

                }}
              />
            </div>
          </div>
        </div>

        <div className="form__container">
          <label className="form__header">{`Article description`}</label>
          <FormInputMultiple
            label="Sub title"
            placeholder="Provide a short description"
            required
            value={formData.subheading}
            maxLength={120}
            error={formError.subheading}
            onInputChange={(subheading) =>
              this.handleFormUpdate({ subheading })
            }
          />
          {getArticleDataReady && (
            <RichTextEditor
              required
              width="max"
              label={"Description"}
              placeholder="Provide a description"
              initialValue={formData.body}
              error={formError.body}
              onInputChange={(body) => this.handleFormUpdate({ body })}
              retailerId={retailerId}
              itemId={itemId}
              promotionType={"Article"}
            />
          )}
        </div>

        {((categories && categories.length > 0) ||
          (holidays && holidays.length > 0)) && (
            <div className="form__container">
              <label className="form__header">{"Meta information"}</label>
              {categories && categories.length > 0 && (
                <div>
                  <div className="is-desktop">
                    <FormListbox
                      label="Category"
                      id="LB_Cat_Desktop"
                      required
                      error={formError.selectedCategories}
                      options={categories}
                      field="selectedCategories"
                      selected={formData.selectedCategories}
                      hint="Press [ctrl] + click to select multiple"
                      handleListbox={(id, field) => this.handleListbox(id, field)}
                      handleSelect={(isSelect, field, options) =>
                        this.handleSelect(isSelect, field, options)
                      }
                    />
                  </div>
                  <div className="is-tablet">
                    <FormListCheckbox
                      label="Category"
                      id="LB_Cat_Mobile"
                      required
                      error={formError.selectedCategories}
                      options={categories}
                      field="selectedCategories"
                      selected={formData.selectedCategories}
                      handleListbox={(id, field) => this.handleListbox(id, field, true)}
                      handleSelect={(isSelect, field, options) =>
                        this.handleSelect(isSelect, field, options)
                      }
                    />
                  </div>
                </div>

              )}
              {holidays && holidays.length > 0 && (
                <div>
                  <div className="is-desktop">
                    <FormListbox
                      label="Special promotions"
                      id="LB_SH_Desktop"
                      options={holidays}
                      field="selectedSpecialPromotion"
                      selected={formData.selectedSpecialPromotion}
                      hint="Press [ctrl] + click to select multiple"
                      handleListbox={(id, field) => this.handleListbox(id, field)}
                      handleSelect={(isSelect, field) =>
                        this.handleSelect(isSelect, field, holidays)
                      }
                    />
                  </div>
                  <div className="is-tablet">
                    <FormListCheckbox
                      label="Special promotions"
                      id="LB_SH_Mobile"
                      options={holidays}
                      field="selectedSpecialPromotion"
                      selected={formData.selectedSpecialPromotion}
                      handleListbox={(id, field) => this.handleListbox(id, field, true)}
                      handleSelect={(isSelect, field, options) =>
                        this.handleSelect(isSelect, field, options)
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          )}

        <div className={`form__container" ${formError.heroImage || formError.thumbnail || formError.heroImageFileType || formError.thumbnailFileType
          ? "has-error"
          : ""
          }`}>
          {getArticleDataReady && (
            <FormImageUpload
              required
              type="hero"
              label={"Hero image"}
              guidelines={
                "To keep the quality of our websites high, the hero banner image needs to be:"
              }
              guidelinesOne={"• At least 1920px wide"}
              guidelinesTwo={"• At least 1080px tall."}
              guidelinesThree={
                "• To avoid your image being cropped, use a ratio of 16:9"
              }
              error={formError.heroImage || formError.heroImageFileType || formError.heroImageFileSize}
              value={formData.heroImage}
              handleFileUpload={(heroImage) =>
                this.handleFormUpdate({ heroImage })
              }
            />
          )}
        </div>

        <div className="form__container">
          {getArticleDataReady && (
            <FormImageUpload
              label={"Thumbnail image"}
              guidelines={"The hero thumbnail image needs to be:"}
              guidelinesOne={"• At least 800px wide"}
              guidelinesTwo={"• At least 800px tall."}
              guidelinesThree={
                "• To avoid your image being cropped, use a ratio of 1:1"
              }
              error={formError.thumbnail || formError.thumbnailFileType || formError.thumbnailFileSize}
              value={formData.thumbnail}
              handleFileUpload={(thumbnail) =>
                this.handleFormUpdate({ thumbnail })
              }
            />
          )}
        </div>

        <div className="form__container">
          <label className="form__header">{"Image gallery"}</label>
          <Checkbox
            checked={showImageGallery}
            label={"Display an additional image gallery?"}
            onCheckboxChange={(showImageGallery) => {
              this.setState({
                showImageGallery,
              });
              if (showImageGallery) {
                this.handleFormUpdate({ imageGallery: imageGalleryHolder });
              } else {
                this.handleFormUpdate({ imageGallery: [] });
              }
            }}
          />
          {showImageGallery && (
            <FormImageGallery
              value={formData.imageGallery}
              error={formError.imageGalleryFileType || formError.imageGalleryFileSize}
              handleAttachments={(imageGallery) => {
                this.setState({
                  imageGalleryHolder: imageGallery,
                });
                this.handleFormUpdate({ imageGallery });
              }}
            />
          )}
        </div>

        {centreStoreList && centreStoreList.length > 0 && (
          <div
            className={`form__container ${articleIdList.length > 0 ? "is-hidden" : ""
              }`}
          >
            <label className="form__header">{`Article visibility`}</label>
            {this.buildCentreStoreList(centreStoreList)}
          </div>
        )}
      </div>
    );
  }
}

export default FormArticles;
