import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import ReactPaginate from "react-paginate";
import { getStoreInfo } from "../../../api/StoreAPI";
import PageHeader from "../../Base/PageHeader";
import ListItem from "../../Base/ListItem";
import NoResultMessage from "../../Base/NoResultMessage";
import FormStoreFront from "../Forms/FormStoreFront";
import { scrollToTop } from "../../../helper/ScrollHelper";

// import GetStoreInfo_Response from "./GetStoreInfo_Response.json";

class FeatureRetailerPortalStoreInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePageNum: 1,
      showPagination: false,
      showLoader: true,
      showSaveHint: false,
      enablePreview: false,
      onClickSave: null,
      onClickSubmit: null,
      modalTitle: null,
      title: null,
      content: null,
      previewTitle: null,
      previewUrl: null,
      storeName: null,
      centreName: null,
      isRetailer: false,
      paginationRange: 7
    };
  }

  componentDidMount() {
    const {
      isDashboard
    } = this.props
    if (window) {
      window.addEventListener("resize", this.handleResize);
    }
    if (document) {
      this.handleResize();
      if (!isDashboard) {
        let params = {
          pageNumber: 1
        };
        this.getData(params, 1);
      } else {
        this.setState({
          showLoader: false,
        });
      }
    }
  }

  handleResize = () => {
    if (window.outerWidth >= 768) {
      this.setState({
        paginationRange: 7
      })
    } else {
      this.setState({
        paginationRange: 3
      })
    }
  }
  getData = async (params, activePageNum) => {
    this.setState({
      showLoader: true
    });

    try {
      const response = await getStoreInfo(params);
      if (response) {
        this.props.dataUpdate(response.data, activePageNum);
        scrollToTop();
        this.setState({
          showPagination: true,
          showLoader: false
        });
      }
    } catch (e) {
      console.error("Get Store Info Data Error: " + e.response.statusText);
      // this.props.dataUpdate(GetStoreInfo_Response, activePageNum);
      // scrollToTop();
      // this.setState({
      //   showPagination: true,
      //   showLoader: false
      // });
    }
  };

  handleOnClickList = (element, formModalLabel, formModalBody, previewUrl, previewTitle, isRetailer) => {
    if (!(/btn|icon|btn__label/.test(element.className)) || (/js-btn-edit|icon-edit/.test(element.className))) {
      this.setState({
        title: formModalLabel,
        content: formModalBody,
        previewTitle,
        previewUrl,
        isRetailer
      })
      this.props.showFormModal({
        title: formModalLabel,
        content: formModalBody,
        previewTitle,
        previewUrl,
        isRetailer
      })
    }
  }

  handleFormUpdate = (showSaveHint, disableBtn, onClickSave, onClickSubmit, onClickPreview, otherFormModalProps) => {
    const {
      title,
      content,
      previewTitle,
      previewUrl,
      isRetailer
    } = this.state;

    this.props.showFormModal({
      title,
      content,
      previewTitle,
      previewUrl,
      isRetailer,
      showSaveHint,
      disableBtn,
      onClickSave,
      onClickSubmit,
      onClickPreview,
      ...otherFormModalProps
    })
  }

  renderDashboardItems = (data) => {
    const {
      dashboardStores
    } = data || {};
    let renderItemLayout = "";

    if (dashboardStores && dashboardStores.length > 0) {
      renderItemLayout =
        <div className="store-info__content">
          <div className="store-info__store-list">
            <div className="store-info__section--wrapper">
              {dashboardStores.map((item, index) => {
                const {
                  ItemId,
                  CentreName,
                  CentreIcon,
                  StoreInfoTitle,
                  PreviewUrl,
                } = item

                let previewModalTitle = "";
                let previewModalUrl = PreviewUrl.value;
                if (StoreInfoTitle && StoreInfoTitle.value && CentreName && CentreName.value) {
                  previewModalTitle = StoreInfoTitle.value + " " + CentreName.value;
                }

                let formModalLabel =
                  <span>
                    {"Manage stores"}
                    &nbsp;&nbsp;&nbsp;
                      {"»"}
                    &nbsp;&nbsp;&nbsp;
                      <Text field={StoreInfoTitle} />
                    &nbsp;
                      <Text field={CentreName} />
                  </span>

                let formModalProps = {
                  previewUrl: PreviewUrl.value,
                  storeInfoTitle: StoreInfoTitle.value,
                  successMessage: StoreInfoTitle.value + " " + CentreName.value + " store info updated",
                }

                let formModalBody =
                  <FormStoreFront
                    centreName={CentreName && CentreName.value ? CentreName.value : ""}
                    storeId={(ItemId && ItemId.value) ? ItemId.value : ""}
                    handleFormData={(showSaveHint, disableBtn, onClickSave, onClickSubmit, onClickPreview) => {
                      this.handleFormUpdate(
                        showSaveHint,
                        disableBtn,
                        onClickSave,
                        onClickSubmit,
                        onClickPreview,
                        formModalProps
                      );
                    }}
                  />
                return (

                  <ListItem
                    key={`store-list-${index}`}
                    logoSrc={(CentreIcon && CentreIcon.value) ? CentreIcon.value : ""}
                    label={StoreInfoTitle}
                    title={CentreName}
                    previewBtn={{ "label": "Preview" }}
                    previewBtnOnClick={() => {
                      this.props.showPreviewModal({
                        url: previewModalUrl,
                        title: previewModalTitle,
                      })
                    }}
                    listItemOnClick={(e) =>
                      this.handleOnClickList(e.target, formModalLabel, formModalBody, previewModalUrl, previewModalTitle)
                    }
                  />
                )
              })}
            </div>
          </div>
        </div>
    }

    return renderItemLayout;
  }

  renderItems = (data) => {
    const {
      StoreInfoList,
      GlobalList,
      IsCentreMarketing
    } = data || {};
    const {
      RetailerSectionTitle = null,
      RetailerSectionSubtext = null,
      StoresSectionTitle = null,
      StoresSectionSubtext = null,
    } = this.props.headerData || {};
    let renderItemLayout = "";

    if ((StoreInfoList && StoreInfoList.length > 0) || (GlobalList && GlobalList.length > 0)) {
      renderItemLayout =
        <div className="store-info__content">
          {(GlobalList && GlobalList.length > 0 &&
            (IsCentreMarketing && !IsCentreMarketing.value)) &&
            <div className="store-info__global-list">
              {RetailerSectionTitle &&
                RetailerSectionTitle.value &&
                <div className="store-info__section--header">
                  <Text field={RetailerSectionTitle} />
                </div>
              }
              {RetailerSectionSubtext &&
                RetailerSectionSubtext.value &&
                <div className="store-info__section--description">
                  <Text field={RetailerSectionTitle} />
                </div>
              }

              <div className="store-info__section--wrapper">
                {GlobalList.map((item, index) => {
                  const {
                    Name,
                    Description,
                    LogoImage,
                    PreviewUrlList
                  } = item

                  let previewModalTitle = "";
                  let previewModalUrl;

                  if (PreviewUrlList && PreviewUrlList.length > 0) {
                    const storeList = PreviewUrlList;
                    const storeItem = storeList[0].Storefronts;

                    if (storeList.length === 1 && storeItem.length === 1) {
                      previewModalUrl = storeItem[0].PreviewUrl.value;
                      previewModalTitle = storeItem[0].Store.value + " " + storeList[0].Centre.value;
                    } else {
                      previewModalUrl = PreviewUrlList
                    }
                  }


                  let imageSrc = "";

                  if (LogoImage && LogoImage.value && LogoImage.value.src) {
                    imageSrc = LogoImage.value.src;
                  }

                  let formModalLabel =
                    <span>
                      {"Manage stores"}
                      &nbsp;&nbsp;&nbsp;
                    {"»"}
                      &nbsp;&nbsp;&nbsp;
                    <Text field={Name} />
                    </span>

                  let formModalProps = {
                    successMessage: Name.value + " store info updated",
                  }

                  let formModalBody =
                    <FormStoreFront
                      centreName={""}
                      storeId={""}
                      previewList={PreviewUrlList}
                      handleFormData={(showSaveHint, disableBtn, onClickSave, onClickSubmit, onClickPreview) => {
                        this.handleFormUpdate(
                          showSaveHint,
                          disableBtn,
                          onClickSave,
                          onClickSubmit,
                          onClickPreview,
                          formModalProps,
                        );
                      }}
                    />
                  return (
                    <ListItem
                      key={`global-list-${index}`}
                      logoBgColor={"#fff"}
                      logoSrc={imageSrc}
                      label={{ value: "All centres" }}
                      title={Name}
                      description={Description}
                      listItemOnClick={(e) =>
                        this.handleOnClickList(e.target, formModalLabel, formModalBody, previewModalUrl, previewModalTitle, true)
                      }
                    />
                  )
                })}
              </div>
            </div>
          }
          {StoreInfoList &&
            StoreInfoList.length > 0 &&
            <div className="store-info__store-list">
              {StoresSectionTitle &&
                StoresSectionTitle.value &&
                <div className="store-info__section--header">
                  <Text field={StoresSectionTitle} />
                </div>
              }
              {StoresSectionSubtext &&
                StoresSectionSubtext.value &&
                <div className="store-info__section--description">
                  <Text field={StoresSectionSubtext} />
                </div>
              }
              <div className="store-info__section--wrapper">
                {StoreInfoList.map((item, index) => {
                  const {
                    ItemId,
                    CentreName,
                    CentreIcon,
                    Website,
                    ContactNumber,
                    PreviewUrl,
                    StoreInfoTitle,
                  } = item

                  const {
                    RetailerID,
                    Name
                  } = item.RetailerDetails || {};
                  const {
                    OpeningToday
                  } = item.OpeningHours || {};

                  let previewModalTitle = "";
                  let previewModalUrl = PreviewUrl.value;
                  if (StoreInfoTitle && StoreInfoTitle.value && CentreName && CentreName.value) {
                    previewModalTitle = StoreInfoTitle.value + " " + CentreName.value;
                  }
                  let descriptionValue =
                    <div>
                      {OpeningToday &&
                        OpeningToday.value &&
                        <span>
                          <Text field={OpeningToday} />
                          <span>
                            ,&nbsp;&nbsp;&nbsp;
                          </span>
                        </span>
                      }
                      {ContactNumber &&
                        ContactNumber.value &&
                        <span>
                          <Text field={ContactNumber} />
                          <span>
                            ,&nbsp;&nbsp;&nbsp;
                          </span>
                        </span>
                      }
                      {Website &&
                        Website.value &&
                        <span>
                          <Text field={Website} />
                          <span>
                            ,&nbsp;&nbsp;&nbsp;
                          </span>
                        </span>
                      }
                    </div>
                  let formModalLabel =
                    <span>
                      {"Manage stores"}
                      &nbsp;&nbsp;&nbsp;
                      {"»"}
                      &nbsp;&nbsp;&nbsp;
                      <Text field={Name} />
                      &nbsp;
                      <Text field={CentreName} />
                    </span>

                  let formModalProps = {
                    previewUrl: PreviewUrl.value,
                    storeInfoTitle: StoreInfoTitle.value,
                    successMessage: Name.value + " " + CentreName.value + " store info updated",
                  }

                  let formModalBody =
                    <FormStoreFront
                      centreName={CentreName && CentreName.value ? CentreName.value : ""}
                      storeId={(ItemId && ItemId.value) ? ItemId.value : ""}
                      handleFormData={(showSaveHint, disableBtn, onClickSave, onClickSubmit, onClickPreview) => {
                        this.handleFormUpdate(
                          showSaveHint,
                          disableBtn,
                          onClickSave,
                          onClickSubmit,
                          onClickPreview,
                          formModalProps
                        );
                      }}
                    />
                  return (

                    <ListItem
                      key={`store-list-${index}`}
                      logoSrc={(CentreIcon && CentreIcon.value) ? CentreIcon.value : ""}
                      label={StoreInfoTitle}
                      title={CentreName}
                      storeDescription={descriptionValue}
                      previewBtn={{ "label": "Preview" }}
                      previewBtnOnClick={() => {
                        this.props.showPreviewModal({
                          url: previewModalUrl,
                          title: previewModalTitle,
                        })
                      }}
                      listItemOnClick={(e) =>
                        this.handleOnClickList(e.target, formModalLabel, formModalBody, previewModalUrl, previewModalTitle)
                      }
                    />
                  )
                })}
              </div>
            </div>
          }
        </div>
    } else {
      renderItemLayout =
        <NoResultMessage
          label="No Result"
        />
    }

    return renderItemLayout;
  }

  renderStoreCount = (data) => {
    const {
      activePageNum
    } = this.state;

    let hasPagination = false;
    let storeCount = 0;
    let dataStoreLength = data.StoreInfoList.length;
    if (activePageNum > 1) {
      storeCount = ((activePageNum - 1) * 10) + dataStoreLength;
    } else {
      storeCount = dataStoreLength
    }
    if (data &&
      data.TotalPageNumber &&
      data.TotalPageNumber.value > 1) {
      hasPagination = true;
    }
    return (
      <div className={`list-count ${!hasPagination ? "no-pagination" : ""}`}>
        {"Showing "}
        {storeCount}
        {" of "}
        {data.TotalResults.value}
      </div>
    )
  }
  handlePaginationClick = data => {
    this.setState({
      activePageNum: data.selected + 1
    })
    let params = {
      pageNumber: data.selected + 1
    };
    this.getData(params, data.selected + 1);
  };

  renderPagination = () => {
    const { data } = this.props;
    const {
      paginationRange
    } = this.state;
    return (
      data &&
      data.TotalPageNumber &&
      data.TotalPageNumber.value > 1 && (
        <ReactPaginate
          previousLabel={
            <div>
              <i className="icon icon-chevron-outline-left" />
              <span>{"Previous"}</span>
            </div>
          }
          nextLabel={
            <div>
              <i className="icon icon-chevron-outline-right" />
              <span>{"Next"}</span>
            </div>
          }
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={data.TotalPageNumber.value}
          marginPagesDisplayed={0}
          pageRangeDisplayed={paginationRange}
          onPageChange={this.handlePaginationClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      )
    );
  };

  render() {
    const {
      Header = null
    } = this.props.headerData || {};
    const {
      data,
      isDashboard
    } = this.props;
    const {
      showPagination,
      showLoader
    } = this.state;
    return (
      <div className="store-info">
        <PageHeader
          title={isDashboard ? { value: "Store information" } : Header}
        />
        {showLoader && (
          <div className="loader-wrapper container">
            <div className="loader"></div>
          </div>
        )}
        {(!showLoader && !isDashboard) ?
          this.renderItems(data) :
          this.renderDashboardItems(data)
        }
        {(!isDashboard &&
          !showLoader &&
          data &&
          data.TotalResults &&
          data.TotalResults.value &&
          data.StoreInfoList &&
          data.StoreInfoList.length > 0) &&
          this.renderStoreCount(data)
        }
        {!isDashboard &&
          showPagination &&
          <div>
            {this.renderPagination()}
          </div>
        }
      </div>
    );
  }
}

export default FeatureRetailerPortalStoreInfo;
