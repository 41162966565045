import React, { Component } from "react";
import { getNotifications } from "../../../../../api/NotificationAPI";

class NavNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newNotif: null,
    };
  }

  componentDidMount() {
    this.getNewNotification();
  }

  getNewNotification = async () => {
    const response = await getNotifications();
    if (response && response.data && response.data.Notifications) {
      let newNotif = response.data.Notifications.filter(
        (item) => !item.MemoViewed.value
      ).length;
      if (newNotif !== 0) {
        this.setState({ newNotif });
      }
    }
  };

  render() {
    const { newNotif } = this.state;
    return (
      <div className="nav-notifications">
        <a href="/notifications">
          <div className="notif-icon">
            <i className="icon icon-notifications" />
            {newNotif && <div id="notif-count" className="notif-count" />}
          </div>
          <span>Correspondence</span>
        </a>
      </div>
    );
  }
}
export default NavNotification;
