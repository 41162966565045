import $ from "jquery";

export const disableBodyScroll = () => {
  if (($(document) && $(document).height()) > $(window).height()) {
    var scrollTop = $("html").scrollTop()
      ? $("html").scrollTop()
      : $("body").scrollTop();
    $("html")
      .addClass("noscroll")
      .css("top", -scrollTop);
  }
};

export const enableBodyScroll = () => {
  var scrollTop = parseInt($("html").css("top"));
  $("html").removeClass("noscroll");
  $("html,body").scrollTop(-scrollTop);
};

// export const disableBodyScrolling = () => {
//   if (document) {
//     let bodyWrapper = document.querySelector(".body-wrapper");
//     if (bodyWrapper) {
//       bodyWrapper.classList.add("disable-scroll");
//     }
//   }
// };

// export const enableBodyScrolling = () => {
//   if (document) {
//     let bodyWrapper = document.querySelector(".body-wrapper");
//     if (bodyWrapper) {
//       bodyWrapper.classList.remove("disable-scroll");
//     }
//   }
// };

export const scrollToTop = (isNotDesktop) => {
  if (document) {
    const ua = window.navigator.userAgent.toLowerCase();
    const bodyWrapper = document.querySelector(".body-wrapper");
    if (isNotDesktop) {
      if (window && window.outerWidth < 992) {
        if ((/msie|trident/.test(ua))) {
          bodyWrapper.scroll(0, 0);
        } else {
          bodyWrapper.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
      }
    } else {
      if ((/msie|trident/.test(ua))) {
        bodyWrapper.scroll(0, 0);
      } else {
        bodyWrapper.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }

  }
};